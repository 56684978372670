(function() {

Ember.ENV.DS_NO_WARN_ON_UNUSED_KEYS = true;

var SL = window.SL = Em.Application.create();
SL.apiPath = 'api';

var defaultPrameters = {
  privacySetting: 'enabled',
  minAge: 16,
  maxAge: 70,
  maxYearOfExperience: 50,
  maxSalary: 10000, //k per annun
  kpi: {},
  socialNetwork: {},
  vacancy: {
    workflow: 'consultant',
    clientCompanySelectable: true,
    salaryNegotiable: false
  },
  sentry: {
    rms: 'https://6b08467763c14e5588abb1f44e539280@sentry.hireall.cn/3'
  },
  isWechatBrowser: (navigator.userAgent.toLowerCase()
    .indexOf('micromessenger') !== -1),
  features: ['coin_center', 'search_cv', 'refresh_job']
};

SL.parameters = defaultPrameters;

SL.CustomParameters = Em.ObjectProxy.extend({
  content: SL.parameters,
  socialNetworkSetup: Em.computed.or('socialNetwork.weibo',
    'socialNetwork.linkedin', 'socialNetwork.wecom'),
  socialNetworkPublish: Em.computed.readOnly('socialNetworkSetup'),
  vacancyCaseFrom: Em.computed.readOnly('kpi.newVacancy'),
  vacancyWithResearcher: Em.computed.equal('vacancy.workflow',
    'consultantWithResearcher'),
  showKPI: Em.computed.or('kpi.newPerson', 'kpi.newVacancy',
    'kpi.clientMeeting', 'kpi.interview', 'kpi.clientInterview'),
  showChannelPerformance: Em.computed.or('socialNetwork.wechat',
    'socialNetwork.weibo', 'socialNetwork.linkedin'),
  siteLogo: function() {
    return this.get('theme.rmsLogo') || 'images/85ff48e8.logo-wtn.png';
  }.property('theme.rmsLogo'),
  hasFeature: function(key) {
    return (this.get('features') || []).indexOf(key) > -1;
  }
});

function normalizeExtra(spec) {
  if (!spec) return;
  Object.keys(spec).forEach(function(k) {
    var field = spec[k];
    Em.set(
      field, 'name', Em.I18n.locale === 'zh' ?
      field.c_name : field.e_name);
    if (field.choices) {
      field.choices.forEach(function(c) {
        Em.set(
          c, 'label', Em.I18n.locale === 'zh' ? c.c_name : c.e_name);
      });
      if (field.choices.length > 5 && !field.hasOwnProperty('searchable'))
        field.searchable = true;
    }
  });
}

SL.refreshParam = function() {
  return Em.$.getJSON('suntory.json', function(json) {
    Em.run(function() {
      normalizeExtra(json.cvExtra);
      normalizeExtra(json.vacancyExtra);
      // Override default settings
      SL.parameters = Em.$.extend(true, {}, defaultPrameters, json);
      if (json.features) SL.parameters.features = json.features;
    });
  });
};

Em.Application.initializer({
  name: 'parameter',
  initialize: function(container, app) {
    app.inject('controller', 'parameters', 'parameters:custom');
    app.inject('route', 'parameters', 'parameters:custom');
    SL.deferReadiness();
    SL.refreshParam().then(function() {
      SL.advanceReadiness();
    });
  }
});

Em.Application.instanceInitializer({
  name: 'parameter',
  initialize: function(app) {
    app.container.lookup('parameters:custom').set('content', SL.parameters);
  }
});

function trySetupIconLink(path, rel) {
  var themeIcon = Em.get(SL.parameters, 'theme.' + path);
  if (themeIcon) {
    var link = document.createElement('link');
    link.rel = rel;
    link.href = themeIcon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

Em.Application.instanceInitializer({
  name: 'theme',
  after: 'parameter',

  initialize: function(app) {
    document.title = Em.get(SL.parameters, 'theme.rmsTitle') ||
       Em.get(SL.parameters, 'theme.title') || 'StarLinks';
    trySetupIconLink('favIcon', 'icon');
    trySetupIconLink('appleTouchIcon', 'apple-touch-icon');
  }
});

function initializeWechatJSSDK() {
  var wxTitle, wxDesc, wxSingleTitle, wxLink, wxLogo;
  Em.$.getScript(
      '//res.wx.qq.com/open/js/jweixin-1.4.0.js').then(function() {
    wx.updateShare = function() {
      if (!wx.isReady) return;
      if (!wxTitle) {
        return wx.hideMenuItems({
          menuList: [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:share:qq',
            'menuItem:share:weiboApp'
          ]
        });
      }

      wx.showAllNonBaseMenuItem();

      var link = wxLink, imgUrl = wxLogo || 'images/fc77c037.logo-icon.png';
      if (imgUrl.charAt(0) !== '/')
        // Relative path, prepend pathname
        imgUrl = location.pathname.replace(/\/$/, '') + '/' + imgUrl;
      imgUrl = location.origin + imgUrl;

      wx.onMenuShareAppMessage({
        title: wxTitle,
        desc: wxDesc,
        link: link,
        imgUrl: imgUrl
      });
      wx.onMenuShareQQ({
        title: wxTitle,
        desc: wxDesc,
        link: link,
        imgUrl: imgUrl
      });
      wx.onMenuShareWeibo({
        title: wxTitle,
        desc: wxDesc,
        link: link,
        imgUrl: imgUrl
      });
      wx.onMenuShareTimeline({
        title: wxSingleTitle,
        link: link,
        imgUrl: imgUrl
      });

    };
    wx.setShare = function(title, desc, singleTitle, logo) {
      // Delay the update as location.href may not be updated yet
      setTimeout(function() {
        wxTitle = title;
        wxDesc = desc;
        wxSingleTitle = singleTitle;
        wxLink = location.href;
        wxLogo = logo;
        wx.updateShare();
      });
    };
    wx.ready(function() {
      wx.isReady = true;
      wx.updateShare();
    });

    // Authentication
    var url = location.href;
    if (url.indexOf('#') > -1)
      url = url.substr(0, url.lastIndexOf('#'));
    Em.$.get('api/wechat/signature?url=' + encodeURIComponent(url),
        function(s) {
      wx.config({
        appId: s.app_id,
        timestamp: s.timestamp,
        nonceStr: s.nonceStr,
        signature: s.signature,
        jsApiList: [
          'hideMenuItems',
          'showAllNonBaseMenuItem',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo'
        ]
      });
    });
  });
}

Em.Application.initializer({
  name: 'wechat',
  initialize: function(container, app) {
    if (defaultPrameters.isWechatBrowser)
      initializeWechatJSSDK();
    else {
      window.wx = {};
      wx.setShare = function() {};
    }
  }
});

Em.Application.initializer({
  name: 'view-manager',
  after: 'simple-auth',

  initialize: function(container, app) {
    app.register('view-manager:main', Em.Object.extend({
      routing: Em.inject.service('-routing'),
      showMenu: function() {
        var route = this.get('routing.currentRouteName');
        if ([
          'register', 'resume', 'staff-access', 'reset-password',
          'change-password'
        ].indexOf(route) > -1)
          return false;
        return this.get('session.isAuthenticated');
      }.property('routing.currentRouteName', 'session.isAuthenticated')
    }));
    app.inject('view-manager:main', 'session', 'session:custom');
    app.inject('controller', 'viewMgr', 'view-manager:main');
  }
});

function trackPageview() {
  posthog.capture('$pageview');
}

SL.Router.reopen({
  handleURL: function() {
    if (window.location.hash)
      this.set('visitingURL', window.location.href);
    return this._super.apply(this, arguments);
  },
  willTransition: function(_, __, trans) {
    var self = this;
    trans.catch(function(e) {
      if (e.name !== 'TransitionAborted')
        // Transition error, visiting url may be invalid
        self.set('visitingURL', null);
    });
    return this._super.apply(this, arguments);
  },
  didTransition: function() {
    Em.run.next(this, trackPageview);
    return this._super.apply(this, arguments);
  }
});

/* Order and include as you please. */


})();

(function() {

/* global Raven, Messenger */

SL.bumpRsvpError = function(cb) {
  return function(error) {
    if (typeof cb === 'function') cb();
    Em.onerror(error);
  };
};

function dumpXhrError(xhr) {
  return 'AjaxError [' + xhr.status + ']' +
    (xhr.responseText ? ' ' + xhr.responseText : '');
}

SL.onXhrError = function(xhr) {
  Em.run.next(Em.onerror, xhr);
};


Em.$(document).ajaxError(function(event, jqxhr) {
  // Keep throwed error with jqxhr
  jqxhr.errorThrown = dumpXhrError(jqxhr);
});

if (SL.parameters.sentry.rms) {
  Raven.config(SL.parameters.sentry.rms, {
    ignoreErrors: [
      'did not match any routes',
      'UnrecognizedURLError',
      'AjaxError [0]',
      'AjaxError [401]',
      'AjaxError [502]',
      'AjaxError [504]',
      'Adapter operation failed [0]',
      'Adapter operation failed [401]',
      'Adapter operation failed [502]',
      'Adapter operation failed [504]'
    ]
  }).install();

  // Also log XHR data with raven breadcrumbs
  if ('XMLHttpRequest' in window) {
    var origSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function(params) {
      origSend.apply(this, arguments);
      if (!params) return;
      for (var prop in this)
        // Find the property raven used to store breadcrumb
        if (this.hasOwnProperty(prop) &&
            this[prop] instanceof Object && this[prop].url) {
          this[prop].data = params;
          break;
        }
    };
  }
}

// Patch raven to catpure jqXHR as error
var _oldOnError = Em.onerror;
Em.onerror = function(e) {
  var error = e, networkErrorStatus;
  if (e.errorThrown) {
    // Handling jqXHR
    error = e.errorThrown;
    networkErrorStatus = e.status;
    if (typeof error === 'string')
      error = new Error(error);
    error.__reason_with_error_thrown__ = e;
  } else if (e instanceof DS.AdapterError) {
    networkErrorStatus = e.errors && e.errors[0].status;
  }
  switch (parseInt(networkErrorStatus)) {
    case 0:
    case 502:
    case 504:
      /* falls through */
      new Messenger({
        theme: 'flat',
        extraClasses: 'messenger-fixed messenger-on-bottom'
      }).post({
        message: Em.I18n.t('common.connection_error'),
        type: 'error',
        showCloseButton: true,
        id: 'connection_error'
      });
      break;
    case 401:
      // session expired, handled by Ember SimpleAuth ajaxError
      break;
    default:
      if (_oldOnError) {
        _oldOnError.call(this, error);
      } else {
        Em.Logger.error(error.stack);
      }
  }
};

SL.Router.reopen({
  setupRouter: function() {
    if (!this._super.apply(this, arguments))
      return false;
    var router = this.router;
    router.transitionByIntent = (function(_super) {
      return function() {
        var transiton = _super.apply(this, arguments),
            promise = transiton.promise;
        if (promise._label === 'Router: Settle transition promise when ' +
            'transition is finalized') {
          transiton.promise = promise.catch(function() {
            // Add catch to prevent this specific promise is logging unexpected
            // TransitionAborted error with Raven.
          });
        }
        return transiton;
      };
    })(router.transitionByIntent);
    return true;
  }
});


})();

(function() {

Em.I18n.pluralForm = (function(_super) {
  return function(count, language) {
    if (count === 0)
      return 'zero';
    return _super(count, language);
  };
})(Em.I18n.pluralForm);

moment.locale = (function(_super) {
  return function(locale) {
    if (locale === 'zh')
      locale = 'zh-cn';
    return _super(locale);
  };
})(moment.locale);

// For usemin to replace with revved version
var TRANSLATIONS = {
  zh: 'locales/d317217a.zh.json',
  en: 'locales/bb8dcdf3.en.json'
};

SL.fetchLocale = function(locale) {
  return Em.$.getJSON(TRANSLATIONS[locale]);
};

Em.Application.initializer({
  name: 'locale',
  initialize: function(container, app) {
    SL.deferReadiness();

    function setLocale(locale) {
      Em.set(Em.I18n, 'locale', locale);
      SL.fetchLocale(locale).then(function(json) {
        Em.run(function() {
          Em.I18n.translations = json;
          moment.locale(locale);
          SL.advanceReadiness();
        });
      });
    }

    var locale = Modernizr.localstorage &&
      localStorage.getItem('preferred_locale');
    if (locale) return setLocale(locale);

    Em.$.get(SL.apiPath + '/preferred_languages').then(function(languages) {
      var locale = languages.split(',')[0].split('-')[0];
      if (locale !== 'zh') locale = 'en';
      setLocale(locale);
    });
  }
});

function initTranslation(target, prop) {
  var isTranslatedAttribute = /(.+)Translation$/;
  function translate(k) {
    var tk = Em.get(this, (prop ? prop + '.' : '') + k + 'Translation');
    return tk === null ? null : Em.I18n.t(tk);
  }
  for (var key in this) {
    var isTranslatedAttributeMatch = key.match(isTranslatedAttribute);
    if (isTranslatedAttributeMatch) {
      var sKey = isTranslatedAttributeMatch[1];
            Em.defineProperty(target || this, sKey,
        Em.computed(prop ? prop + '.' + key : key, 'i18n.locale', translate));
    }
  }
}

SL.TranslatablePropertyMixin = Em.Mixin.create({
  init: function() {
    this._super();
    this.set('i18n', Em.I18n);
    initTranslation.call(this);
    if (this.hasOwnProperty('attrs'))
      initTranslation.call(this.attrs, this, 'attrs');
  }
});


})();

(function() {

SL.computed = {};

SL.computed.yearValue = function(dateKey, monthKey) {
  var yearKey = '_' + dateKey + 'Year';
  return Em.computed(dateKey, yearKey, {
    get: function() {
      var d = this.get(dateKey);
      if (d) this.set(yearKey, undefined);
      return d ? d.getFullYear() : this.get(yearKey);
    },
    set: function(dummy, value) {
      var d = this.get(dateKey);
      if (d) this.set(yearKey, undefined);
      if (!d) {
        var month = this.get(monthKey);
        if (!month)
          this.set(yearKey, value);
        else
          this.set(dateKey, new Date(value, month, 1));
      } else {
        d.setFullYear(value);
        this.set(dateKey, new Date(+d));
      }
      return value;
    }
  });
};

SL.computed.monthValue = function(dateKey, yearKey) {
  var monthKey = '_' + dateKey + 'Month';
  return Em.computed(dateKey, monthKey, {
    get: function() {
      var d = this.get(dateKey);
      if (d) this.set(monthKey, undefined);
      return d ? d.getMonth() : this.get(monthKey);
    },
    set: function(dummy, value) {
      var d = this.get(dateKey);
      if (d) this.set(monthKey, undefined);
      if (!d) {
        var year = this.get(yearKey);
        if (!year)
          this.set(monthKey, value);
        else
          this.set(dateKey, new Date(year, value, 1));
      } else {
        d.setMonth(value);
        this.set(dateKey, new Date(+d));
      }
      return value;
    }
  });
};

SL.computed.eql = function(keyA, keyB) {
  return function() {
    /* jslint eqeq: true */
    return this.get(keyA) == this.get(keyB);
  }.property(keyA, keyB).readOnly();
};

SL.computed.toNow = function(date) {
  return Em.computed(date, {
    get: function(key) {
      return +(this.get(date)) === +(new Date(3000, 0, 1));
    },
    set: function(key, value) {
      if (value !== this.get(key))
        this.set(date, value ? new Date(3000, 0, 1) : undefined);
      return value;
    }
  });
};

SL.computed.any = function() {
  var i, len = arguments.length, properties = [len + 1];
  for (i = 0; i < len; i++) {
    properties[i] = arguments[i];
  }

  properties[len] = function() {
    for (i = 0; i < len; i++) {
      var key = properties[i];
      if (this.get(key)) return this.get(key);
    }
    return null;
  };

  return Em.computed.apply(this, properties).readOnly();
};

SL.computed.salary = function(salary) {
  return Em.computed(salary, {
    get: function(key) {
      var s = this.get(salary);
      if (!s) return s;
      var unit = s.unit || 'kPerYear',
          value = Em.typeOf(s) === 'object' ? s.amount : s;
      if (Em.isEmpty(value)) return;
      if (unit === 'kPerYear' && Em.I18n.locale === 'zh') {
        return value * 1000 / 10000;
      }
      return value;
    },
    set: function(key, value) {
      var s = this.get(salary), unit = s && s.unit || 'kPerYear',
          amount = value;
      if (!Em.isEmpty(amount)) {
        amount = parseFloat(amount);
        if (unit === 'kPerYear') {
          // Default senario, set amount only
          if (Em.I18n.locale === 'zh')
            amount = value * 10;
        }
      }
      if (unit === 'kPerYear')
        this.set(salary, amount);
      else
        this.set(salary, { amount: amount, unit: unit });
      return value;
    }
  });
};

SL.computed.salaryUnit = function(unit) {
  var salaryKeys = Array.prototype.slice.call(arguments, 1);
  
  var args = [unit];
  args.push.apply(args, salaryKeys);
  args.push({
    set: function(dummy, value) {
      salaryKeys.forEach(function(key) {
        var s = this.get(key);
        this.set(key, {
          amount: Em.typeOf(s) === 'object' ? s && s.amount : s,
          unit: value
        });
      }, this);
      this.set(unit, value);
      return value;
    },
    get: function() {
      return this.get(salaryKeys[0] + '.unit') || this.get(unit) || 'kPerYear';
    }
  });
  return Em.computed.apply(this, args);
};


})();

(function() {

SL.AndHelper = Em.Helper.helper(function(params) {
  for (var i = 0, len = params.length; i < len; i++) {
    if (!params[i]) {
      return params[i];
    }
  }
  return params[params.length - 1];
});

SL.OrHelper = Em.Helper.helper(function(params) {
  for (var i = 0, len = params.length; i < len; i++) {
    if (params[i] && !Em.isEmpty(params[i])) {
      return params[i];
    }
  }
  return params[params.length - 1];
});

SL.EqHelper = Em.Helper.helper(function(params) {
    return params[0] === params[1];
});

SL.JoinHelper = Em.Helper.extend({
  compute: function(params) {
    if (Em.isNone(params[0])) params[0] = Em.A([]);
        this.setupRecompute(params[0], params[1]);
    return params[0].mapBy(params[1]).
      join(typeof params[2] === 'string' ? params[2] : ' / ');
  },
  destory: function() {
    if (this.teardown) this.teardown();
    this._super.apply(this, arguments);
  },
  setupRecompute: function(records, prop) {
    this.set('_array', records);
    if (this.teardown) this.teardown();

    var propPath = '_array.@each.' + prop;
    this.addObserver(propPath, this, this.recompute);
    this.teardown = function() {
      this.removeObserver(propPath, this, this.recompute);
    };
  }
});

SL.EvenHelper = Em.Helper.helper(function(params) {
  return params[0] % 2 === 0;
});

SL.ArrayContainsHelper = Em.Helper.helper(function(params) {
    return params[0] && params[0].indexOf(params[1]) > -1;
});

SL.FormatChangeHelper = Em.Helper.helper(function(params) {
  var add = params[0], del = params[1];
  return new Em.Handlebars.SafeString(SL.fmtChange(add, del));
});


})();

(function() {

SL.helper = {};

var formatDate = SL.helper.formatDate = function(date, type) {
  var d = moment(date);
  if (!d.isValid()) return 'N/A';
  switch (type) {
    case 'common':
      return d.format('L');
    case 'fromNow': {
      return new Em.Handlebars.SafeString(
        '<span title="' + d.format('L HH:mm:ss') + '">' + d.fromNow() +
        '</span>');
    }
    case 'duration':
      if (d.year() <= 1000)
        return 'N/A';
      else if (d.year() >= 3000)
        return Em.I18n.t('common.label.now');
      return d.format('YYYY/MM');
    case 'comments':
      if (d.isBefore(moment(), 'year'))
        return d.format('L HH:mm:ss');
      return formatDate(d, 'fromNow');
    case 'transaction':
      return d.format('L HH:mm');
    default:
      return d.format('L HH:mm:ss');
  }
};

Em.Handlebars.helper('formatDate', function(date, options) {
  return formatDate(date, options.hash.dateType);
});

SL.helper.formatDateRange = function(duration) {
  if (!duration) return '';
  var start = Em.get(duration, 'startDate'),
      end = Em.get(duration, 'endDate'),
      endDate = moment(end),
      toNow = endDate.isValid() && endDate.year() >= 3000;

  start = formatDate(start, 'duration');
  end = formatDate(end, 'duration');

  if (start === 'N/A') {
    if (end !== 'N/A') {
      if (toNow)
        return end;
      return Em.I18n.t('common.label.date_end', { endDate: end });
    }
  } else if (end === 'N/A') {
    if (start !== 'N/A')
      return Em.I18n.t('common.label.date_start', { startDate: start });
  }
  if (start === end)
     return start;
  return start + ' - ' + end;
};

Em.Handlebars.helper(
    'formatDateRange', SL.helper.formatDateRange, 'startDate', 'endDate');

Em.Handlebars.helper('listItems', function(value) {
  var i = '<ul class="list-items">';
  //var array = [];
  if (value && value.trim() !== '') {
    value.split(/[\n]/).forEach(function(item) {
      if (item.trim() !== '')
        //array.push(item.trim());
        i += '<li>' + item + '</li>';
    });
    return new Em.Handlebars.SafeString(i + '</ul>');
  }else {
    return '';
  }
});

SL.helper.peopleName = function(p, plain) {
  var name;
  if (!p)
    name = '';
  else if (p.get('isMasked'))
    name = '******';
  else {
    var eName = p.get('eName'), cName = p.get('cName');
    if (!eName || !cName)
      name = eName || cName || Em.I18n.t('common.no_name');
    else if (Em.I18n.locale === 'zh')
      name = cName + ' | ' + eName;
    else
      name = eName + ' | ' + cName;
  }
  if (plain) return name;
  var startWorkYear = p && p.get('startWorkYear');
  if (startWorkYear >= moment().year())
    name += ' <i class="fa fa-graduation-cap"></i>';
  return new Em.Handlebars.SafeString(name);
};
Em.Handlebars.helper('peopleName', function(people, opts) {
  return SL.helper.peopleName(people, opts.hash.plain);
}, 'eName', 'cName');

SL.helper.peopleJob = function(people) {
  if (!people) return '';
  var ret = [];
  if (people.get('currentEducation')) {
    var school = people.get('currentEducation.school.name'),
        major = people.get('currentEducation.major.name'),
        degree = people.get('currentEducation.degreeId');
    degree = degree && SL.helper.degree(degree);
    if (major && degree)
      ret.push(Em.I18n.t(
        'people.profile.major_degree', { major: major, degree: degree }));
    else
      ret.push(major || degree || null);
    ret.push(school);
  } else {
    ret.push(people.get('currentWork.title.name'));
    ret.push(people.get('currentWork.company.name'));
  }
  ret = ret.compact();
  return ret && ret.join(' @ ') || Em.I18n.t('people.profile.unemployed');
};
Em.Handlebars.helper('peopleJob', SL.helper.peopleJob, 'currentWork',
  'currentWork.title.name', 'currentWork.company.name',
  'currentEducation.degreeId', 'currentEducation.school.name',
  'currentEducation.major.name');

SL.helper.peopleAge = function(birthDate) {
  return moment().diff(moment(birthDate), 'year');
};
Em.Handlebars.helper('peopleAge', SL.helper.peopleAge);

function workLength(startWorkYear) {
  if (!startWorkYear) return;
  var l = moment().year() - startWorkYear;
  if (l < -1) return Em.I18n.t('people.profile.student');
  if (l <= 0) return Em.I18n.t('people.profile.graduates');
  return Em.I18n.t('people.profile.year_of_experiences', { count: l });
}
Em.Handlebars.helper('workLength', workLength);

var maritalStatus = ['unknown', 'single', 'married_no_child',
  'married_with_child', 'divorced', 'widow'];

SL.helper.maritalStatus = function(value) {
  if (maritalStatus[value])
    return Em.I18n.t('people.profile.marital_status.' +
      maritalStatus[value]);
};
Em.Handlebars.helper('maritalStatus', SL.helper.maritalStatus);

var vacancyStatus = ['active', 'pending', 'failed', 'placed', 'cancelled'];
SL.helper.vacancyStatus = function(value) {
  //for HR edition
  if (value === 2 && !SL.parameters.vacancy.clientCompanySelectable)
    value = 4;
  if (vacancyStatus[value])
    return Em.I18n.t('vacancy.profile.status_value.' +
      vacancyStatus[value]);
};
Em.Handlebars.helper('vacancyStatus', SL.helper.vacancyStatus);

var gender = ['male', 'female', 'unknown'];
SL.helper.gender = function(value) {
  if (gender[value])
    return Em.I18n.t('people.profile.gender_value.' + gender[value]);
};
Em.Handlebars.helper('gender', SL.helper.gender);

var motivation = [];
motivation[0] = 'no_intention';
motivation[1] = 'unknown';
motivation[10] = 'unemployed';
motivation[6] = 'look_for_job';
motivation[4] = 'open_talk';
SL.helper.motivationLevel = function(value) {
  if (motivation[value])
    return Em.I18n.t('people.profile.motivation_value.' + motivation[value]);
};
Em.Handlebars.helper('motivation', SL.helper.motivationLevel);

var employmentType = ['unknown', 'permanent', 'casual', 'intern'];
SL.helper.employmentType = function(value) {
  if (employmentType[value])
    return Em.I18n.t('people.profile.employment_type_value.' +
      employmentType[value]);
};
Em.Handlebars.helper('employmentType', SL.helper.employmentType);

Em.Handlebars.helper('languageCapacity', function(value) {
  var languageCapacity = ['Average', 'Fluent/Professional working language',
    'Native or bilingal language'];
  return languageCapacity[value];
});

var degree = ['unknown', 'bachelor', 'master', 'phd', 'diploma',
    'vocational_school', 'mba', 'highschool'];
SL.helper.degree = function(value) {
  if (degree[value])
    return Em.I18n.t('people.profile.degree_value.' + degree[value]);
};
Em.Handlebars.helper('degree', SL.helper.degree);

var sizes = ['unknown', '1_10', '11_50', '51_100', '101_500', '501_1000',
    '1001_5000', '5001_10000', 'above_10001'];
SL.helper.companySize = function(size) {
  if (sizes[size])
    return Em.I18n.t('company.create_edit.company_size_value.' + sizes[size]);
};
Em.Handlebars.helper('companySize', SL.helper.companySize);

SL.helper.companyLegalEntity = function(le) {
  var legalEntities = ['Unknown', 'Global Head Quarter', 'AP Head Quarter',
    'China Head Quarter', 'Sales', 'R&D', 'Plant', 'Sourcing',
    'Customer Service', 'Distribution', 'Operation'];
  return legalEntities[le];
};
Em.Handlebars.helper('companyLegalEntity', SL.helper.companyLegalEntity);

SL.helper.nationalFlag = function(nation) {
  return new Em.Handlebars.SafeString('<span class="flag-icon flag-icon-' +
    nation.toLowerCase() + '"></span>');
};
Em.Handlebars.helper('nationalFlag', SL.helper.nationalFlag);

var types = ['unknown', 'wofe', 'jv', 'soe', 'private', 'listed'];
SL.helper.companyType = function(type) {
  if (types[type])
    return Em.I18n.t('company.create_edit.company_type_value.' + types[type]);
};
Em.Handlebars.helper('companyType', SL.helper.companyType);

SL.helper.companyName = function(company) {
  if (!company) return '';
  var eName = company.get('eName');
  var cName = company.get('cName');
  if (!eName) return cName;
  if (!cName) return eName;
  if (Em.I18n.locale === 'zh') return cName + ' (' + eName + ')';
  return eName + ' (' + cName + ')';
};
Em.Handlebars.helper('companyName', SL.helper.companyName, 'eName', 'cName');

Em.Handlebars.helper('companySummary', function(company) {
  if (!company) return;
  var loc = company.get('location.fullName');
  loc = loc && '<i class="fa fa-map-marker"></i> ' + loc;
  return new Em.Handlebars.SafeString([
    loc, company.get('industry.fullName')
  ].compact().join(' · '));
}, 'location.fullName', 'industry.fullName');

Em.Handlebars.helper('companyTypeSize', function(company) {
  if (!company) return;
  var tp = company.get('companyType'), sz = company.get('companySize');
  tp = tp && SL.helper.companyType(tp);
  sz = sz && SL.helper.companySize(sz);
  if (tp && sz)
    return tp + '(' + sz + ')';
  return tp || sz;
}, 'companyType', 'companySize');

SL.helper.phoneNumber = function(tn1, tn2, tn3) {
  return (tn1 || '86') + (tn2 ? ('-' + tn2) : '') + (tn3 ? ('-' + tn3) : '');
};
Em.Handlebars.helper('phoneNumber', SL.helper.phoneNumber);

Em.Handlebars.helper('salaryRange', function(from, to) {
  function range(from, to) {
    if (!from && !to) return '';
    if (!from) return to;
    if (!to) return from;
    return from + ' - ' + to;
  }
  return range(from, to);
});

/*
 Vacancy Candidate status:

 0    - Screened
 1    - Reported by Vendor
 2    - Interview 1
 3    - Interview 2
 4    - Interview 3
 5    - Interview 4
 6    - Interview 5
 7    - Interview 6
 8    - Interview 7
 9    - Interview 8
 10   - Offered
 11   - Placed

 Status stored are only the ones listed above
*/

var vpStatusMapping = {
  0: 'screened',
  1: 'reported',
  2: 'i_1',
  3: 'i_2',
  4: 'i_3',
  5: 'i_4',
  6: 'i_5',
  7: 'i_6',
  8: 'i_7',
  9: 'i_8',
  10: 'offered',
  11: 'onboarded',
  12: 'pass_probation',
  interview: 'interview',
  null: 'not_set',
  out: 'out'
};

SL.helper.vacancySearchStatus = function(statusCode) {
  var isOut;
  if (statusCode instanceof SL.VacancyCandidate) {
    isOut = statusCode.get('out');
    statusCode = statusCode.get('status');
    if (statusCode === null)
      statusCode = undefined;
  }
  if (vpStatusMapping[statusCode] || isOut)
    return Em.I18n.t('vacancy.search_list.status.' + (isOut ? 'out' :
      vpStatusMapping[statusCode]));
};
Em.Handlebars.helper('vacancySearchStatus',
  SL.helper.vacancySearchStatus, 'out', 'status');

SL.helper.vacancyChannel = function(channel) {
  channel = channel && channel.camelize();
  return Em.I18n.t('vacancy.search_list.channel.' + channel);
};

Em.Handlebars.helper('vacancyChannel',
  SL.helper.vacancyChannel, 'sourcingChannel');

Em.Handlebars.helper('vcSource', function(vc) {
  var method = vc.get('sourcingChannel');
  method = method && method.camelize();
  if (method === 'externalReferral') {
    return Em.I18n.t('vacancy.search_list.channel.referred_by', {
      channel: vc.get('channel.name')
    });
  } else if (method) {
    return Em.I18n.t('vacancy.search_list.channel.applied_from', {
      method: Em.I18n.t('vacancy.search_list.channel.' + method),
      channel: vc.get('channel.isDefault') ?
        Em.I18n.t('vacancy.search_list.channel.owned_site') :
        vc.get('channel.name')
    });
  } else {
    if (vc.get('channel.isDefault'))
      return Em.I18n.t('vacancy.search_list.channel.tp_cv');
    return Em.I18n.t('vacancy.search_list.channel.tp_cv_with_channel', {
      channel: vc.get('channel.name')
    });
  }
}, 'sourcingChannel', 'channel.name', 'channel.isDefault');

var vpChannelIcons = {
  all: 'i i-flow-tree i-fw',
  talentPool: 'i i-user3 i-fw',
  socialMedia: 'i i-share i-fw',
  website: 'fa fa-link fa-fw',
  wechat: 'fa fa-wechat fa-fw',
  weibo: 'fa fa-weibo fa-fw',
  linkedin: 'fa fa-linkedin-square fa-fw',
  mail: 'fa fa-envelope fa-fw',
  externalReferral: 'i i-users3 i-fw',
  others: 'fa fa-link fa-fw'
};

SL.helper.vacancyChannelIcon = function(channel) {
  if (channel instanceof SL.VacancyCandidate) {
    channel = channel.get('sourcingChannel');
  }
  channel = channel && channel.camelize() || 'talentPool';
  return vpChannelIcons[channel];
};
Em.Handlebars.helper('vacancyChannelIcon',
  SL.helper.vacancyChannelIcon, 'sourcingChannel');

SL.helper.displayFilename = function(people, options) {
  if (!people) return '';
  var file = options.hash.file,
      eName = people.get('eName'),
      cName = people.get('cName'),
      id = file.get('id'),
      filename = file.get('filename'),
      fileType = file.get('fileType'),
      ext = '', nameParts = [];
  if (id && id.indexOf(':') > -1)
    id = id.split(':')[1];
  if (fileType)
    nameParts.push(fileType.toUpperCase());
  if (eName)
    nameParts.push(eName.replace(' ', '_'));
  else if (cName)
    nameParts.push(cName);

  if (filename) {
    var f = filename.split('.');
    if (f.length > 1)
      ext = '.' + f[f.length - 1];
  }

  nameParts.push(id + ext);
  return nameParts.join('_');
};
Em.Handlebars.helper('displayFilename', SL.helper.displayFilename,
  'eName', 'cName');

SL.helper.requiredExperience = function(exp) {
  return Em.I18n.t('vacancy.required_experience.' + exp);
};
Em.Handlebars.helper('requiredExperience', SL.helper.requiredExperience);

SL.helper.jobType = function(type) {
  return Em.I18n.t('vacancy.job_type.' + type);
};
Em.Handlebars.helper('jobType', SL.helper.jobType);

Em.Handlebars.helper('splitAction', function(split) {
  var transKey = split.get('standardAction');
  if (transKey) {
    transKey = 'ledger.actions.' + transKey;
    return Em.I18n.exists(transKey) ? Em.I18n.t(transKey) : Em.I18n.t(
      'ledger.actions.other');
  }
  return split.get('customAction');
});

Em.Handlebars.helper('splitAmount', function(split) {
  var amount = split.get('amount'),
      styleClass = amount < 0 ? 'text-danger' : 'text-success';
  // amount is given as debit so negtive value is acturally positive
  var sign = amount < 0 ? '+' : '-';
  return new Em.Handlebars.SafeString(
    '<span class="' + styleClass + '">' + sign +
     split.get('absAmount') + '</span>');
});

SL.helper.noLaterThan = function(dt, unit, count) {
  return dt && moment().diff(dt, unit) < count;
};

function currency(amount, currency) {
  switch (currency.toLowerCase()) {
  case 'cny':
    return new Em.Handlebars.SafeString('&yen;' + amount);
  default:
    return amount;
  }
}

Em.Handlebars.helper('currency', function(amount, options) {
  return currency(amount, options.hash.currency);
});

SL.helper.salaryValue = function(salary) {
  if (Em.isEmpty(salary)) return salary;
  var amount = Em.typeOf(salary) === 'object' ? salary.amount : salary,
      unit = salary && salary.unit || 'kPerYear';
  if (unit.indexOf('k') === 0)
    return amount * 1000;
  return amount;
};

SL.helper.salaryLabel = function(unit, from, to) {

  
  to = to || 0;
  if (unit === 'kPerYear') {
    // Make accuracy up to k for yearly salary
    from = Math.round(from);
    to = Math.round(to);
    if (Em.I18n.locale === 'zh') {
      from = Math.round(from) / 10;
      to = Math.round(to) / 10;
    }
  } else if (unit === 'kPerMonth') {
    // Make accuracy up to 0.1k for monthly salary
    from = Math.round(from * 10) / 10;
    to = Math.round(to * 10) / 10;
  }

  var valueStr = to && from !== to ? from + '-' + to : from;
  return Em.I18n.t('common.salary_unit.' + unit, { salary: valueStr });
};

Em.Handlebars.helper('salaryLabel', function(from, to) {
  if (Em.isEmpty(from) && to)
    from = to;
  return SL.helper.salaryLabel(from.unit, from.amount, to.amount);
});

SL.helper.mappingInfo = function(entry, opts) {
  if (!entry) return;
  var desc =  [];
  var title = entry.get('title.name'), company = entry.get('company.name');
  if (title || company) {
    if (title && company)
       desc.addObject(title + ' @ ' + company);
    else
       desc.addObject(title || company);
  }
  if (entry.get('associate.id')) {
    var name = entry.get('associate.name');
    if (opts && !opts.hash.plain)
      name += '<a href="#/real-person/' + entry.get('associate.id') +
        '"><i class="fa fa-fw fa-external-link"></i></a>';
    desc.addObject(Em.I18n.t(
      'list.mapping.associate_with.' + entry.get('associateType'),
      { associate: name }));
  }
  desc = desc.join(', ');
  var remarks = entry.get('description') || '';
  if (remarks)
    remarks = (desc && '<br>') + '<i>' + remarks + '<i>';
  return new Em.Handlebars.SafeString(desc + remarks);
};
Em.Handlebars.helper('mappingInfo', SL.helper.mappingInfo, 'associate.name',
  'associateType', 'title.name', 'company.name', 'description');

function dumpNames(o) {
  if (!Em.isArray(o)) o = [o];
  return o.map(function(i) {
    return i.name;
  }).compact().join(', ');
}

var personChange;
function dumpNested(deleted, added) {
  deleted = deleted || [];
  added = added || [];
  var changes = {};
  deleted.concat(added).forEach(function(c) {
    if (!changes.hasOwnProperty(c.id)) changes[c.id] = Em.Object.create();
    changes[c.id].set(added.indexOf(c) > -1 ? 'added' : 'deleted', c);
  });
  return Object.keys(changes).map(function(k) {
    return '[' + personChange(changes[k]) + ']';
  }).join(',');
}

function dumpSalary(s) {
  if (Em.I18n.locale === 'zh')
    s = s * 1000 / 10000;
  return Em.I18n.t('common.salary_unit.kPerYear', { salary: s });
}

var fieldValue = SL.helper.fieldValue = function(field) {
  var value = field.get('value');
  switch (field.get('type')) {
    case 'single':
      var c = field.get('choices').findBy('value', value);
      return c && (Em.I18n.locale === 'zh' ? c.c_name : c.e_name);
    case 'multiple': {
      return field.get('choices').filter(function(c) {
        return Em.isArray(value) && value.indexOf(Em.get(c, 'value')) > -1;
      }).map(function(c) {
        return Em.I18n.locale === 'zh' ? c.c_name : c.e_name;
      }).join(', ') || '';
    }
    default:
      return value;
  }
};

function fieldColor(field) {
  var value = field.get('value'), tagColor = field.get('tag_color');
  switch (field.get('type')) {
    case 'single':
      var c = field.get('choices').findBy('value', value);
      return c && c.tag_color || tagColor;
    default:
      return tagColor;
  }
}

Em.Handlebars.helper('fieldValue', SL.helper.fieldValue);

function dumpExtra(old, new_) {
  old = JSON.parse(old || '{}');
  new_ = JSON.parse(new_ || '{}');
  return '[' + Object.keys(old).concat(Object.keys(new_)).uniq().map(
      function(k) {
    if (JSON.stringify(old[k]) === JSON.stringify(new_[k])) return;
    var field = SL.parameters.cvExtra[k];
    var label = field && Em.I18n.locale === 'zh' ?
      field.c_name : field.e_name || k;
    old[k] = fieldValue(Em.ObjectProxy.create({
      content: field,
      value: old[k]
    }));
    new_[k] = fieldValue(Em.ObjectProxy.create({
      content: field,
      value: new_[k]
    }));

    return '<b>' + label + '</b>: ' +
       (old[k] && '<span class="text-l-t">' + old[k] + '</span>' || '') +
       (new_[k] || '');
  }).compact().join(', ') + ']';
}

function vacancyTag(extra) {
  var fields = SL.parameters.vacancyExtra || {};
  var html = Object.keys(extra).filter(function(k) {
    return k.startsWith('_s_t_') || k.startsWith('_t_');
  }).map(function(k) {
    var field = Em.get(fields, k);
    if (!field) return;
    field = Em.ObjectProxy.create({
      content: field,
      value: extra[k]
    });
    var value = fieldValue(field);
    if (!value) return;
    return '<span class="badge m-t-sm m-l-sm" style="background-color:' +
      fieldColor(field) + '">' + value + '</span>';
  }).compact().join(' ');
  return new Em.Handlebars.SafeString(html);
}
Em.Handlebars.helper('vacancyTag', vacancyTag);

var personChanges = {
  e_name: {
    translation: 'people.create_edit.e_name'
  },
  c_name: {
    translation: 'people.create_edit.c_name'
  },
  emails: {
    translation: 'people.create_edit.p_email',
    stringify: dumpNames
  },
  mobiles: {
    translation: 'people.create_edit.mobile',
    stringify: dumpNames
  },
  target_companies: {
    translation: 'people.create_edit.target_companies',
    stringify: dumpNames,
    loadMore: true
  },
  gender: {
    translation: 'people.create_edit.gender',
    stringify: SL.helper.gender
  },
  marital_status: {
    translation: 'people.create_edit.marital_status',
    stringify: SL.helper.maritalStatus
  },
  location_id: {
    translation: 'people.create_edit.location',
    stringify: dumpNames,
    loadMore: true
  },
  address: {
    translation: 'people.create_edit.address'
  },
  birth_date: {
    translation: 'people.create_edit.birth_date',
    stringify: function(d) { return formatDate(d, 'common'); }
  },
  functions: {
    translation: 'people.create_edit.p_functions',
    stringify: dumpNames,
    loadMore: true
  },
  prefer_locations: {
    translation: 'people.create_edit.p_locations',
    stringify: dumpNames,
    loadMore: true
  },
  prefer_industries: {
    translation: 'people.create_edit.p_industries',
    stringify: dumpNames,
    loadMore: true
  },
  skills: {
    translation: 'people.create_edit.skills',
    stringify: dumpNames,
    loadMore: true
  },
  languages: {
    translation: 'people.create_edit.languages',
    stringify: dumpNames,
    loadMore: true
  },
  certificates: {
    translation: 'people.create_edit.certificates',
    stringify: dumpNames,
    loadMore: true
  },
  employment_status: {
    translation: 'people.create_edit.motivation',
    stringify: SL.helper.motivationLevel
  },
  start_work_year: {
    translation: 'people.create_edit.start_year'
  },
  career_summary: {
    translation: 'people.create_edit.career_summary'
  },
  intrest: {
    translation: 'people.create_edit.intrest'
  },
  employment_type: {
    translation: 'people.create_edit.employment_type',
    stringify: SL.helper.employmentType
  },
  expected_salary: {
    translation: 'people.create_edit.expected_salary',
    stringify: dumpSalary
  },
  work_experiences: {
    translation: 'people.create_edit.work_experience',
    nested: true
  },
  start_date: {
    translation: 'people.create_edit.start_date',
    stringify: function(d) { return formatDate(d, 'duration'); }
  },
  end_date: {
    translation: 'people.create_edit.end_date',
    stringify: function(d) { return formatDate(d, 'duration'); }
  },
  gl: {
    translation: 'people.create_edit.extension'
  },
  companyEmail: {
    translation: 'people.create_edit.c_email'
  },
  title_id: {
    translation: 'people.create_edit.title',
    stringify: dumpNames,
    loadMore: true
  },
  responsibility: {
    translation: 'people.create_edit.responsibility'
  },
  achievement: {
    translation: 'people.create_edit.achievement'
  },
  leave_reason: {
    translation: 'people.create_edit.leave_reason'
  },
  function_id: {
    translation: 'people.create_edit.function',
    stringify: dumpNames,
    loadMore: true
  },
  company_id: {
    translation: 'people.create_edit.company',
    stringify: dumpNames,
    loadMore: true
  },
  total_salary: {
    translation: 'people.create_edit.salary',
    stringify: dumpSalary
  },
  salary_detail: {
    translation: 'people.create_edit.salary_detail'
  },
  report_to_id: {
    translation: 'people.create_edit.report_to',
    stringify: dumpNames,
    loadMore: true
  },
  company_email: {
     translation: 'people.create_edit.c_email'
  },
  education_experiences: {
    translation: 'people.create_edit.education',
    nested: true
  },
  major_id: {
    translation: 'people.create_edit.major',
    stringify: dumpNames,
    loadMore: true
  },
  degree_id: {
    translation: 'people.create_edit.degree',
    stringify: SL.helper.degree
  },
  school_id: {
    translation: 'people.create_edit.school',
    stringify: dumpNames,
    loadMore: true
  },
  full_time: {
    translation: 'people.create_edit.full_time'
  },
  project_experiences: {
    translation: 'people.create_edit.projects',
    nested: true
  },
  name: {
    translation: 'people.create_edit.project_name'
  },
  description: {
    translation: 'people.create_edit.description'
  },
  extra: {
    translation: 'people.create_edit.extra'
  },
  files: {
    translation: 'people.create_edit.attachment',
    stringify: function(files) {
      return files.map(function(f) {
        if (f.file_type && f.file_type !== 'photo')
          return f.file_type.toUpperCase() + '_' + f.id;
      }).compact().join(', ');
    }
  },
  photo_id: {
    translation: 'people.history.photo',
    stringify: function() {
      return Em.I18n.t('people.history.avatar');
    }
  }
};

SL.fmtChange = function(add, del, loadMore) {
  if (add || del) {
    if (del) del = '<span class="text-l-t">' + del + '</span>';
    return (del || '') + (add || '');
  }
  if (loadMore)
    return '<a href="#" class="more"><i class="i i-ellipsis"></i></a>';
};

personChange = SL.helper.personChange = function(c) {
  var rv = [], added = c.getWithDefault('added', {}),
      deleted = c.getWithDefault('deleted', {});
  Object.keys(added).concat(Object.keys(deleted)).uniq().forEach(function(k) {
    if (!personChanges.hasOwnProperty(k))
      return;
    var change = personChanges[k];
    var item = ['<b>' + Em.I18n.t(change.translation) + '</b>'];
    if (k === 'extra') {
      item[1] = dumpExtra(deleted[k], added[k]);
    } else if (change.nested) {
      item[1] = dumpNested(deleted[k], added[k]);
    } else {
      var stringify = (change.stringify || function(v) { return v; });
      var del = Em.isPresent(deleted[k]) && stringify(deleted[k]),
          add = Em.isPresent(added[k]) && stringify(added[k]);
      item[1] = SL.fmtChange(add, del, change.loadMore);
    }
    if (item[1])
      rv.addObject(item.join(': '));
  });
  return new Em.Handlebars.SafeString(rv.join(', '));
};
Em.Handlebars.helper('personChange', SL.helper.personChange);

SL.peopleCommentTypes = [
  [
    { value: 'phone_record',
      textTranslation: 'people.comments.type.phone_record',
      icon: 'fa fa-fw fa-phone' },
    { value: 'bd_call', textTranslation: 'people.comments.type.bd_call',
      icon: 'i i-fw i-phone-office' },
    { value: 'client_follow',
      textTranslation: 'people.comments.type.client_follow',
      icon: 'fa fa-fw fa-user-plus' }
  ],
  { value: 'interview_note',
    textTranslation: 'people.comments.type.interview_notes',
    icon: 'fa fa-fw fa-comment' },
  { value: 'meeting_minutes',
    textTranslation: 'people.comments.type.meeting_minutes',
    icon: 'fa fa-fw fa-coffee' },
  { value: 'info', textTranslation: 'people.comments.type.info',
    icon: 'i i-fw i-info2' }
];

SL.filterCommentType = function(typeMap, category) {
  for (var i = 0; i < typeMap.length; i++) {
    var t = typeMap.objectAt(i);
    if (Em.isArray(t)) {
      var val = t.findBy('value', category);
      if (val) return val;
    } else if (Em.get(t, 'value') === category) {
      return t;
    }
  }
};

Em.Handlebars.helper('commentType', function(category) {
  var t = SL.filterCommentType(SL.peopleCommentTypes, category);
  return t && Em.I18n.t(t.textTranslation);
});

Em.Handlebars.helper('commentIcon', function(category) {
  var t = SL.filterCommentType(SL.peopleCommentTypes, category);
  return t && t.icon;
});

SL.extraFunction = function(extra, funcId) {
  var spec = Em.get(SL.parameters, 'cvExtra') || {}, labels = [];
  funcId = parseInt(funcId);
  Object.keys(spec).forEach(function(k) {
    if (!extra[k]) return;
    var f = spec[k];
    var forFunctions = f.for_functions;
    if (!forFunctions || forFunctions.indexOf(funcId) < 0) return;
    labels.push(f.name + ': ' + fieldValue(
        Em.ObjectProxy.create({
      content: f,
      value: extra[k]
    })));
  });
  return labels;
};

Em.Handlebars.helper('extraFunc', function(extra, funcId) {
  var labels = SL.extraFunction(extra, funcId);
  if (labels.length)
    return '(' + labels.join('; ') + ')';
});

Em.Handlebars.helper('kpiMissReason', function(kpi) {
  if (!kpi || kpi.get('status') || !Em.isPresent(kpi.get('missReason'))) return;
  return Em.I18n.t('reports.kpi.missing') +
      kpi.get('missReason').map(function(r) {
    return Em.I18n.t('reports.kpi.miss_reason.' + r);
  }).join(', ');
});

SL.validProfileName = function(name) {
  return ![
        '先生', '女士', '小姐', 'Mr ', 'Mrs ',
        'Mr.', 'Mrs.', '未知', 'Unknown'
      ].find(function(key) {
    return name && name.indexOf(key) > -1;
  });
};


})();

(function() {

SL.Alerts = Em.Object.extend({
  valueInFocus: false,
  errors: Em.computed.readOnly('validation.errors'),

  init: function() {
    this.pending = Em.Object.create({
      unknownProperty: function(property) {
        this.set(property, {});
        return this.get(property);
      }
    });
  },

  unknownProperty: function(property) {
    function setInFocus() {
      // Observes property and disable alert when there is value in focus
      if (!this.get('valueInFocus')) return;
      this.set('pending.' + property + '.inFocus', 'focused');
      this.get('errors').removeObserver(property, this, setInFocus);
    }
    Em.defineProperty(this, property,
      Em.computed('errors.' + property, 'pending.' + property + '.inFocus',
        'pending.' + property + '.remoteCall', function() {
      var p = this.get('pending.' + property);
      if (!p.inFocus || (p.remoteCall === false))
        return this.get('errors.' + property);
    }));
    this.addObserver('valueInFocus', this, function() {
      Em.run.begin();
      var p = this.get('pending');
      if (this.get('valueInFocus')) {
        if (p[property].inFocus !== 'focused')
          this.get('errors').addObserver(property, this, setInFocus);
        if (p[property].remoteCall === false) {
          p.set(property + '.remoteCall', undefined);
        }
      } else {
        if (p[property].inFocus === 'focused') {
          p.set(property + '.inFocus', undefined);
        } else {
          this.get('errors').removeObserver(property, this, setInFocus);
        }
      }
      Em.run.end();
    });
    return this.get(property);
  }
});

Em.Validations.Mixin.reopen({
  init: function() {
    this._super();
    this.alerts = SL.Alerts.create({ validation: this });
  },
  toggleAlerts: function(action) {
    function setPending(v) {
      for (var k in this.get('validations')) {
        var path = 'alerts.pending.' + k + '.inFocus';
        if (v !== 'volatile' || this.get(path) !== 'focused')
          this.set(path, v);
      }
    }
    switch (action) {
      case 'focusIn':
      case 'focusOut':
        this.set('alerts.valueInFocus', (action === 'focusIn'));
        break;
      case 'purge':
        this.set('alerts.valueInFocus', false);
        setPending.call(this, undefined);
        break;
      case 'volatile':
        setPending.call(this, action);
        break;
      default:
        throw new Error('Unhandled action "' + action + '"');
    }
    // toggleAlerts recursively
    this.get('validators').forEach(function(v) {
      if (Em.$.isFunction(v.toggleAlerts))
        v.toggleAlerts(action);
    });
  },
  enforceRemoteValidation: function() {
    this.toggleAlerts('focusIn');
    Em.run.next(this, function() {
      this.toggleAlerts('focusOut');
    });
  }

});

SL.ValidationMixin = Em.Mixin.create({
  validationAction: 'toggleAlerts',
  enableAlerts: function() {
    if (this.$().hasClass('validatable'))
      this.sendAction('validationAction', 'focusOut');
  },
  disableAlerts: function() {
    if (this.$().hasClass('validatable'))
      this.sendAction('validationAction', 'focusIn');
  }
});

SL.LazyValidationMixin = Em.Mixin.create({
  validationAction: 'toggleAlerts',
  focusIn: function() {
    if (this.$().hasClass('validatable'))
      this.sendAction('validationAction', 'focusIn');
    return this._super.apply(this, arguments);
  },
  focusOut: function() {
    if (this.$().hasClass('validatable'))
      this.sendAction('validationAction', 'focusOut');
    return this._super.apply(this, arguments);
  },
  _elementValueDidChange: function(e) {
    var value = this.readDOMAttr('value');
    if (value !== '')
      return this._super.apply(this, arguments);
    if (!Em.isEmpty(this.get('value')))
      // Bypass empty value setting to prevent value change. And force the
      // value to be null for non-null value
      this.set('value', null);
  }
});

Em.TextField.reopen(SL.LazyValidationMixin, SL.TranslatablePropertyMixin);
Em.TextArea.reopen(SL.LazyValidationMixin, SL.TranslatablePropertyMixin);

Em.Validations.validators.remote.Unique =
  Em.Validations.validators.local.Format.extend({

  _validate: function() {
    var self = this,
    result = this._super();

    return result.then(function(success) {
      if (!success || !self.get(self.property) ||
          !self.get('alerts.valueInFocus') || !self.canValidate())
        return result;
      if (!self.uniquePromise)
        self.uniquePromise = self.createUniquePromise();
      return self.uniquePromise;
    });
  },

  createUniquePromise: function() {
    var self = this;

    return new Em.RSVP.Promise(function(resolve) {
      var alerts = self.model.alerts,
          pending = alerts.pending;

      function checkUnique(value) {
        var hash = {
          url: SL.apiPath + '/unique',
          data: {
            type: self.options.remote.path,
            name: value,
            id: self.get(self.options.remote.idKey || 'id')
          },
          complete: function() {
            Em.run(function() {
              self.uniquePromise = undefined;
              pending.set(self.property + '.remoteCall', false);
            });
          }
        };
        hash.success = function(data) {
          Em.run(function() {
            if (data.unique === 'format_error') {
              self.errors.pushObject(
                Em.I18n.t(self.options.messageTranslation));
            } else if (data.unique !== true) {
              var options = self.options.remote;
              if (options.messageTranslation)
                self.errors.pushObject(new Em.Handlebars.SafeString(Em.I18n.t(
                  options.messageTranslation, { conflict: data.unique })));
              else
                self.errors.pushObject(options.message);
            }
            resolve(data.unique);
          });
        };
        hash.error = function(error) {
          Em.run(function() {
            self.errors.pushObject('Error communicating with the server');
            resolve(false);
          });
        };
        Em.run(function() {
          pending.set(self.property + '.remoteCall', true);
        });
        Em.$.ajax(hash);
      }
      function observeFocus() {
        if (alerts.valueInFocus) return;
        alerts.removeObserver('valueInFocus', null, observeFocus);
        var value = self.get(self.property);
        if (self.canValidate() && value)
          checkUnique(value);
        else {
          resolve(true);
          self.uniquePromise = undefined;
        }
      }
      if (alerts.valueInFocus)
        alerts.addObserver('valueInFocus', null, observeFocus);
      else
        observeFocus();
    });
  }
});

SL.validations = {};

Ember.Validations.validators.Base.reopen({
  init: function() {
    var translation = this.get('options.messageTranslation');
    if (translation) {
      this.set('options.message',  Em.I18n.t(translation));
    }
    translation = this.get('options.messagesTranslation');
    if (translation) {
            this.set('options.messages', {});
      for (var key in translation) {
        this.set('options.messages.' + key, Em.I18n.t(translation[key]));
      }
    }
    this._super();
  }
});



})();

(function() {

function initializeBootstrapConfirmation(comp) {
  comp.$().confirmation({
    placement: comp.get('placement'),
    title: comp.get('title'),
    href: function() {
      return void(0);
    },
    target:  '_self',
    singleton: true,
    popout: true,
    btnOkClass: 'btn btn-sm btn-primary',
    btnOkLabel: Em.I18n.t('common.component.confirmation.delete_button'),
    btnCancelLabel: Em.I18n.t('common.component.confirmation.cancel_button'),
    onConfirm: function() {
      Em.run(function() {
        comp.sendAction('action', comp.get('param'));
      });
    },
    container: comp.get('anchor')
  });
}

SL.BootstrapConfirmationComponent = Em.Component.extend(
  SL.TranslatablePropertyMixin, {
  tagName: 'span',
  classNames: ['confirmation'],

  didInsertElement: function() {
    initializeBootstrapConfirmation(this);
  }
});


})();

(function() {

function initializeBootstrapPopover(comp) {
  var htmlContent = Em.$(comp.get('content'));
  if (htmlContent)
    htmlContent.addClass('hide');
  else
    htmlContent = comp.get('content');
  //console.log(comp.get('content'));
  comp.$().popover({
    animation: comp.get('animation'),
    placement: comp.get('placement'),
    html: true,
    content: htmlContent,
    title: comp.get('title'),

  }).on('show.bs.popover', function() {
    if (Em.$(comp.get('content')))
      htmlContent.removeClass('hide');
  });
}

SL.BootstrapPopoverComponent = Em.Component.extend({
  tagName: 'div',
  className: '',
  placement: 'top', //default placement
  animation: true,
  //trigger: 'click', //default trigger |hover
  didInsertElement: function() {
    //console.log(this);
    initializeBootstrapPopover(this);
  },
  willDestroyElement: function() {
    this.$().popover('destroy');
  }

});


})();

(function() {

function initializeBootstrapSelect(comp, items) {

  function makeOption($root, items) {
        items.forEach(function(i) {
      if (i.items) {
                var $og = Em.$('<optgroup label="' + i.groupLabel + '"></optgroup>');
        makeOption($og, i.items);
        $root.append($og);
      } else {
        var v = i.label;
        if (i.value && Em.get(i.value, 'id'))
          v = Em.get(i.value, 'id');
        var $opt = Em.$(
          '<option value="' + v + '"><a href="#">' + i.label +
          '</a></option>');
        $opt.data('value', i.value);
        $root.append($opt);
      }
    });
  }
  var $comp = comp.$();

  // DOM element no longer exists
  if (!$comp) return;

  if (items)
    makeOption($comp, items);

  // TODO: wierd that Chrome / Safari has default value set as unexpected,
  // so we clear the selection
  $comp.val([]);

  if (comp.get('dropup'))
    $comp.addClass('dropup');

  comp.selectpicker = $comp.selectpicker({
    noneSelectedText: Em.I18n.t('common.component.nothing_select'),
    noneResultsText: Em.I18n.t('common.component.no_match'),
    liveSearch: comp.get('search'),
    size: ($comp.find('option').size() > 6) ? 6 : 'auto',
    width: 'auto',
    dropupAuto: !comp.get('dropup')
  });

  var $select = $comp.next('.bootstrap-select');
  var classes = $comp.attr('class'),
      $btn = $select.find('button.btn');
  $btn.removeClass('btn-default');
  $btn.attr('name', comp.get('name'));
  Em.$.each(classes.split(/\s+/), function(i, c) {
    if (c.indexOf('btn-') === 0) {
      $comp.removeClass(c);
      $select.removeClass(c);
      $btn.addClass(c);
    }
  });
  $btn.click(function() {
    comp[$btn.parent().hasClass('open') ? 'enableAlerts' : 'disableAlerts']();
  });
  $btn.next().click(function() {
    Em.run.scheduleOnce('afterRender', comp, 'enableAlerts');
  });

  // Slim Scroll
  $select.find('.dropdown-menu.inner').
    slimScroll({ height: 'auto', wheelStep: 5 });
  $select.find('.slimScrollDiv').addClass('inner');
  if (comp.get('search')) {
    var $search = $select.find('input');
    $search.on('input propertychange', function() {
      // Re-calculate the height of slimscroll
      $select.find('.dropdown-menu.inner').
        slimScroll({ height: 'auto', wheelStep: 5 });
    });
  }

  if (comp.get('unselect')) {
    Em.$(
      '<a href="#" class="text-u-l m-l-sm unselect">' +
      Em.I18n.t('common.component.unselect') + '</a>'
    ).insertAfter(
      $select
    ).click(function(e) {
      e.preventDefault();
      Em.run(comp, 'set', 'value', null);
    });
  }

  comp.valueToWidget();
}

Em.$(function() {
  // As Hierarchy is broken by slim-scroll, disable keydown event for menus
  Em.$(document).off('keydown', '.bootstrap-select [data-toggle=dropdown], ' +
    '.bootstrap-select [role="menu"], .bs-searchbox input');
  Em.$(document).on('keydown', '.bs-searchbox input',
    Em.$.fn.selectpicker.Constructor.prototype.keydown);
});

SL.SelectListComponent = Em.Component.extend(SL.ValidationMixin,
  SL.TranslatablePropertyMixin, {

  tagName: 'select',
  attributeBindings: ['label:title', 'multiple:multiple'],
  didInsertElement: function() {
    if (this.get('itemsets') &&
      typeof this.get('itemsets').then === 'function') {
      var self = this;
      this.get('itemsets').then(function(items) {
        initializeBootstrapSelect(self, items);
      });
    }
    else {
      initializeBootstrapSelect(this, this.get('itemsets'));
    }
  },
  change: function() {
    var selected = this.$('option:selected');
    var value = this.get('multiple') ? selected.map(function() {
      return Em.$(this).data('value');
    }).get() : selected.data('value');
    this.$().siblings('.unselect')[Em.isPresent(value) ? 'show' : 'hide']();
    this.setProperties({
      value: value,
      expectValueChange: true
    });
  },
  valueToWidget: function() {
    if (this.get('expectValueChange'))
      return Em.run.next(this, function() {
        if (this.isDestroyed || this.isDestroying) return;
        this.set('expectValueChange', false);
      });

    if (!this.selectpicker) return;

    var v = null, value = this.get('value');
    // FIXME: dirty fix for the value to be a promise object case
    if (value instanceof DS.PromiseObject) value = value.get('content');
    if (!this.$()) return;
    this.$().siblings('.unselect')[value ? 'show' : 'hide']();
    var $options = this.$('option');
    if (this.get('multiple')) {
      v = [];
      if (!Em.isArray(value)) value = [];
      value.forEach(function(i) {
        $options.each(function() {
          var $o = Em.$(this);
          if ($o.data('value') === i) {
            if (i && Em.get(i, 'id')) {
              v.push(Em.get(i, 'id'));
            } else {
              v.push($o.text() && $o.text().trim());
            }
            return false;
          }
        });
      });
    } else {
      $options.each(function() {
        var $o = Em.$(this);
        if ($o.data('value') === value) {
          if (value && Em.get(value, 'id')) {
            v = Em.get(value, 'id');
          } else {
            v = $o.text();
            v = v && v.trim();
          }
          return false;
        }
      });
    }
    this.$().selectpicker('val', v);
  }.observes('value', 'value.isFulfilled')
});


})();

(function() {

SL.ButtonGroupComponent = Em.Component.extend({

  activeIndex: function() {
    return this.filterIndexBy(this.get('value'));
  }.property('itemset.[]', 'value'),

  filterIndexBy: function(val) {
    for (var i = 0; i < this.get('itemset.length'); i++) {
      var item = this.get('itemset').objectAt(i);
      if (Em.isArray(item) && item.findBy('value', val))
        return i;
      else if (Em.get(item, 'value') === val)
        return i;
    }
  },

  actions: {
    setActive: function(v) {
      var index = this.filterIndexBy(v);
      if (Em.isArray(this.get('itemset').objectAt(index)))
        this.get('itemset').objectAt(index).set('lastActive', v);
      this.set('value', v);
      if (this.get('activeChangedAction'))
        this.sendAction('activeChangedAction');
    }
  }
});


})();

(function() {

var Types = {
  liepin: { offset: [0, 60] },
  wuyou: { offset: [0, 60] }
};

SL.ClickCaptchaComponent = Em.Component.extend({
  classNames: ['click-captcha'],
  classNameBindings: ['type'],

  init: function() {
    this._super();
        this.set('value', []);
    this.notifyPropertyChange('value');
  },

  click: function(e) {
    var offset = Types[this.get('type')].offset,
        clickX = e.offsetX - offset[0],
	clickY = e.offsetY - offset[1];

    if (clickX < 0 || clickY < 0) return;

    this.get('value').pushObject([e.offsetX, e.offsetY]);
    var index = this.get('value.length'),
        $point = Em.$('<div class="click-point">' + index + '</div>');
    Em.run.schedule('render', this, function() {
      this.$().append($point);
      $point.css({
        top: e.offsetY - $point.height() / 2,
        left: e.offsetX - $point.width() / 2
      });
    });
  },

  _setSrc: function() {
    // Check if src image changed
    var src = this.get('src');
    if (!this.$() || src && this.$().css('background-image').indexOf(src) > -1)
      return;

    // Clear value
    if (this.get('value.clear'))
      this.get('value').clear();
    else
      this.set('value', []);
    this.notifyPropertyChange('value');

    var img = new Image(), self = this;
    img.onload = function() {
      if (!self.$()) return;
      self.$().css('background-image', 'url(' + src + ')');
      Em.run(function() {
        self.set('loading', false);
      });
    };
    this.set('loading', true);
    img.src = src;
  },

  _updateSpin: function() {
    var $spin = this.$('.spin');
    if (!$spin) return;
    if (this.get('loading') && !$spin.length) {
      var $this = this.$();
      $spin = Em.$('<div class="spin"></div>');
      $this.css('background-image', 'none');
      $this.empty();
      $this.append($spin);
      $spin.spin();
    } else if (!this.get('loading') && $spin.length) {
      $spin.remove();
    }
  },

  updateSpin: function() {
    Em.run.scheduleOnce('afterRender', this, '_updateSpin');
  }.observes('loading'),

  setSrc: function() {
    Em.run.scheduleOnce('afterRender', this, '_setSrc');
  }.observes('src').on('didInsertElement')

});


})();

(function() {

function initializeClockPicker(comp) {
  comp.$().clockpicker({
    placement   : comp.get('placement'),
    align       : comp.get('left'),
    donetext    : 'Done',
    autoclose   : true,
    'default'   : 'now',
  });
}

SL.ClockPickerComponent = Em.Component.extend({
  tagName: 'div',
  classNames: ['input-group', 'clockpicker'],

  didInsertElement: function() {
    initializeClockPicker(this);
  }
});


})();

(function() {

SL.IconInfoComponent = Em.Component.extend({
  classNameBindings: ['infoText::hide'],

  tagName: 'i',

  setClassNames: function() {
    if (!this.get('attrs.class'))
      this.get('classNames').addObjects(['fa', 'fa-info-circle']);
  }.on('init'),

  updateInfo: function() {
    if (this.get('infoText'))
      Em.run.scheduleOnce('afterRender', this, 'buildTooltip');
  }.observes('infoText').on('didInsertElement'),

  buildTooltip: function() {
    if (!this.$()) return;
    this.$().tooltip('destroy');
    this.$().tooltip({
      html: true,
      title: this.get('infoText').toString(),
      container: this.get('parentBox')
    });
  }

});

SL.ContactInfoComponent = SL.IconInfoComponent.extend({

  setClassNames: function() {
    this.get('classNames').addObjects(['fa', 'fa-user']);
  }.on('init'),

  profileLoader: function() {
    var profile = this.get('profile');
    if (profile && !profile.get('fullModel'))
      profile.reload();
  }.observes('profile'),

  profile: Em.computed.readOnly('contact.defaultProfile'),

  infoText: function() {
    var text = this.get('contactText');
    if (text) return text;
    var mobile = this.get('profile.mobiles'),
        email = this.get('profile.lastWork.companyEmail'),
        phone = this.get('profile.workPhoneNumber'), result = [];
    if (phone)
      result.push('<i class="fa fa-phone"></i> ' + phone);
    if (mobile && mobile.get('length'))
      result.push('<i class="fa fa-mobile"></i> ' +
        mobile.mapBy('name').join(' / '));
    if (email)
      result.push('<i class="fa fa-envelope-o"></i> ' + email);
    return result.join('<br>');
  }.property('profile.mobiles.@each.name', 'profile.workPhoneNumber',
    'profile.lastWork.companyEmail').readOnly()

});

SL.ExtraFunctionComponent = SL.IconInfoComponent.extend({
  infoText: function() {
    var labels = SL.extraFunction(
      this.get('extra'), this.get('function.id'));
    if (labels.length)
      return labels.join('<br>');
  }.property('extra', 'function')
});


})();

(function() {

/* global moment */

function initializeDatePicker(comp) {
  var locale = Em.I18n.locale === 'zh' ? 'zh-CN' : 'en';

  if (comp.dp) {
    comp.$().datepicker('remove');
  }

  comp.dp = comp.$().datepicker({
    format: comp.get('format') || 'yyyy-mm-dd',
    autoclose: comp.get('autoClose') || true,
    todayBtn: comp.get('todayBtn') || false,
    todayHighlight: true,
    forceParse: false,
    startDate: comp.get('startDate'),
    endDate: comp.get('endDate'),
    language: locale,
    minViewMode: comp.get('mode')
  });

  comp.$('input').attr('autocomplete', 'off');

  if (comp.get('attrs').hasOwnProperty('value')) {
    var d = moment(comp.get('value'));
    comp.dp.datepicker('update', d.isValid() ? d.toDate() : '');
    comp.dp.on('changeDate', function(e) {
      var d = moment(e.date);
      if (!d.isValid()) return;
      Em.run(function() {
        comp.set('expectValueChange', true);
        comp.set('value', d.format('YYYY-MM-DD'));
      });
    });
  } else if (comp.$('input').hasClass('validatable')) {
    comp.dp.on('show', function() {
      comp.$('input').removeClass('validatable');
      comp.$().addClass('validatable');
    }).on('hide', function() {
      comp.$().removeClass('validatable');
      comp.$('input').addClass('validatable');
    });
  }
}

SL.DatePickerComponent = Ember.Component.extend(SL.LazyValidationMixin, {

  classNameBindings: ['_embedded::date'],

  _embedded: function() {
    var embedded = this.get('embedded');
    if (!Em.isEmpty(embedded))
      return embedded;
    return this.get('attrs').hasOwnProperty('value');
  }.property('embedded'),

  didInsertElement: function() {
    if (!this.get('embedded')) {
      // Em.assert('Should have .add-on wrapped with the date picker',
      //   this.$('.add-on, .input-group-addon, .btn').length);

      var $input = this.$('input');
      if ($input.val()) {
        // Force the format sync with date picker
        var d = moment(Date.parse($input.val()));
                $input.val(d.format('YYYY-MM-DD'));
      }
    }
    initializeDatePicker(this);
  },

  valueToWidget: function() {
    if (!this.$()) return;
    if (this.get('expectValueChange'))
      return this.set('expectValueChange', false);

    var d = moment(this.get('value'));
    this.dp.datepicker('update', d.isValid() ? d.toDate() : '');
  }.observes('value'),

  _boundaryChange: function() {
    initializeDatePicker(this);
  },
  boundChange: function() {
    if (!this.$()) return;
    Em.run.scheduleOnce('afterRender', this, '_boundaryChange');
  }.observes('startDate', 'endDate')
});


})();

(function() {

SL.FuelUxComponent = Ember.Component.extend({

  tagName: 'input',

  attributeBindings: ['name', 'type', 'checked:value', 'isChecked:checked',
    'isDisabled:disabled'],

  isDisabled: function() {
    return !!this.get('disabled');
  }.property('disabled'),

  change: function() {
    var isChecked = this.$().is(':checked'),
        checked = this.get('checked');

    // For radio
    if (this.get('type') === 'radio' && isChecked)
      return this.set('value', checked);

    // For checkbox
    var _value = this.get('_value');
    if (!_value) _value = [];
    _value[isChecked ? 'addObject' : 'removeObject'](checked);
    if (!_value.length) return this.set('value', null);
    if (this.get('isArray')) return this.set('value', _value);
    this.set('value', typeof checked === 'string' ?
      _value.join(',') : _value[_value.length - 1]);
  },

  isArray: function() {
    return (typeof this.get('checked') !== 'string' &&
      typeof this.get('checked') !== 'boolean') || this.get('arrayValue');
  }.property('checked', 'arrayValue'),

  _value: function() {
    var value = this.get('value');

    // For null value and radio
    if (Em.isNone(value) || this.get('type') === 'radio') return value;

    // For checkbox, ensure _value is array
    if (!this.get('isArray') && typeof value === 'string') {
      value = value.split(',');
    }

    if (!Em.$.isArray(value)) value = [value];
    return value;
  }.property('value.[]'),

  isChecked: function() {
    var value = this.get('_value'),
      checked = this.get('checked');
    // For radio
    if (this.get('type') === 'radio') return value === checked;
    // For checkbox
    if (!value) return false;
    return value.indexOf(checked) > -1;
  }.property('_value.[]', 'checked'),

  didInsertElement: function() {
    var type = this.get('type');
    Em.$('<i class="fa ' +
      (type === 'radio' ? 'fa-circle-o' : 'fa-check-square-o') + '">').
      insertBefore(this.$());
        this.$()[type]();
  },

  updateState: function() {
    Em.run.scheduleOnce('afterRender', this, function() {
      var checked = this.get('isChecked'),
          widget = this.$().data('fu.' + this.get('type'));
      if (!widget) return;
      widget[checked ? 'check' : 'uncheck']();
      widget.setState(this.$());
      if (widget.$parent)
        // No disabled class set on wrapping parent
        widget.$parent.removeClass('disabled');
    });
  }.observes('isChecked', 'isDisabled').on('didInsertElement')

});


})();

(function() {

SL.ImgThumbComponent = Em.Component.extend({
  selectAction: null,

  classNames: ['img-thumb'],
  classNameBindings: ['selectable'],

  didInsertElement: function() {
    var size = this.$().width();
    // TODO: not sure why this breaks add to vacancy test
    // Em.assert('Must define size with css direct on this component', size);
    this.set('size', size);
  },

  click: function(e) {
    if (Em.isPresent(this.get('selectAction')) && !this.get('disabled')) {
      e.preventDefault();
      e.stopPropagation();
      this.sendAction('selectAction');
    }
  },

  selectable: function() {
    if (this.get('selectAction'))
      return this.get('disabled') ? 'disabled' : 'selectable';
  }.property('selectAction', 'disabled'),

  setSrc: function() {
    if (!this.$()) return;
    this.$().css('background-image', 'url(' + this.get('src') + ')');
  }.observes('src').on('didInsertElement'),

  src: function() {
    if (this.get('img.thumb')) {
      if (this.get('showOrigin'))
        return this.get('img.thumb.i');
      if (this.get('size') <= 50) {
        return this.get('img.thumb.s');
      } else if (this.get('size') <= 200) {
        return this.get('img.thumb.m');
      } else {
        return this.get('img.thumb.l');
      }
    } else {
            return this.get('fallback');
    }
  }.property('img.thumb', 'fallback', 'size').readOnly()
});


})();

(function() {

function initTabs(comp) {
  comp.$().infiniteTabs({
    initialTabSlideOffset: comp.get('initialOffset') || 0
  });
  comp.valueToWidget();
  comp.$('li.scroller ul').css('left', comp.get('initialOffset'));
}

SL.InfiniteTabsComponent = Em.Component.extend({
  tagName: 'ul',
  classNames: ['infinite-tabs'],

  didInsertElement: function() {
    initTabs(this);
  },

  syncName: function() {
    var wTabs = this.$('li.scroller ul>li'),
        vTabs = this.get('value'), pending = vTabs.get('length'), i,
        closeHTML = '<span class="close">&times;</span>';
    // Sync tab number first
    if (wTabs.length < vTabs.get('length')) {
      pending = wTabs.length;
      for (i = wTabs.length; i < vTabs.get('length'); i++) {
        var t = vTabs.objectAt(i);
        var $t = Em.$('<li><a href="#"><span class="title">' +
          t.get('name') + '</span>' + closeHTML + '</a></li>');
        this.$().infiniteTabs('append-tab', $t);
      }
    } else if (vTabs.get('length') < wTabs.length) {
      for (i = vTabs.get('length'); i < wTabs.length; i++)
        this.$().infiniteTabs('remove-tab', wTabs[i]);
    }
    // Sync tab content and active status
    for (i = 0; i < pending; i++) {
      var tab = vTabs.objectAt(i);
      var $tab = Em.$(wTabs[i]);
      this.$().infiniteTabs('set-tab-content', $tab, '<span class="title">' +
        tab.get('name') + '</span>' + closeHTML);
    }
    var self = this;
    this.$('li.scroller li').off().on('click', function(e) {
      var cTab = vTabs.objectAt(Em.$(this).index());
      if (Em.$(e.target).hasClass('close'))
        self.sendAction('close', cTab);
      else
        self.sendAction('open', cTab);
    });
  },

  syncActive: function() {
    var wTabs = this.$('li.scroller ul>li'),
        vTabs = this.get('value');
    wTabs.removeClass('active');
    wTabs.eq(vTabs.indexOf(vTabs.findBy('active'))).addClass('active');
  },

  observeName: function() {
    Em.run.once(this, 'syncName');
  }.observes('value.@each.name'),

  observeActive: function() {
    Em.run.once(this, 'syncActive');
  }.observes('value.@each.active'),

  valueToWidget: function() {
    this.syncName();
    this.syncActive();
  }
});


})();

(function() {

SL.JqueryQrcodeComponent = Em.Component.extend({
  serverGenerate: false,
  update: function() {
    if (!this.$()) return;
    this.$('canvas').remove();
    this.$('img').remove();

    if (this.get('serverGenerate')) {
      var size = this.get('width'),
          url = SL.apiPath + '/qr?size=' + size +
            '&url=' + encodeURIComponent(this.get('text'));

      var $this = this.$(), $spin = Em.$('<div class="spin"></div>');
      $spin.width(size / 2).height(size / 2);
      $spin.css('margin', size / 4);
      $this.empty();
      $this.append($spin);
      $spin.spin();

      var img = new Image(), self = this;
      img.onload = function() {
        if (!self.$()) return;
        $spin.remove();
        self.$().html(Em.$(img));
      };
      img.src = url;
    } else {
      this.$().qrcode({
        width: this.get('width'),
        height: this.get('height'),
        text: this.get('text')
      });
    }
  }.observes('text').on('didInsertElement')
});


})();

(function() {

SL.ListItemComponent = Em.Component.extend({
  selectAction: 'select',
  multiSelectionBg: 'bg-light',
  tagName: 'li',
  classNames: ['list-group-item'],
  classNameBindings: [
    'isSelected:active', 'multiSelectedBg', 'isDisabled:disabled'],

  isSelected: SL.computed.eql('singleSelection', 'item'),

  isMultiSelected: function() {
    var item = this.get('item'), multi = this.get('multiSelections') || [];
    if (multi.contains(item))
      return true;
    var semi = this.get('semiSelections') || [];
    return semi.contains(item) && 'semi';
  }.property('multiSelections.[]', 'semiSelections.[]', 'item'),

  multiSelectedBg: function() {
    if (this.get('isMultiSelected'))
      return 'bg-light lter';
  }.property('isMultiSelected'),

  click: function(e) {
    if (!this.get('disabled'))
      this.sendAction('selectAction', this.get('item'));
  },

  didRender: function() {
    // Dirty way for tooltips
    var $tooltip = this.$('[data-toggle="tooltip"]');
    if ($tooltip.data('bs.tooltip'))
      $tooltip.tooltip('destroy');
    $tooltip.tooltip();
  },

  isDisabled: function() {
    return !!this.get('disabled');
  }.property('disabled'),

  showDisabledReason: function() {
    if (this.tooltip)
      this.$().tooltip('destroy');
    var reason = this.get('disabled');
    if (typeof reason === 'string') {
      this.tooltip = this.$() && this.$().tooltip({
        title: reason,
        container: 'body'
      });
    }
  }.observes('disabled').on('didInsertElement')
});

var className = ['on', 'away', 'off', 'busy'];

SL.VacancyListItemComponent = SL.ListItemComponent.extend({
  classNameByStatus: function() {
    return className[this.get('item.status')];
  }.property('item.status'),
  statusName: function() {
    return SL.helper.vacancyStatus(this.get('item.status'));
  }.property('item.status')
});

var flagColor = ['text-danger', 'text-success', 'text-warning'];
SL.FolderListItemComponent = SL.ListItemComponent.extend({
  flagColor: function() {
    return flagColor[this.get('item.status')] || 'hide';
  }.property('item.status')
});

SL.FolderItemComponent = SL.ListItemComponent.extend({
  withUpdates: function() {
    return this.get('updates').contains(this.get('item.id'));
  }.property('item.id', 'updates.[]')
});

SL.ChannelListComponent = SL.ListItemComponent.extend({
  layoutName: 'components/list-item',
  disabled: function() {
    var key = this.get('item.inhibited');
    if (key)
      return Em.I18n.t('vacancy.channel.inhibited.' + key);
    return this.get('item.inhibitedMsg');
  }.property('item.inhibitedMsg', 'item.inhibited')
});


})();

(function() {

SL.LoadingMaskComponent = Ember.Component.extend({
  layoutName: 'loading-mask',
  classNames: ['loading-mask']
});


})();

(function() {

SL.MergeItemComponent = Em.Component.extend({
  radio: true,
  changeEvent: 'selectionChanged',
  initSelection: function() {
    var sourceValue = this.get('sourceValue'),
        targetValue = this.get('targetValue'), plan;
    if (sourceValue) sourceValue = sourceValue.toString();
    if (targetValue) targetValue = targetValue.toString();

    if (this.get('radio')) {
      plan = 'target';
      if (!Em.isEmpty(sourceValue) && Em.isEmpty(targetValue))
        plan = 'source';
      this.set('plan', plan);
    } else {
      var index = this.get('index');
      plan = this.get('plan');
      if (targetValue)
        plan.addObject(
          Em.isPresent(index) ? 'target:' + index : 'target');
      else
        plan.addObject(
          Em.isPresent(index) ? 'source:' + index : 'source');
    }
  },
  didInsertElement: function() {
    Em.run.once(this, 'initSelection');
  },
  change: function() {
    this.sendAction('changeEvent');
  },
  mergePlan: Em.computed.alias('plan'),
  muteSource: function() {
    var plan = this.get('mergePlan');
    if (Em.isArray(plan))
      return plan.indexOf('source:' + this.get('index')) < 0;
    return plan !== 'source';
  }.property('mergePlan', 'mergePlan.[]'),
  muteTarget: function() {
    var plan = this.get('mergePlan');
    if (Em.isArray(plan))
      return plan.indexOf('target:' + this.get('index')) < 0;
    return plan !== 'target';
  }.property('mergePlan', 'mergePlan.[]')

});


})();

(function() {

function initializePhotoEditor(comp) {
  // Modal Dialog
  comp.$().find('div.modal').modal({
    show: false
  }).on('hidden.bs.modal', function() {
    comp.$().focus();
    if (comp.jCrop) {
      comp.jCrop.destroy();
      Em.run(comp, 'setProperties', { jCrop: null, tmpValue: null });
    }
  });

  comp.$().find('ul button').click(function() {
    comp.$().find('div.modal').modal('show').
        one('shown.bs.modal', function() {
      Em.run(comp, 'set', 'tmpValue', comp.get('value'));
    });
  });
}

SL.PhotoEditorComponent = Em.Component.extend(SL.TranslatablePropertyMixin, {
  uploadPath: 'photo',
  postMessageAction: 'postMessage',
  loadingAction: 'loading',
  isValueEmpty: Em.computed.not('tmpValue'),

  croppedPhoto: function() {
    var url = this.get('value.thumb.l');
    return url || this.get('defaultPhoto');
  }.property('value.thumb').readOnly(),

  didInsertElement: function() {
    initializePhotoEditor(this);
    this.cropPhoto();
  },

  resizedPhoto: Em.computed.or('tmpValue.thumb.i', 'defaultPhoto'),

  cropPhoto: function() {
    //init jcrop
    //To do : observe0
    var url = this.get('resizedPhoto');
    var aspect = this.get('height') / this.get('width');

    if (url && url !== this.get('lastUrl')) {
      if (this.jCrop) {
        this.jCrop.destroy();
        this.set('jCrop', null);
        // Fix for jCrop destroy not clearing the styles
        this.$('div.modal .preview-image').removeAttr('style');
      }
      this.set('lastUrl', url);
      var img = new Image(), self = this;
      img.onload = function() {
        if (!self.$()) return;
        var $img = self.$('div.modal .preview-image'),
        minL = $img.width();
        if ($img.height() < minL) minL = $img.height();
        $img.Jcrop({
          setSelect: [15, 15, minL - 15, (minL - 15) * aspect],
          aspectRatio: 1 / aspect,
          onSelect: function(c) {
            Em.run(self, 'set', 'selection', c);
          },
          onRelease: function() {
            Em.run(self, 'set', 'selection', null);
          }
        }, function() {
          Em.run(self, 'set', 'jCrop', this);
        });
      };
      img.src = url;
    }
  }.observes('resizedPhoto'),

  actions: {
    saveCroppedPhoto: function() {
      var self = this,
        $img = this.$('div.modal .preview-image'),
        selectionArea = this.jCrop.tellSelect();

      var maxL = $img.width();
      if ($img.height() > maxL) maxL = $img.height();
      // 350 is the value supposed to be understanded by backend
      var scale = 350 / maxL;

      var query = SL.apiPath + '/crop_photo?photo_id=' +
        this.get('tmpValue.id') + '&photo_type=' +
        this.get('tmpValue.fileType') + '&left=' + selectionArea.x * scale +
        '&right=' + selectionArea.x2 * scale + '&upper=' +
           selectionArea.y * scale + '&lower=' + selectionArea.y2 * scale;

      /* jslint eqeq: true */
      if (this.get('value.id') != this.get('tmpValue.id'))
        this.set('value', this.get('tmpValue')).sendAction('save');

      Em.$.get(query).then(function(data) {
        Em.run(function() {
          if (self.get('handler'))
            // Push the updated time into model to get cropped images updated
            self.get('handler')({ files: [data.file] });
        });
      });
    },

    deletePhoto: function() {
      var photo = this.get('value');
      this.set('value', null);
      if (photo && photo.get('attached'))
        this.sendAction('delete');
    },

    // Ember 1.9.0 seem to force the parent component have a handler
    // if child compoment is sending an action.
    postMessage: function(msg) {
      this.sendAction('postMessageAction', msg);
    },
    loading: function(c) {
      this.sendAction('loadingAction', c);
    }
  }
});


})();

(function() {

Em.$(function() {
  if (!Em.$.fn.pillbox.Constructor) return;
  var prototype = Em.$.fn.pillbox.Constructor.prototype;

  prototype.placeItems = (function(_super) {
    return function() {
      var base = this.$pillGroup.children('.pill').length;
      var result = _super.apply(this, arguments);

      // Get parameters
      var items, index;
      if (isFinite(String(arguments[0])) &&
        !(arguments[0] instanceof Array)) {
        items = [].slice.call(arguments).slice(1);
        index = arguments[0];
      } else {
        items = [].slice.call(arguments).slice(0);
      }

      if (items[0] instanceof Array)
        items = items[0];

      // Set values back
      if (items.length) {
        base = (!index || index > base || index === -1) ? base + 1 : index;
        for (var i = 0; i < items.length; i++)
          this.$pillGroup.find('.pill:nth-child(' + (base + i) + ')').
            data('value', items[i].value);
      }

      return result;
    };
  })(prototype.placeItems);
});

function showTooltip($pills) {
  if (!$pills) return;
  $pills.each(function() {
    if (this.offsetWidth >= this.scrollWidth) return;
    var $pill = Em.$(this);
    $pill.tooltip({
      title: $pill.text(),
      placement: 'bottom'
    });
  });
}

function initialPillBox(comp) {
  // If no autocomplete dataset is configured, always allow free typing
      var typeFree = comp.get('dataset') ? comp.get('typeFree') : true;

  var pillbox = comp.$().pillbox({
    edit: comp.get('editable') === true,
    acceptKeyCodes: typeFree ? [13, 188] : [],
  }).on('added.fu.pillbox edited.fu.pillbox removed.fu.pillbox', function() {
    Em.run(function() {
      comp.widgetToValue();
    });
  }).on('added.fu.pillbox edited.fu.pillbox', function() {
    Em.run.once(comp, 'clearInput');
  });

  // Pill is clickable
  if (Em.$.isFunction(comp.get('editable'))) {
    comp.$().addClass('pills-clickable');
    pillbox.on('clicked.fu.pillbox', function(e, data) {
      comp.get('editable')(data.value);
    });
  }

  comp.pillbox = comp.$().data('fu.pillbox');
    // Bug fix for type-ahead
  comp.pillbox.$addItemWrap = comp.$('.pillbox-input-wrap');
  comp.changeReadonly();

  comp.valueToWidget();

  if (comp.get('autoFocus'))
    comp.$('.pillbox-add-item').attr('autofocus', 'true');
}

SL.PillBoxComponent = Em.Component.extend(SL.TranslatablePropertyMixin, {
  classNames: ['pillbox'],
  attributeBindings: ['safeStyle:style'],
  displayKey: 'name',
  mutable: true,
  placeholderTranslation: 'common.component.pill_box.placeholder',
  newPill: undefined,
  lazy: true,

  init: function() {
    this._super();

    var displayKey = this.get('displayKey');
        if (typeof displayKey === 'string')
      this.displayFunc = function(suggestion) {
        return Em.get(suggestion, displayKey);
      };
    else
      this.displayFunc = displayKey;
  },

  didInsertElement: function() {
    initialPillBox(this);
  },

  safeStyle: function() {
    var style = this.get('style') || '';
    return style.htmlSafe();
  }.property('style'),

  // This is where the new pill is selected from the auto-complete list
  newPillSelected: function() {
    var pill = this.get('newPill');
    if (!pill) return;
    this.pillbox.addItems(-1, { text: this.displayFunc(pill), value: pill });
    this.$().trigger('added.fu.pillbox');
  }.observes('newPill'),

  clearInput: function() {
    this.setProperties({
      newPill: undefined,
      newPillLabel: undefined
    });
    if (this.get('dataset')) {
      var typeahead = this.$('.tt-input').data('ttTypeahead');
      if (!typeahead) return;
      typeahead.input.setQuery('');
      typeahead.dropdown.empty();
      typeahead.dropdown.close();
    }
  },

  widgetToValue: function() {
    this.set('expectValueChange', true);
    var self = this, typeFree = this.get('typeFree');
    var values = this.$('.pill').map(function(i) {
      var value = Em.$(this).data('value');
      if (typeof value === 'number') value = value.toString();
      // Check the value to see if it can be re-formed
      if (typeof value === 'string' && Em.$.isFunction(typeFree)) {
        value = typeFree(value);
        // This need to be scheduled after rendering
        Em.run.scheduleOnce('afterRender', self, function() {
          this.$('.pill').eq(i).data('value', value);
        });
      }
      return value;
    }).get();

    if (Em.$.isFunction(self.get('value.setObjects')))
      self.get('value').setObjects(values);
    else if (!this.dataset)
      self.set('value', values.join(','));
    else
      self.set('value', values);
  },

  valueToWidget: function() {
    if (!this.pillbox) return;

    if (this.get('expectValueChange'))
      this.set('expectValueChange', false);
    else {
      var self = this;
      this.$('.pill').remove();
      var value = this.get('value');
      if (!value) return;
      else if (!this.get('dataset') && !Em.isArray(value)) {
                value = value.split(',');
      }
      this.pillbox.addItems(-1, value.map(function(v) {
        return {
          text: typeof v === 'string' ? v : self.displayFunc(v),
          value: v
        };
      }));
    }

    // Styling the pills
    var pillStyle = this.get('pillStyle');
    if (!pillStyle) pillStyle = 'btn-primary bg-primary lter';
    var $pills = this.$('.pill');
    $pills.removeClass('btn-default');
    $pills.each(function() {
      var $pill = Em.$(this);
      if (Em.$.isFunction(pillStyle))
        $pill.addClass(pillStyle($pill.data('value')));
      else
        $pill.addClass(pillStyle);
    });

    // Tooltips
    Em.run.next(this, function() {
      // Have to be deffered to gain focus after pill added
      showTooltip(this.$('.pill>span:first-child'));
    });

  }.observes('value.[]', 'value.isFulfilled'),

  isReadonly: function() {
    return this.get('readonly') || !this.get('mutable');
  }.property('readonly', 'mutable').readOnly(),

  changeReadonly: function() {
    if (!this.pillbox) return;
    var readonly = this.get('isReadonly');
    this.pillbox.readonly(readonly);
    // Setup / Cleanup
    if (readonly) {
      this.$().removeClass('pills-editable pills-clickable');
      // FIXME: Appearently this doesn't work
      // this.clearInput();
    }
    else if (this.get('editable') === true) {
      this.$().addClass('pills-editable');
    }
  }.observes('isReadonly')

});

SL.PillBoxCustomComponent = SL.PillBoxComponent.extend();


})();

(function() {

SL.QuickSearchComponent = Em.Component.extend({
  layoutName: 'quick-search',
  category: 0,

  lazyLoading: function() {
    return parseInt(this.get('category')) > 0;
  }.property('category').readOnly(),

  didInsertElement: function() {
    var self = this;
    var $categories = this.$('.dropdown-menu a');
    $categories.click(function(e) {
      e.preventDefault();
      var $target = Em.$(e.target).closest('a');
      self.set('category', $target.data('category'));
    });
  },
  reload: function() {
    var $categories = this.$('.dropdown-menu a');
    var $quickSearchIcon = this.$('#quick-search-icon');
    if (!$quickSearchIcon) return;
    $quickSearchIcon.attr('class',
      $categories.eq(this.get('category')).find('i').attr('class'));
  }.observes('category').on('didInsertElement')
});


})();

(function() {

function initializeSmallCalendar(comp) {
  comp.$().calendar({
    months: ['January', 'February', 'March',
     'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'],
    days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    events: comp.get('events'),
    popover_options:{
      placement: 'top',
      html: true
    }
  });
}

SL.SmallCalendarComponent = Em.Component.extend({
  didInsertElement: function() {
    initializeSmallCalendar(this);
  }
});


})();

(function() {

SL.SortIndicatorComponent = Em.Component.extend({
  tagName: 'i',
  classNames: ['fa'],
  classNameBindings: ['mute:text-muted', 'order'],

  mute: function() {
    return !this.get('sortProperties') ||
      !this.get('sortProperties').contains(this.get('columnProperty'));
  }.property('columnProperty', 'sortProperties.[]').readOnly(),

  order: function() {
    if (this.get('mute')) return 'fa-sort';
    return this.get('sortAscending') ? 'fa-sort-asc' : 'fa-sort-desc';
  }.property('mute', 'sortAscending').readOnly()
});


})();

(function() {

SL.SparkLineComponent = Em.Component.extend({

  willClearRender: function() {
    this._super();
    var resizeHandler = this.get('resizeHandler');
    if (resizeHandler) {
      this.$(window).unbind('resize', resizeHandler);
      this.set('resizeHandler', null);
    }
  },

  generateSparkline: function() {
    var self = this;
    if (this.get('resize') === 'true' && !this.get('resizeHandler')) {
      this.set('resizeHandler', function() {
        self.generateSparkline();
      });
      this.$(window).bind('resize', this.get('resizeHandler'));
    }

    var options = { type: this.get('type') };
    if (options.type === 'bar') {
      options.barColor = this.get('barColor') || '#3fcf7f';
      options.barSpacing = this.get('barSpacing') || 2;
      options.barWidth = this.get('barWidth');
      options.barSpacing = this.get('barSpacing');
      this.$().next('.axis').find('li')
        .css('width', options.barWidth + 'px')
        .css('margin-right', options.barSpacing + 'px');
    } else if (options.type === 'pie') {
      var sliceColors = this.get('sliceColors');
      if (sliceColors)
        options.sliceColors = sliceColors.split(',');
    } else if (options.type === 'line') {
      this.$().next('.axis').addClass('axis-full');
    }

    options.height = this.get('height');
    options.width = this.get('width');
    options.fillColor = this.get('fillColor');
    options.highlightLineColor = this.get('highlightLineColor');
    options.spotRadius = this.get('spotRadius');
    options.spotColor = options.minSpotColor = options.maxSpotColor =
      options.highlightSpotColor = options.lineColor = this.get('lineColor');

    this.$().sparkline(this.get('data').split(',') || 'html', options);

    var compositeData = this.get('compositeData');
    if (!compositeData) return;
    var cOptioins = {
      composite: true,
      spotRadius: options.spotRadius,
      lineColor: this.get('compositeLineColor') || '#a3e2fe',
      fillColor: this.get('compositeFillColor') || '#e3f6ff',
      highlightLineColor: options.highlightLineColor
    };
    cOptioins.spotColor = cOptioins.minSpotColor = cOptioins.maxSpotColor =
      cOptioins.highlightSpotColor = cOptioins.lineColor;
    if (this._isRgbaSupport())
      cOptioins.fillColor = this._toRgba(cOptioins.fillColor, 0.5);
    this.$().sparkline(compositeData.split(','), cOptioins);
  }.on('didInsertElement'),

  _isRgbaSupport : function() {
    var value = 'rgba(1,1,1,0.5)',
    el = document.createElement('p'),
    result = false;
    try {
      el.style.color = value;
      result = /^rgba/.test(el.style.color);
    } catch (e) {}
    el = null;
    return result;
  },

  _toRgba : function(str, alpha) {
    var patt = /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})$/;
    var matches = patt.exec(str);
    return 'rgba(' + parseInt(matches[1], 16) + ',' + parseInt(matches[2], 16) +
      ',' + parseInt(matches[3], 16) + ',' + alpha + ')';
  }

});


})();

(function() {

var chineseNames = {
  ac: '阿森松岛',
  ad: '安道尔',
  ae: '阿拉伯联合酋长国',
  af: '阿富汗',
  ag: '安提瓜和巴布达',
  ai: '安圭拉',
  al: '阿尔巴尼亚',
  am: '亚美尼亚',
  ao: '安哥拉',
  ar: '阿根廷',
  as: '美属萨摩亚',
  at: '奥地利',
  au: '澳大利亚',
  aw: '阿鲁巴',
  ax: '奥兰群岛',
  az: '阿塞拜疆',
  ba: '波斯尼亚和黑塞哥维那',
  bb: '巴巴多斯',
  bd: '孟加拉国',
  be: '比利时',
  bf: '布基纳法索',
  bg: '保加利亚',
  bh: '巴林',
  bi: '布隆迪',
  bj: '贝宁',
  bl: '圣巴泰勒米',
  bm: '百慕大',
  bn: '文莱',
  bo: '玻利维亚',
  bq: '荷属加勒比区',
  br: '巴西',
  bs: '巴哈马',
  bt: '不丹',
  bw: '博茨瓦纳',
  by: '白俄罗斯',
  bz: '伯利兹',
  ca: '加拿大',
  cc: '科科斯（基林）群岛',
  cd: '刚果（金）',
  cf: '中非共和国',
  cg: '刚果（布）',
  ch: '瑞士',
  ci: '科特迪瓦',
  ck: '库克群岛',
  cl: '智利',
  cm: '喀麦隆',
  cn: '中国',
  co: '哥伦比亚',
  cr: '哥斯达黎加',
  cu: '古巴',
  cv: '佛得角',
  cw: '库拉索',
  cx: '圣诞岛',
  cy: '塞浦路斯',
  cz: '捷克',
  de: '德国',
  dj: '吉布提',
  dk: '丹麦',
  dm: '多米尼克',
  do: '多米尼加共和国',
  dz: '阿尔及利亚',
  ec: '厄瓜多尔',
  ee: '爱沙尼亚',
  eg: '埃及',
  eh: '西撒哈拉',
  er: '厄立特里亚',
  es: '西班牙',
  et: '埃塞俄比亚',
  fi: '芬兰',
  fj: '斐济',
  fk: '福克兰群岛',
  fm: '密克罗尼西亚',
  fo: '法罗群岛',
  fr: '法国',
  ga: '加蓬',
  gb: '英国',
  gd: '格林纳达',
  ge: '格鲁吉亚',
  gf: '法属圭亚那',
  gg: '根西岛',
  gh: '加纳',
  gi: '直布罗陀',
  gl: '格陵兰',
  gm: '冈比亚',
  gn: '几内亚',
  gp: '瓜德罗普',
  gq: '赤道几内亚',
  gr: '希腊',
  gt: '危地马拉',
  gu: '关岛',
  gw: '几内亚比绍',
  gy: '圭亚那',
  hk: '中国香港特别行政区',
  hn: '洪都拉斯',
  hr: '克罗地亚',
  ht: '海地',
  hu: '匈牙利',
  id: '印度尼西亚',
  ie: '爱尔兰',
  il: '以色列',
  im: '马恩岛',
  in: '印度',
  io: '英属印度洋领地',
  iq: '伊拉克',
  ir: '伊朗',
  is: '冰岛',
  it: '意大利',
  je: '泽西岛',
  jm: '牙买加',
  jo: '约旦',
  jp: '日本',
  ke: '肯尼亚',
  kg: '吉尔吉斯斯坦',
  kh: '柬埔寨',
  ki: '基里巴斯',
  km: '科摩罗',
  kn: '圣基茨和尼维斯',
  kp: '朝鲜',
  kr: '韩国',
  kw: '科威特',
  ky: '开曼群岛',
  kz: '哈萨克斯坦',
  la: '老挝',
  lb: '黎巴嫩',
  lc: '圣卢西亚',
  li: '列支敦士登',
  lk: '斯里兰卡',
  lr: '利比里亚',
  ls: '莱索托',
  lt: '立陶宛',
  lu: '卢森堡',
  lv: '拉脱维亚',
  ly: '利比亚',
  ma: '摩洛哥',
  mc: '摩纳哥',
  md: '摩尔多瓦',
  me: '黑山',
  mf: '法属圣马丁',
  mg: '马达加斯加',
  mh: '马绍尔群岛',
  mk: '北马其顿',
  ml: '马里',
  mm: '缅甸',
  mn: '蒙古',
  mo: '中国澳门特别行政区',
  mp: '北马里亚纳群岛',
  mq: '马提尼克',
  mr: '毛里塔尼亚',
  ms: '蒙特塞拉特',
  mt: '马耳他',
  mu: '毛里求斯',
  mv: '马尔代夫',
  mw: '马拉维',
  mx: '墨西哥',
  my: '马来西亚',
  mz: '莫桑比克',
  na: '纳米比亚',
  nc: '新喀里多尼亚',
  ne: '尼日尔',
  nf: '诺福克岛',
  ng: '尼日利亚',
  ni: '尼加拉瓜',
  nl: '荷兰',
  no: '挪威',
  np: '尼泊尔',
  nr: '瑙鲁',
  nu: '纽埃',
  nz: '新西兰',
  om: '阿曼',
  pa: '巴拿马',
  pe: '秘鲁',
  pf: '法属波利尼西亚',
  pg: '巴布亚新几内亚',
  ph: '菲律宾',
  pk: '巴基斯坦',
  pl: '波兰',
  pm: '圣皮埃尔和密克隆群岛',
  pr: '波多黎各',
  ps: '巴勒斯坦领土',
  pt: '葡萄牙',
  pw: '帕劳',
  py: '巴拉圭',
  qa: '卡塔尔',
  re: '留尼汪',
  ro: '罗马尼亚',
  rs: '塞尔维亚',
  ru: '俄罗斯',
  rw: '卢旺达',
  sa: '沙特阿拉伯',
  sb: '所罗门群岛',
  sc: '塞舌尔',
  sd: '苏丹',
  se: '瑞典',
  sg: '新加坡',
  sh: '圣赫勒拿',
  si: '斯洛文尼亚',
  sj: '斯瓦尔巴和扬马延',
  sk: '斯洛伐克',
  sl: '塞拉利昂',
  sm: '圣马力诺',
  sn: '塞内加尔',
  so: '索马里',
  sr: '苏里南',
  ss: '南苏丹',
  st: '圣多美和普林西比',
  sv: '萨尔瓦多',
  sx: '荷属圣马丁',
  sy: '叙利亚',
  sz: '斯威士兰',
  tc: '特克斯和凯科斯群岛',
  td: '乍得',
  tg: '多哥',
  th: '泰国',
  tj: '塔吉克斯坦',
  tk: '托克劳',
  tl: '东帝汶',
  tm: '土库曼斯坦',
  tn: '突尼斯',
  to: '汤加',
  tr: '土耳其',
  tt: '特立尼达和多巴哥',
  tv: '图瓦卢',
  tw: '台湾地区',
  tz: '坦桑尼亚',
  ua: '乌克兰',
  ug: '乌干达',
  us: '美国',
  uy: '乌拉圭',
  uz: '乌兹别克斯坦',
  va: '梵蒂冈',
  vc: '圣文森特和格林纳丁斯',
  ve: '委内瑞拉',
  vg: '英属维尔京群岛',
  vi: '美属维尔京群岛',
  vn: '越南',
  vu: '瓦努阿图',
  wf: '瓦利斯和富图纳',
  ws: '萨摩亚',
  xk: '科索沃',
  ye: '也门',
  yt: '马约特',
  za: '南非',
  zm: '赞比亚',
  zw: '津巴布韦',
  searchPlaceholder: '搜索'
};

function initComponent() {
  var $parent = this.$().parent(), $prev = this.$().prev();
  this.$()[0].setAttribute('value', this.get('value') || '');
  this.comp = window.intlTelInput(this.$()[0], {
    i18n: Em.I18n.locale === 'zh' ? chineseNames : null,
    initialCountry: 'cn',
    countryOrder: [
      'cn', 'sg', 'my', 'th', 'vn', 'id', 'ae', 'sa', 'us', 'uk', 'jp', 'hk'
    ],
    strictMode: true
  });
  if ($parent.hasClass('input-group')) {
    this.$().closest('.iti').insertAfter($parent);
    $parent.insertAfter(this.$());
    if ($prev.length)
      Em.$().appendTo($prev);
    else
      $parent.prepend(this.$());
  }
}

SL.TelInputComponent = Em.TextField.extend({
  init: function() {
    this._super();
    this.set('attributeBindings', Em.copy(
      this.get('attributeBindings')).removeObject('value'));
  },
  didInsertElement: function() {
    this._super();
    Em.run.scheduleOnce('afterRender', this, initComponent);
  },
  valueChagned: function() {
    if (this.get('value') !== this._value())
      Em.run.next(this, function() {
        this.comp.setNumber(this.get('value') || '');
      });
  }.observes('value'),
  _value: function() {
    if (!this.comp) return;
    var number = this.comp.getNumber();
    if (this.comp.getSelectedCountryData().iso2 === 'cn')
      number = number.replace(/^\+86/, '');
    return number;
  },
  _elementValueDidChange: function() {
    this.set('value', this._value());
  }
});


})();

(function() {

function initializeTouchSpin(comp) {
  var $textField = comp.$().find('input.spinner-input');
  $textField.TouchSpin({

    min              : comp.get('min'),
    max              : comp.get('max'),
    step             : comp.get('step'),
    verticalbuttons  : comp.get('vertical'),
    verticalupclass  : 'fa fa-chevron-up',
    verticaldownclass: 'fa fa-chevron-down',
    readonly         : comp.get('readonly'),
  });
}

SL.TouchSpinComponent = Em.Component.extend({
  didInsertElement: function() {
    initializeTouchSpin(this);
  }
});


})();

(function() {

var ExtraField = Em.ObjectProxy.extend(Em.Validations.Mixin, {
  validations: {
    value: Em.Validations.validator('value.[]', 'isMandatory', function() {
      if (this.get('isMandatory') && !Em.isPresent(this.get('value')))
        return Em.I18n.t('common.extra_field.field_required');
    })
  }
});

function normalizeData(hash, array) {
  if (Em.isPresent(array))
    for (var i = 0; i < array.length; i++) { hash[array[i].id] = array[i].child;
      normalizeData(hash, array[i].child);
    }
}

function findNodes(source, id) {
  if (Em.isEmpty(source)) return;
  if (!source.normalized) {
    source.hash = {};
    normalizeData(source.hash, source);
    source.normalized = 'true';
  }
  if (id === -1) return source;
  return source.hash[id];
}

function childWithName(node, keyword) {
  node.withKeyword = (node.name.toLowerCase().indexOf(keyword) >= 0);
  if (Em.isPresent(node.child)) {
    node.childWithKeyword = false;
    for (var i = 0; i < node.child.length; i++) {
      node.child[i].parentWithKeyword =
        node.withKeyword || node.parentWithKeyword;
      if (childWithName(node.child[i], keyword) ||
          node.child[i].withKeyword)
        node.childWithKeyword = true;
    }
    return node.childWithKeyword;
  }
  return false;
}

function childWithValue(node, ids) {
  if (Em.isPresent(node.child)) {
    node.childWithValue = false;
    for (var i = 0; i < node.child.length; i++)
      if (childWithValue(node.child[i], ids) ||
        ids.indexOf(node.child[i].id) >= 0)
        node.childWithValue = true;
    return node.childWithValue;
  }
  return false;
}

function updateSelection(node, ids) {
  node.select = (ids.indexOf(node.id) >= 0);
  if (Em.isPresent(node.child))
    for (var i = 0; i < node.child.length; i++)
      updateSelection(node.child[i], ids);
}

function findTreeData(
  source, id, keyword, formatter, displayKey) {
  var nodes, result = [];
  keyword = keyword && keyword.toLowerCase();
  if (id === -1 && keyword)
    Em.$.each(source, function() {
      childWithName(this, keyword);
    });

  nodes = findNodes(source, id);
  Em.$.each(nodes, function() {
    var hasChild = keyword ?
        this.childWithKeyword || this.childWithValue ||
          Em.isPresent(this.child) && (this.withKeyword ||
          this.parentWithKeyword) : Em.isPresent(this.child);
    if (!keyword || this.withKeyword || this.parentWithKeyword ||
        this.select || hasChild) {
      var obj = formatter(this);
      result.push({
        id: this.id,
        name: displayKey(obj),
        type:  hasChild ? 'folder' : 'item',
        value: obj,
        expand: (!keyword && this.select) || this.childWithValue || keyword,
        select: this.select
      });
    }
  });
  return result;
}

Em.$(function() {
  if (!Em.$.fn.tree.Constructor) return;
  var prototype = Em.$.fn.tree.Constructor.prototype;

  prototype.openFolder = (function(_super) {
    return function(el) {
      var $el = Em.$(el);

      var $branch = $el.closest('.tree-branch'),
      $treeFolderContent = $branch.find('.tree-branch-children');

      // Fixes the bug with FuelUX tree that the data is not set on the
      // <ul> for folder content.
      $treeFolderContent.data($branch.data());
      var result = _super.apply(this, arguments);

      return result;
    };
  })(prototype.openFolder);

  prototype.selectFolder = (function(_super) {
    return function(clickedElement) {
      if (this.options.multiSelect)
        return _super.apply(this, arguments);

      // Also deselect any selected item
      var $selectedItem = this.$element.find('.tree-item.tree-selected');
      $selectedItem.removeClass('tree-selected');
      return _super.apply(this, arguments);
    };
  })(prototype.selectFolder);

  prototype.populate = (function(_super) {
    return function($el) {
      _super.apply(this, arguments);
      this.expand($el);
    };
  })(prototype.populate);

  Em.$.extend(prototype, {
    expand: function($el) {
      var self = this;
      $el.children('.tree-branch:not([data-template])').each(function() {
        var $child = Em.$(this);
        if ($child.data('select')) {
          $child.addClass('tree-selected');
        }
        if ($child.data('expand') &&
            $child.find('.glyphicon-folder-close').length) {
          self.openFolder($child.find('.tree-branch-name'));
          self.expand($child.find('.tree-branch-children'));
        }
      });
      $el.children('.tree-item:not([data-template])').each(function() {
        var $child = Em.$(this),
            hasClass = $child.hasClass('tree-selected'),
            selected = $child.data('select');
        if (selected && !hasClass)
          $child.addClass('tree-selected');
        else if (!selected && hasClass) {
          $child.removeClass('tree-selected');
        }
      });
    }
  });
});

function initializeTreeView(comp) {
  // Display Key
  var ds = comp.get('dataSource');
  var $filter = comp.$('input');
  var $tree = comp.$('.tree');
  var $spin = comp.$('.spin');

  if (comp.get('autoFocus'))
    $filter.attr('autofocus', 'true');

  $tree.tree({
    dataSource: function(options, callback) {
      var id = options.id ? options.id : -1;
      function processData(data) {
        Em.run.begin();
        if (id === -1) {
          var value = comp.get('value'),
              selectedIds = [];
          if (value && value.then) value = value.get('content');
          if (value && (value.id || value.length > 0))
            selectedIds = comp.get('multiSelect') ?
              value.map(function(v) {
                return parseInt(v.id);
              }) : [parseInt(value.id)];
          Em.$.each(data, function() {
            childWithValue(this, selectedIds);
            updateSelection(this, selectedIds);
          });
        }
        callback({ data: findTreeData(
          data, id, comp.get('filter'), ds.formatter, comp.displayFunc) });
        Em.run.end();
      }
      if (typeof ds.source === 'string')
        ds.source = { ajax: { url: SL.apiPath + '/' + ds.source + '/tree' +
          (Em.I18n.locale === 'zh' ? '/cn' : '') } };

      if (ds.source.ajax) {
        if (ds.source.data)
          processData(ds.source.data);
        else {
          $spin.spin();
          ds.source.ajax.success = function(data) {
            if (comp.get('isDestroyed')) return;
            ds.source.data = data;
            processData(data);
            $spin.addClass('hide');
          };
          Em.$.ajax(ds.source.ajax);
          $spin.removeClass('hide');
        }
      } else {
        processData(ds.source);
      }
    },
    multiSelect: comp.get('multiSelect'),
    folderSelect: comp.get('folderSelect')
  }).on('updated.fu.tree', function() {
    // The tree component is buggy so we use our own processing
    // logic here:
    Em.run(function() {
      var $selected = $tree.find('.tree-selected');
      comp.set('selectedData', Em.$.map($selected, function(s) {
        if (s) {
          var $d = Em.$(s).data();
          return { id: $d.id, name: $d.name, type: Em.get($d.value, 'type') };
        }
      }));
      comp.set('expectValueChange', true);

      if (comp.get('multiSelect')) {
        var values = Em.$.map($selected, function(s) {
          if (s) return Em.$(s).data().value;
        });
        // Replace array contents as possible
        if (Em.$.isFunction(comp.get('value.setObjects')))
          comp.get('value').setObjects(values);
        else
          comp.set('value', values);
      }
      else {
        comp.set('value', $selected.length ? $selected.data().value
          : undefined);
      }
    });
  }).on('loaded.fu.tree', function(e, el) {
    // Check if it's the tree loading itself
    var tree = Em.$(el).data('fu.tree');
    if (tree) tree.expand(tree.$element);
    $tree.slimScroll({ height: 'auto', wheelStep: 5 });
  });

  comp.tree = $tree.data('fu.tree');
  if (comp.get('folderSelect')) {
    // Should auto expand selection children in folderSelect mode
    comp.tree.$element.on('click.fu.tree', '.tree-branch-name',
      function(ev) {
        var target = ev.currentTarget,
            $branch = Em.$(target).closest('.tree-branch');
        if (!$branch.hasClass('tree-open') &&
            $branch.hasClass('tree-selected'))
          comp.tree.openFolder(target);
      });
  }
    comp.tree.component = comp;

  if (localStorage && comp.get('rememberSelection')) {
    var selected = localStorage.getItem(comp.get('rememberSelection'));
    if (selected)
      Em.run.next(comp, 'set', 'lastSelectedData', JSON.parse(selected));
  }
}

SL.TreeViewComponent = Em.Component.extend({
  displayKey: 'name',
  folderSelect: true,
  filter: '',

  init: function() {
    this._super.apply(this, arguments);
    var displayKey = this.get('displayKey');
    if (typeof displayKey === 'string')
      this.displayFunc = function(value) {
        return Em.get(value, displayKey);
      };
    else
      this.displayFunc = displayKey;
  },

  didInsertElement: function() {
    this._super();
    initializeTreeView(this);
  },

  willDestroyElement: function() {
    this.saveLastSelection();
    this._super();
  },

  lastSelection: function() {
    var ds = this.get('dataSource'), displayFunc = this.displayFunc;
    var values = this.get('value');
    if (values && Em.get(values, 'isFulfilled'))
      values = values.get('content');
    if (!Em.isPresent(values))
      values = [];
    else if (!Em.isArray(values))
      values = [values];
    return (this.get('lastSelectedData') || []).map(function(obj) {
      var val = ds.formatter(obj);
      return {
        name: displayFunc(val),
        value: val,
        selected: values.indexOf(val) > -1
      };
    }).reverse();
  }.property('lastSelectedData.[]', 'value', 'value.[]'),

  saveLastSelection: function() {
    var saveKey = this.get('rememberSelection');
    if (!saveKey || !localStorage)
      return;

    var values = localStorage.getItem(saveKey);
    values = values ? JSON.parse(values) : [];
    (this.get('selectedData') || []).forEach(function(d) {
      /* jslint eqeq:true */
      if (!values.find(function(v) { return v.id == d.id; }))
        values.push(d);
    });
    // Only keep last 5 elements
    values = values.slice(-5);
    localStorage.setItem(this.get('rememberSelection'), JSON.stringify(values));
    this.set('lastSelectedData', values);
  },

  filterChanged: function() {
    Em.run.debounce(this, 'filterTree', 200);
  }.observes('filter'),

  filterTree: function() {
    var tree = this.tree;
    if (!tree) return;
    tree.$element.find('li:not([data-template])').remove();
    tree.render();
  },

  valueChanged: function() {
    if (this.get('expectValueChange'))
      return this.set('expectValueChange', false);

    var tree = this.tree;
    if (!tree) return;

    // Data is not ready
    if (this.get('dataSource.source.ajax') &&
        !this.get('dataSource.source.data'))
      return;

    tree.$element.find('li:not([data-template])').remove();
    tree.render();
  }.observes('value.[]'),

  extraFields: function() {
    if (!this.get('attrs').hasOwnProperty('extra')) return;
    var spec = Em.get(SL.parameters, 'cvExtra');
    if (!spec) return [];
    var extra = this.get('extra'), values = this.get('value'),
        validateExtra = this.get('validateExtra');
    if (values && Em.get(values, 'isFulfilled'))
      values = values.get('content');
    if (!Em.isPresent(values))
      values = [];
    else if (!Em.isArray(values))
      values = [values];

    return Object.keys(spec).filter(function(k) {
      var forFunctions = spec[k].for_functions;
      if (!forFunctions) return;
      return !!forFunctions.find(function(f) {
        return values.find(function(v) {
          // jscs:disable
          return (v instanceof SL.Function) && v.id === '' + f;
        });
      });
    }).map(function(k) {
      var mandatoryFunctions = spec[k].mandatory_functions || [];
      var isMandatory = validateExtra &&
          !!mandatoryFunctions.find(function(f) {
        return values.find(function(v) {
          // jscs:disable
          return (v instanceof SL.Function) && v.id === '' + f;
        });
      });
      var f = ExtraField.create({
        key: k,
        extra: extra,
        isMandatory: isMandatory,
        value: Em.computed.alias('extra.' + k),
        content: spec[k]
      });
      f.toggleAlerts('volatile');
      return f;
    });
  }.property('extra', 'value.[]'),

  extraError: function() {
    var fields = this.get('extraFields');
    if (!fields) return;
    for (var i=0; i<fields.length; i++) {
      if (!fields[i].get('isValid'))
        return true;
    }
  }.property('extraFields.@each.isValid'),

  actions: {
    toggleSelection: function(val) {
      var values = this.get('value');
      if (values && Em.get(values, 'isFulfilled'))
        values = values.get('content');
      if (this.get('multiSelect')) {
        if (!Em.isPresent(values))
          values = [];
        if (values.indexOf(val) > -1)
          values.removeObject(val);
        else
          values.push(val);
        // Replace array contents as possible
        if (Em.$.isFunction(this.get('value.setObjects')))
          this.get('value').setObjects(values);
        else
          this.set('value', values);
      } else {
        this.set('value', values === val ? undefined : val);
      }
      Em.run.scheduleOnce('afterRender', this, function() {
        var $tree = this.tree.$element;
        $tree.find('.tree-selected').each(function() {
          var $e = Em.$(this);
          if ($e.data('value') !== val) return;
          if ($e.hasClass('tree-branch'))
            $e = $e.find('.tree-branch-header');  // locate the item
          var $scrollDiv = $tree.parent('.slimScrollDiv'),
              elTop = $e.offset().top,
              elBottom = elTop + $e.height(),
              containerTop = $scrollDiv.offset().top,
              containerBottom = containerTop + $scrollDiv.height();
          if (elTop < containerTop || elBottom > containerBottom)
            $tree.slimScroll({
              scrollTo: elBottom + $tree.scrollTop() - containerBottom + 24
            });
        });
      });
    }
  }

});

SL.TreeViewInputComponent = Em.Component.extend(SL.TranslatablePropertyMixin,
  SL.ValidationMixin, {
  displayKey: 'name',
  folderSelect: true,
  readonly: true,
  classNameBindings: ['compClass:class'],

  init: function() {
    this._super.apply(this, arguments);

    // Display Key
    var displayKey = this.get('displayKey');
    if (typeof displayKey === 'string')
      this.displayFunc = function(value) {
        return Em.get(value, displayKey);
      };
    else
      this.displayFunc = displayKey;
  },

  showModal: function() {
    var value = this.get('value');
    if (this.get('multiSelect')) {
      if (value && Em.$.isFunction(value.toArray)) value = value.toArray();
      if (!Em.$.isArray(this.get('checkedValue')))
        this.set('checkedValue', Em.$.extend(true, [], value));
      else
        this.get('checkedValue').setObjects(value);
    }
    else {
      this.set('checkedValue', value);
    }
    if (this.get('attrs').hasOwnProperty('extra'))
      this.set('checkedExtra', Em.copy(this.get('extra') || {}));
    this.$('div.modal').modal('show');
  },

  setClass: function() {
    var $input = this.$('input:eq(0)'), parentClass = '';
    if (!$input) return;
    $input.attr('class', this.get('class'));
    if ($input.hasClass('validatable')) {
      $input.removeClass('validatable');
      parentClass = 'validatable';
    }
    $input.addClass('tree-input');
    Em.run.schedule('afterRender', this, function() {
      this.$() && this.$().attr('class', parentClass);
    });
  }.observes('class').on('didInsertElement'),

  didInsertElement: function() {
    this._super();

    var $input = this.$('input:eq(0)'), self = this;
    $input.click(function() {
      self.showModal();
    });

    // Modal Dialog
    this.$('div.modal').modal({
      show: false
    }).on('hidden.bs.modal', function() {
      if (!$input) return;
      $input.focus();
      self.enableAlerts();
    }).on('shown.bs.modal', function() {
      if (!$input) return;
      var $modal = self.$('div.modal');
      if (!$modal.find('.spin').is(':visible'))
        $modal.find('.tree').slimScroll({ height: 'auto', wheelStep: 5 });
      $modal.find('.dropdown-menu.inner').slimScroll({
        height: 'auto', wheelStep: 5
      });
      $modal.find('input').focus();
      self.disableAlerts();
    });
    // Initial Value
    this.selectionObserver();
  },

  selectionObserver: function() {
    var $input = this.$('input:eq(0)');
    if (!$input) return;
    var value = this.get('value');
    if (!value) return $input.val('');
    var displayKey = this.displayFunc;
    $input.val(this.get('multiSelect') ?
      value.map(function(s) {
        if (s) return displayKey(s);
      }).join(', ')
      : displayKey(value));
  }.observes('value.[]', 'value.isFulfilled'),

  actions: {
    saveValue: function() {
      var tree = this.$('.tree').data('fu.tree').component;
      if (this.get('attrs').hasOwnProperty('extra')) {
        tree.get('extraFields').forEach(function(f) {
          f.toggleAlerts('purge');
        });
        if (tree.get('extraError')) return;
      }
      if (this.get('multiSelect') &&
        Em.$.isFunction(this.get('value.setObjects')))
        // Replace array contents as possible
        this.get('value').setObjects(this.get('checkedValue'));
      else
        this.set('value', this.get('checkedValue'));
      if (this.get('attrs').hasOwnProperty('extra'))
        this.set('extra', this.get('checkedExtra'));
      this.$('div.modal').modal('hide');
      tree.saveLastSelection();
    }
  }

});


})();

(function() {

/* global Bloodhound:true */

// Override Bloodhound to add lazy loading function
Em.$.extend(Bloodhound.prototype, {
  // Lazy loading
  nextPage: function(target) {
    var transport = this.transport,
        lastUrl = transport.lastUrl,
        lastResp = transport._cache.get(lastUrl);

    // This is possible if it's still fetching the last page
    if (!lastResp) return;

    // Check if there are more pages to fetch
    
    if (lastResp.page >= lastResp.num_pages) return;

    var url = lastUrl + '&page=' + (lastResp.page + 1);

    function appendToCache(dummy, resp) {
      if (!resp) return;
      lastResp.hits = lastResp.hits.concat(resp.hits);
      lastResp.page = resp.page;
      var dataset = target.datasets[0];
      dataset.update(dataset.query);
    }

    transport.lastUrl = url;
    transport._get(url, this.remote.ajax, appendToCache);
    target.$menu.addClass('lazy-loading');
  }
});

// Override Bloodhound constructor to have defalut options.
// Ref: http://stackoverflow.com/questions/9267157/
//   why-is-it-impossible-to-change-constructor-function-from-prototype
(function() {
  var defaultOpt = {
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    limit: Number.MAX_VALUE,
    remote: {
      filter: function(parsedResponse) {
        return Em.$.map(parsedResponse.hits, function(hit) {
          return hit.data;
        });
      }
    }
  };
  var proto = Bloodhound.prototype;
  Bloodhound = function(o, filter) {
    var opt = Em.$.extend(true, {}, defaultOpt);
    if (typeof o === 'string') {
      // Construct option to load remote data with the given string
      /* jshint camelcase:false */
      opt.remote.url = SL.apiPath + '/suggest/' + o + '?' +
        'filter%5Bname%5D=%QUERY&' + Em.$.param({
        results_per_page: 20
      });
      if (typeof filter === 'function')
        opt.remote.filter = filter;
    } else {
      Em.$.extend(true, opt, o);
      if (!o.remote) delete(opt.remote);
    }
    filter = opt.remote.filter;
    if (filter) {
      opt.remote.filter = function(resp) {
        return Em.run(function() {
          return filter(resp);
        });
      };
    }
    return proto.constructor.call(this, opt);
  };
  Bloodhound.prototype = proto;
}());

function initializeTypeahead(comp) {
  var datasets = comp.get('dataset');
  
  if (!Em.isArray(datasets)) datasets = [datasets];

  // Customize options with defaults
  // engine is the source of the last dataset
  var engine;
  Em.$.each(datasets, function() {
    this.displayKey = comp.displayFunc;
    if (typeof this.source.ttAdapter === 'function') {
      engine = this.source;
      this.engine = engine;
      engine.initialize();
      this.source = engine.ttAdapter();
    } else {
      engine = this.engine;
    }

    // Clear cache to load latest suggestions
    if (engine && engine.clearRemoteCache)
      engine.clearRemoteCache();

    if (!this.templates) this.templates = {};
    if (!this.templates.empty)
      this.templates.empty = '<div class="tt-empty">No matches.</div>';
  });

  var typeahead = comp.$().typeahead({
    hint: comp.hint || false,
    highlight: comp.hightlight || true,
    minLength: comp.minLength || 1
  }, datasets);
  comp.typeahead = typeahead;

  typeahead.on('typeahead:selected typeahead:autocompleted',
    function(event, item) {
    // User selected the suggestion
    Em.run(function() {
      comp.setProperties({
        expectValueChange: true,
        value: item
      });
    });
  });
  var $typeahead = comp.$().data('ttTypeahead'),
      $menu = $typeahead.dropdown.$menu;

  $menu.click(function(e) {
    if (e.target.tagName === 'A')
      $menu.hide();
  });

  // Override default _hide function since it loses focus
  var proto = $typeahead.dropdown.constructor.prototype;
  proto._hide = (function(_super) {
    return function() {
      this.$menu.css('display', 'none');
    };
  })(proto._hide);

  // Display value always reflects the input value
  $typeahead.input.$input.on('keyup', function() {
    Em.run.debounce(comp, 'setDisplayValue', 300);
  });

  
  comp.$typeahead = $typeahead;

  var hasRemoteData = false;
  Em.$.each(datasets, function() {
    if (!this.engine || !this.engine.remote) return;
    hasRemoteData = true;
  });

  if (hasRemoteData) {
    $menu.append('<div class="tt-loading"><span class="spin" ' +
      'style="height:20px;width:20px;vertical-align:middle;"></span> ' +
      'Loading...</div>');
    $menu.find('.spin').spin();

    // Let remote engine be able to have status of 'loading...' while ajax is
    // being sent.
    $typeahead.input.onSync('queryChanged', function(e, query) {
      if (query.length && !$menu.hasClass('loading') &&
        !$menu.hasClass('lazy-loading')) {
        $menu.addClass('loading');
        $typeahead.dropdown._show();
      }
    });
    $typeahead.dropdown.onSync('datasetRendered', function() {
      $menu.removeClass('loading');
      Em.run.later(function() {
        // Delay the clearance of lazy-loading status for slimScroll to
        // gain the proper height.
        // TODO: Make it right
        $menu.removeClass('lazy-loading');
      }, 100);
    });
  }

  // Slim Scroll
  $menu.children().wrapAll('<div class="scroll"></div>');
  var slimScroll = $menu.find('.scroll');
  $typeahead.dropdown.onAsync('datasetRendered', function() {
    slimScroll.slimScroll({ height: 'auto', wheelStep: 5 });
  });

  // Add lazy loading function
  if (comp.get('lazyLoad')) {
        
    slimScroll.bind('slimscroll', function(e, pos) {
      if (pos === 'bottom' && !$menu.hasClass('lazy-loading')) {
        Em.run.debounce(engine, 'nextPage', $typeahead.dropdown, 300, true);
      }
    });
  }
}

SL.TypeAheadComponent = Em.Component.extend(SL.TranslatablePropertyMixin,
  SL.LazyValidationMixin, {
  tagName: 'input',
  displayKey: 'value',
  fallbackValue: null,
  attributeBindings: ['name', 'autofocus', 'placeholder', 'disabled'],

  init: function() {
    this._super.apply(this, arguments);
    var displayKey = this.get('displayKey');
        if (typeof displayKey === 'function') {
      this.displayFunc = displayKey;
    } else {
      this.displayFunc = function(suggestion) {
        return displayKey && Em.get(suggestion, displayKey) || '';
      };
    }
  },

  setDisplayValue: function() {
    if (this.$() && !this.get('isDestroyed'))
      this.set('displayValue', this.$().val());
  },

  fallback: function() {
    var displayValue = this.get('displayValue') || null;
    if (this.$().val() !== displayValue)
      this.$().val(displayValue);
    if (displayValue === this._displayValue()) return;

    var value = this.get('value');
    // FIXME: dirty fix for the value to be a promise object case
    if (value instanceof DS.PromiseObject) value = value.get('content');
    if (value === this.get('fallbackValue')) return;

    this.setProperties({
      expectValueChange: true,
      value: this.get('fallbackValue')
    });
  },

  displayValueObserver: function() {
    Em.run.scheduleOnce('afterRender', this, 'fallback');
  }.observes('displayValue'),

  syncDisplayValue: function() {
    if (this.get('expectValueChange'))
      return Em.run.next(this, function() {
        if (this.isDestroyed || this.isDestroying) return;
        this.set('expectValueChange', false);
      });
    var value = this._displayValue();
    this.typeahead.val(value);
    this.$typeahead.input.setQuery(value ? value : '');
    // This is setting a value with another value change and is causing
    // uncertain behaviors like setting value after model is deleted.
    // TODO: Make it a right way
    this.set('displayValue', value);
  },

  selectionObserver: function() {
    Em.run.scheduleOnce('afterRender', this, 'syncDisplayValue');
  }.observes('value', 'value.isFulfilled'),

  didInsertElement: function() {
    this._super();
    initializeTypeahead(this);
  },

  _displayValue: function() {
    var value = this.get('value');
    if (value) value = this.displayFunc(value);
    return value || null;
  }

});


})();

(function() {

function initializeUploadFile(comp) {
  var btnClass = comp.get('buttonClass'),
      uploadType = comp.get('uploadType');
  if (!uploadType)
    btnClass += ' disabled';
  comp.btn = comp.$().find('div.uploadifive').uploadifive({
    uploadScript   : SL.apiPath + '/upload_file/' + uploadType,
    buttonClass    : btnClass,
    buttonText     : comp.get('label'),
    fileSizeLimit  : comp.get('sizeLimit'),
    height         : '30px',
    width          : '125px',
    removeCompleted: comp.get('removeQueueAfter'),
    fileType       : comp.get('fileType'),
    queueID        : comp.$().attr('id'),
    multi          : comp.get('multiSelection'),
    itemTemplate   : '<div class="uploadifive-queue-item">' +
                        '<a class="close" href="#">' +
                        '<i class="i i-cross2"></i></a>' +
                        '<div><span class="filename"></span>' +
                        '<span class="fileinfo"></span></div>' +
                        '<div class="progress">' +
                            '<div class="progress-bar"></div>' +
                        '</div>' +
                      '</div>',
    overrideEvents : [
      'onError', 'onFallback', 'onQueueComplete', 'onUpload',
      'onUploadComplete'
    ],
    onUploadComplete: function(file, data) {
      file.queueItem.find('.progress-bar').css('width', '100%');
      file.queueItem.find('.fileinfo').html(
        '<span class="text-success-dker">' +
        Em.I18n.t('people.create_edit.upload_file.completed') +
        '</span>');
      file.queueItem.find('.progress').slideUp(250);
      file.queueItem.addClass('complete');

      if (comp.get('handler')) {
        var value = comp.get('handler')(JSON.parse(data));
        if (comp.get('multiSelection')) {
          if (comp.get('value'))
            comp.get('value').addObject(value);
          else
            comp.set('value', [value]);
        } else {
          comp.set('value', value);
        }
      } else {
        comp.set('value', JSON.parse(data));
      }
    },
    onUpload: function(file) {
      if (file)
        comp.sendAction('onUpload');
    },
    onQueueComplete: function(files) {
      comp.sendAction('onComplete', files);
    },
    onCancel: function(file) {
    },
    onFallback: function() {
      //send no HTML5 error
      comp.sendAction('postMessageAction',
        { type: 'error', info: 'Your browser do not support HTML5!' });
    },
    onError: function(errorType, file, data) {
      //send select error
      var errorMsg = 'Error';
      // Get the error message
      switch (errorType) {
        case '404_FILE_NOT_FOUND':
          errorMsg = 'people.errors.upload_file.e404';
          break;
        case '403_FORBIDDEN':
          errorMsg = 'people.errors.upload_file.e403';
          break;
        case 'FORBIDDEN_FILE_TYPE':
        case 'Invalid file type.':
          errorMsg = 'people.errors.upload_file.invalid_type';
          break;
        case 'FILE_SIZE_LIMIT_EXCEEDED':
          errorMsg = 'people.errors.upload_file.too_large';
          break;
        case 'duplicated':
        case 'analyze_failed':
        case 'import_failed':
          errorMsg = 'people.errors.upload_file.' + errorType;
          break;
        default:
          errorMsg = 'people.errors.upload_file.unknown';
          break;

      }
      // Add the error class to the queue item
      file.queueItem.addClass('error')
      // Output the error in the queue item
      .find('.fileinfo').html(
        ' - ' + '<span class="text-danger">' + Em.I18n.t(errorMsg) +
        '</span>');
      // Hide the progress
      file.queueItem.find('.progress').remove();
      if (comp.get('removeQueueAfter'))
        setTimeout(this.uploadifive.bind(this, 'cancel', file), 3000);
    }
  });
}

SL.UploadFileComponent = Em.Component.extend(SL.TranslatablePropertyMixin, {
  noQueue: false,
  removeQueueAfter: true,
  didInsertElement: function() {
    initializeUploadFile(this);
  },
  willDestroyElement: function() {
    this.btn.uploadifive('destroy');
  },
  update: function() {
    if (!this.btn || !this.btn.data('uploadifive')) return;
    if (this.get('uploadType')) {
      this.btn.data('uploadifive').settings.uploadScript =
        SL.apiPath + '/upload_file/' + this.get('uploadType');
      this.$('.uploadifive-button').removeClass('disabled');
    } else {
      this.$('.uploadifive-button').addClass('disabled');
    }
  }.observes('uploadType')
});


})();

(function() {

SL.XPaginationComponent = Em.Component.extend(SL.TranslatablePropertyMixin, {
  layoutName: 'pagination',
  classNameBindings: ['compact'],
  goPageAction: 'goPage',
  pageStringTranslation: 'common.component.pager',

  didInsertElement: function() {
    var self = this;
    this.$('.pagination:eq(0)').jqPagination({
      page_string: this.get('pageString'),
      max_page: this.get('count'),
      current_page: this.get('current') || 1,
      paged: function(p) {
        if ((self.get('current') || 1) === p) return;
        self.sendAction('goPageAction', { page: p });
      }
    });
    this.$('.pagination:eq(1) .dropdown-menu a').click(function(e) {
      e.preventDefault();
      self.sendAction('goPageAction', { page: 1, pageSize: e.target.text });
    });
  },
  _refresh: function() {
    var max = this.get('count');
    if (max) {
      var $pager = this.$('.pagination:eq(0)');
      $pager.jqPagination('option', 'current_page', Math.min(
        this.get('current') || 1, max));
      $pager.jqPagination('option', 'max_page', max);
    }
  },
  refresh: function() {
    Em.run.once(this, '_refresh');
  }.observes('count', 'current')
});


})();

(function() {

function initializeXSlider(comp) {
  var tooltip = comp.get('tooltip'), formatter = comp.get('formatter');
  if (tooltip && tooltip !== 'hide') {
        var label = tooltip;
    tooltip = 'show';
    formatter = function() {
      return label;
    };
  }
  comp.$().slider({
    min: comp.get('minValue'),
    max: comp.get('maxValue'),
    orientation: comp.get('orientation') || 'horizontal',
    step: comp.get('step'),
    value: comp.get('value'),
    tooltip: tooltip || 'show',
    formater: formatter,
    handle: comp.get('handle') || 'round'
  }).on('slide', function(evt) {
    comp.set('value', evt.value);
  }).parent().click(function(e) {
    e.stopPropagation();
  });
  var slider = comp.slider = comp.$().data('slider');
  if (slider.touchCapable) {
    // Patch the instance to accept mouse down / up events if touchable
    // device is detected
    slider.getPercentage = (function(_super) {
      return function(ev) {
        if (this.touchCapable && !ev.type.startsWith('touch'))
          // Fake a touch event
          ev.touches = [ev];
        return _super.call(this, ev);
      };
    })(slider.getPercentage);
    slider.mouseup = (function(_super) {
      return function() {
        if (this.touchCapable) {
          Em.$(document).off({
            mousemove: this.mousemove,
            mouseup: this.mouseup
          });
        }
        return _super.apply(this, arguments);
      };
    })(slider.mouseup);
    slider.mousedown = (function(_super) {
      return function() {
        if (this.touchCapable) {
          Em.$(document).on({
            mousemove: Em.$.proxy(this.mousemove, this),
            mouseup: Em.$.proxy(this.mouseup, this)
          });
        }
        return _super.apply(this, arguments);
      };
    })(slider.mousedown);
    slider.picker.on({
      mousedown: Em.$.proxy(slider.mousedown, slider)
    });
  }
}

SL.XSliderComponent = Em.Component.extend({
  tagName: 'input',
  classNames: ['slider'],
  attributeBindings: ['safeStyle:style', 'value'],

  didInsertElement: function() {
    initializeXSlider(this);
  },

  safeStyle: function() {
    return (this.get('style') || '').htmlSafe();
  }.property('style'),

  refresh: function() {
    if (!this.$()) return;
    var value = this.get('value'), $parent = this.$().parent();
    this.slider.setValue(value);
    if (value < this.get('minValue') ||
        value > this.get('maxValue')) {
      $parent.find('.slider-handle').hide();
      $parent.find('.tooltip').hide();
    } else {
      $parent.find('.slider-handle').show();
      $parent.find('.tooltip').show();
    }
  }.observes('value').on('didRender')
});


})();

(function() {

SL.XSpinnerComponent = Em.Component.extend({
  classNames: ['spin'],
  didInsertElement: function() {
    if (this.get('size')) {
      this.$().width(this.get('size'));
      this.$().height(this.get('size'));
    }
    this.$().spin();
  }
});


})();

(function() {

SL.SocialAccountsControllerMixin = Em.Mixin.create({
  socialAccounts: Em.computed.validatable('session.account', {
    validations: {
      linkedinToken: {
        presence: { messageTranslation:
          'settings.social.errors.linkedin_not_connect' },
        inline: Em.Validations.validator('linkedinToken.expireAt', function() {
          var expireAt = this.get('linkedinToken.expireAt');
          if (expireAt && moment(expireAt).diff(new Date(), 'd') < 1)
            return Em.I18n.t('settings.social.errors.linkedin_expire');
        })
      },
      weiboToken: {
        presence: { messageTranslation:
          'settings.social.errors.weibo_not_connect' },
        inline: Em.Validations.validator('weiboToken.expireAt', function() {
          var expireAt = this.get('weiboToken.expireAt');
          if (expireAt && moment(expireAt).diff(new Date(), 'd') < 1)
            return Em.I18n.t('settings.social.errors.weibo_expire');
        })
      }
    },
    validationInhibitors: function() {
      var social = SL.parameters.socialNetwork;
      return {
        linkedinToken: !social.linkedin,
        weiboToken: !social.weibo
      };
    }.property()
  }),

  socialError:
    SL.computed.any('socialAccounts.alerts.linkedinToken.firstObject',
      'socialAccounts.alerts.weiboToken.firstObject')
});


})();

(function() {

SL.validations.companyName = {
  eName: {
    length: { allowBlank: true, minimum: 3, maximum: 255, messagesTranslation: {
      tooShort: 'company.errors.english_name.too_short',
      tooLong: 'company.errors.english_name.too_long' }
    },
    unique: {
      /* jscs:disable maximumLineLength */
      allowBlank: true, 'with': /^[\u0020-\u007e]*[A-Za-z][\u0020-\u007e]*$/,
      /* jscs:enable maximumLineLength */
      messageTranslation: 'company.errors.english_name.wrong_format',
      remote: { path: 'company',
        messageTranslation: 'company.errors.english_name.duplication' }
    }
  },
  cName: {
    length: { allowBlank: true, minimum: 2, maximum: 255, messagesTranslation: {
      tooShort: 'company.errors.chinese_name.too_short',
      tooLong: 'company.errors.chinese_name.too_long' }
    },
    unique: {
      allowBlank: true, 'with': /[\u4e00-\u9fa5]/,
      messageTranslation: 'company.errors.chinese_name.wrong_format',
      remote: { path: 'company',
        messageTranslation: 'company.errors.english_name.duplication' }
    }
  }
};

SL.validations.companyBase = Em.$.extend(true, {
  companyType: {
    presence: { messageTranslation: 'company.errors.company_type.presence' }
  },
  companySize: {
    presence: { messageTranslation: 'company.errors.company_size.presence' }
  },
  location:
    Em.Validations.validator('location.content', function() {
      if (Em.isNone(this.get('location.content')))
        return Em.I18n.t('company.errors.location.presence');
    }),
  industry:
    Em.Validations.validator('industries.content.[]', function() {
      if (!this.get('industries.content.length'))
        return Em.I18n.t('company.errors.industry.presence');
    })
}, SL.validations.companyName);

SL.validations.companyBasic = Em.$.extend(true, {
  eName: {
    presence: { 'if': 'name.isAscIIName',
      messageTranslation: 'company.errors.english_name.presence' },
    length: { 'if': 'name.isAscIIName' },
    unique: { 'if': 'name.isAscIIName' }
  },
  cName: {
    length: { 'if': 'name.cName' },
    unique: { 'if': 'name.cName' }
  }
}, SL.validations.companyBase);

SL.validations.companyWhole = Em.$.extend(true, {
  eName: {
    inline: Em.Validations.validator('cName', function() {
      if (Em.isEmpty(this.get(this.property)) &&
        Em.isEmpty(this.get('cName')))
      return Em.I18n.t('company.errors.chinese_name.presence');
    }),
  },
  cName: {
    inline: Em.Validations.validator('eName', function() {
      if (Em.isEmpty(this.get(this.property)) &&
        Em.isEmpty(this.get('eName')))
      return Em.I18n.t('company.errors.chinese_name.presence');
    })
  },
  address: {
    length: { minimum: 10, maximum: 255, allowBlank: true,
    messagesTranslation: {
      tooShort: 'company.errors.address.too_short',
      tooLong: 'company.errors.address.too_long' }
    }
  },
  tn1: {
    length: { minimum: 2, maximum: 3, allowBlank: true,
      messagesTranslation: { tooShort: 'company.errors.tn1.too_short',
        tooLong: 'company.errors.tn1.too_long' }
    },
    format: {
      with: /^([1-9][0-9]|[1-9][0-9]{2,3})$/, allowBlank: true,
      messageTranslation: 'company.errors.tn1.wrong_format'
    }
  },
  tn2: {
    length: { minimum: 2, maximum: 3, allowBlank: true,
      messagesTranslation: { tooShort: 'company.errors.tn2.too_short',
        tooLong: 'company.errors.tn2.too_long' }
    },
    format: {
      with: /^([1-9][0-9]|[1-9][0-9]{2,3})$/, allowBlank: true,
      messageTranslation: 'company.errors.tn2.wrong_format'
    }
  },
  tn3: {
    length: { minimum: 7, maximum: 8, allowBlank: true,
      messagesTranslation: { tooShort: 'company.errors.tn3.too_short',
        tooLong: 'company.errors.tn3.too_long' }
    },
    format: {
      with: /\d/, allowBlank: true,
      messageTranslation: 'company.errors.tn3.wrong_format'
    }
  },
  website: {
    length: { maximum: 255, messagesTranslation: {
      tooLong: 'company.errors.website.too_long' }
    },
    url: { allowBlank: true, allowIp: false, allowPort: false,
      protocols: ['http', 'https'],
      messageTranslation:
        'company.errors.website.wrong_format'
    }
  },
  description: {
    length: { minimum: 20,
      messagesTranslation: { tooShort: 'company.errors.description.too_short' }
    }
  }
}, SL.validations.companyBase);


})();

(function() {

/* jscs:disable maximumLineLength */
var emailRule = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // See emailregex.com
/* jscs:enable maximumLineLength */

SL.validations.dateDuration = {
  startDate: {
    presence: { messageTranslation:
      'people.errors.duration.start_date.presence' }
  },
  endDate: {
    presence: { messageTranslation:
      'people.errors.duration.end_date.presence' },
    inline: Em.Validations.validator('startDate', function() {
      if (this.get(this.property) < this.get('startDate'))
        return Em.I18n.t('people.errors.duration.end_date.wrong_order');
    })
  }
};

SL.validations.email = {
  name: {
    unique: { with: emailRule,
      allowBlank: true, messageTranslation: 'people.errors.email.wrong_format',
      remote: { path: 'person_email', idKey: 'owner.owner.id',
        messageTranslation: 'people.errors.email.duplication' }
    }
  }
};

SL.validations.mobile = {
  name: {
    length: { maximum: 20, messagesTranslation:
      { tooLong: 'people.errors.mobile.too_long' }},
    unique: { allowBlank: true,
      messageTranslation: 'people.errors.mobile.wrong_format',
      remote: { path: 'person_mobile', idKey: 'owner.owner.id',
        messageTranslation: 'people.errors.mobile.duplication' }
    }
  }
};

SL.validations.people = {
  eName: {
    length: { minimum: 3, maximum: 255, allowBlank: true,
      messagesTranslation: {
        tooShort: 'people.errors.english_name.too_short',
        tooLong: 'people.errors.english_name.too_long'
      }
    },
    format: { with: /^[A-Za-z][A-Za-z\s]*[A-Za-z]$/,
      allowBlank: true, messageTranslation:
        'people.errors.english_name.wrong_format' }
  },
  cName: {
    length: { minimum: 2, maximum: 255, allowBlank: true,
      messagesTranslation: {
        tooShort: 'people.errors.chinese_name.too_short',
        tooLong: 'people.errors.chinese_name.too_long'
      }
    },
    format: { with: /^[\u00b7\u3400-\u4dbf\u4e00-\u9fa5]+$/, allowBlank: true,
      messageTranslation:
        'people.errors.chinese_name.wrong_format' }
  },
  emailRequired: Em.Validations.validator('currentEmail.name', function() {
    if (this.get('isSelf') && Em.isEmpty(this.get('currentEmail.name')))
      return Em.I18n.t('people.errors.email.required');
  }),
  mobileRequired: Em.Validations.validator('currentMobile.name', function() {
    if (this.get('isSelf') && Em.isEmpty(this.get('currentMobile.name')))
      return Em.I18n.t('people.errors.mobile.required');
  }),
  currentEmail: true,
  moreEmail: true,
  currentMobile: true,
  moreMobile: true,
  startWorkYear: {
    presence: { messageTranslation: 'people.errors.start_year.presence' }
  },
  currentWork: true
};

SL.validations.peopleBasic = Em.$.extend(true, {
  eName: {
    presence: { if: 'isAscIIName',
      messageTranslation: 'people.errors.english_name.wrong_char' },
    length: { if: 'isAscIIName' },
    format: { if: 'isAscIIName' }
  },
  cName: {
    length: { if: 'cName' },
    format: { if: 'cName' }
  }
}, SL.validations.people);

SL.validations.peopleEdit = Em.$.extend(true, {
  eName: {
    inline: Em.Validations.validator('cName', function() {
      if (Em.isEmpty(this.get(this.property)) &&
        Em.isEmpty(this.get('cName')))
      return Em.I18n.t('people.errors.english_name.presence');
    })
  },
  cName: {
    inline: Em.Validations.validator('eName', function() {
      if (Em.isEmpty(this.get(this.property)) &&
        Em.isEmpty(this.get('eName')))
      return Em.I18n.t('people.errors.english_name.presence');
    })
  },
  expectedSalary: {
    numericality: { onlyInteger: true, allowBlank: true, greaterThan: 0 },
    inline: Em.Validations.validator(function() {
      var val = this.get(this.property);
      if (val >= SL.parameters.maxSalary) {
        return Em.I18n.t('errors.lessThan', { count: Em.I18n.locale === 'zh' ?
          SL.parameters.maxSalary / 10 : SL.parameters.maxSalary });
      }
    })
  },
  employmentStatus: {
    presence: { messageTranslation: 'people.errors.motivation.presence' }
  },
  birthDate: Em.Validations.validator(function() {
    var date = this.get(this.property);
    if (!date) return;
    date = typeof date === 'string' ?
      moment(date, 'YYYY-MM-DD', true) : moment(date);
    if (!date.isValid())
      return Em.I18n.t('people.errors.birth_date.wrong_format');
    if (moment().diff(date, 'y') > SL.parameters.maxAge)
      return Em.I18n.t('people.errors.birth_date.too_young',
        { age: SL.parameters.maxAge });
    if (moment().diff(date, 'y') < SL.parameters.minAge)
      return Em.I18n.t('people.errors.birth_date.too_old',
        { age: SL.parameters.minAge });
  }),
  currentEdu: true,
  currentProject: true
}, SL.validations.people);

SL.validations.workExperienceBasic = Em.$.extend(true, {
  company: {
    presence: { messageTranslation: 'people.errors.company.presence' }
  },
  title: {
    presence: { messageTranslation: 'people.errors.title.presence' }
  },
  function: {
    inline: Em.Validations.validator('workingAtCurrentCompany', function() {
      if (this.get('workingAtCurrentCompany') &&
        Em.isEmpty(this.get(this.property + '.content'))) {
        return Em.I18n.t('people.errors.function.presence');
      }
    })
  },
  totalSalary: {
    numericality: { allowBlank: true, onlyInteger: true, greaterThan: 0 },
    inline: Em.Validations.validator('workingAtCurrentCompany', function() {
      var val = this.get(this.property);
      if (this.get('workingAtCurrentCompany') && Em.isEmpty(val)) {
        return Em.I18n.t('people.errors.salary.presence');
      }
      if (val >= SL.parameters.maxSalary) {
        return Em.I18n.t('errors.lessThan', { count: Em.I18n.locale === 'zh' ?
          SL.parameters.maxSalary / 10 : SL.parameters.maxSalary });
      }
    })
  },
  newCompany: true,
  newTitle: true
}, SL.validations.dateDuration);

SL.validations.workExperience = Em.$.extend(true, {
  companyEmail: {
    format: { with: emailRule, allowBlank: true,
      messageTranslation: 'people.errors.c_email.wrong_format' }
  },
  gl: {
    format: { with: /^[\d]{2,8}$/, allowBlank: true,
      messageTranslation: 'people.errors.telephone.wrong_format' }
  }
}, SL.validations.workExperienceBasic);

SL.validations.title = {
  name: {
    format: { with: /\S/, messageTranslation: 'people.errors.title.blank' }
  }
};

function emptyRecordObserver(holderKey, arrayKey) {
  return function(sender, key) {
    var empty = Em.isEmpty(this.get(holderKey + '.name'));
    var array = this.get(arrayKey),
        rec = this.get(holderKey + '.content');
    if (!Em.isArray(array) || !rec) return;
    array[empty ? 'removeObject' : 'addObject'](rec);
  }.observes(holderKey + '.name').on('init');
}

SL.PersonalInfoMixin = Em.Mixin.create({
  isSelf: false,
  currentEmail: Em.computed.validatable({
    validations: SL.validations.email
  }),
  moreEmail: Em.computed.validatable({
    validations: Em.$.extend(true, {
      name: { unique: { if: 'owner.owner.id' } }
    }, SL.validations.email),
    invalid: Em.computed.notEmpty('alerts.name')
  }),
  moreEmailCount: function() {
    var c = this.get('emails.length') - 1;
    return c > 0 ? c : '';
  }.property('emails.length'),

  currentMobile: Em.computed.validatable({
    validations: SL.validations.mobile
  }),
  moreMobile: Em.computed.validatable({
    validations: Em.$.extend(true, {
      name: { unique: { if: 'owner.owner.id' } }
    }, SL.validations.mobile),
    invalid: Em.computed.notEmpty('alerts.name')
  }),
  moreMobileCount: function() {
    var c = this.get('mobiles.length') - 1;
    return c > 0 ? c : '';
  }.property('mobiles.length'),

  mailEmptyObserver: emptyRecordObserver('currentEmail', 'emails'),
  mobileEmptyObserver: emptyRecordObserver('currentMobile', 'mobiles')
});

SL.BasicWorkExpMixin = Em.Mixin.create({
  // Work duration handling
  profileType: 'resume',
  workingAtCurrentCompany: SL.computed.toNow('endDate'),
  workDurationError: SL.computed.any('alerts.startDate.firstObject',
    'alerts.endDate.firstObject'),

  // New Company Handling
  newCompany: Em.computed.validatable({
    validations: SL.validations.companyBasic
  }),
  createNewCompany: function() {
    // Only need to create new company if the company name is not empty and
    // current company alias id is not selected
    var name = this.get('newCompany.name.name');
    return (name && name.trim() &&
      !this.get('company.id'));
  }.property('newCompany.name.name', 'company.id').readOnly(),
  companyError: SL.computed.any('alerts.company.firstObject',
    'newCompany.alerts.eName.firstObject',
    'newCompany.alerts.cName.firstObject'),
  newCompanyObserver: function() {
    if (this.get('createNewCompany'))
      this.get('newCompany').toggleAlerts('volatile');
  }.observes('createNewCompany'),

  // New title Handling
  newTitle: Em.computed.validatable({
    validations: SL.validations.title
  }),
  titleError: SL.computed.any('alerts.title.firstObject',
    'newTitle.alerts.name.firstObject'),

  validationInhibitors: function() {
    var isContact = this.get('profileType') === 'contact';
    return {
      newCompany: !this.get('company') || !!this.get('company.id'),
      newTitle: !this.get('title') || !!this.get('title.id'),
      function: isContact,
      totalSalary: isContact,
      startDate: isContact,
      endDate: isContact
    };
  }.property(
    'company', 'company.id', 'title', 'title.id', 'profileType').readOnly(),
  salary: SL.computed.salary('totalSalary')

});

SL.validations.educationExp = Em.$.extend(true, {
  school: {
    presence: { messageTranslation: 'people.errors.school.presence' }
  },
  major: {
    presence: { messageTranslation: 'people.errors.major.presence' }
  },
  newMajor: true,
  newSchool: true
}, SL.validations.dateDuration);

SL.validations.major = {
  name: {
    format: { with: /\S/, messageTranslation: 'people.errors.major.blank' }
  }
};

SL.validations.school = {
  name: {
    format: { with: /\S/, messageTranslation: 'people.errors.school.presence' }
  }
};

SL.validations.projectExp = Em.$.extend(true, {
  name: {
    format: { with: /\S/, messageTranslation: 'people.errors.project.no_name' }
  },
  company: {
    presence: { messageTranslation: 'people.errors.project.presence' }
  },
  newCompany: true
}, SL.validations.dateDuration);


})();

(function() {

function salaryValidator(allowEmpty, fromProp) {
  return Em.Validations.validator(function() {
    var s = this.get(this.property),
        amount = Em.typeOf(s) === 'object' ? s.amount : s;
    if (Em.isEmpty(amount) && !allowEmpty || !Em.isEmpty(amount) &&
        !Em.Validations.patterns.numericality.test(amount))
      return Em.Validations.messages.render('notANumber');
    if (allowEmpty && amount < 0)
      return Em.Validations.messages.render('greaterThanOrEqualTo', {
        count: 0
      });
    else if (!allowEmpty && amount <= 0)
      return Em.Validations.messages.render('greaterThan', { count: 0 });
    var unit = s && s.unit || 'kPerYear';
    if (unit === 'perDay' && amount && !(/^[+\-]?\d+$/.test(amount)))
      return Em.Validations.messages.render('notAnInteger');
    if (!Em.isEmpty(amount) && fromProp) {
      var from = this.get(fromProp);
      var fromAmount = Em.typeOf(from) === 'object' ? from.amount : from;
      if (amount <= fromAmount)
        return Em.I18n.t('common.filter.range_error');
    }
    var maxAmount;
    switch (unit) {
      case 'kPerMonth':
        maxAmount = 500;
        break;
      case 'perDay':
        maxAmount = 10000;
        break;
      default:
        maxAmount = 5000;
    }
    if (amount > maxAmount) {
      if (unit === 'kPerYear' && Em.I18n.locale === 'zh')
        maxAmount /= 10;
      return Em.Validations.messages.render('lessThanOrEqualTo', {
        count: maxAmount
      });
    }
  });
}
SL.validations.salaryValidator = salaryValidator;

SL.validations.vacancy = {
  salaryFrom: salaryValidator(),
  salaryTo: salaryValidator(),
  headCount: {
    numericality: { onlyInteger: true, allowBlank: false,
      greaterThanOrEqualTo: 1, lessThanOrEqualTo: 100 }
  },
  workLocation:
    Em.Validations.validator('workLocation.content', function() {
      if (Em.isNone(this.get('workLocation.content')))
        return Em.I18n.t('vacancy.errors.location.presence');
    }),
  jobFunction:
    Em.Validations.validator('jobFunction.content', function() {
      if (Em.isNone(this.get('jobFunction.content')))
        return Em.I18n.t('vacancy.errors.function.presence');
    }),
  clientCompany:
    Em.Validations.validator('clientCompany', function() {
      if (Em.isNone(this.get('clientCompany')))
        return Em.I18n.t('vacancy.errors.client.presence');
    }),
  hrContact:
    Em.Validations.validator('hrContact.content', 'notifyEmailContact',
      'hrContact.defaultProfile.emails.length',
       'hrContact.defaultProfile.lastWork.companyEmail',
      function() {
      if (Em.isNone(this.get('hrContact.content')) &&
        this.get('clientContactRequired'))
        return Em.I18n.t('vacancy.errors.hr_contact.presence');
      else if (this.get('notifyEmailContact')) {
        var p = this.get('hrContact.defaultProfile');
        if (!p || (!p.get('lastWork.companyEmail') &&
         !p.get('emails.length'))) {
          return Em.I18n.t('vacancy.errors.hr_contact.no_email_set');
        }
      }
    }),
  caseFrom:
    Em.Validations.validator('caseFrom.content', function() {
      if (Em.isNone(this.get('caseFrom.content')))
        return Em.I18n.t('vacancy.errors.case_from.presence');
    }),
  title: {
    presence: { messageTranslation: 'vacancy.errors.title.presence' }
  },
  companyDesc: Em.Validations.validator(function() {
    var companyDesc = this.get('companyDesc');
    if (Em.isEmpty(companyDesc))
      return Em.I18n.t('vacancy.errors.company_desc.presence');
    var isAscII = !companyDesc || /^[\x00-\x7F]*$/.test(companyDesc);
    var companyDescLen = companyDesc.length;
    if (companyDescLen > 80)
      return Em.I18n.t('vacancy.errors.company_desc.tooLong');
    else if (isAscII && companyDescLen < 10)
      return Em.I18n.t('vacancy.errors.company_desc.eTooShort');
    else if (!isAscII && companyDescLen < 5)
      return Em.I18n.t('vacancy.errors.company_desc.cTooShort');
  }),
  highlight: {
    length: { minimum: 30, allowBlank: true, messagesTranslation: {
        tooShort: 'vacancy.errors.highlight.tooShort' }}
  },
  responsibility: Em.Validations.validator('requirementRequired', function() {
    var resp = this.get('responsibility'),
        required = this.get('requirementRequired');
    if (required && Em.isEmpty(resp))
      return Em.I18n.t('vacancy.errors.responsibility.presence');
  }),
  requirements: Em.Validations.validator('requirementRequired', function() {
    var resp = this.get('requirements'),
        required = this.get('requirementRequired');
    if (required && Em.isEmpty(resp))
      return Em.I18n.t('vacancy.errors.requirements.presence');
  }),
  newTitle: true,
  newCompany: true,
  requiredExperience: {
    presence: {
      messageTranslation: 'vacancy.errors.required_experience.presence' }
  },
  creator:
    Em.Validations.validator('creator.content', function() {
      if (Em.isNone(this.get('creator.content')))
        return Em.I18n.t('vacancy.errors.creator.presence');
    })
};


})();

(function() {

SL.ExistenceMixin = Em.Mixin.create({
  existenceBindKey: 'content',

  init: function() {
    this._super.apply(this, arguments);
    this.with = Em.Object.create({
      unknownProperty: function(key) {
        Em.defineProperty(this, key,
          Em.computed.notEmpty('model.' + key).readOnly());
        return this.get(key);
      }
    });
    Em.bind(this, 'with.model', this.get('existenceBindKey'));
  }
});


})();

(function() {

SL.Filter = Em.Object.extend({
  tmpQueryWeight: 10,

  createEntryPoint: Em.K,
  save: Em.K,
  filter: Em.K,
  invalid: Em.computed.not('valid'),
  query: function() {
    var queryType = this.get('queryType');
    var query = queryType === 'plus' ?
        { boost: this.get('queryWeight') + 1 } :
        { type: queryType };
    query.filter = this.get('filter');
    return query;
  }.property('queryType', 'queryWeight', 'filter').readOnly(),
  label: function() {},
  title: function() {
    return this._label();
  }.property().readOnly(),
  queryType: function() {
    switch (this.get('queryWeight')) {
      case 0:
        return 'must_not';
      case 10:
        return 'must';
      default:
        return 'plus';
    }
  }.property('queryWeight').readOnly(),
  certainty: function(v) {
    switch (v) {
      case 10:
        return Em.I18n.t('common.filter.mandatory');
      case 0:
        return Em.I18n.t('common.filter.exclusive');
      default:
        return Em.I18n.t('common.filter.optional', { weight: v });
    }
  },
  certaintyInfo: function() {
    switch (this.get('tmpQueryWeight')) {
      case 10:
        return Em.I18n.t('common.filter.must');
      case 0:
        return Em.I18n.t('common.filter.must_not');
      default:
        return Em.I18n.t('common.filter.plus',
        { weight: this.get('tmpQueryWeight') });
    }
  }.property('tmpQueryWeight').readOnly(),
  background: function() {
    if (this.get('tmpQueryWeight') === 0)
      return 'alert-warning bg-warning-ltest';
    return 'alert-info bg-info-ltest';
  }.property('tmpQueryWeight').readOnly(),
  _label: function() {
    if (this.constructor.labelTranslation)
      return Em.I18n.t(this.constructor.labelTranslation);
    return this.constructor.label;
  }
});

SL.RecordsFilter = SL.Filter.extend({
  init: function() {
    this._super();
    this.values = [];
    this.tmpValues = [];
  },
  filter: function() {
    return this.getFilter(this.get('values'));
  }.property('values.[]').readOnly(),
  filterValue: function(value) {
    var res = {};
    if (typeof value === 'string') {
      res.query = { multi_match: {
        query: value,
        type: 'phrase',
        fields: [
          this.constructor.queryKey + '.e_name',
          this.constructor.queryKey + '.c_name',
          this.constructor.queryKey + '.c_name.*'
        ]
      }};
    } else {
      res.term = {};
      res.term[this.constructor.queryKey + '.id'] = value.id;
    }
    return res;
  },
  getFilter: function(values) {
    var self = this;
    if (!values.length) return;
    var filters = Em.$.map(values, function(v) {
      return self.filterValue(v);
    });
    if (filters.length === 1) return filters[0];
    return { or: filters };
  },
  label: function() {
    var self = this;
    return this._label() + ': ' +
      this.get('values').map(function(v) {
        return self.displayKey(v);
      }).join(', ');
  }.property('values.[]').readOnly(),
  displayKey: function(v) {
    return typeof v.get === 'function' ? v.get('name') : v;
  },
  createEntryPoint: function() {
    this.set('tmpValues', this.values.slice(0));
  },
  save: function() {
    this.get('values').setObjects(this.tmpValues);
  },
  valid: function() {
    return !!this.get('tmpValues.length');
  }.property('tmpValues.[]').readOnly()
});

SL.PlainRecordsFilter = SL.RecordsFilter.extend({
  displayKey: function(v) {
    return typeof v.get === 'function' ? v.get('name') : v;
  },
  filterValue: function(value) {
    var res = {};
    if (typeof value === 'string') {
      res.query = { match: {} };
      res.query.match[this.constructor.queryKey + '.name'] = value;
    } else {
      res.term = {};
      res.term[this.constructor.queryKey + '.id'] = value.id;
    }
    return res;
  }
});

SL.TypedRecordsFilter = SL.RecordsFilter.extend({
  filterValue: function(value) {
    var key = {};
    key[this.constructor.queryKey] = value;
    return { term: key };
  },
  displayKey: function(v) {
    return this.constructor.helper(v);
  }
});

SL.InheritedRecordsFilter = SL.RecordsFilter.extend({
  queryKey: function() {
    return this.constructor.queryKey;
  }.property(),
  filterValue: function(value) {
    return this._f(value, this.get('queryKey'));
  },
  _f: function(value, key) {
    var res = [];
    res[0] = { term: {} };
    res[0].term[key + '.id'] = value.id;
    var type = Em.get(value, 'type');
    if (type < this.constructor.levels.length) {
      res[1] = { term: {} };
      res[1].term[key + '.' + this.constructor.levels[type]] = value.id;
    }
    return res;
  }
});

SL.RangeFilter = SL.Filter.extend(Em.Validations.Mixin, {
  createEntryPoint: function() {
    this.setProperties({
      tmpFrom: this.from,
      tmpTo: this.to
    });
  },
  save: function() {
    this.setProperties({
      from: this.tmpFrom,
      to: this.tmpTo
    });
  },
  rangeCond: function(from, to) {
    var cond = { gte: from, lte: to };
    if (!cond.gte) delete cond.gte;
    if (!cond.lte) delete cond.lte;
    return cond;
  },
  filter: function() {
    var res = { range: {} };
    res.range[this.constructor.queryKey] =
      this.rangeCond(this.get('from'), this.get('to'));
    return res;
  }.property('from', 'to').readOnly(),
  label: function() {
    var from = this.get('from'),
        to = this.get('to');
    var str;
    if (from && to)
      str = from + ' - ' + to;
    else if (from)
      str = '&ge; ' + from;
    else if (to)
      str = '&le; ' + to;
    return this._label() + ': ' + str + ' ' +
      (this.constructor.unitTranslation ?
        Em.I18n.t(this.constructor.unitTranslation) : this.constructor.unit);
  }.property('from', 'to').readOnly(),
  valid: function() {
    return (this.get('isValid') && (Em.isPresent(this.get('tmpFrom')) ||
      Em.isPresent(this.get('tmpTo'))));
  }.property('isValid', 'tmpFrom', 'tmpTo').readOnly(),
  rangeError: SL.computed.any('alerts.tmpFrom.firstObject',
    'alerts.tmpTo.firstObject'),
});


})();

(function() {

SL.FiltersControllerMixin = Em.Mixin.create({
  init: function() {
    this._super.apply(this, arguments);
    this.set('filters', []);
    this.filterExists = Em.Object.create({
      filters: this.filters,
      unknownProperty: function(key) {
        Em.defineProperty(this, key, function() {
          return this.get('filters').find(function(item) {
            return item.constructor.type === key;
          });
        }.property('filters.[]'));
        return this.get(key);
      }
    });
  },

  filterChanged: function() {
    if (this.get('model'))
      this.send('refresh');
  }.observes('filters.@each.query'),

  updatePills: function() {
    // Force to notify the array changed to refresh the pillboxes
    this.get('filters').notifyPropertyChange('[]');
  }.observes('filters.@each.label', 'filters.@each.queryType'),

  applyFilter: function(queryData) {
    var scopeFilter = this.get('filters').find(function(filter) {
      return filter.constructor.type === 'myTalents';
    });
    var q = {
      mandatory_filters: this.get('filters').filter(function(filter) {
        if (filter === scopeFilter) return;
        return filter.get('queryType') !== 'plus';
      }).mapBy('query'),
      optional_filters: this.get('filters').filter(function(filter) {
        if (filter === scopeFilter) return;
        return filter.get('queryType') === 'plus';
      }).mapBy('query')
    };

    if (!q.mandatory_filters.length) delete q.mandatory_filters;
    if (!q.optional_filters.length) delete q.optional_filters;
    if (scopeFilter)
      q.scope = scopeFilter.get('query');
    Em.$.extend(true, queryData, { query: q });
  },

  editFilter: function() {
    var self = this;
    return function(filter) {
      self.set('curFilter', filter);
      filter.set('tmpQueryWeight', filter.queryWeight);
      filter.createEntryPoint();
      self.send('openFilter', filter);
    };
  }.property(),

  filterStyle: function(filter) {
    var style = 'btn-sm';
    switch (filter.get('queryWeight')) {
      case 10:
        return style + ' btn-success bg-success lt';
      case 0:
        return style + ' btn-warning bg-warning lter';
      default:
        return style + ' btn-success bg-success-ltest';
    }
  },

  newFilter: function(filterName) {
    var Filter = this.container._registry.resolve('filter:' + filterName);
    if (!Filter.singleton || !this.get('filterExists.' + Filter.type)) {
      var opts = Array.prototype.slice.call(arguments);
      opts.shift();
      this.set('curFilter', Filter.create({ options: opts }));
      return this.get('curFilter');
    }
  },

  saveFilter: function() {
    var filter = this.get('curFilter');
        filter.set('queryWeight', filter.tmpQueryWeight).set(
      'accountId', this.get('session.accountId'));
    filter.save();
    this.get('filters').addObject(filter);
    this.set('page', 1);
  },

  actions: {
    newFilter: function(filterName) {
      var filter = this.newFilter.apply(this, arguments);
      if (filter)
        this.send('openFilter', filter);
    },
    saveFilter: function() {
      this.saveFilter();
    },
    toggleAlerts: function(action) {
      if (typeof this.get('curFilter.toggleAlerts') === 'function')
        this.get('curFilter').toggleAlerts(action);
      return this._super(action);
    }
  }
});


})();

(function() {

SL.PillsFilterMixin = Em.Mixin.create({
  createEntryPoint: function() {
    this._super();
    this.set('pillInput', undefined);
  },
  save: function() {
    var input = this.get('pillInput');
    if (Em.isPresent(input)) {
      this.tmpValues.push(input);
    }
    this._super();
  },
  valid: Em.computed.or('tmpValues.length', 'pillInput')
});

SL.AliasedRecordsFilter = SL.RecordsFilter.extend({
  filterValue: function(value) {
    var res = this._super(value);
    if (value && Em.get(value, 'ownerId')) {
      res = [res];
      res[1] = { term: {} };
      res[1].term[this.constructor.queryKey + '.owner.id'] =
        value.get('ownerId');
    }
    return res;
  }
});

SL.CompanyAliasFilter = SL.AliasedRecordsFilter.extend({
  filterValue: function(value) {
    var res = this._super(value);
    if (value && Em.get(value, 'owner.asGroup')) {
      res[2] = { term: {} };
      res[2].term[this.constructor.queryKey + '.owner.groups_ids'] =
        Em.get(value, 'owner.asGroup.id');
    }
    return res;
  }
});

SL.CurrentCompanyFilter = SL.CompanyAliasFilter.extend(SL.PillsFilterMixin);

SL.CurrentCompanyFilter.reopenClass({
  queryKey: 'profiles.current_work.company',
  type: 'currentCompany',
  singleton: true,
  labelTranslation: 'common.filter.current_company'
});

SL.PastCompaniesFilter = SL.CompanyAliasFilter.extend(SL.PillsFilterMixin);

SL.PastCompaniesFilter.reopenClass({
  type: 'pastCompanies',
  queryKey: 'profiles.past_work.company',
  labelTranslation: 'common.filter.past_companies'
});

SL.TitleFilter = SL.RecordsFilter.extend(SL.PillsFilterMixin);

SL.TitleFilter.reopenClass({
  type: 'title',
  queryKey: 'profiles.last_work.title',
  labelTranslation: 'common.filter.title'
});

SL.UniversityFilter = SL.AliasedRecordsFilter.extend(SL.PillsFilterMixin, {
  createEntryPoint: function() {
    this.set('tmpIs211', this.get('is211'));
    this.set('tmpIs985', this.get('is985'));
    this.set('tmpOversea', this.get('oversea'));
    this._super();
  },
  save: function() {
    this._super();
    this.set('is211', this.get('tmpIs211'));
    this.set('is985', this.get('tmpIs985'));
    this.set('oversea', this.get('tmpOversea'));
  },
  filter: function() {
    var self = this;
    var filters = ['is211', 'is985', 'oversea'].reduce(
        function(fs, key) {
      if (self.get(key)) {
        var f;
        if (key === 'oversea') {
          var k = self.constructor.queryKey + '.owner.location.country_id';
          f = { or: [{
              range: {}
            }, {
              range: {}
            }]
          };
          f.or[0].range[k] = { gt: 1, lt: 369 };
          f.or[1].range[k] = { gt: 371 };
        } else {
          f = { term: {}};
          f.term[self.constructor.queryKey + '.owner.' + key] = true;
        }
        fs.push(f);
      }
      return fs;
    }, []);
    var superFilters = this._super.apply(this, arguments);
    if (superFilters)
      superFilters = superFilters.or || [superFilters];
    filters.push.apply(filters, superFilters);
    return filters.length === 1 ? filters[0] : { or: filters };
  }.property('values.[]', 'is211', 'is985', 'oversea').readOnly(),
  label: function() {
    var label = this._super.apply(this, arguments), self = this;
    var exLabels = ['is211', 'is985', 'oversea'].reduce(
        function(ls, key) {
      if (self.get(key))
        ls.push(Em.I18n.t('common.filter.school_' + key));
      return ls;
    }, []);
    if (exLabels.length)
      label += (this.get('values.length') ? ', ' : '') + exLabels.join(', ');
    return label;
  }.property('values.[]', 'is211', 'is985', 'oversea').readOnly(),
  valid: Em.computed.or(
    'tmpValues.length', 'pillInput', 'tmpIs211', 'tmpIs985', 'tmpOversea')
});

SL.UniversityFilter.reopenClass({
  type: 'university',
  queryKey: 'profiles.education_experiences.school',
  labelTranslation: 'common.filter.university'
});

SL.LanguageFilter = SL.RecordsFilter.extend(SL.PillsFilterMixin);

SL.LanguageFilter.reopenClass({
  type: 'language',
  queryKey: 'profiles.languages',
  labelTranslation: 'common.filter.languages',
});

SL.CertificateFilter = SL.RecordsFilter.extend(SL.PillsFilterMixin);

SL.CertificateFilter.reopenClass({
  type: 'certificate',
  queryKey: 'profiles.certificates',
  labelTranslation: 'common.filter.certificates',
});

SL.SkillFilter = SL.RecordsFilter.extend(SL.PillsFilterMixin);

SL.SkillFilter.reopenClass({
  type: 'skill',
  queryKey: 'profiles.skills',
  labelTranslation: 'common.filter.skills',
});

SL.KeywordFilter = SL.PlainRecordsFilter.extend(SL.PillsFilterMixin, {
  getFilter: function(values) {
    if (!values.length) return;
    return { query: { query_string: {
      query: Em.$.map(values, function(v) {
        return '"' + v + '"';
      }).join(' OR ')
    }}};
  }
});

SL.KeywordFilter.reopenClass({
  type: 'keyword',
  labelTranslation: 'common.filter.keywords'
});

SL.ClientCompanyFilter = SL.RecordsFilter.extend(SL.PillsFilterMixin);

SL.ClientCompanyFilter.reopenClass({
  queryKey: 'client_company.name',
  type: 'clientCompany',
  labelTranslation: 'common.filter.client_company',
  singleton: true,
});

SL.JobTitleFilter = SL.RecordsFilter.extend(SL.PillsFilterMixin);

SL.JobTitleFilter.reopenClass({
  type: 'title',
  singleton: true,
  queryKey: 'title',
  labelTranslation: 'common.filter.job_title'
});

SL.CreatorFilter = SL.PlainRecordsFilter.extend({
  init: function() {
    this._super();
    this.set('tmpAlsoAsResearcher', true);
  },
  alsoAsResearcher: true,
  createEntryPoint: function() {
    this.set('tmpAlsoAsResearcher', this.get('alsoAsResearcher'));
    this._super();
  },
  save: function() {
    this._super();
    this.set('alsoAsResearcher', this.get('tmpAlsoAsResearcher'));
  },
  filterValue: function(value) {
    var res = { term : {}};
    res.term[this.constructor.queryKey] = value.id;
    if (this.get('alsoAsResearcher'))
      res = {
        or: [
          res,
          { term: { collaborators_ids: value.id }}
        ]
      };
    return res;
  }
});

SL.CreatorFilter.reopenClass({
  type: 'creator',
  singleton: true,
  queryKey: 'create_by',
  labelTranslation: 'common.filter.creator'
});

SL.CaseFromFilter = SL.PlainRecordsFilter.extend({
  filterValue: function(value) {
    var res = { term : {}};
    res.term[this.constructor.queryKey] = value.id;
    return res;
  }
});

SL.CaseFromFilter.reopenClass({
  type: 'caseFrom',
  template: 'creator',
  singleton: true,
  queryKey: 'case_from_id',
  labelTranslation: 'vacancy.create_edit.case_from'
});

SL.ExtraFieldFilter = SL.PlainRecordsFilter.extend(
    SL.PillsFilterMixin, {
  field: function() {
    return this.get('options.firstObject');
  }.property('options.firstObject'),
  _label: function() {
    return this.get('field.name');
  },
  displayKey: function(v) {
    return this.get('field.choices') ? v.label : v;
  },
  choices: function() {
    var choices = this.get('field.choices');
    return choices && {
      source: function(q, cb) {
        var substrRegex = new RegExp(q, 'i');
        cb(choices.filter(function(c) {
          return substrRegex.test(c.e_name) || substrRegex.test(c.c_name);
        }));
      }
    };
  }.property('field.choices.[]'),
  filterValue: function(value) {
    var res = {},
        key = this.constructor.queryKey + '.' + this.get('field.key');
    if (this.get('field.choices')) {
      value = value.value;
      res.term = {};
      res.term[key] = value;
    } else {
      res.query = { match: {} };
      res.query.match[key] = value;
    }
    return res;
  },
  valid: function() {
    var withValues = !!this.get('tmpValues.length');
    if (this.get('choices'))
      return withValues;
    return withValues || !!this.get('pillInput');
  }.property('tmpValues.length', 'pillInput')
});

SL.ExtraFieldFilter.reopenClass({
  type: 'extraField',
  queryKey: 'profiles.extra'
});

SL.VacancyExtraFieldFilter = SL.ExtraFieldFilter.extend();
SL.VacancyExtraFieldFilter.reopenClass({
  template: 'extra-field',
  type: 'vacancyExtraField',
  queryKey: 'extra'
});


})();

(function() {

function ageToBirthDate(age) {
  if (age) return moment().subtract(age, 'years').format('YYYY-MM-DD');
}

SL.AgeFilter = SL.RangeFilter.extend({
  rangeCond: function(from, to) {
    return this._super(ageToBirthDate(to), ageToBirthDate(from));
  },

  validations: {
    tmpFrom: {
      numericality: { onlyInteger: true, allowBlank: true,
        greaterThanOrEqualTo: SL.parameters.minAge,
        lessThanOrEqualTo: SL.parameters.maxAge }
    },
    tmpTo: {
      numericality: { onlyInteger: true, allowBlank: true,
        greaterThanOrEqualTo: SL.parameters.minAge,
        lessThanOrEqualTo: SL.parameters.maxAge },
      inline: Em.Validations.validator('tmpFrom', function() {
        if (parseInt(this.get(this.property)) <= this.get('model.tmpFrom'))
          return 'To age should be greater than from age';
      })
    }
  }
});

SL.AgeFilter.reopenClass({
  singleton: true,
  type: 'age',
  queryKey: 'profiles.birth_date',
  labelTranslation: 'common.filter.age',
  unitTranslation: 'common.filter.age_unit',
});

SL.SalaryRangeFilter = SL.RangeFilter.extend({
  tmpFromConv: SL.computed.salary('tmpFrom'),
  tmpToConv: SL.computed.salary('tmpTo'),
  fromConv: SL.computed.salary('from'),
  toConv: SL.computed.salary('to'),
  salaryUnit: SL.computed.salaryUnit(
    '_salaryUnit', 'tmpFrom', 'tmpTo'),

  label: function() {
    var from = this.get('from'), to = this.get('to'),
        unit = from && from.unit || to && to.unit || 'kPerYear';
    if (Em.typeOf(from) === 'object')
      from = from.amount;
    if (Em.typeOf(to) === 'object')
      to = to.amount;
    var str;
    if (!to)
      str = '≥' + SL.helper.salaryLabel(unit, from);
    else if (!from)
      str = '≤' + SL.helper.salaryLabel(unit, to);
    else
      str = SL.helper.salaryLabel(unit, from, to);
    return this._label() + ': ' + str;
  }.property('from', 'to').readOnly(),
  validations: {
    tmpFrom: SL.validations.salaryValidator(true),
    tmpTo: SL.validations.salaryValidator(true, 'tmpFrom')
  },
  valid: function() {
    return (this.get('isValid') && (Em.isPresent(this.get('tmpFromConv')) ||
      Em.isPresent(this.get('tmpToConv'))));
  }.property('isValid', 'tmpFrom', 'tmpTo').readOnly(),
});

SL.SalaryRangeFilter.reopenClass({
  singleton: true,
  type: 'salaryRange',
  labelTranslation: 'common.filter.salary_range',
  unitTranslation: 'common.salary_unit',
  queryKey: 'profiles.last_work.total_salary'
});

function durationToYear(d) {
  if (Em.isPresent(d)) return moment().year() - d;
}

SL.YearOfExperienceFilter = SL.RangeFilter.extend({
  graduatesOnly: [],
  createEntryPoint: function() {
    this.set('tmpGraduatesOnly', (this.get('graduatesOnly') || []).slice(0));
    this._super();
  },
  save: function() {
    this._super();
    this.set('graduatesOnly', this.get('tmpGraduatesOnly'));
  },
  rangeCond: function(from, to) {
    return this._super(durationToYear(to), durationToYear(from));
  },
  filter: function() {
    if (this.get('graduatesOnly.length')) {
      var res, min = -1, max = -2;  // Default to student
      this.get('graduatesOnly').forEach(function(g) {
        switch (g) {
          case 'student':
            max = Math.max(max, -2);
            min = undefined;
            break;
          case 'graduates':
            max = Math.max(max, 0);
            break;
          case 'intern':
            max = Math.max(max, 1);
            min = undefined;
            break;
        }
      });
      if (max !== min) {
        res = { range: {} };
        res.range[this.constructor.queryKey] = this.rangeCond(min, max);
      } else {
        res = { term: {} };
        res.term[this.constructor.queryKey] = durationToYear(max);
      }
      return res;
    }
    return this._super.apply(this, arguments);
  }.property('from', 'to', 'graduatesOnly.[]').readOnly(),
  label: function() {
    if (this.get('graduatesOnly.length')) {
      var res = this.get('graduatesOnly').map(function(g) {
        if (g === 'intern')
          return Em.I18n.t('common.filter.intern');
        return Em.I18n.t('people.profile.' + g);
      });
      return this._label() + ': ' + res.join(', ');
    }
    return this._super.apply(this, arguments);
  }.property('from', 'to', 'graduatesOnly.[]').readOnly(),
  setGraduatesOnly: function() {
    if (this.get('tmpGraduatesOnly.length')) {
      this.setProperties({
        tmpFrom: undefined,
        tmpTo: undefined
      });
    }
  }.observes('tmpGraduatesOnly.[]'),

  disableSave: function() {
    return !this.get('tmpGraduatesOnly.length') && this.get('invalid');
  }.property('invalid', 'tmpGraduatesOnly.[]'),

  validations: {
    tmpFrom: {
      numericality: { onlyInteger: true,
        allowBlank: true, greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: SL.parameters.maxYearOfExperience }
    },
    tmpTo: {
      numericality: { onlyInteger: true,
        allowBlank: true, greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: SL.parameters.maxYearOfExperience },
      inline: Em.Validations.validator('tmpFrom', function() {
        if (parseInt(this.get(this.property)) <= this.get('model.tmpFrom'))
          return 'To value should be greater than from value';
      })
    }
  }
});

SL.YearOfExperienceFilter.reopenClass({
  singleton: true,
  type: 'yearOfExperience',
  queryKey: 'profiles.start_work_year',
  labelTranslation: 'common.filter.year_of_experiences',
  unitTranslation: 'common.filter.year_of_experiences_unit'
});

SL.DurationFilter = SL.RangeFilter.extend({
  init: function() {
    this._super();
    // Hack: let from as selected date, to as range
    this.set('tmpFrom', moment().format('YYYY-MM-DD'));
    this.set('tmpTo', '0');
  },
  rangeCond: function(selectedDate, range) {
    var startDate, endDate,
        localDate = moment(selectedDate);
    switch (range) {
    case 'earlier':
      endDate = localDate.format();
      break;
    case 'later':
      startDate = localDate.format();
      break;
    default:
      range = parseInt(range);
      startDate = localDate.clone().subtract(range, 'd').format();
      endDate = localDate.add(range + 1, 'd').format();
    }
    return this._super(startDate, endDate);
  },
  label: function() {
    var l = this.get('from'),
        range = this.get('to');
    switch (range) {
    case 'earlier':
      l = Em.I18n.t('common.filter.earlier_than') + l;
      break;
    case 'later':
      l = Em.I18n.t('common.filter.later_than') + l;
      break;
    case '0':
      break;
    default:
      l = l + ' &plusmn;' + range +
      Em.I18n.t('common.filter.update_time_unit');
    }
    return Em.I18n.t(this.constructor.labelTranslation) + ': ' + l;
  }.property('from', 'to')
});

SL.DurationFilter.reopenClass({
  template: 'duration',
  singleton: true,
  queryKey: 'create_at',
  valid: function() {
    return !!this.get('tmpFrom');
  }.property('tmpFrom')
});

SL.UpdateTimeFilter = SL.DurationFilter.extend();

SL.UpdateTimeFilter.reopenClass({
  type: 'updateTime',
  queryKey: 'profiles.update_at',
  labelTranslation: 'common.filter.update_time'
});

SL.VacancySalaryRangeFilter = SL.SalaryRangeFilter.extend({
  _filter: function(from, to, unit) {
    // Match range overlap
    from = Em.isPresent(from) &&
      { range: { 'salary_to.amount': this.rangeCond(from, null) }};
    to = Em.isPresent(to) &&
      { range: { 'salary_from.amount': this.rangeCond(null, to) }};
    return { and: [
      from && to ? { and: [from, to] } : from || to,
      { term: { 'salary_from.unit': unit.underscore() } }
    ] };
  },
  filter: function() {
    var from = this.get('from'), to = this.get('to');
    var unit = from && from.unit || to && to.unit || 'kPerYear';
    unit = unit.replace(/^kP/, 'p');
    from = SL.helper.salaryValue(from);
    to = SL.helper.salaryValue(to);
    var filter = this._filter(from, to, unit);
    if (unit === 'perYear')
      return { or: [filter, this._filter(
        Math.floor(from / 12), Math.floor(to / 12), 'perMonth')] };
    if (unit === 'perMonth')
      return { or: [filter, this._filter(from * 12, to * 12, 'perYear')] };
    return filter;
  }.property('from', 'to').readOnly()
});

SL.VacancySalaryRangeFilter.reopenClass({
  template: 'vacancySalaryRange'
});

SL.VacancyOpenDateFilter = SL.DurationFilter.extend();

SL.VacancyOpenDateFilter.reopenClass({
  type: 'vacancyOpenDate',
  labelTranslation: 'common.filter.open_date'
});

SL.VacancyCloseDateFilter = SL.DurationFilter.extend();

SL.VacancyCloseDateFilter.reopenClass({
  queryKey: 'closed_at',
  type: 'vacancyCloseDate',
  labelTranslation: 'common.filter.close_date'
});


})();

(function() {

SL.FunctionFilter = SL.InheritedRecordsFilter.extend({
  init: function() {
    this._super();
    this.tmpExtraValues = {};
    this.tmpCurrentFunctionOnly = null;
  },
  createEntryPoint: function() {
    this.set('tmpCurrentFunctionOnly', this.get('currentFunctionOnly'));
    this.set('tmpExtraValues', Em.copy(this.get('extraValues') || {}));
    this._super();
  },
  save: function() {
    this._super();
    this.set('currentFunctionOnly', this.get('tmpCurrentFunctionOnly'));
    var extra = this.get('tmpExtraValues');
    Object.keys(extra).forEach(function(k) {
      if (Em.isEmpty(extra[k]))
        delete extra[k];
    });
    this.set('extraValues', extra);
  },
  filterValue: function(value) {
    var res = this._super(value);
    if (!this.get('currentFunctionOnly'))
      // Also add expected functions to the query
      res.addObjects(this._f(value, 'profiles.functions'));
    var extra = this.get('extraValues');
    if (!Object.keys(extra).length) return res;
    res = { and: [{ or: res }, { or: [] }] };
    Object.keys(extra).forEach(function(k) {
      var filter = { terms: {} };
      filter.terms['profiles.extra.' + k] = extra[k];
      res.and[1].or.addObject(filter);
    });
    return res;
  },
  label: function() {
    var label = this._super();
    var extra = this.get('extraValues');
    if (!Object.keys(extra).length) return label;
    var spec = Em.get(SL.parameters, 'cvExtra');
    Object.keys(extra).forEach(function(k) {
      var f = spec[k];
      label += ' (' + f.name + ': ' + SL.helper.fieldValue(
          Em.ObjectProxy.create({
        content: f,
        value: extra[k]
      })) + ')';
    });
    return label;
  }.property('values.[]', 'extraValues').readOnly(),
  queryKey: function() {
    if (this.get('currentFunctionOnly'))
      return 'profiles.last_work.function';
    return this._super();
  }.property('currentFunctionOnly'),
});

SL.FunctionFilter.reopenClass({
  type: 'function',
  queryKey: 'profiles.work_experiences.function',
  labelTranslation: 'common.filter.functions',
  levels: ['function_group_id', 'function_id']
});

SL.IndustryFilter = SL.InheritedRecordsFilter.extend({
  createEntryPoint: function() {
    this._super();
    this.set('tmpCurrentIndustryOnly', this.currentIndustryOnly);
  },
  save: function() {
    this._super();
    this.set('currentIndustryOnly', this.tmpCurrentIndustryOnly);
  },
  queryKey: function() {
    if (this.get('currentIndustryOnly'))
      return 'profiles.last_work.company.owner.industries';
    return this._super();
  }.property('currentIndustryOnly')
});

SL.IndustryFilter.reopenClass({
  type: 'industry',
  queryKey: 'profiles.work_experiences.company.owner.industries',
  labelTranslation: 'common.filter.industries',
  levels: ['category_id']
});

SL.LocationFilter = SL.InheritedRecordsFilter.extend();

SL.LocationFilter.reopenClass({ singleton: true,
  levels: ['country_id', 'province_id']
});

SL.CurrentLocationFilter = SL.LocationFilter.extend({
  tmpWithCompanyLocation: 1,
  createEntryPoint: function() {
    this._super();
    this.set('tmpWithCompanyLocation', this.withCompanyLocation);
  },
  save: function() {
    this._super();
    this.set('withCompanyLocation', this.tmpWithCompanyLocation);
  },
  filterValue: function(value) {
    var res = this._f(value, 'profiles.last_work.company.owner.location');
    switch (this.get('withCompanyLocation')) {
    case 1:
      return this._super(value).addObject({ and: [
        { not: { exists: { field: this.get('queryKey') }}},
        { or: res }
      ] });
    case 2:
      return this._super(value).addObjects(res);
    }
  },
  filter: function() {
    return this._super();
  }.property('values.[]', 'withCompanyLocation').readOnly()
});

SL.CurrentLocationFilter.reopenClass({
  type: 'currentLocation',
  queryKey: 'profiles.location',
  labelTranslation: 'common.filter.current_location'
});

SL.PreferredLocationFilter = SL.LocationFilter.extend();

SL.PreferredLocationFilter.reopenClass({
  type: 'preferredLocation',
  queryKey: 'profiles.prefer_locations',
  labelTranslation: 'common.filter.p_locations'
});

SL.WorkLocationFilter = SL.LocationFilter.extend();

SL.WorkLocationFilter.reopenClass({
  type: 'workLocation',
  queryKey: 'work_location',
  labelTranslation: 'common.filter.work_location'
});

SL.VacancyIndustryFilter = SL.InheritedRecordsFilter.extend({
  noAdvancedOptions: true
});

SL.VacancyIndustryFilter.reopenClass({
  type: 'vacancyIndustry',
  singleton: true,
  labelTranslation: 'common.filter.industries',
  queryKey: 'industries',
  levels: ['category_id']
});

SL.VacancyFunctionFilter = SL.InheritedRecordsFilter.extend({
  noAdvancedOptions: true
});

SL.VacancyFunctionFilter.reopenClass({
  type: 'vacancyFunction',
  singleton: true,
  labelTranslation: 'common.filter.functions',
  queryKey: 'job_function',
  levels: ['function_group_id', 'function_id']
});

SL.CompanyIndustryFilter = SL.InheritedRecordsFilter.extend({
  noAdvancedOptions: true
});

SL.CompanyIndustryFilter.reopenClass({
  type: 'industry',
  singleton: true,
  labelTranslation: 'common.filter.industries',
  queryKey: 'industries',
  levels: ['category_id']
});

SL.CompanyLocationFilter = SL.LocationFilter.extend();

SL.CompanyLocationFilter.reopenClass({
    type: 'companyLocation',
    queryKey: 'location',
    labelTranslation: 'common.filter.location'
});


})();

(function() {

SL.DegreeFilter = SL.TypedRecordsFilter.extend({
  createEntryPoint: function() {
    this._super();
    this.set('tmpFullTime', this.fullTime);
  },
  save: function() {
    this._super();
    this.set('fullTime', this.tmpFullTime);
  }
});

SL.DegreeFilter.reopenClass({
  type: 'degree',
  singleton: true,
  labelTranslation: 'common.filter.degree',
  queryKey: 'profiles.education_experiences.degree_id',
  helper: SL.helper.degree
});

SL.GenderFilter = SL.TypedRecordsFilter.extend();

SL.GenderFilter.reopenClass({
  type: 'gender',
  singleton: true,
  labelTranslation: 'common.filter.gender',
  queryKey: 'profiles.gender',
  helper: SL.helper.gender
});

function _talentsAllSelect(selections) {
  return Em.computed('tmpValues.[]', {
    get: function() {
      var values = this.get('tmpValues') || [];
      return selections.every(function(selection) {
        return values.indexOf(selection) > -1;
      });
    },
    set: function(dummy, value) {
      this.get('tmpValues')[
        value ? 'addObjects' : 'removeObjects'](selections);
      return value;
    }
  });
}

SL.MyTalentsFilter = SL.TypedRecordsFilter.extend({
  init: function() {
    this._super.apply(this, arguments);
    this.set('allCommon', true).set(
      'allClient', true).set('allCandidate', true);
  },
  label: function() {
    return this._label();
  }.property(),
  allCommon: _talentsAllSelect(['create_by', 'update_by']),
  allCandidate: _talentsAllSelect([
    'phone_record_by', 'interview_note_by', 'info_by', 'recommended_by',
    'client_interviewed_by', 'offered_by']),
  allClient: _talentsAllSelect([
    'bd_call_by', 'client_follow_by', 'meeting_minutes_by', 'case_owner_by']),
  getFilter: function(values) {
    return {
      has_child: {
        type: 'person_meta',
        filter: {
          or: values.map(function(v) {
            var flt = { term: {} };
            flt.term[v] = this.get('accountId');
            return flt;
          }, this)
        }
      }
    };
  }
});

SL.MyTalentsFilter.reopenClass({
  type: 'myTalents',
  singleton: true,
  labelTranslation: 'common.filter.my_talents'
});

SL.MaritalStatusFilter = SL.TypedRecordsFilter.extend();

SL.MaritalStatusFilter.reopenClass({
  type: 'maritalStatus',
  singleton: true,
  labelTranslation: 'common.filter.marital_status',
  queryKey: 'profiles.marital_status',
  helper: SL.helper.maritalStatus
});

SL.CompanyTypeFilter = SL.TypedRecordsFilter.extend();

SL.CompanyTypeFilter.reopenClass({
  type: 'companyType',
  labelTranslation: 'common.filter.company_type',
  queryKey: 'profiles.work_experiences.company.owner.company_type',
  helper: SL.helper.companyType
});

SL.CompanySizeFilter = SL.TypedRecordsFilter.extend();

SL.CompanySizeFilter.reopenClass({
  type: 'companySize',
  labelTranslation: 'common.filter.company_size',
  queryKey: 'profiles.work_experiences.company.owner.company_size',
  helper: SL.helper.companySize
});

SL.VacancyStatusFilter = SL.TypedRecordsFilter.extend();

SL.VacancyStatusFilter.reopenClass({
  type: 'vacancyStatus',
  singleton: true,
  labelTranslation: 'common.filter.status',
  queryKey: 'status',
  helper: SL.helper.vacancyStatus
});

SL.VacancyCompanyTypeFilter = SL.TypedRecordsFilter.extend();

SL.VacancyCompanyTypeFilter.reopenClass({
  type: 'companyType',
  labelTranslation: 'common.filter.company_type',
  queryKey: 'client_company.company_type',
  helper: SL.helper.companyType
});

SL.VacancyCompanySizeFilter = SL.TypedRecordsFilter.extend();

SL.VacancyCompanySizeFilter.reopenClass({
  type: 'companySize',
  labelTranslation: 'common.filter.company_size',
  queryKey: 'client_company.company_size',
  helper: SL.helper.companySize
});

SL.CompanyCompanyTypeFilter = SL.TypedRecordsFilter.extend();

SL.CompanyCompanyTypeFilter.reopenClass({
  type: 'companyType',
  labelTranslation: 'common.filter.company_type',
  queryKey: 'company_type',
  helper: SL.helper.companyType
});

SL.CompanyCompanySizeFilter = SL.TypedRecordsFilter.extend();

SL.CompanyCompanySizeFilter.reopenClass({
  type: 'companySize',
  labelTranslation: 'common.filter.company_size',
  queryKey: 'company_size',
  helper: SL.helper.companySize
});

SL.JobTypeFilter = SL.TypedRecordsFilter.extend();

SL.JobTypeFilter.reopenClass({
  type: 'jobType',
  singleton: true,
  labelTranslation: 'vacancy.create_edit.job_type',
  queryKey: 'job_type',
  helper: SL.helper.jobType
});

SL.RequiredExperienceFilter = SL.TypedRecordsFilter.extend({
  getFilter: function(values) {
    // NOTICE: Since not_required_graduates is not visible to user now, it is
    // implicitly added with not_required while filtering vacancy search
    var valuesAnother = values.slice(0);
    if (valuesAnother.indexOf('not_required') !== -1)
      valuesAnother.addObject('not_required_graduates');
    return this._super(valuesAnother);
  },
});

SL.RequiredExperienceFilter.reopenClass({
  type: 'requiredExperience',
  singleton: true,
  labelTranslation: 'vacancy.create_edit.required_experience',
  queryKey: 'required_experience',
  helper: SL.helper.requiredExperience
});

SL.ContactInfoFilter = SL.TypedRecordsFilter.extend({
  init: function() {
    this._super.apply(this, arguments);
    this.tmpValues = [0];
  },
  getFilter: function(values) {
    if (values.indexOf(0) > -1) {
      return { exists: {
        field: 'profiles.mobiles'
      }};
    }
  }
});

var contactInfo = ['with_mobile'];
function getContactInfo(value) {
  if (contactInfo[value])
    return Em.I18n.t('common.filter.contact_info.' + contactInfo[value]);
}

SL.ContactInfoFilter.reopenClass({
  type: 'contactInfo',
  singleton: true,
  labelTranslation: 'people.history.contact_info',
  helper: getContactInfo
});


})();

(function() {

SL.MultiSelectionControllerMixin = Em.Mixin.create({

  init: function() {
    this._super.apply(this, arguments);
    this.set('multiSelected', Em.makeArray());
  },

  clearMultiSelection: function() {
    this.get('multiSelected').clear();
  }.observes('model.[]'),

  multiSelect: function(item) {
    if (this.get('multiSelected').contains(item))
      this.get('multiSelected').removeObject(item);
    else
      this.get('multiSelected').addObject(item);
  },

  multiSelectedCount: Em.computed.readOnly('multiSelected.length'),

  actions: {
    clearMultiSelection: function() {
      this.clearMultiSelection();
    },
    multiSelect: function(item) {
      this.multiSelect(item);
    },
    selectAll: function() {
      this.get('multiSelected').addObjects(this.get('model').toArray());
    }
  }
});

SL.FilterableMultiSelectionControllerMixin =
  Em.Mixin.create(SL.MultiSelectionControllerMixin, {

  filterStr: Em.K,

  filteredContent: function() {
    var filter = this.get('filterValue');
    if (Em.isEmpty(filter)) return this.get('model');
    filter = filter.toLowerCase();
    return (this.getWithDefault('model') || []).filter(function(v) {
      return this.filterStr(v).indexOf(filter) > -1;
    }, this);
  }.property('filterValue', 'model.[]'),

  filterChanged: function() {
    Em.run.debounce(this, 'set', 'filterValue',
      this.get('filterValueTmp'), 300);
  }.observes('filterValueTmp'),

  clearMultiSelection: function() {
    this._super();
  }.observes('filteredContent')

});


})();

(function() {

SL.PartialLoadListControllerMixin = Em.Mixin.create({
  pageLoaded: function() {
    var metaLen = this.get('model.meta.numItems');
    return !metaLen || metaLen === this.get('model.length');
  }.property('model.meta.numItems', 'model.length'),

  removeObjects: function(objs) {
    this._super(objs);
    this.set('model.meta.numItems', this.get('model.length'));
  }
});

SL.PaginationControllerMixin = Em.Mixin.create(
  SL.PartialLoadListControllerMixin, {
  queryParams: ['page', 'pageSize'],
  page: 1,
  pageSize: 15,

  totalPages: Em.computed.readOnly('model.meta.totalPages'),
  totalResults: Em.computed.readOnly('model.meta.numResults'),
  sizeExceed: Em.computed.readOnly('model.meta.sizeExceed'),
});

SL.PagedMultiSelectionControllerMixin = Em.Mixin.create(
  SL.PaginationControllerMixin, {

  excludeMode: null,

  init: function() {
    this._super.apply(this, arguments);
    this.setProperties({
      saved: Em.makeArray(),
      toAdd: Em.makeArray(),
      toRemove: Em.makeArray(),
      semi: Em.makeArray()
    });
  },

  clearMultiSelection: function() {
    this.reset();
  },

  multiSelectedInclude: function() {
    var selected = this.get('saved').slice(0);
    selected.addObjects(this.get('toAdd'));
    selected.removeObjects(this.get('toRemove'));
    return selected;
  }.property('toAdd.[]', 'toRemove.[]', 'saved.[]'),

  multiSelectedExclude: function() {
    var selected = (this.get('model') || []).slice(0);
    selected.removeObjects(this.get('toRemove'));
    return selected;
  }.property('toRemove.[]', 'model.[]'),

  multiSelected: function() {
    if (this.get('excludeMode') === 'on')
      return this.get('multiSelectedExclude');
    return this.get('multiSelectedInclude');
  }.property(
    'multiSelectedInclude.[]', 'multiSelectedExclude.[]', 'excludeMode'),

  semiSelected: function() {
    var semi = (this.get('semi') || []).slice(0);
    semi.removeObjects(this.get('toRemove'));
    semi.removeObjects(this.get('toAdd'));
    return semi;
  }.property('toAdd.[]', 'toRemove.[]', 'semi.[]'),

  multiSelectedCount: function() {
    if (this.get('excludeMode') === 'on')
      return this.get('totalResults') - this.get('toRemove.length');
    return this.get('multiSelected.length');
  }.property(
    'excludeMode', 'totalResults', 'toRemove.length', 'multiSelected.length'),

  reset: function() {
    this.get('toAdd').clear();
    this.get('toRemove').clear();
    this.set('excludeMode', null);
  },

  multiSelect: function(item) {
    var toAdd = this.get('toAdd'),
        toRemove = this.get('toRemove');

    if (this.get('excludeMode') === 'on') {
      if (toRemove.contains(item))
        return toRemove.removeObject(item);
      return toRemove.addObject(item);
    }

    var isSemi = this.get('semi').contains(item);
    if (toAdd.contains(item)) {
      if (isSemi) toRemove.addObject(item);
      return toAdd.removeObject(item);
    }
    if (toRemove.contains(item)) {
      if (isSemi) toAdd.addObject(item);
      return toRemove.removeObject(item);
    }
    if (this.get('saved').contains(item))
      return toRemove.addObject(item);
    toAdd.addObject(item);
  },

  getPostData: function() {
    var exclude = (this.get('excludeMode') === 'on');
    return {
      mode: exclude ? 'exclude' : 'select',
      data: exclude ? this.get('toRemove').toArray() :
        this.get('toAdd').toArray()
    };
  },

  actions: {
    multiSelect: function(item) {
      this.multiSelect(item);
    },
    clearMultiSelection: function() {
      this.clearMultiSelection();
    },
    selectAll: function() {
      this.get('model').map(function(item) {
        this.multiSelect(item);
      }, this);
      if (this.get('multiSelectedCount') < this.get('totalResults'))
        this.set('excludeMode', 'offered');
    },
    setExcludeMode: function(mode) {
      if (!mode) {
        this.set('excludeMode', null);
        return;
      }
      this.reset();
      this.set('excludeMode', 'on');
    }
  }

});


})();

(function() {

SL.RequiredExperienceMixin = Em.Mixin.create({

  requiredExperienceCalc: Em.computed('vacancy.requiredExperience', {
    set: function(dummy, value) {
      this.set('vacancy.requiredExperience', value);
      return value;
    },
    get: function() {
      var value = this.get('vacancy.requiredExperience');
      if (value === 'not_required_graduates')
        return 'not_required';
      return value;
    }
  }),

  considerGraduates: Em.computed('vacancy.requiredExperience', {
    set: function(dummy, value) {
      var exp = this.get('vacancy.requiredExperience');
      if (exp === 'not_required' && value)
        this.set('vacancy.requiredExperience', 'not_required_graduates');
      else if (exp === 'not_required_graduates' && !value)
        this.set('vacancy.requiredExperience', 'not_required');
      return value;
    },
    get: function() {
      return this.get(
        'vacancy.requiredExperience') === 'not_required_graduates';
    }
  }),

  showRequiredExperience: function() {
    return this.get('vacancy.jobType') !== 'intern';
  }.property('vacancy.jobType').readOnly(),

  showConsiderGraduates: function() {
    var value = this.get('vacancy.requiredExperience');
    return value === 'not_required_graduates' || value === 'not_required';
  }.property('vacancy.requiredExperience').readOnly(),

});


})();

(function() {

SL.SingleSelectionControllerMixin = Em.Mixin.create({
  selectedItem: null,
  unselectable: false,

  actions: {
    select: function(item) {
      if (this.get('unselectable') && this.get('selectedItem') === item)
        return this.set('selectedItem', null);
      this.set('selectedItem', item);
    }
  }
});


})();

(function() {

/* global Bloodhound */

function listFromHelper(helper, levels) {
    if (levels) {
    return function() {
      var res = [levels.length];
      for (var i = 0; i < levels.length; i++) {
        var l = levels[i];
        res[i] = { value: l, label: helper(l) };
      }
      return res;
    }.property().readOnly();
  }
  return function() {
    var i = 0, s, res = [];
    while ((s = helper(i++)))
      res.push({ label: s, value: i - 1 });
    return res;
  }.property().readOnly();
}

SL.MainSources = Em.Object.extend({

  companies: function() {
    var store = this.store;
    return {
     source: new Bloodhound('company', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          var c = store.push(store.normalize('company', obj.data));
          return c.get('name');
        });
      }),
      templates: {
        empty: [
          '<div class="tt-empty">',
          '<small class="text-muted">',
             'No matches',
          '</div>'
        ].join('\n'),
        suggestion: function(data) {
          function template(data) {
            var defaultPhoto = 'images/2307360e.company_logo.png';
            return [
              '<div id="suggest-company-' + data.get('id') +
                '" class="media">',
                '<span class="pull-left avatar thumb-sm">',
                  '<div style="background-image:url(' +
                  (data.get('owner.photo.thumb.s') || defaultPhoto) +
                  ')" class="img-thumb img-circle"></div>',
                  data.get('owner.asGroup') ?
                    '<i class="grouped bottom"></i>' : '',
                '</span>',
                '<div class="media-body text-ellipsis">',
                  '<div>' + data.get('name') + '</div>',
                  '<small class="text-muted">',
                    Em.I18n.locale === 'zh' ?
                      data.get('cName') && data.get('eName') :
                      data.get('eName') && data.get('cName'),
                    '<i class="i i-users2"></i> ' + Em.I18n.t(
                       'company.employees',
                       { count: data.get('owner.employees') }
                    ),
                  '</small>',
                '</div>',
              '</div>'].join('\n');
          }
          return template(data);
        }
      }
    };
  }.property().readOnly(),

  cvCompanies: function() {
    var store = this.store;
    var companies = this.get('companies');
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/suggest/company?' +
            'filter%5Bname%5D=%QUERY&filter%5Bsenario%5D=cv_select&' +
            Em.$.param({
              results_per_page: 20
            }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              var c = store.push(store.normalize('company', obj.data));
              return c.get('name');
            });
          }
        }
      }),
      templates: companies.templates
    };
  }.property('companies').readOnly(),

  vacancyClients: function() {
    var store = this.store;
    var companies = this.get('companies');
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/suggest/company?' +
            'filter%5Bname%5D=%QUERY&filter%5Bsenario%5D=vacancy_client&' +
            Em.$.param({
              results_per_page: 20
            }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(store.normalize('company', obj.data));
            });
          }
        }
      }),
      templates: {
        empty: companies.templates.empty,
        suggestion: function(data) {
          return companies.templates.suggestion(data.get('name'));
        }
      }
    };
  }.property('companies').readOnly(),

  accountMembers: function() {
    var store = this.store,
        people = this.get('people');
    return {
      source: new Bloodhound('user', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('user', obj.data));
        });
      }),
      templates: {
        suggestion: function(data) {
          var rp;
          Em.run(function() {
            rp = data.get('realPerson');
            if (rp.get('id') && !rp.get('isLoaded')) {
              // If real person is being loaded, should refresh the profile
              rp.then(function(rp) {
                if (rp.get('defaultProfile')) {
                  Em.$('#quick-search-people-' + rp.get('id')).
                    replaceWith(people.templates.suggestion(rp));
                }
              });
            }
          });
          if (!rp.get('defaultProfile')) {
            // The profile to show not available yet, fake a profile
            rp = Em.Object.create({
              id: rp.get('id'),
              defaultProfile: Em.Object.create({
                eName: data.get('nickname') || data.get('email')
              })
            });
          }
          return people.templates.suggestion(rp);
        }
      }
    };
  }.property('people').readOnly(),

  accountUsers: function() {
    var users = this.get('accountMembers'), store = this.store;
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/suggest/user?' +
            'filter%5Bname%5D=%QUERY&' + Em.$.param({
              with_inactive: 1,
              results_per_page: 20
            }),
          filter:function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(store.normalize('user', obj.data));
            });
          }
        }
      }),
      templates: users.templates
    };
  }.property('accountMembers').readOnly(),

  teamMembers: function() {
    var users = this.get('accountMembers'), store = this.store;
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/suggest/user?' +
            'filter%5Bname%5D=%QUERY&filter%5Bgroup%5D=true&' + Em.$.param({
            results_per_page: 20
          }),
          filter:function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(store.normalize('user', obj.data));
            });
          }
        }
      }),
      templates: users.templates
    };
  }.property('accountMembers').readOnly(),

  titles: function() {
    var store = this.store;
    return {
      source: new Bloodhound('title', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('title', obj.data));
        });
      })
    };
  }.property().readOnly(),

  functions: function() {
    var store = this.store;
    return {
      source: 'function',
      formatter: function(obj) {
        var attr = { type: obj.type };
        attr[Em.I18n.locale === 'zh' ? 'cName' : 'eName'] = obj.name;
        return store.push({
          id: obj.id,
          type: 'function',
          attributes: attr
        });
      }
    };
  }.property().readOnly(),

  tailoredFuncs: function() {
    var store = this.store;
    return {
      source: 'tailored-funcs',
      formatter: function(obj) {
        var attr = { type: obj.type };
        attr[Em.I18n.locale === 'zh' ? 'cName' : 'eName'] = obj.name;
        return store.push({
          id: obj.id,
          type: 'function',
          attributes: attr
        });
      }
    };
  }.property().readOnly(),

  industries: function() {
    var store = this.store;
    return {
      source: 'industry',
      formatter: function(obj) {
        var attr = { type: obj.type };
        attr[Em.I18n.locale === 'zh' ? 'cName' : 'eName'] = obj.name;
        return store.push({
          id: obj.id,
          type: 'industry',
          attributes: attr
        });
      }
    };
  }.property().readOnly(),

  locations: function() {
    var store = this.store;
    return {
      source: 'location',
      formatter: function(obj) {
        var attr = { type: obj.type };
        attr[Em.I18n.locale === 'zh' ? 'cName' : 'eName'] = obj.name;
        return store.push({
          id: obj.id,
          type: 'location',
          attributes: attr
        });
      }
    };
  }.property().readOnly(),

  nations: function() {
    var f = {
      filters: [{ name: 'type', op: 'eq', val: 0 }],
      order_by: [{ field: 'e_fullname', direction: 'asc' }]
    };
    return this.store.query('location', {
      q: JSON.stringify(f),
      page: 1,
      results_per_page: 999999
    }).then(function(nations) {
      var generic = { groupLabel: Em.I18n.t('common.other'), items: [] },
          mostUsed = ['China', 'United States', 'United Kingdom', 'Germany',
            'France', 'Japan', 'Italy', 'South Korea'],
          nameKey = Em.I18n.locale === 'zh' ? 'cFullname' : 'eFullname';
      nations.forEach(function(n) {
        var m = mostUsed.indexOf(n.get('eFullname'));
        if (m > -1)
          mostUsed[m] = { label: n.get(nameKey), value: n };
        else
          generic.items.push({ label: n.get(nameKey), value: n });
      });
      return [{ groupLabel: Em.I18n.t('common.mostly_used'), items: mostUsed },
        generic];
    });
  }.property().readOnly(),

  channels: function() {
    var f = {
      filters: [{ or: [
        { name: 'category', op: 'eq', val: 'vendor' },
        { name: 'category', op: 'is_null' }
      ] }]
    };
    return this.store.query('channel', {
      q: JSON.stringify(f),
      results_per_page: 100,
    }).then(function(result) {
      return result.map(function(ch) {
        if (ch.get('isDefault'))
          return {
            label: Em.I18n.t('vacancy.add_candidate.inhouse_recruitment'),
            value: ch
          };
        return { label: ch.get('name'), value: ch };
      });
    });
  }.property().readOnly(),

  recentWorkYears: function() {
    var currentYear = new Date().getFullYear(),
        years = [];
    for (var i = -6; i <= SL.parameters.maxYearOfExperience; i++)
      years.push({ value: currentYear - i, label: currentYear - i });
    return years;
  }.property().readOnly(),

  months: function() {
    var arrMonths = [];
    for (var i = 1; i <= 12; i++)
      arrMonths.push({ value: i - 1, label: moment(i, 'M').format('MMM') });
    return arrMonths;
  }.property().readOnly(),

  personFileType: function() {
    var data = Em.I18n.translations.people.create_edit.file_type,
        fileTypes = [];
    for (var key in data)
      fileTypes.push({ value: key,
        label: Em.I18n.t('people.create_edit.file_type.' + key) });
    return fileTypes;
  }.property().readOnly(),

  showCompanyName: function(company) {
    var name = SL.helper.companyName(company);
    return name;
  },

  processFiles: function() {
    var store = this.store;
    return function(fileData) {
      if (fileData && fileData.files) {
        fileData = fileData.files[0];
        var fileType = fileData.file_type === 'company_photo' ?
          'companyFile' : 'personFile';
        return store.push(store.normalize(fileType, fileData));
      }
    };
  }.property().readOnly(),

  schools: function() {
    var store = this.store;
    return {
      source: new Bloodhound('school', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('schoolAlias', obj.data));
        });
      })
    };
  }.property().readOnly(),

  languages: function() {
    var store = this.store;
    return {
      source: new Bloodhound('language', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('language', obj.data));
        });
      })
    };
  }.property().readOnly(),

  skills: function() {
    var store = this.store;
    return {
      source: new Bloodhound('skill', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('skill', obj.data));
        });
      })
    };
  }.property().readOnly(),

  newSkill: function(str) {
        var store = this.store;
    return function(str) {
      return store.createRecord('skill', {
        name: str
      });
    };
  }.property().readOnly(),

  newCompany: function(str) {
        var store = this.store;
    return function(str) {
      return store.createRecord('companyAlias', {
        name: str
      });
    };
  }.property().readOnly(),

  certificates: function() {
    var store = this.store;
    return {
      source: new Bloodhound('certificate', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('certificate', obj.data));
        });
      })
    };
  }.property().readOnly(),

  tempLists: function() {
    var store = this.store;
    return {
      source: new Bloodhound('templist', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('folder', obj.data));
        });
      })
    };
  }.property().readOnly(),

  newCertificate: function(str) {
        var store = this.store;
    return function(str) {
      return store.createRecord('certificate', {
        name: str
      });
    };
  }.property().readOnly(),

  majors: function() {
    var store = this.store;
    return {
      source: new Bloodhound('major', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('major', obj.data));
        });
      })
    };
  }.property().readOnly(),

  quickSearch: function() {
    var store = this.store,
        people = this.get('people'),
        company = this.get('quickSearchCompanies'),
        vacancy = this.get('quickSearchVacancies');

    var peopleHeader = '<small class="tt-header"><strong>' +
      Em.I18n.t('application.quick_search.people') + '</strong>';
    if (SL.parameters.features.indexOf('search_cv') > -1)
      peopleHeader += '<a href="#/candidate" class="m-l">' +
        Em.I18n.t('application.quick_search.more_people') + '&raquo;</a>';
    peopleHeader += '</small>';

    return [{
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/real_person?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
            results_per_page: 5
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('realPerson', Em.copy(obj.data, true))
              ).set('source', 'quick-search');
            });
          }
        }
      }),
      templates: {
        header: peopleHeader,
        empty: people.templates.empty,
        suggestion: people.templates.suggestion
      }
    }, {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/company?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
            results_per_page: 2
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('company', Em.copy(obj.data, true)));
            });
          }
        }
      }),
      templates: {
        header: '<small class="tt-header"><strong>' +
          Em.I18n.t('application.quick_search.company') + '</strong></small>',
        empty: company.templates.empty,
        suggestion: company.templates.suggestion
      }
    }, {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/vacancy?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
             results_per_page: 2
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('vacancy', Em.copy(obj.data, true)));
            });
          }
        }
      }),
      templates: {
        header: '<small class="tt-header"><strong>' +
          Em.I18n.t('application.quick_search.vacancy') + '</strong></small>',
        empty: vacancy.templates.empty,
        suggestion: vacancy.templates.suggestion
      }
    }];
  }.property('people', 'quickSearchCompanies',
    'quickSearchVacancies').readOnly(),

  quickSearchPeople: function() {
    var store = this.store,
        people = this.get('people');

    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/real_person?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
            results_per_page: 8
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('realPerson', Em.copy(obj.data, true))
              ).set('source', 'quick-search');
            });
          }
        }
      }),
      templates: {
        empty: people.templates.empty,
        suggestion: people.templates.suggestion
      }
    };
  }.property().readOnly(),

  quickSearchCompanies: function() {
    var store = this.store;
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/company?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
            results_per_page: 8
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('company', Em.copy(obj.data, true)));
            });
          }
        }
      }),
      templates: {
        empty: [
          '<div class="tt-empty">',
          '<small class="text-muted">',
             'Unable to find any company with current query</small>',
          '</div>'
        ].join('\n'),
        suggestion: function(data) {
          function template(data) {
            var defaultPhoto = 'images/2307360e.company_logo.png',
                type = data.get('companyType'),
                size = data.get('companySize'),
                location = data.get('location.name');

            return [
              '<div id="quick-search-company-' + data.get('id') +
                '" class="media">',
                '<span class="pull-left avatar thumb-sm">',
                  '<div style="background-image:url(' +
                  (data.get('photo.thumb.s') || defaultPhoto) +
                  ')" class="img-thumb img-circle"></div>',
                  data.get('asGroup') ? '<i class="grouped bottom"></i>' : '',
                '</span>',
                '<div class="media-body text-ellipsis">',
                  '<div>' + SL.helper.companyName(data) + '</div>',
                  '<small class="text-muted">',
                     location ? '<i class="fa fa-map-marker"></i> ' +
                       location + '|' : '',
                     type ? SL.helper.companyType(type) + ' | ' : '',
                     SL.helper.companySize(size),
                  '</small>',
                '</div>',
              '</div>'].join('\n');
          }
          function refresh(v) {
            v && Em.$('#quick-search-company-' + data.get('id')).
              replaceWith(template(data));
          }
          Em.run(function() {
            if (!data.get('location.isLoaded'))
              data.get('location').then(refresh);
          });
          return template(data);
        }
      }
    };
  }.property().readOnly(),

  quickSearchVacancies: function() {
    var store = this.store;
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/vacancy?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
            results_per_page: 8
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('vacancy', Em.copy(obj.data, true)));
            });
          }
        }
      }),
      templates: {
        empty: [
          '<div class="tt-empty">',
          '<small class="text-muted">',
            'Unable to find any vacancy with current query</small>',
          '</div>'
        ].join('\n'),
        suggestion: function(data) {
          var defaultPhoto = 'images/2307360e.company_logo.png';
          function template(data) {
            var location = data.get('workLocation.name') || '',
                company = data.get('clientCompany.name.name') || '';
            return ['<div id="quick-search-vacancy-' + data.get('id') +
              '" class="media">',
              '<span class="pull-left avatar thumb-sm">',
                '<div style="background-image:url(' +
                 (data.get('clientCompany.photo.thumb.s') || defaultPhoto) +
                ')" class="img-thumb img-circle"></div>',
              '</span>',
              '<div class="media-body text-ellipsis">',
                '<div>' + data.get('title.name') + '</div>',
                '<small class="text-muted">',
                   location && '<i class="fa fa-map-marker"></i> ' + location,
                   location && company && '|',
                   '<span class="company-name">' + company + '</span>',
                '</small>',
              '</div>',
            '</div>'].join('\n');
          }
          function refresh(v) {
            v && Em.$('#quick-search-vacancy-' + data.get('id')).
              replaceWith(template(data));
          }
          // take care of async data
          Em.run(function() {
            ['workLocation'].forEach(function(key) {
              if (!data.get(key + '.isLoaded'))
                data.get(key).then(refresh);
            });
          });
          return template(data);
        }
      }
    };
  }.property().readOnly(),

  people: function() {
    var store = this.store;
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/search/real_person?' +
            'query%5Bquery_string%5D=%QUERY&' + Em.$.param({
            results_per_page: 8
          }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(
                store.normalize('realPerson', Em.copy(obj.data, true))
              ).set('source', 'auto-complete');
            });
          }
        }
      }),
      templates: {
        empty: [
          '<div class="tt-empty">',
          '<small class="text-muted">',
            'Unable to find any people with current query</small>',
          '</div>'
        ].join('\n'),
        suggestion: function(data) {
          var defaultPhoto = 'images/3aa0ef98.no-avatar.png';
          var profile = data.get('defaultProfile');
          return [
            '<div id="quick-search-people-' + data.get('id') +
             '" class="media">',
              '<span class="pull-left avatar thumb-sm">',
                '<div style="background-image:url(' +
                 (profile && profile.get('photo.thumb.s') || defaultPhoto) +
                ')" class="img-thumb img-circle"></div>',
              '</span>',
              '<div class="media-body text-ellipsis">',
                '<div>' + SL.helper.peopleName(profile) + '<br></div>',
                '<small class="text-muted">' +
                  SL.helper.peopleJob(profile) + '</small>',
              '</div>',
            '</div>'].join('\n');
        }
      }
    };
  }.property().readOnly(),

  vacancyTags: function() {
    var store = this.store;
    return {
      source: new Bloodhound('vacancy_tag', function(resp) {
        return Em.$.map(resp.hits, function(obj) {
          return store.push(store.normalize('vacancyTag', obj.data));
        });
      })
    };
  }.property().readOnly(),

  newVacancyTag: function(str) {
        var store = this.store;
    return function(str) {
      return store.createRecord('vacancyTag', {
        name: str
      });
    };
  }.property().readOnly(),

  birthDateStart: function() {
    return moment().subtract(SL.parameters.maxAge, 'y').toDate();
  }.property().readOnly(),

  birthDateEnd: function() {
    return moment().subtract(SL.parameters.minAge, 'y').toDate();
  }.property().readOnly(),

  companyType: listFromHelper(SL.helper.companyType),
  companySize: listFromHelper(SL.helper.companySize),
  motivationLevel: listFromHelper(SL.helper.motivationLevel, [0, 1, 10, 6, 4]),
  maritalStatus: listFromHelper(SL.helper.maritalStatus),
  employmentType: listFromHelper(SL.helper.employmentType),
  degree: listFromHelper(SL.helper.degree),
  vacancyStatus: listFromHelper(SL.helper.vacancyStatus, [0, 1, 2, 3]),
  requiredExperience: listFromHelper(SL.helper.requiredExperience, [
   'not_required', 'zero', 'one_to_three', 'three_to_five', 'five_to_ten',
   'gt_ten']),

  reports: function() {
    return this.store.query('report', {
      results_per_page: 100
    }).then(function(reports) {
      var managerReports = [], teamReports = [];
      reports.forEach(function(report) {
        var entry = { label: report.get('name'), value: report };
        if (report.get('managerOnly'))
          managerReports.push(entry);
        else
          teamReports.push(entry);
      });
      if (!Em.isEmpty(managerReports))
         return [{
           groupLabel: Em.I18n.t('statistics.manager_reports'),
           items: managerReports
         }, {
           groupLabel: Em.I18n.t('statistics.team_reports'),
           items: teamReports
         }];
      return teamReports;
    });
  }.property().readOnly(),

  cvFormats: function() {
    var store = this.store;
    var formats = (SL.parameters.cvFormats || []).map(function(f) {
      f = store.push(store.normalize('cvFormat', f));
      return { label: f.get('name'), value: f };
    });
    var def = store.push(store.normalize('cvFormat', {
      id: 'default', e_name: 'CV Download', c_name: '默认模版'
    }));
    formats.unshift({ label: def.get('name'), value: def });
    return formats;
  }.property().readOnly()
});

Em.Application.initializer({
  name: 'sources',
  after: 'store',

  initialize: function(container, app) {
    app.inject('sources:main', 'store', 'service:store');
    app.inject('controller', 'sources', 'sources:main');
  }
});


})();

(function() {

SL.TransactionControllerMixin = Em.Mixin.create({
  queryParams: ['startDate', 'filter', 'filterValue'],
  actions: {
    resetFilters: function() {
      this.setProperties({
        filter: undefined,
        filterValue: undefined
      });
    },
    refresh: function() {
      this.set('startDate', undefined);
      return true;
    }
  }
});


})();

(function() {

SL.MainUpdates = Em.Object.extend({
  folder: Em.A(),
  mapping: Em.A()
});

Em.Application.initializer({
  name: 'updates',

  initialize: function(container, app) {
    app.inject('controller', 'updates', 'updates:main');
    app.inject('route', 'updates', 'updates:main');
  }
});


})();

(function() {

SL.ActivitiesController = Em.Controller.extend(
    SL.TransactionControllerMixin, {
  transactions: Em.computed.readOnly('model'),
  queryParams: ['user'],
  syncUser: function() {
    var user = this.get('_user.id');
    /* jslint eqeq: true */
    if (user && user != this.get('user')) {
      this.set('user', user);
    }
  }.observes('_user.id'),
  actions: {
    clearUser: function() {
      this.setProperties({
        _user: undefined,
        user: undefined
      });
    }
  }
});


})();

(function() {

function filterTypeBy(key) {
  return Em.computed(key, function() {
    var category = this.get(key);
    return SL.filterCommentType(this.get('commentTypeItem'), category);
  });
}

SL.BaseCommentsController = Em.Controller.extend({
  sortedComments: Em.computed.alias('comments'),
  commentTypeItem: [],

  newCommentType: filterTypeBy('newComment.category'),
  enableSave: function() {
    var newComment = this.get('newComment');
    if (newComment.get('category') === 'client_follow' &&
      !newComment.get('vacancy.id'))
      return false;
    return newComment.get('comment') && !newComment.get('isSaving');
  }.property('newComment.comment', 'newComment.isSaving',
    'newComment.category', 'newComment.vacancy.id').readOnly(),
  componentType: function() {
    if (this.get('category') === 'followup')
      return 'people';
    return this.get('type');
  }.property('type', 'category')
});

SL.BaseCommentItemComponent = Em.Component.extend({
  // layoutName: 'comments',
  tagName: 'article',
  classNames: ['comment-item'],
  editing: false,
  updateCommentAction: 'updateComment',
  deleteCommentAction: 'deleteComment',
  commentTypeItem: [],
  itemType: filterTypeBy('comment.category'),
  itsMe: function() {
    if (this.get('displayMode') === 'vacancy' &&
        this.get('itemType.value') === 'client_follow')
      return false;
    /* jslint eqeq:true */
    return this.get('comment.creator.id') == this.get('session.accountId');
  }.property(
    'comment.creator.id', 'session.accountId', 'displayMode',
    'itemType.value'),
  actions: {
    edit: function() {
      this.set('editing', true);
    },
    save: function() {
      this.set('editing', false);
      this.sendAction('updateCommentAction', this.get('comment'));
    },
    cancel: function() {
      this.set('editing', false);
      // TODO: action this up
      this.get('comment').rollback();
    },
    delete: function() {
      this.sendAction('deleteCommentAction', this.get('comment'));
    }
  }
});


})();

(function() {

SL.BindWechatController = Em.Controller.extend({
  noShow: false,
  setNoShow: function() {
    if (!localStorage) return;
    localStorage.setItem('init_no_bind_wechat', this.get('noShow'));
  }.observes('noShow'),
  showNoShow: function() {
    return !!localStorage;
  }.property()
});


})();

(function() {

SL.CompanyIndexController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, SL.FiltersControllerMixin,
  SL.PaginationControllerMixin, {

  companyProfile: Em.inject.controller(),

  updateProfile: function() {
    this.set('companyProfile.company', this.get('selectedItem'));
  }.observes('selectedItem')

});


})();

(function() {

SL.CompanyNewController = Em.Controller.extend(Em.Validations.Mixin, {

  init: function() {
    this._super.apply(this, arguments);
    Em.bind(this, 'model.isOwner', 'isOwner');
  },

  model: Em.computed.validatable({
    isOwner: null,
    validations: SL.validations.companyWhole,
    validationInhibitors: function() {
      if (!this.get('isOwner'))
        return {
          description: true
        };
    }.property('isOwner').readOnly(),
    tnError: SL.computed.any('alerts.tn1.firstObject', 'alerts.tn2.firstObject',
      'alerts.tn3.firstObject').readOnly()
  }),

  isOwner: SL.computed.eql('model.id', 'session.account.company.id'),

  attentionRequired: function() {
    var editable = this.get('model.editable');
    if (editable) return '';
    return 'attention warning';
  }.property('model.editable'),

  actions: {
    validateCompany: function() {
      function checkValid() {
        if (this.get('model.isValid')) {
          this.send('saveCompany');
        } else {
          this.send('loading', false);
          this.send('postMessage',
            { type: 'error', info:
              Em.I18n.t('company.message.create_company_error') });
        }
      }
      this.send('loading', true);
      this.send('toggleAlerts', 'purge');

      var self = this;
      this.get('model').enforceRemoteValidation();
      this.get('model').validate().catch(function() {
      }).finally(function() {
        checkValid.call(self);
      });
    },
    cancelEdit: function() {
      this.send('toggleAlerts', 'volatile');
      return true;
    },
    toggleAlerts: function(action) {
      this.get('model').toggleAlerts(action);
    }
  }
});


})();

(function() {

SL.CompanyProfileController = Em.Controller.extend(SL.ExistenceMixin, {
  existenceBindKey: 'company',
  isEmbedded: false,

  _groupMembers: Em.computed.readOnly('company.asGroup.members'),

  groupMembers: Em.computed.readOnly('_groupMembers.normal'),

  pendingGroupMembers: function() {
    var members = this.get('_groupMembers'), pending = [];
    ['add_pending', 'delete_pending'].forEach(
        function(reason) {
      pending.addObjects((members && Em.get(members, reason) || []).map(
        function(c) {
          return c.set('pendingReason', reason);
        }));
    });
    return pending;
  }.property(
    '_groupMembers.add_pending.[]', '_groupMembers.delete_pending.[]'
  ).readOnly()
});


})();

(function() {

SL.FavoriteController = Em.Controller.extend({
  isFavorite: Em.computed.notEmpty('favorite')
});


})();

(function() {

SL.FolderAddController = Em.Controller.extend(
  SL.FilterableMultiSelectionControllerMixin, {

  filterStr: function(item) {
    /* jscs:disable */
    return ('' + item.get('name')).toLowerCase();
    /* jscs:enable */
  }
});


})();

(function() {

function makeFilter(key, valueKey) {
  return Em.computed(key, valueKey, {
    get: function() {
      if (!this.get(valueKey))
        return false;
      return this.get('_' + key);
    },
    set: function(_, v) {
      this.set('_' + key, v);
      return v;
    }
  });
}

SL.FolderAddMappingController = Em.Controller.extend(
    SL.SingleSelectionControllerMixin, {

  unselectable: true,

  _filterTitle: true,
  _filterCompany: true,
  _filterSupervisor: true,
  filterTitle: makeFilter('filterTitle', 'lastTitle'),
  filterCompany: makeFilter('filterCompany', 'lastCompany'),
  filterSupervisor: makeFilter('filterSupervisor', 'lastSupervisor'),

  cvDiff: function(e) {
    return e.get('company') && e.get('company') !== this.get('lastCompany') ||
      e.get('title') && e.get('title') !== this.get('lastTitle') || (
        e.get('associate.id') && e.get('associateType') === 'subordinate' &&
        e.get('associate') !== this.get('lastSupervisor'));
  },

  updateFilter: function() {
    Em.run.once(this, 'send', 'fetchMappingEntries', this);
  }.observes('filterTitle', 'filterCompany', 'filterSupervisor'),

  actions: {
    resetFilter: function() {
      this.setProperties({
        filterTitle: false,
        filterCompany: false,
        filterSupervisor: false
      });
    },
    add: function() {
      this.send('addToMapping', this);
    }
  }

});

SL.FolderAddMappingConfirmController = Em.Controller.extend({
  person: Em.inject.controller('people.edit'),
  mapping: Em.inject.controller('folder.add-mapping'),
  entry: Em.computed.readOnly('mapping.selectedItem'),
  work: Em.computed.readOnly('person.person.lastJob')
});


})();

(function() {

SL.FolderController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, SL.MultiSelectionControllerMixin, {

  folder: null,

  peopleEdit: Em.inject.controller(),
  sidebarStatusChanged: function() {
    this.set('peopleEdit._sidebarActive', this.get('sidebarActive'));
  }.observes('sidebarActive'),

  updateProfile: function() {
    this.set('peopleEdit.person',
      this.get('selectedItem.person.defaultProfile'));
  }.observes('selectedItem.person'),

  actions: {
    setFlag: function(flag) {
      this.get('multiSelected').invoke('set', 'status', flag);
      return true;
    }
  }
});

function findUpdates(key) {
  key = 'updates.' + key;
  return Em.computed(key + '.[]', 'folder.id', function() {
    return this.get(key).contains(this.get('folder.id'));
  }).readOnly();
}

SL.FolderIndexController = SL.FolderController.extend(
  SL.PaginationControllerMixin, {

  queryParams: ['fid'],
  fid: null,
  withUpdates: findUpdates('folder'),

  selectedItem: Em.computed('folder.selectedItem', {
    get: function() {
      return this.get('folder.selectedItem');
    },
    set: function(k, v) {
      return Em.trySet(this, 'folder.selectedItem', v);
    }
  })
});

SL.MappingEntriesController = SL.FolderIndexController.extend(
    Em.Validations.Mixin, {

  withUpdates: findUpdates('mapping'),

  entryCount: '1',
  validations: {
    mappingDescription: Em.Validations.validator(
        'mappingTitle', 'mappingCompany', 'mappingTitleNew',
        'mappingCompanyNew', 'mappingAssociateInput', function() {
      var values = this.getProperties(
        'mappingTitle', 'mappingCompany', 'mappingTitleNew',
        'mappingCompanyNew', 'mappingAssociateInput'),
        filtered = Object.keys(values).filter(function(key) {
          return !!values[key];
        });
      if (Em.isEmpty(filtered) && !this.get(this.property))
        return Em.I18n.t('list.mapping.description_required');
    }),
    mappingAssociateInput: Em.Validations.validator(
        'mappingAssociate', function() {
      if (this.get('mappingAssociateInput') && !this.get('mappingAssociate'))
        return Em.I18n.t('list.mapping.select_associate');
    })
  },
  mappingDescription: null,
  mappingProgress: function() {
    var folder = this.get('folder');
    if (folder)
      return (folder.get('integrity') || 0) + '%';
  }.property('folder.integrity'),
  mappingProgressStyle: function() {
    return Em.String.htmlSafe('width: ' + this.get('mappingProgress'));
  }.property('mappingProgress'),
  disableAddTo: function() {
    return this.get('multiSelected').isAny('person', null);
  }.property('multiSelected.@each.person'),
  actions: {
    toggleAlerts: function(action) {
      this.toggleAlerts(action);
    },
    validateMappingEntry: function() {
      this.send('toggleAlerts', 'purge');
      var self = this;
      this.validate().catch(function() {
      }).finally(function() {
        if (self.get('isValid'))
          return self.send('saveMappingEntry');
      });
    },
    resetMappingEntry: function() {
      this.setProperties({
        entryCount: '1',
        mappingTitle: undefined,
        mappingTitleNew: null,
        mappingCompany: undefined,
        mappingCompanyNew: null,
        mappingAssociate: undefined,
        mappingAssociateInput: null,
        mappingAssociateType: null,
        mappingDescription: null
      });
      this.toggleAlerts('volatile');
    }
  }
});

SL.FolderFavoriteController = SL.FolderController.extend(
  SL.PaginationControllerMixin, {
  withUpdates: Em.computed.readOnly('updates.favorite')
});


})();

(function() {

SL.FolderListController = Em.Controller.extend(
    SL.SingleSelectionControllerMixin, SL.MultiSelectionControllerMixin, {

  defaultCategory: 'private',
  currUpdates: Em.computed.readOnly('updates.folder'),

  actions: {
    multiSelect: function(f) {
      Em.run.schedule('afterRender', function() {
        Em.$('[name=folder-name][autofocus]:not(:focus)').eq(0).focus();
      });
      return this._super(f);
    }
  }
});

SL.MappingsController = SL.FolderListController.extend({
  defaultCategory: 'mapping',
  currUpdates: Em.computed.readOnly('updates.mapping')
});


})();

(function() {

SL.IndexController = Em.Controller.extend({
  shareType: 'owned',
  shareUrl: function() {
    var type = this.get('shareType'), user = this.get('session.account'),
        path = '/#/?s=wechat&init=shareWechat&hirer=' + user.get('accountId');
    if (type === 'owned')
      path += '&owner=' + user.get('id');
    return SL.parameters.mobileSite + path;
  }.property('shareType', 'session.account.id')
});


})();

(function() {

SL.LedgerSharedController = Em.Controller.extend(
    SL.PaginationControllerMixin, Em.Validations.Mixin, {
  action: '',
  transref: null,
  startDate: null,
  endDate: null,
  queryParams: ['action', 'transref', 'startDate', 'endDate'],
  actionItems: function() {
    if (this.get('session.account.isPublisher'))
      return [
        { value: '', textTranslation: 'ledger.actions.all' },
        { value: 'P', textTranslation: 'ledger.actions.P' },
        { value: 'W', textTranslation: 'ledger.actions.W' },
      ];
    return [
      { value: '', textTranslation: 'ledger.actions.all' },
      { value: 'B', textTranslation: 'ledger.actions.B' },
      { value: 'D', textTranslation: 'ledger.actions.D' },
      { value: 'other', textTranslation: 'ledger.actions.other' }
    ];
  }.property('session.account.isPublisher').readOnly(),
  showSplits: function() {
    if (this.get('session.account.isStaff'))
      // Only manager is allowed to view shared splits
      return this.get('session.account.isManager');
    return this.get('session.account.isPublisher');
  }.property('session.account.isManager', 'session.account.isStaff',
    'session.account.isPublisher'),

  account: Em.computed.readOnly('model.account'),
  minWithdrawAmount: Em.computed.readOnly('account.minWithdrawAmount'),
  maxWithdrawAmount: Em.computed.readOnly('account.balance'),

  validations: {
    withdrawAmount: {
      numericality: { allowBlank: true },
      inline: Em.Validations.validator(
          'minWithdrawAmount', 'maxWithdrawAmount', function() {
        var val = this.get(this.property), min = this.get('minWithdrawAmount'),
            max = this.get('maxWithdrawAmount');
        if (val > parseFloat(max) || val && val < parseFloat(min))
          return Em.I18n.t('ledger.withdraw.amountError',
            { min: min, max: max });
      })
    }
  },

  actions: {
    toggleAlerts: function(action) {
      return this.toggleAlerts(action);
    },
    withdraw: function() {
      this.set('withdrawAmount', null);
      return true;
    },
    doWithdraw: function() {
      // Echo back the normalized value
      var amount = parseFloat(this.get('withdrawAmount'));
      this.set('withdrawAmount', amount.toFixed(2));

      if (amount && !this.get('errors.withdrawAmount.length'))
        // Valid amount, continue processing with route
        return true;
    }
  }
});


})();

(function() {

SL.LoginController = Em.Controller.extend({
  queryParams: ['logonType', 'username'],
  logonType: '',
  qrScanUrl: '',
  rememberMe: true,

  valueEmpty: function() {
    return !this.get('identification') || !this.get('password');
  }.property('identification', 'password'),

  showScanQR: function() {
    var wechat = SL.parameters.socialNetwork.wechat,
        sites = SL.parameters.website + '|' + SL.parameters.mobileSite;
    return Em.$('html').hasClass('no-touch') &&
      sites && sites.indexOf(location.host) > -1 &&
      wechat && wechat.oauth;
  }.property(),

  disabled: function() {
    if (!Modernizr.cookies)
      return 'noCookie';
  }.property().readOnly(),

  actions: {
    reLogin: function() {
      this.set('logonType', '');
      return true;
    },
    authenticate: function() {
      var data = this.getProperties('identification', 'password',
        'rememberMe'), self = this;
      this.send('loading', true);
      return this.get('session').authenticate(
        'authenticator:custom', data
      ).then(function() {
        self.setProperties({
          identification: null,
          password: null
        });
      }).catch(function(error) {
        if (error && error.msg === 'login_failed')
          self.set('password', null);
      });
    }
  }

});


})();

(function() {

SL.FollowUpVacancyController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, {
});

SL.PeopleCommentsController = SL.BaseCommentsController.extend({
  commentTypeItem: SL.peopleCommentTypes,
  type: 'people'
});

SL.PeopleCommentItemComponent = SL.BaseCommentItemComponent.extend({
  commentTypeItem: SL.peopleCommentTypes,
  changeVacancy: 'changeFollowUpVacancy',
  enableSave: function() {
    var comment = this.get('comment');
    if (comment.get('category') === 'client_follow' &&
      !comment.get('vacancy.id'))
      return false;
    return comment.get('comment') && !comment.get('isSaving');
  }.property('comment.comment', 'comment.isSaving',
    'comment.category', 'comment.vacancy.id').readOnly(),

  actions: {
    changeFollowUpVacancy: function(c) {
      this.sendAction('changeVacancy', c);
    }
  }
});


})();

(function() {

SL.sortExperience = function(a, b) {
  var va = a ? +a.get('startDate') : 0,
      vb = b ? +b.get('startDate') : 0;
  return va > vb ? -1 : va === vb ? 0 : 1;
};

function editingBlock(block, experiences) {
  block = 'editing.' + block;
  return Em.computed(block, 'content.' + experiences + '.[]', {
    get: function(k) {
      var editingId = this.get(block);
      if (!editingId) return undefined;
      return this.get(experiences).find(function(exp) {
        return editingId === true ?
          !exp.get('id') : editingId === exp.get('id');
      });
    },
    set: function(k, v) {
            this.get('content.' + experiences).addObject(v);
      return v;
    }
  });
}

var validationBlocks = {
  personalInfo: ['eName', 'cName', 'currentEmail', 'currentMobile',
    'moreEmail', 'moreMobile', 'startWorkYear', 'birthDate', 'emailRequired',
    'mobileRequired'],
  careerIntention: ['expectedSalary', 'employmentStatus'],
  work: ['currentWork'],
  education: ['currentEdu'],
  project: ['currentProject']
};

SL.PeopleSectionControllerMixin = Em.Mixin.create({
  showPersonalInfo: Em.computed.or('editFlag',
    'person.with.gender', 'person.birthDate', 'person.with.maritalStatus',
    'person.emails.length', 'person.mobiles.length', 'person.address',
    'person.interest'),
  showCareerIntention: Em.computed.or('editFlag',
    'person.with.employmentType', 'person.with.expectedSalary',
    'person.with.employmentStatus', 'person.preferLocations.length',
    'person.preferIndustries.length', 'person.functions.length',
    'person.targetCompanies.length', 'person.blockedCompanies.length'),
  showWorkExp: Em.computed.or('editFlag', 'person.workExperiences.length'),
  showEduExp: Em.computed.or('editFlag', 'person.educationExperiences.length'),
  showProjectExp: Em.computed.or('editFlag',
    'person.projectExperiences.length'),
  showCareerSummary: Em.computed.or('editFlag', 'person.careerSummary'),
  showLanguages: Em.computed.or('editFlag', 'person.languages.length'),
  showCertificates: Em.computed.or('editFlag', 'person.certificates.length'),
  showSkills: Em.computed.or('editFlag', 'person.skills.length'),
  showFiles: Em.computed.or('editFlag', 'person.files.length'),
  showExtra: function() {
    var extra = this.get('extraFields');
    if (!extra) return;
    if (this.get('editFlag'))
      return !!extra.get('length');
    return !!extra.findBy('value');
  }.property('editFlag', 'extraFields.@each.value')
});

SL.PeopleEditController = Em.Controller.extend(
    SL.PeopleSectionControllerMixin, {

  queryParams: ['vc'],
  isEmbedded: false,
  editFlag: true,

  init: function() {
    this._super.apply(this, arguments);
    Em.Binding.from('session').to('person.session').connect(this);
  },

  editing: function() {
    return this.get('editFlag') && this.get('person.editing');
  }.property('person.editing', 'editFlag'),

  isOwnedProfile: Em.computed.readOnly('person.isOwnedProfile'),

  attentionRequired: function() {
    var status = this.get('person.owner.vcStatus');
    if (!status) return '';
    if (Em.get(status, 'offered.off_limit') ||
        Em.get(status, 'onboard.off_limit'))
      return 'attention danger';
    if (Em.get(status, 'interview.off_limit'))
      return 'attention warning';
    return '';
  }.property('person.owner.vcStatus'),

  downloadUrl: function() {
    if (this.get('session.account.isStaff'))
      return SL.apiPath + '/person/' + this.get('person.id') + '/download';
  }.property('person.id', 'session.account.isStaff'),

  commentable: function() {
    return !this.get('isOwnedProfile') && !this.get('person.isMasked') &&
      !this.get('noAction');
  }.property('isOwnedProfile', 'person.isMasked', 'noAction'),

  multiProfile: Em.computed.readOnly('person.parentId'),

  person: Em.computed.validatable(SL.PersonalInfoMixin, SL.ExistenceMixin, {
    session: null,
    workExperiences:
      Em.computed.sort('content.workExperiences', SL.sortExperience),
    educationExperiences:
      Em.computed.sort('content.educationExperiences', SL.sortExperience),
    projectExperiences:
      Em.computed.sort('content.projectExperiences', SL.sortExperience),

    editingWork: editingBlock('work', 'workExperiences'),
    editingEdu: editingBlock('education', 'educationExperiences'),
    editingProject: editingBlock('project', 'projectExperiences'),

    isSelf: SL.computed.eql('session.account.realPerson.id', 'ownerId'),
    withCv: function() {
      return this.get('files').findBy('fileType', 'cv');
    }.property('files.@each.fileType').readOnly(),

    // Validations
    validations: SL.validations.peopleEdit,
    validationInhibitors: function() {
      var res = {};
      Object.keys(validationBlocks).forEach(function(block) {
        var inhibited = !this.get('editing.' + block);
        validationBlocks[block].forEach(function(p) {
          res[p] = inhibited;
        });
      }, this);
            return res;
    }.property('editing').readOnly(),

    currentWork: Em.computed.validatable('editingWork', SL.BasicWorkExpMixin, {
      validations: SL.validations.workExperience
    }),
    currentEdu: Em.computed.validatable('editingEdu', {
      validations: SL.validations.educationExp,
      validationInhibitors: function() {
        return {
          newMajor: !this.get('major') || !!this.get('major.id'),
          newSchool: !this.get('school') || !!this.get('school.id')
        };
      }.property('major', 'major.id', 'school', 'school.id').readOnly(),

      studingAtCurrentSchool: SL.computed.toNow('endDate'),

      // New major handling
      newMajor: Em.computed.validatable({
        validations: SL.validations.major
      }),
      // New school handling
      newSchool: Em.computed.validatable({
        validations: SL.validations.school
      }),

      majorError: SL.computed.any('alerts.major.firstObject',
        'newMajor.alerts.name.firstObject'),
      schoolError: SL.computed.any('alerts.school.firstObject',
        'newSchool.alerts.name.firstObject')
    }),
    currentProject: Em.computed.validatable('editingProject', {
      validations: SL.validations.projectExp,
      ongoingProject: SL.computed.toNow('endDate'),
      validationInhibitors: function() {
        return {
          newCompany: !this.get('company') || !!this.get('company.id')
        };
      }.property('company', 'company.id').readOnly(),
      newCompany: Em.computed.validatable({
        validations: Em.$.extend(true, {
          name: { format: {
              with: /\S/,
              messageTranslation: 'people.errors.project.presence'
          }}
        }, SL.validations.companyName)
      }),
      companyError: SL.computed.any('alerts.company.firstObject',
        'newCompany.alerts.name.firstObject',
        'newCompany.alerts.eName.firstObject',
        'newCompany.alerts.cName.firstObject')
    }),
    salary: SL.computed.salary('lastJob.totalSalary'),
    shareable: function() {
      // Shareable conditions:
      // * Current user is staff - and -
      //   * Profile is accessed via vacancy candidate apps - or -
      //   * Profile is not masked
      return this.get('session.account.isStaff') && (
        !!this.get('vc.id') || !this.get('isMasked'));
    }.property('session.account.isStaff', 'vc.id', 'isMasked'),
    lastJob: Em.computed.readOnly('workExperiences.firstObject')
  }),

  showPersonalInfo: Em.computed.or('editFlag',
    'person.with.gender', 'person.birthDate', 'person.with.maritalStatus',
    'person.emails.length', 'person.mobiles.length', 'person.address',
    'person.interest'),
  showCareerIntention: Em.computed.or('editFlag',
    'person.with.employmentType', 'person.with.expectedSalary',
    'person.with.employmentStatus', 'person.preferLocations.length',
    'person.preferIndustries.length', 'person.functions.length',
    'person.targetCompanies.length', 'person.blockedCompanies.length'),
  showWorkExp: Em.computed.or('editFlag', 'person.workExperiences.length'),
  showEduExp: Em.computed.or('editFlag', 'person.educationExperiences.length'),
  showProjectExp: Em.computed.or('editFlag',
    'person.projectExperiences.length'),
  showCareerSummary: Em.computed.or('editFlag', 'person.careerSummary'),
  showLanguages: Em.computed.or('editFlag', 'person.languages.length'),
  showCertificates: Em.computed.or('editFlag', 'person.certificates.length'),
  showSkills: Em.computed.or('editFlag', 'person.skills.length'),
  showFiles: Em.computed.or('editFlag', 'person.files.length'),
  showExtra: function() {
    if (this.get('editFlag'))
      return !!this.get('extraFields.length');
    return !!this.get('extraFields').findBy('value');
  }.property('editFlag', 'extraFields.@each.value'),
  editFiles: Em.computed.readOnly('editFlag'),
  editPhoto: function() {
    return this.get('editFlag') && Em.isNone(this.get('person.editing'));
  }.property('editFlag', 'person.editing').readOnly(),

  cost: function() {
    var vcPrice = this.get('person.vc.price');
    if (!Em.isNone(vcPrice))
      return vcPrice;
    return this.get('person.cost');
  }.property('person.vc.price', 'person.cost').readOnly(),

  nameCardPanel: function() {
    var isStaff = this.get('session.account.isStaff');
    if (this.get('person.isMasked'))
      return 'panel-info';
    if (isStaff && this.get('multiProfile'))
      return 'panel-warning';
    return 'panel-default';
  }.property(
    'session.account.isStaff', 'multiProfile', 'person.isMasked').readOnly(),

  updateCvFormat: function() {
    Em.run.once(
      this, 'send', 'fetchCvFormat', this.get('person'),
      this.get('cvFormat'));
  }.observes('cvFormat'),

  reportToName: function(rp) {
    return SL.helper.peopleName(rp.get('defaultProfile'), true);
  },

  extraFields: function() {
    var spec = this.get('parameters.cvExtra') || {},
        extra = this.get('person.extra');
    return Object.keys(spec).filter(function(k) {
      return !spec[k].for_functions;
    }).map(function(k) {
      var field = spec[k];
      return Em.ObjectProxy.create({
	content: field,
	key: k,
	extra: extra,
	value: Em.computed.alias('extra.' + k)
      });
    });
  }.property('parameters.cvExtra', 'person.extra'),

  sidebarActive: Em.computed('isEmbedded', 'person.sidebarActive',
      '_sidebarActive', {
    get: function() {
      if (this.get('isEmbedded'))
        return this.get('_sidebarActive');
      return this.get('person.sidebarActive');
    },
    set: function(_, value) {
      if (!this.get('isEmbedded'))
	this.set('person.sidebarActive', value);
      return value;
    }
  }),

  fileTypes: function() {
    var types = this.get('sources.personFileType');
    var cName = this.get('model.cName'), eName = this.get('model.eName');
    var needParse = !SL.validProfileName(cName) ||
      !SL.validProfileName(eName);
    for (var i = 0; i < types.length; i++) {
      if (types[i].value.indexOf('cv') === 0) {
        types[i].value = needParse ? 'cv?parse=ignore_error' : 'cv';
        break;
      }
    }
    return types;
  }.property('sources.personFileType.[]', 'model.cName', 'model.eName'),

  fileUploaded: function() {
    var self = this;
    return function(data) {
      if (data.profiles)
        self.send('profileParsed', data.profiles);
      return self.get('sources.processFiles')(data);
    };
  }.property().readOnly(),

  actions: {
    editBlock: function(block, id) {
      var editing = {};
      editing[block] = id ? id : true;
      this.set('person.editing', editing);
      return true;
    },
    cancelEdit: function() {
      this.get('person').setProperties({
        editing: undefined,
        currentEmail: null,
        currentMobile: null
      });
      return true;
    },

    validateBlock: function() {
      function checkValid() {
        if (this.get('person.isValid')) {
          this.send('updatePeople');
        } else {
          this.send('loading', false);
          this.send('postMessage',
            { type: 'error', info:
              Em.I18n.t('people.message.update_people_error') });
        }
      }

      this.send('loading', true);
      this.send('toggleAlerts', 'purge');

      var self = this;
      if (this.get('person.editing.personalInfo') ||
          this.get('person.editing.work'))
        this.get('person').enforceRemoteValidation();
      this.get('person').validate().catch(function() {
      }).finally(function() {
        checkValid.call(self);
      });
    },

    toggleAlerts: function(action) {
      this.get('person').toggleAlerts(action);
    }
  }

});

SL.ResumeController = SL.PeopleEditController.extend({
  noAction: true,
  downloadUrl: function() {
    return SL.apiPath + '/resume/' + this.get('person.id') + '/download';
  }.property('person.id').readOnly()
});


})();

(function() {

SL.PeopleHistoryController = Em.Controller.extend(
    SL.TransactionControllerMixin, {
  showUser: true,
  transactions: Em.computed.readOnly('model.changes')
});


})();

(function() {

SL.PeopleIndexController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, SL.MultiSelectionControllerMixin,
  SL.FiltersControllerMixin, SL.PaginationControllerMixin, {

  peopleEdit: Em.inject.controller(),
  sidebarStatusChanged: function() {
    this.set('peopleEdit._sidebarActive', this.get('sidebarActive'));
  }.observes('sidebarActive'),

  updateProfile: function() {
    this.set('peopleEdit.person', this.get('selectedItem.defaultProfile'));
  }.observes('selectedItem'),

  addDefaultFilter: function() {
    if (SL.parameters.features.indexOf('my_talents') < 0) return;
    var filter = this.newFilter('my-talents');
        this.saveFilter();
  },

  addCompanyFilter: function(company) {
    var filter = this.newFilter('current-company');
    filter.set('tmpValues', [company.get('name')]);
    this.saveFilter();
  },

  allowExport: Em.computed.and('parameters.exportCandidates', 'totalResults'),

  extraFields: function() {
    var spec = this.get('parameters.cvExtra') || {};
    return Object.keys(spec).filter(function(k) {
      return k.indexOf('_s_') === 0 && !spec[k].for_functions;
    }).map(function(k) {
      return Em.ObjectProxy.create({
        key: k,
        content: spec[k]
      });
    });
  }.property('parameters.cvExtra'),

  actions: {
    exportCandidates: function() {
      if (this.get('totalResults') > 10000) {
        this.send('postMessage', { type: 'error', info: Em.I18n.t(
          'people.search.export_limited') });
        return false;
      }
      return true;
    }
  }
});

SL.CandidateIndexController = SL.PeopleIndexController.extend({
  listCandiate: true,
  clearMultiSelection: false,
  allowExport: false,
  updateProfile: function() {
    this.get('peopleEdit').setProperties({
      person: this.get('selectedItem')
    });
  }.observes('selectedItem.id')
});


})();

(function() {

function sameMonth(start, end) {
  start = start && +(new Date(start.getFullYear(), start.getMonth(), 1)) || 0;
  end = end && +(new Date(end.getFullYear(), end.getMonth(), 1)) || 0;
  return start === end;
}

function durationPairs(type_, key) {
  return Em.computed(
      'mergeFrom.' + type_ + '.[]', 'mergeTo.' + type_ + '.[]', function() {
    var fromList = this.get('mergeFrom.' + type_).slice(),
        toList = this.get('mergeTo.' + type_).slice(), sorted = [];
    fromList.sort(SL.sortExperience);
    toList.sort(SL.sortExperience);
    while (fromList.length || toList.length) {
      var f1 = fromList.get('firstObject'), t1 = toList.get('firstObject');
      if (f1 && t1) {
        var equal = key ? f1.get(key) === t1.get(key) : (
            sameMonth(f1.get('startDate'), t1.get('startDate')) &&
            sameMonth(f1.get('endDate'), t1.get('endDate')));
        if (equal) {
          fromList.removeObject(f1);
          toList.removeObject(t1);
          sorted.addObject({ source: f1, target: t1 });
          continue;
        }
        var greater = key ? f1.get(key) >= t1.get(key) :
          f1.get('startDate') >= t1.get('startDate');
        if (greater) {
          fromList.removeObject(f1);
          sorted.addObject({ source: f1 });
          continue;
        }
        toList.removeObject(t1);
        sorted.addObject({ target: t1 });
      } else if (f1) {
        fromList.removeObject(f1);
        sorted.addObject({ source: f1 });
      } else {
        toList.removeObject(t1);
        sorted.addObject({ target: t1 });
      }
    }
    return sorted;
  });
}

SL.PeopleMergeController = Em.Controller.extend({
  queryParams: ['from', 'to'],
  workExperiencesPairs: durationPairs('workExperiences'),
  educationExperiencesPairs: durationPairs('educationExperiences'),
  projectExperiencesPairs: durationPairs('projectExperiences'),
  filesPairs: durationPairs('files', 'id'),
  actions: {
    validatePeople: function() {
      this.send('doMerge');
    },
    selectionChanged: function() {
      this.set('selectionChanged', true);
    }
  }
});


})();

(function() {

SL.PeopleNewController = Em.Controller.extend({
  queryParams: ['owner'],
  editFiles: true,
  owner: null,
  profileType: 'resume',

  init: function() {
    this._super.apply(this, arguments);
    Em.Binding.from('owner').to('model.isSelf').connect(this);
    Em.Binding.from('profileType').to('model.profileType').connect(this);
    Em.Binding.from('profileType').to(
      'model.currentWork.profileType').connect(this);
  },

  model: Em.computed.validatable(SL.PersonalInfoMixin, {
    profileType: 'resume',
    nameError: function() {
      return this.get('alerts.' + (this.get('isAscIIName') ?
        'eName' : 'cName') + '.firstObject');
    }.property('isAscIIName', 'alerts.eName.firstObject',
      'alerts.cName.firstObject').readOnly(),

    isEmployed: function() {
      return this.get('employmentStatus') !== 10;
    }.property('employmentStatus').readOnly(),

    // Validations
    validations: SL.validations.peopleBasic,
    validationInhibitors: function() {
      var isContact = this.get('profileType') === 'contact';
      return {
        startWorkYear: isContact,
        currentWork: !this.get('isEmployed'),
      };
    }.property('isEmployed', 'profileType').readOnly(),

    currentWork: Em.computed.validatable(
      'workExperiences.firstObject', SL.BasicWorkExpMixin, {
      validations: SL.validations.workExperienceBasic
    })

  }),

  actions: {
    validatePeople: function() {
      function checkValid() {
        if (this.get('model.isValid'))
          this.send('createPeople');
        else {
          this.send('loading', false);
          this.send('postMessage',
            { type: 'error', info:
              Em.I18n.t('people.message.create_people_error') });
        }
      }

      this.send('loading', true);
      this.send('toggleAlerts', 'purge');

      var self = this;
      this.get('model').enforceRemoteValidation();
      this.get('model').validate().catch(function() {
      }).finally(function() {
        checkValid.call(self);
      });
    },
    toggleAlerts: function(action) {
      this.get('model').toggleAlerts(action);
    },
    cancelEdit: function() {
      this.set('profileType', 'resume');
      return true;
    }
  }
});


})();

(function() {

function makeDupObserver(property, type) {
  return function() {
    /* jscs:disable disallowImplicitTypeConversion */
    var error = '' + this.get('cv.' + property + '.alerts.name.firstObject');
    if (error.indexOf('duplicated:') === 0) {
      var pId = error.replace('duplicated:', '');
      Em.run.once(this, 'send', 'loadDup', type, pId);
      this.set(type + 'SamePerson', { loading: true });
    } else {
      this.set(type + 'SamePerson', null);
    }
  }.observes('cv.' + property + '.alerts.name.firstObject');
}

SL.PeopleParseController = Em.Controller.extend(
    SL.PeopleSectionControllerMixin, {
  step: '',
  mobileRequired: false,
  person: Em.computed.readOnly('cv'),

  init: function() {
    this._super.apply(this, arguments);
    Em.oneWay(this, 'cv.currentMobile.required', 'mobileRequired');
  },
  cv: Em.computed.validatable(SL.PersonalInfoMixin, SL.ExistenceMixin, {
    nameError: function() {
      return this.get('alerts.' + (this.get('isAscIIName') ?
        'eName' : 'cName') + '.firstObject');
    }.property('isAscIIName', 'alerts.eName.firstObject',
      'alerts.cName.firstObject').readOnly(),
    currentMobile: Em.computed.validatable({
      required: null,
      validations: Em.$.extend(true, {}, SL.validations.mobile, {
        name: {
          inline: Em.Validations.validator(
              'name', 'required', function() {
            if (Em.isEmpty(this.get('name')) && this.get('required'))
              return Em.I18n.t('people.errors.mobile.required');
          }),
          unique: {
            remote: { messageTranslation: 'common.duplicated' }
          }
        }
      })
    }),
    currentEmail: Em.computed.validatable({
      validations: Em.$.extend(true, {}, SL.validations.email, {
        name: {
          unique: {
            remote: { messageTranslation: 'common.duplicated' }
          }
        }
      })
    }),
    currentWork: Em.computed.validatable({
      salary: SL.computed.salary('totalSalary'),
      workingAtCurrentCompany: true,
      validations: {
        function: Em.Validations.validator(function() {
          if (Em.isEmpty(this.get(this.property + '.content')))
            return Em.I18n.t('people.errors.function.presence');
        }),
        totalSalary: SL.validations.workExperience.totalSalary
      }
    }),
    validations: {
      eName: SL.validations.peopleBasic.eName,
      cName: SL.validations.peopleBasic.cName,
      currentEmail: true,
      currentMobile: true,
      currentWork: true
    }
  }),
  mobileDuplicated: makeDupObserver('currentMobile', 'mobile'),
  emailDuplicated: makeDupObserver('currentEmail', 'email'),
  actions: {
    toggleAlerts: function(action) {
      this.get('cv').toggleAlerts(action);
    },
    setStep: function(step) {
      this.set('step', step);
    },
    checkImport: function() {
      this.send('loading', true);
      this.send('toggleAlerts', 'purge');
      var self = this;
      this.get('cv').enforceRemoteValidation();
      this.get('cv').validate().catch(function() {
      }).finally(function() {
        if (self.get('cv.isValid'))
          self.send('doImport', self.get('updateTo'));
        else
          self.send('loading', false);
      });
    },
    transferToMerge: function(rp) {
      this.set('updateTo', rp);
      this.get('cv').enforceRemoteValidation();
      this.get('cv').set('owner', rp).toggleAlerts('volatile');
    }
  }

});


})();

(function() {

SL.PeopleReportingController = Em.Controller.extend({
  traceback: '240',
  enableTraceback: true,
  queryParams: ['traceback'],

  calcTraceback: function() {
    if (!this.get('enableTraceback'))
      return this.set('traceback', '');
    var diff = moment().diff(this.get('tracebackDate'), 'months', true);
    this.set('traceback', Math.floor(diff));
  },
  updateTraceback: function() {
    Em.run.once(this, 'calcTraceback');
  }.observes('enableTraceback', 'tracebackDate')
});


})();

(function() {

SL.PerformanceController = Em.Controller.extend({
  duration: Em.computed.alias('data.duration'),

  durationType: function() {
    return Em.I18n.t(this.get('duration') < 0 ? 'reports.index.last_week' :
      'reports.index.this_week');
  }.property('duration'),

  actions: {
    increaseDuration: function(v) {
      var duration = this.get('duration');
      this.set('duration', duration + v);
      this.send('updateKpi');
    }
  }
});

function sumScore(type) {
  return function() {
    var kpis = this.get('data.kpi.' + type);
    return kpis.reduce(function(prev, kpi) {
      return prev + kpi.get('status');
    }, 0);
  }.property('data.kpi.' + type + '.@each.status').readOnly();
}

SL.KpiDetailController = SL.PerformanceController.extend({
  queryParams: ['duration'],
  duration: 0,
  newPersonScore: sumScore('newPerson'),
  newVacancyScore: sumScore('newVacancy'),
  clientMeetingScore: sumScore('clientMeeting'),
  interviewScore: sumScore('interview'),
  clientInterviewScore: sumScore('clientInterview'),
  reportedScore: sumScore('reported'),
  phoneRecordScore: sumScore('phoneRecord'),
  bdCallScore: sumScore('bdCall'),
  clientFollowScore: sumScore('followUpCall'),

  actions: {
    increaseDuration: function(v) {
      var duration = this.get('data.kpi.duration');
      this.set('duration', duration  + v);
      this.send('refresh');
    }
  }
});

SL.KpiIndexController = SL.PerformanceController.extend(Em.SortableMixin, {
  queryParams: ['duration'],
  duration: 0,
  sortProperties: ['user.name'],

  actions: {
    increaseDuration: function(v) {
      var duration = this.get('model.duration');
      this.set('duration', duration  + v);
      this.send('refresh');
    },
    sortBy: function(kpi) {
      var props = this.get('sortProperties');
      if (!props.contains('kpi.' + kpi + '.total')) {
        this.set('sortAscending', false);
        if (props.length === 2) props.removeAt(0);
        props.insertAt(0, 'kpi.' + kpi + '.total');
      } else if (this.get('sortAscending')) {
        props.removeAt(0);
      } else {
        this.set('sortAscending', true);
      }
    }
  }

});


})();

(function() {

SL.QuickSearchController = Em.Controller.extend({
  value: '',
  valueChanged: function() {
    var value = this.get('value'), route;
    if (!value) return;

    if (value instanceof SL.RealPerson) {
      value = value.get('defaultProfile');
      route = 'people.profile';
    } else if (value instanceof SL.Company) {
      route = 'company.profile';
    } else if (value instanceof SL.Vacancy) {
      route = 'vacancy.profile';
    }

    if (route)
      this.transitionToRoute(route, value);
  }.observes('value')
});


})();

(function() {

SL.RegisterController = Em.Controller.extend({
  step: 'setup',

  model: Em.computed.validatable({
    password: Em.computed.alias('user.password'),
    validations: {
      password: {
        length: { minimum: 6, messagesTranslation: {
          tooShort: 'register.errors.password_too_short' }},
      },
      confirmPassword: Em.Validations.validator('password', function() {
        var password = this.get('password');
        var confirmPassword = this.get('confirmPassword');
        if (!password || password.length < 6 || confirmPassword === undefined)
          return;
        if (confirmPassword !== password)
          return Em.I18n.t('register.errors.password_not_same');
      })
    },
  }),

  bindQR: SL.apiPath + '/wechat/subscribe_qr?action=create_account',

  actions: {
    toggleAlerts: function(action) {
      this.get('model').toggleAlerts(action);
    },
    setupDone: function() {
      this.send('toggleAlerts', 'purge');

      var self = this;
      this.get('model').validate().catch(function() {
      }).finally(function() {
        if (!self.get('model.isValid'))
          return;
        self.send('setupNext');
      });
    },
    setStep: function(step) {
      this.set('step', step);
    }
  }
});


})();

(function() {

/* global Bloodhound */

SL.KpiReportController = SL.ReportsController = Em.Controller.extend({
  queryParams: ['report'],

  updateReport: function() {
    var report = this.get('selectedReport');
    Em.run.once(this, 'set', 'report', report && report.id || undefined);
  }.observes('selectedReport.id'),

  teamMembers: function() {
    var users = this.get('sources.accountMembers'), store = this.store;
    return {
      source: new Bloodhound({
        remote: {
          /* jshint camelcase:false */
          url: SL.apiPath + '/suggest/user?' +
            'filter%5Bname%5D=%QUERY&filter%5Bgroup%5D=' +
            this.get('model.groupId') + '&' + Em.$.param({
              results_per_page: 20
            }),
          filter: function(resp) {
            return Em.$.map(resp.hits, function(obj) {
              return store.push(store.normalize('user', obj.data));
            });
          }
        }
      }),
      templates: users.templates
    };
  }.property('sources.accountMembers', 'model.groupId').readOnly(),

  actions: {
    goPage: function(widget, params) {
      this.send('updateQuery', widget, params);
    }
  }

});


})();

(function() {

function makeDupObserver(property, type) {
  return function() {
    /* jscs:disable disallowImplicitTypeConversion */
    var error = '' + this.get('cv.alerts.' + property + '.firstObject');
    if (error.indexOf('duplicated:') === 0) {
      var pId = error.replace('duplicated:', '');
      Em.run.once(this, 'send', 'loadDup', type, pId);
      this.set(type + 'SamePerson', { loading: true });
    } else {
      this.set(type + 'SamePerson', null);
    }
  }.observes('cv.alerts.' + property + '.firstObject');
}

SL.TlImportController = Em.Controller.extend({
  queryParams: ['action', 'data'],
  action: 'import',
  data: '',
  init: function() {
    this._super.apply(this, arguments);
    Em.oneWay(this, 'cv.mobileRequired', 'mobileRequired');
  },
  mobileRequired: false,
  cv: Em.computed.validatable('model', {
    eName: function() {
      if (this.get('isAscIIName')) return this.get('name');
    }.property('name').readOnly(),
    cName: function() {
      if (!this.get('isAscIIName')) return this.get('name');
    }.property('name').readOnly(),
    salaryConv: SL.computed.salary('salary'),
    isAscIIName: function() {
      var name = this.get('name');
      return !name || /^[\x00-\x7F]*$/.test(name);
    }.property('name').readOnly(),
    nameError: function() {
      return this.get('alerts.' + (this.get('isAscIIName') ?
        'eName' : 'cName') + '.firstObject');
    }.property('isAscIIName', 'alerts.eName.firstObject',
      'alerts.cName.firstObject').readOnly(),
    mobileRequired: null,
    workingAtCurrentCompany: true,
    validations: {
      eName: SL.validations.peopleBasic.eName,
      cName: SL.validations.peopleBasic.cName,
      email: Em.$.extend(true, {}, SL.validations.email.name, {
        unique: {
          remote: { messageTranslation: 'common.duplicated' }
        }
      }),
      mobile: Em.$.extend(true, {}, SL.validations.mobile.name, {
        inline: Em.Validations.validator(
            'mobile', 'mobileRequired', function() {
          if (Em.isEmpty(this.get('mobile')) && this.get('mobileRequired'))
            return Em.I18n.t('people.errors.mobile.required');
        }),
        unique: {
          remote: { messageTranslation: 'common.duplicated' }
        }
      }),
      function: {
        presence: { messageTranslation: 'people.errors.function.presence' }
      },
      salary: SL.validations.workExperienceBasic.totalSalary
    }
  }),
  mobileDuplicated: makeDupObserver('mobile', 'mobile'),
  emailDuplicated: makeDupObserver('email', 'email'),
  actions: {
    toggleAlerts: function(action) {
      this.get('cv').toggleAlerts(action);
    },
    checkImport: function() {
      this.send('loading', true);
      this.send('toggleAlerts', 'purge');
      var self = this;
      this.get('cv').enforceRemoteValidation();
      this.get('cv').validate().catch(function() {
      }).finally(function() {
        if (self.get('cv.isValid'))
          self.send('doImport', self.get('action'));
        else
          self.send('loading', false);
      });
    }
  }
});


})();

(function() {

SL.VacancyAddCandidateController = Em.Controller.extend({

  uploadType: function() {
    var tid = this.get('model.addCandidate.tempList.id');
    return tid && 'cv?folder_id=' + this.get('model.addCandidate.tempList.id');
  }.property('model.addCandidate.tempList.id').readOnly(),

  actions: {
    setVacancyStatus: function(s) {
      this.set('model.addCandidate.status', s);
    }
  }

});


})();

(function() {

SL.VacancyAddController = Em.Controller.extend(
  SL.FilterableMultiSelectionControllerMixin, {

  filterStr: function(item) {
    /* jscs:disable */
    return ('' + item.get('title.name') +
      item.get('clientCompany.name.name')).toLowerCase();
    /* jscs:enable */
  }
});


})();

(function() {

var SlaveChannel = Em.Object.extend(SL.TranslatablePropertyMixin, {
  inhibited: function() {
    return !this.get('ctl.defaultSelected') && 'master_channel_unselected' ||
      this.get('localError');
  }.property('ctl.defaultSelected', 'localError'),
  inhibitedMsg: Em.computed.readOnly('localErrorMsg')
});

var LinkedinChannel = (function() {
  var configKey = 'ctl.parameters.socialNetwork.linkedin',
    errorKey = 'ctl.socialAccounts.errors.linkedinToken.firstObject',
    companyKey = 'ctl.socialAccounts.linkedinToken.companyShare';

  return SlaveChannel.extend({
    action: 'syncLinkedin',
    localError: function() {
      return !this.get(configKey) && 'no_linkedin_config' ||
        this.get('isCompany') && !this.get(companyKey) &&
          'linkedin_no_company';
    }.property(configKey, companyKey),
    localErrorMsg: Em.computed.readOnly(errorKey)
  });
})();

var WeiboChannel = (function() {
  var configKey = 'ctl.parameters.socialNetwork.weibo',
    errorKey = 'ctl.socialAccounts.errors.weiboToken.firstObject';

  return SlaveChannel.extend({
    action: 'syncWeibo',
    localError: function() {
      return !this.get(configKey) && 'no_weibo_config';
    }.property(configKey),
    localErrorMsg: Em.computed.readOnly(errorKey)
  });
})();

SL.VacancyChannelsController = Em.Controller.extend(
  SL.SocialAccountsControllerMixin, SL.PagedMultiSelectionControllerMixin, {

  _toAdd: Em.computed.readOnly('vacancy.channelToAdd'),
  _toRemove: Em.computed.readOnly('vacancy.channelToRemove'),
  _defaultChannel: Em.computed.alias('vacancy.defaultChannel'),

  showSocial: function() {
    return !Em.isArray(this.get('vacancy')) && !this.get('vacancy.id');
  }.property('vacancy.[]', 'vacancy.id').readOnly(),

  defaultChannel: function() {
    return Em.Object.extend(SL.TranslatablePropertyMixin, {
      inhibited: function() {
        var channel = this.get('ctl._defaultChannel');
        return !channel && 'private' || channel.get('inhibited');
      }.property('ctl._defaultChannel', 'ctl._defaultChannel.inhibited')
    }).create({
      id: 0,
      ctl: this,
      logo: 'images/wechat_logo.svg',
      nameTranslation: 'vacancy.channel.buildin.default',
      descriptionTranslation: 'vacancy.channel.description.default'
    });
  }.property(),

  defaultSelected: function() {
    return this.get('multiSelected').contains(this.get('defaultChannel'));
  }.property('multiSelected.[]', 'defaultChannel'),

  slaveChannels: function() {
    return [
      LinkedinChannel.create({
        ctl: this,
        logo: 'images/linkedin_logo.svg',
        nameTranslation: 'vacancy.channel.buildin.linkedin',
        descriptionTranslation: 'vacancy.channel.description.linkedin'
      }), LinkedinChannel.create({
        ctl: this,
        isCompany: true,
        logo: 'images/linkedin_logo.svg',
        nameTranslation: 'vacancy.channel.buildin.linkedin_company',
        descriptionTranslation: 'vacancy.channel.description.linkedin_company'
      }), WeiboChannel.create({
        ctl: this,
        logo: 'images/weibo_logo.svg',
        nameTranslation: 'vacancy.channel.buildin.weibo',
        descriptionTranslation: 'vacancy.channel.description.weibo'
      })];
  }.property(),

  createEntryPoint: function(updateOnSave, vacancies) {
    this.setProperties({
      model: [],
      saved: [],
      toAdd: this.get('_toAdd').slice(0),
      toRemove: this.get('_toRemove').slice(0),
      updateOnSave: updateOnSave,
      vacancies: vacancies
    });
  },

  reset: function() {
    this._super.apply(this, arguments);
    this.get('_toAdd').clear();
    this.get('_toRemove').clear();
    this.set('vacancy.channelRefreshed', false);
  },

  changed: SL.computed.any('_toAdd.length', '_toRemove.length'),

  channelUnavailable: function() {
    return !!(this.get('defaultChannel.inhibited') ||
      !!(this.get('model') || []).findBy('inhibited'));
  }.property('defaultChannel.inhibited', 'model.@each.inhibited'),

  actions: {
    multiSelect: function(item) {
      var toAdd = this.get('toAdd');
      if (toAdd.contains(item) && item === this.get('defaultChannel'))
        toAdd.removeObjects(this.get('slaveChannels').toArray());
      return this._super.apply(this, arguments);
    },

    save: function() {
      this.get('_toAdd').setObjects(this.get('toAdd'));
      this.get('_toRemove').setObjects(this.get('toRemove'));
      if (this.get('updateOnSave'))
        this.send('updateChannelStatus', this.get('vacancies'));
    }
  }

});


})();

(function() {

var vacancyCommentTypes = [
  { value: 'memo', textTranslation: 'vacancy.comments.type.memo',
    icon: 'fa fa-fw fa-comment' }
];

SL.VacancyCommentsController = SL.BaseCommentsController.extend({
  category: null,
  commentTypeItem: vacancyCommentTypes,
  type: 'vacancy'
});

SL.VacancyCommentItemComponent = SL.BaseCommentItemComponent.extend({
  commentTypeItem: vacancyCommentTypes,
  enableSave: function() {
    var comment = this.get('comment');
    return comment.get('comment') && !comment.get('isSaving');
  }.property('comment.comment', 'comment.isSaving').readOnly()
});


})();

(function() {

SL.VacancyFindController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, SL.MultiSelectionControllerMixin,
  SL.PaginationControllerMixin, {

  queryParams: ['status', 'channel'],
  status: '',
  channel: '',

  peopleEdit: Em.inject.controller(),
  sidebarStatusChanged: function() {
    this.set('peopleEdit._sidebarActive', this.get('sidebarActive'));
  }.observes('sidebarActive'),

  summary: Em.computed.readOnly('model.vacancy.candidateSummary'),

  filteringInterview: function() {
    var status = this.get('status') || '';
    return status > 1 && status < 10 || status === 'interview';
  }.property('status'),

  selectedItem: Em.computed.alias('model.vacancy.selectedCandidate'),

  updateProfile: function() {
    var profile = this.get('selectedItem.candidate.defaultProfile');
    if (profile)
      profile.set('vc', this.get('selectedItem'));
    this.get('peopleEdit').set('person', profile);
  }.observes(
    'selectedItem.candidate.defaultProfile', 'selectedItem.id'),

  disableOut: function() {
    return this.get('multiSelected').isEvery('out');
  }.property('multiSelected.@each.out'),

  disableEnroll: function() {
    return !this.get('multiSelected').isAny('out');
  }.property('multiSelected.@each.out'),

  disableAdd: function() {
    return this.get('multiSelected').isAny(
      'candidate.defaultProfile.isMasked');
  }.property('multiSelected.@each.candidate.defaultProfile.isMasked'),

  disableDelete: function() {
    // Candidates applied with sourcing channel could not be deleted.
    return !this.get('multiSelected').find(function(vs) {
      return Em.isNone(vs.get('sourcingChannel')) ||
        !Em.isNone(vs.get('status'));
    });
  }.property('channel', 'multiSelected.@each.sourcingChannel'),

  resetPage: function() {
    this.set('page', 1);
  }.observes('status', 'channel'),

  finalRound: 0,
  interviewRounds: function() {
    var finalRound = this.get('finalRound'), rounds = [];
    for (var i = 1; i <= 8; i++)
      rounds.addObject({
        value: i + 1,
        enabled: i <= finalRound
      });
    return rounds;
  }.property('finalRound'),

  selectionInterviewed: false,
  allowPostInterview: function() {
    var parameters = this.get('parameters');
    if (!parameters.get('kpi.interview') ||
        !parameters.hasFeature('interview_mandatory'))
      return true;
    return this.get('selectionInterviewed');
  }.property(
    'selectionInterviewed', 'parameters.features.[]',
    'parameters.kpi.interview'),

  actions: {
    setOut: function(status) {
      this.get('multiSelected').setEach('out', status);
      this.send('saveSearchList');
    },
    setVacancyStatus: function(status) {
      if (!this.get('allowPostInterview') && status > 1)
        // Try to set status to higher than recommended but not allowed
        return;
      if (status > (this.get('finalRound') || 0) + 1 && status <= 9)
        // Try to set status to interview higher than final round
        return;
      var vcs = this.get('multiSelected').filterBy('out', false);
      if (this.get('finalRound'))
        vcs.setEach('interviewRounds', this.get('finalRound'));
      vcs.setEach('status', status);
      this.send('saveSearchList');
    },
    multiSelect: function() {
      this._super.apply(this, arguments);
      var vcs = this.get('multiSelected').filterBy('out', false),
          rounds = vcs.get('firstObject.interviewRounds');
      this.set('finalRound', vcs.isEvery(
        'interviewRounds', rounds) && rounds || 0);
    }
  }
});


})();

(function() {

var JobBoard = Em.Object.extend(SL.TranslatablePropertyMixin, {
  epochType: 'enterprise'
});

SL.VacancyImportController = Em.Controller.extend(
  SL.PagedMultiSelectionControllerMixin, {
  step: 'jobBoard',
  jobBoards: function() {
    var isHunter = this.get('session.account.isHunter'),
        liepinSite = isHunter ? 'liepin_hunter' : 'liepin';
    var boards = [
      JobBoard.create({
        site: '51job',
        siteNameTranslation: 'vacancy.import.51job',
        logo: 'images/51job.svg',
        nameTranslation: 'vacancy.import.51job'
      }),
      JobBoard.extend({
        siteName: function() {
          return Em.I18n.t('vacancy.import.' +
            (this.get('site') === 'liepin' ? 'liepin_hr' : 'liepin_hunter'));
        }.property('site').readOnly()
      }).create({
        site: liepinSite,
        logo: 'images/liepin.svg',
        nameTranslation: 'vacancy.import.liepin'
      }),
    ];
    if (!isHunter)
      boards.push(
        JobBoard.create({
          site: 'liepin',
          siteNameTranslation: 'vacancy.import.liepin_hr',
          logo: 'images/liepin.svg',
          epochType: 'campus',
          nameTranslation: 'vacancy.import.liepin_campus',
        }));
    return boards;
  }.property('session.account.isHunter'),
  disabled: function() {
    var step = this.get('step');
    switch (step) {
    case 'jobBoard':
      return {
        list: 'disabled',
        import: 'disabled'
      };
    case 'list':
      return {
        import: 'disabled'
      };
    case 'import':
      return {
        list: 'disabled'
      };
    }
  }.property('step').readOnly(),
  setStep: function(step) {
    if (this.get('disabled.' + step))
      return;
    this.set('step', step);
    if (step === 'jobBoard') {
      this.set('page', 1);
      this.reset();  // clear selection
    }
  },
  liepinAccountTypeUnknown: function() {
    return (this.get('board.site') || '').startsWith('liepin') &&
      this.get('board.epochType') !== 'campus' &&
      !this.get('session.account.isHr') &&
      !this.get('session.account.isHunter');
  }.property('board.site', 'board.epochType',
    'session.account.isHr', 'session.account.isHunter').readOnly(),

  captchaURL: function() {
    return SL.apiPath + '/import_vacancy/' + this.get('board.site') +
      '/captcha?' + this.get('showCaptcha') + '&r=' + Date.now();
  }.property('showCaptcha', 'board.site'),

  importPercent: function() {
    var progress = this.get('importProgress');
    progress = progress && progress.done / progress.total || 0;
    return Em.String.htmlSafe('width: ' +
      (progress < 1 ? progress * 100 : 100) + '%');
  }.property('importProgress'),

  loginReady: function() {
    var site = this.get('board.site');
    if (site === '51job' && Em.isEmpty(this.get('member')))
      return false;
    if (this.get('showCaptcha') && Em.isEmpty(this.get('captcha')))
      return false;
    return Em.isPresent(this.get('username')) &&
      Em.isPresent(this.get('password'));
  }.property('board.site', 'member', 'username', 'password', 'captcha',
    'captcha.[]'),

  shareUrl: function() {
    var user = this.get('session.account'),
        path = '/#/?s=wechat&init=shareWechat&hirer=' + user.get('accountId');
    path += '&owner=' + user.get('id');
    return SL.parameters.mobileSite + path;
  }.property('session.account.id'),

  showShareQR:
    Em.computed.and('importProgress.done', 'parameters.mobileSite'),

  resendSmsTimeout: Em.computed.alias('smsVerify.resend_timeout'),
  resendSmsCountdown: function() {
    Em.run.later(this, function() {
      var timeout = this.get('resendSmsTimeout');
      if (!timeout) return;
      this.set('resendSmsTimeout', timeout - 1);
      this.resendSmsCountdown();
    }, 1000);
  },

  actions: {
    init: function(board, forced) {
      this.set('board', board);
      return true;
    },
    setStep: function(step) {
      return this.setStep(step);
    },
    switchSite: function(site) {
      this.set('board.site', site).send('init', this.get('board'));
    }
  }
});


})();

(function() {

SL.VacancyIndexController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, SL.MultiSelectionControllerMixin,
  SL.FiltersControllerMixin, SL.PaginationControllerMixin, {

  vacancyProfile: Em.inject.controller(),
  wechatNewsPublish: Em.inject.controller('wechat-news-publish'),
  selectedShare: [],

  updateProfile: function() {
    this.set('vacancyProfile.vacancy', this.get('selectedItem'));
  }.observes('selectedItem'),

  addDefaultFilter: function() {
    var filter = this.newFilter('creator');
        filter.get('tmpValues').pushObject(this.get('session.account'));
    this.saveFilter();
    filter = this.newFilter('vacancyStatus');
        filter.get('tmpValues').pushObjects([0, 1]);
    this.saveFilter();
  },

  weChatShareUrl: function() {
    var url = SL.parameters.mobileSite;
    if (this.get('selectedShare.length') === 1) {
      url += '/#/vacancy.html?id=' + this.get('selectedShare.firstObject.id');
    } else {
      url += '/#/?vids=' +
        encodeURIComponent(this.get('selectedShare').mapBy('id').join(','));
    }
    return url + '&s=wechat&init=shareWechat&hirer=' +
      this.get('session.account.accountId');
  }.property('selectedShare.@each.id', 'session.account.accountId').readOnly(),

  sharePartial: function() {
    return !this.get('multiSelected').isEvery('sharable', true);
  }.property('multiSelected.@each.sharable').readOnly(),

  inhibitPublishTo: function() {
    return !this.get('multiSelected').isEvery('hireAsOwner');
  }.property('multiSelected.@each.hireAsOwner').readOnly(),

  inhibitSetStatus: function() {
    return !this.get('multiSelected').isEvery('hireAsOwner');
  }.property('multiSelected.@each.hireAsOwner').readOnly(),

  wechatSitesSelected: Em.computed.readOnly('wechatNewsPublish.multiSelected'),

  extraFields: function() {
    var spec = this.get('parameters.vacancyExtra') || {};
    return Object.keys(spec).filter(function(k) {
      return k.indexOf('_s_') === 0;
    }).map(function(k) {
      return Em.ObjectProxy.create({
        key: k,
        content: spec[k]
      });
    });
  }.property('parameters.vacancyExtra'),

  batchUpdateFields: function() {
    var spec = this.get('parameters.vacancyExtra') || {};
    return Object.keys(spec).filter(function(k) {
      return !!spec[k].batch_update;
    }).map(function(k) {
      var c = spec[k];
      return Em.ObjectProxy.create({
        name: Em.I18n.locale === 'zh' ? c.c_name : c.e_name,
        key: k,
        content: c
      });
    });
  }.property('parameters.vacancyExtra'),

  actions: {
    batchSetExtra: function(field, value) {
      this.get('multiSelected').forEach(function(v) {
        var extra = v.get('extra');
        if (Em.get(extra, field.key) !== value) {
          v.set('extra', Em.copy(extra));
          v.set('extra.' + field.key, value);
        }
      });
      return true;
    },
    batchSetStatus: function(status) {
      this.get('multiSelected').setEach('status', status);
      return true;
    },
    multiShare: function(vacancies) {
      if (vacancies && vacancies.length <= 20)
        this.set('selectedShare', vacancies.slice());
      return true;
    }
  }
});


})();

(function() {

var ExtraField = Em.ObjectProxy.extend(Em.Validations.Mixin, {
  validations: {
    value: Em.Validations.validator('value.[]', 'isMandatory', function() {
      if (this.get('isMandatory') && !Em.isPresent(this.get('value')))
        return Em.I18n.t('common.extra_field.field_required');
    })
  }
});

function notifyExist(method) {
  return function() {
    var notifications = this.get('notifications') || [];
    /* jslint eqeq: true */
    return notifications.find(function(item) {
      if (item.get('method') === method &&
        item.get('event') === 'applied' &&
        (method == 'email_contact' ? true :
         (item.get('userId') == this.get('creator.id')))) {
        return true;
      }
    }, this);
  }.property('notifications.[]');
}

SL.VacancyNewController = Em.Controller.extend(Em.Validations.Mixin, {

  queryParams: ['copyFrom'],
  vacancyChannels: Em.inject.controller(),
  copyFrom: '',

  init: function() {
    this._super.apply(this, arguments);
    Em.Binding.from('showCreator').to('model.showCreator').connect(this);
  },

  validations: {
    model: true,
    extraFields: Em.Validations.validator(
        'extraFields.@each.isValid', function() {
      var fields = this.get(this.property);
      if (!fields) return;
      for (var i = 0; i < fields.length; i++) {
        if (!fields[i].get('isValid'))
          return fields[i].get('alerts.value.firstObject');
      }
    })
  },

  showCreator: function() {
    return this.get('session.account.isManager') && this.get('editFlag');
  }.property('session.account.isManager', 'editFlag'),

  showNotifyWechat: Em.computed.readOnly('parameters.enterprise.bindWechat'),
  showCollaborators: function() {
    return this.get('parameters.vacancyWithResearcher') && (
      !this.get('editFlag') ||
      this.get('session.account.isManager') ||
      /* jslint eqeq: true */
      this.get('session.accountId') == this.get('model.creator.id'));
  }.property(
    'parameters.vacancyWithResearcher', 'session.account.isManager',
    'model.creator.id', 'session.accountId', 'editFlag'),

  checkWechat: function() {
    if (this.get('model.notifyWechat') && !this.get('model._notifyWechat') &&
        !this.get('session.account.weixinToken')) {
      this.set('model.notifyWechat', false);
      this.send('bindWechat', this, function(result) {
        if (result === 'success')
          this.set('model.notifyWechat', true);
      });
    }
  }.observes('model.notifyWechat'),

  fetchHrContact: function() {
    var p = this.get('model.hrContact.defaultProfile');
    if (p)
      this.store.findRecord('person', p.id);
  }.observes('model.hrContact.defaultProfile'),

  model: Em.computed.validatable(SL.RequiredExperienceMixin, {
    showCreator: false,
    validations: SL.validations.vacancy,
    vacancy: Em.computed.alias('content'),
    revealCompany: Em.computed('_revealCompany', 'companyDesc', {
      set: function(dummy, value) {
        if (value)
          this.set('companyDesc', null);
        this.set('_revealCompany', value);
        return value;
      },
      get: function() {
        if (this.get('_revealCompany') === undefined)
          return !this.get('companyDesc');
        return this.get('_revealCompany');
      }
    }),

    defaultSalaryUnit: function() {
      if (this.get('_salaryUnit') !== undefined) return;
      var from = this.get('salaryFrom'), to = this.get('salaryTo');
      if (typeof from === 'number' ||
          from && Object.keys(from).indexOf('amount') > -1 ||
          typeof to === 'number' ||
          to && Object.keys(to).indexOf('amount') > -1)
        return;
      var unit;
      switch (this.get('jobType')) {
        case 'intern':
          unit = 'perDay';
          break;
        case 'temp':
        case 'parttime':
          unit = 'kPerMonth';
          break;
        default:
          unit = 'kPerYear';
      }
      Em.run.once(this, 'setProperties', {
        salaryFrom: { unit: unit },
        salaryTo: { unit: unit }
      });
    }.observes('jobType'),

    salaryUnit: SL.computed.salaryUnit(
      '_salaryUnit', 'salaryFrom', 'salaryTo'),

    addWorkingCompany: Em.computed('workingCompany', {
      set: function(dummy, value) {
        if (!value)
          this.set('workingCompany', null);
        this.set('_addWorkingCompany', value);
        return value;
      },
      get: function() {
        if (this.get('_addWorkingCompany') === undefined)
          return !!this.get('workingCompany.content');
        return this.get('_addWorkingCompany');
      }
    }),

    showCaseFrom: function() {
      return SL.parameters.kpi.newVacancy && !this.get('ownership');
    }.property('ownership'),

    clientContactRequired: function() {
      return SL.parameters.vacancy.clientInfoMandatory;
    }.property(),
    requirementRequired: Em.computed.not('highlight'),

    validationInhibitors: function() {
      return {
        newTitle: !this.get('title') || !!this.get('title.id'),
        newCompany: !this.get('clientCompany') ||
          !!this.get('clientCompany.id'),
        caseFrom: !this.get('showCaseFrom'),
        companyDesc: !!this.get('revealCompany'),
        clientCompany: this.get('ownership'),
        requiredExperience: !this.get('showRequiredExperience'),
        headCount: !this.get('showMoreOptions'),
        creator: !this.get('showCreator')
      };
    }.property('title', 'title.id', 'clientCompany', 'ownership',
      'clientCompany.id', 'showCaseFrom', 'revealCompany',
      'showRequiredExperience', 'showCreator'),

    newTitle: Em.computed.validatable({
      validations: SL.validations.title
    }),

    newCompany: Em.computed.validatable({
      validations: SL.validations.companyBasic
    }),
    createNewCompany: function() {
      // Only need to create new company if the company name is not empty and
      // current company alias id is not selected
      var name = this.get('newCompany.name.name');
      return (name && name.trim() &&
        !this.get('clientCompany.id'));
    }.property('newCompany.name.name', 'clientCompany.id').readOnly(),
    companyError: SL.computed.any('alerts.clientCompany.firstObject',
      'newCompany.alerts.eName.firstObject',
      'newCompany.alerts.cName.firstObject'),
    newCompanyObserver: function() {
      if (this.get('createNewCompany'))
        this.get('newCompany').toggleAlerts('volatile');
    }.observes('createNewCompany'),

    salaryRangeError: SL.computed.any('alerts.salaryFrom.firstObject',
      'alerts.salaryTo.firstObject'),

    titleError: SL.computed.any('alerts.title.firstObject',
      'newTitle.alerts.name.firstObject'),

    clientError: SL.computed.any('alerts.hrContact.firstObject'),

    channelSummaryDirty: function() {
      var channels = this.get('publishedChannels') || [];
      channels = channels.slice(0);
      return channels.addObjects(this.get('channelToAdd') || []).
        removeObjects(this.get('channelToRemove') || []);
    }.property('publishedChannels.[]', 'channelToAdd.[]', 'channelToRemove.[]'),

    clearClientInfo: function() {
      var _ownership = this.get('_ownership');
      if (_ownership === this.get('ownership')) return;
      this.set('_ownership', this.get('ownership'));
      if (_ownership === undefined) return;
      if (this.get('ownership')) {
        this.set('_hrContact', this.get('hrContact'));
        this.set('_clientCompany', this.get('clientCompany'));
        this.set('clientCompany', null);
        this.set('hrContact', this.get('_reportLine'));
      } else {
        this.set('_reportLine', this.get('hrContact'));
        this.set('clientCompany', this.get('_clientCompany'));
        this.set('hrContact', this.get('_hrContact'));
      }
    }.observes('ownership'),

    expireAt: Em.computed('content.expireAt', {
      get: function(key) {
        var d = this.get('content.expireAt');
        return d && moment(d).subtract(1, 'day').toDate();
      },
      set: function(key, value) {
        var d = value && moment(value).add(1, 'day').toDate();
        this.set('content.expireAt', d);
        return value;
      }
    }),

    _notifyWechat: notifyExist('wechat'),

    _notifyEmail: notifyExist('email'),

    _notifyEmailContact: notifyExist('email_contact'),

  }),

  expireAtStart: function() {
    return moment().toDate();
  }.property().readOnly(),

  salaryFrom: SL.computed.salary('model.salaryFrom'),
  salaryTo: SL.computed.salary('model.salaryTo'),

  extraFields: function() {
    var spec = this.get('parameters.vacancyExtra') || {},
        extra = this.get('extra');
    return Object.keys(spec).map(function(k) {
      var field = spec[k];
      var f = ExtraField.create({
        content: field,
        key: k,
        extra: extra,
        isMandatory: field.is_mandatory,
        value: Em.computed.alias('extra.' + k)
      });
      f.toggleAlerts('volatile');
      return f;
    });
  }.property('parameters.vacancyExtra', 'extra'),

  actions: {
    validateVacancy: function() {
      function checkValid() {
        if (this.get('isValid')) {
          if (this.get('model.ownership'))
            this.set('model.caseFrom', null);
          this.send('saveVacancy');
        } else {
          this.send('loading', false);
          this.send('postMessage',
            { type: 'error', info:
              Em.I18n.t('vacancy.message.create_vacancy_error') });
        }
      }
      this.send('loading', true);
      this.send('toggleAlerts', 'purge');

      var self = this;
      this.get('model').enforceRemoteValidation();
      this.validate().catch(function() {
      }).finally(function() {
        checkValid.call(self);
      });
    },
    cancelEdit: function() {
      this.setProperties({
        'model._ownership': undefined,
        'model._revealCompany': undefined,
        'model._addWorkingCompany': undefined,
        'model.notifyWechat': !!this.get('model._notifyWechat'),
        'model.notifyEmail': !!this.get('model._notifyEmail'),
        'model.notifyEmailContact': !!this.get('model._notifyEmailContact')
      });
      this.toggleAlerts('volatile');
      this.get('vacancyChannels').reset();
      return true;
    },
    toggleAlerts: function(action) {
      this.toggleAlerts(action);
      this.get('extraFields').forEach(function(f) {
        f.toggleAlerts(action);
      });
    },
    toggleMoreOptions: function() {
      this.toggleProperty('model.showMoreOptions');
    }
  },
});


})();

(function() {

SL.VacancyProfileController = Em.Controller.extend(SL.ExistenceMixin,
  Em.Validations.Mixin, SL.SocialAccountsControllerMixin,
  SL.RequiredExperienceMixin, {

  isEmbedded: false,
  linkedinMsg: null,
  weiboMsg: null,
  othersMsg: null,
  existenceBindKey: 'vacancy',
  showCopyButton: function() {
    return window.clipboardData;
  }.property().readOnly(),

  validations: {
    linkedinMsg: {
      length: { maximum: 700, messages:
        { tooLong: 'Comment should be less than 700 characters.' }
      }
    },
    weiboMsg: Em.Validations.validator(function() {
      var val = this.get(this.property), len = 0;
      if (!val) return;
      for (var i = 0; i < val.length ; i++)
        len += val.charCodeAt(i) > 255 ? 2 : 1;
      // TODO: Weibo treats url as 10 Chinese characters
      if (len > 280)
        return 'Weibo message should be less than 140 Chinese characters.';
    })
  },

  weChatUrl: function() {
    return SL.parameters.mobileSite + '/#/vacancy.html?id=' +
      this.get('vacancy.id') + '&s=wechat&init=shareWechat&hirer=' +
      this.get('session.account.accountId') + '&shared_by=' +
      this.get('session.account.id');
  }.property(
    'vacancy.id', 'session.account.accountId', 'session.account.id'
  ).readOnly(),

  disableLinkedinShare: Em.computed.or('errors.linkedinMsg.firstObject',
    'socialAccounts.errors.linkedinToken.firstObject'),

  disableWeiboShare: Em.computed.or('errors.weiboMsg.firstObject',
    'socialAccounts.errors.weiboToken.firstObject'),

  salaryFrom: SL.computed.salary('vacancy.salaryFrom'),
  salaryTo: SL.computed.salary('vacancy.salaryTo'),

  showShareHint: function() {
    return !this.get('vacancy.shareClicked') &&
      moment().subtract(1, 'minutes').isBefore(this.get('vacancy.createAt'));
  }.property('vacancy.createAt', 'vacancy.shareClicked'),

  extraFields: function() {
    var spec = this.get('parameters.vacancyExtra') || {},
        extra = this.get('vacancy.extra');
    return Object.keys(spec).map(function(k) {
      var field = spec[k];
      return Em.ObjectProxy.create({
	content: field,
	key: k,
	extra: extra,
	value: Em.computed.alias('extra.' + k)
      });
    });
  }.property('parameters.vacancyExtra', 'vacancy.extra'),

  actions: {
    shareSingle: function() {
      this.set('vacancy.shareClicked', true);
    }
  }
});


})();

(function() {

SL.VacancyRecommendController = Em.Controller.extend(
  SL.SingleSelectionControllerMixin, SL.MultiSelectionControllerMixin,
  SL.PaginationControllerMixin, {

  clearMultiSelection: false,
  peopleEdit: Em.inject.controller(),
  sidebarStatusChanged: function() {
    this.set('peopleEdit._sidebarActive', this.get('sidebarActive'));
  }.observes('sidebarActive'),

  selectedItem: Em.computed.alias('model.vacancy.selectedRecommendation'),

  updateProfile: function() {
    this.set('peopleEdit.person', this.get('selectedItem.defaultProfile'));
  }.observes('selectedItem.defaultProfile')

});


})();

(function() {

SL.VacancyRenewController = Em.Controller.extend({
  actions: {
    setPackage: function(package) {
      this.set('package', package);
    }
  }
});


})();

(function() {

SL.WechatComponentController = Em.Controller.extend({
  queryParams: {
    authorizerId: 'authorizer_id'
  },
  authorizerId: null
});


})();

(function() {

SL.WechatNewsPublishController = Em.Controller.extend(
  SL.MultiSelectionControllerMixin);


})();

(function() {

// Go through all relationship to see if there are new record without ID set
function parseRelationship(record, normalizedModel) {
  var canonicalModel = normalizedModel || record._internalModel;

  record.eachRelationship(function(key, descriptor) {
    if (descriptor.options.async) return;

    if (descriptor.kind === 'belongsTo') {
      var re = record._internalModel._relationships.get(key).inverseRecord;
      if (re) re = re.getRecord();
      if (!re || re.id) return;
      var canonical =
        canonicalModel._relationships.get(key).canonicalMembers.list[0];
            parseRelationship(re, canonical);
      // Em.debug('new Record saved for key: ' + key);
    } else if (descriptor.kind === 'hasMany') {
      var res = record._internalModel._relationships.get(key).manyArray;
      var canonicals =
        canonicalModel._relationships.get(key).canonicalMembers.list;
      var newRes = [];
      res.filterBy('id').forEach(function(re) {
        // canonicals only contains new records
        canonicals = canonicals.rejectBy('id', re.id);
      });
      canonicals = canonicals.sortBy('id');
      res.forEach(function(re) {
        if (!re || re.id) return;
                newRes.push(re._internalModel);
        parseRelationship(re, canonicals[newRes.length - 1]);
        // Em.debug('new Record saved for key: ' + key);
      });
      for (var i = 0; i < newRes.length; i++) {
        record._internalModel._relationships.get(key).
          removeRecordFromOwn(newRes[i]);
      }
    }
  });

  if (normalizedModel) {
    // Calculate changed keys to be updated
    var changedKeys = record._internalModel._changedKeys(normalizedModel._data);
    normalizedModel.record = record;
    record.setProperties({
      id: normalizedModel.id,
      store: normalizedModel.store,
      _internalModel: normalizedModel,
      currentState: Em.get(normalizedModel, 'currentState'),
      isError: normalizedModel.isError,
      error: normalizedModel.error
    });
    record._notifyProperties(changedKeys);
    normalizedModel._triggerDeferredTriggers();
  }
}

SL.StoreService = DS.Store.extend({
  populateRecord: function(record, data) {
    var internalModel = record._internalModel;
    var normalizedData = this.normalize(internalModel.modelName, data);
    record.setProperties(normalizedData.data.attributes);
    var serializer = this.serializerFor(internalModel.modelName);
    internalModel.type.eachRelationship(function(key, descriptor) {
      var rel = internalModel._relationships.get(key),
          kind = descriptor.kind, value = Em.get(
            data, serializer.keyForRelationship(key, kind, 'deserialize'));
      if (Em.isBlank(value)) return;
      if (kind === 'belongsTo') {
        if (rel.isAsync)
          return rel.setRecord(this._internalModelForId(
            rel.relationshipMeta.type, value));
        if (value.id)
          return record.set(
            key, this.push(this.normalize(descriptor.type, value)));
        record.set(key, this.populateRecord(
          this.createRecord(descriptor.type), value));
      } else if (kind === 'hasMany') {
        if (rel.isAsync)
          return rel.updateRecordsFromAdapter(value.map(function(v) {
            return this._internalModelForId(rel.relationshipMeta.type, v);
          }, this));
        record.set(key, value.map(function(v) {
          if (v.id)
            return this.push(this.normalize(descriptor.type, v));
          return this.populateRecord(
            this.createRecord(descriptor.type), v);
        }, this));
      }
    }, this);
    return record;
  },

  _setupRelationships: function(internalModel, type, data) {
    this._super.apply(this, arguments);
    parseRelationship(internalModel.getRecord());
  },

  _load: function(data) {
    var internalModel = this._super.apply(this, arguments);

    // Embedded record status remains uncommitted (Bug?). Do reset!
    if (Object.keys(internalModel._attributes).length > 0) {
      internalModel._attributes = Object.create(null);
      internalModel.adapterWillCommit();
      internalModel.didCleanError();
      internalModel.send('didCommit');
    }

    return internalModel;
  },

  _findEmptyInternalModel: function(internalModel, options) {
    if (!internalModel.isNew() && !internalModel.isEmpty() &&
        !internalModel.isLoading()) {
      // Check reloading
      var typeClass = internalModel.type;
      var adapter = this.adapterFor(typeClass.modelName);
      if (adapter.shouldReloadRecord(this, internalModel.createSnapshot()))
        return this.scheduleFetch(internalModel, options);
    }
    return this._super.apply(this, arguments);
  }

});

SL.ApplicationSerializer = DS.ActiveModelSerializer.extend(
  DS.EmbeddedRecordsMixin, {

  attrs: {
    // Never serialize fullModel as its readOnly
    fullModel: { serialize: false }
  },

  isNewSerializerAPI: true,

  // FIXME: Active Model Serializer is processing the type attribute in the
  // payload but we are using it as an plain attribute.

  extractAttributes: function(modelClass, resourceHash) {
    if (resourceHash.type) {
      resourceHash.mtype = resourceHash.type;
      delete resourceHash.type;
    }
    return this._super(modelClass, resourceHash);
  },

  extractRelationship: function(relationshipModelName, relationshipHash) {
    if (Em.typeOf(relationshipHash) === 'object') {
      if (relationshipHash.type) {
        relationshipHash.mtype = relationshipHash.type;
        delete relationshipHash.type;
      }
    }
    return this._super(relationshipModelName, relationshipHash);
  },

  keyForAttribute: function(key, method) {
    if (key === 'type' && method === 'deserialize') return 'mtype';
    return this._super.apply(this, arguments);
  },

  serializeIntoHash: function(hash, type, record, options) {
    options = options || { includeId: true };
    Ember.merge(hash, this.serialize(record, options));
  },

  normalizeResponse: function(store, type, payload, id, requestType) {
    if (!payload.objects)
      return this._super(store, type, payload, id, requestType);

    /* It's returning objects as array type, wrap the meta info */
    var wrapper = {};
    for (var prop in payload) {
      if (!prop || prop === 'objects') continue;
      if (!wrapper.meta) wrapper.meta = {};
      wrapper.meta[prop.camelize()] = payload[prop];
    }
    wrapper.objects = payload.objects;
    return this._super(store, type, wrapper, id, requestType);
  },

  normalizeSingleResponse: function(store, type, payload, id, requestType) {
    var root = this.keyForAttribute(type.modelName);
    var wrapper = {};
    wrapper[this._underscoredName(root)] = payload;
    return this._super(store, type, wrapper, id, requestType);
  },

  normalizeArrayResponse: function(store, type, arrayPayload, id, requestType) {
    var root = this._underscoredName(this.keyForAttribute(type.modelName));
    arrayPayload[Em.String.pluralize(root)] = arrayPayload.objects;
    delete arrayPayload.objects;
    return this._super(store, type, arrayPayload, id, requestType);
  },

  _underscoredName: function(typeName) {
    var decamelized = Em.String.decamelize(typeName);
    return Em.String.underscore(decamelized);
  },

  serializeAttribute: function(snapshot, json, key, attribute) {
    var im = snapshot._internalModel;
    if (key in im._inFlightAttributes ||   // For main record
        key in im._attributes)             // For sub records
      this._super.apply(this, arguments);
  },

  serializeBelongsTo: function(snapshot, json, relationship) {
    var attr = relationship.key, rec = snapshot.belongsTo(attr);
    if (Em.typeOf(rec) === 'undefined')
      // Pass if it's partially loaded model
      return;

    var im = snapshot._internalModel,
        canonical = im._relationships.get(attr).canonicalMembers.list[0];
    /* jslint eqeq: true */
    if (canonical && rec && canonical.id == rec.id)
      if (relationship.options.async || this.hasSerializeIdsOption(attr))
        // For async relationship or embedded records serialized by id,
        // only serialize if the id changes
        return;

    if (canonical || rec)
      this._super.apply(this, arguments);
  },

  serializeHasMany: function(snapshot, json, relationship) {
    var attr = relationship.key, rec = snapshot.hasMany(attr);
    if (Em.typeOf(rec) === 'undefined')
      // Pass if it's partially loaded model
      return;
    this._super.apply(this, arguments);
  },

  keyForRelationship: function(key, type) {
    if (key === 'creator')
      return 'create_by';
    else if (key === 'updater')
      return 'update_by';
    return this._super.apply(this, arguments);
  }
});

SL.ApplicationAdapter = DS.ActiveModelAdapter.extend({
  namespace: SL.apiPath,

  shouldReloadRecord: function(store, snapshot) {
    // The fullModel attribute indicates whether the model is fully loaded.
    // A model with this attribute might be loaded partially (e.g. with
    // indexed value). So if that's the case, it need to be reloaded.
    if ('fullModel' in snapshot.attributes())
      return !snapshot.attr('fullModel');
    return false;
  },
  shouldReloadAll: function() {
    return true;
  },
  shouldBackgroundReloadRecord: function() {
    return false;
  },

  _buildURL: function() {
    var url = this._super.apply(this, arguments);
    var host = this.get('host');
    if (!host && url && url.charAt(0) === '/')
      return url.slice(1);
    return url;
  },

  handleResponse: function(status, headers, payload) {
    var result = this._super(status, headers, payload);
    if (result instanceof DS.AdapterError)
      // Append status code for more information. Raven will
      // pick this up to see if should ignore the error
      result.message += ' [' + status + ']';
    return result;
  },

  urlForFindRecord: function(id, modelName, snapshot) {
    var url = this._super.apply(this, arguments);
    if (snapshot.adapterOptions)
      url += '?' + Em.$.param(snapshot.adapterOptions, true);
    return url;
  },

  urlForCreateRecord: function(modelName, snapshot) {
    var url = this._super.apply(this, arguments);
    if (snapshot.adapterOptions)
      url += '?' + Em.$.param(snapshot.adapterOptions, true);
    return url;
  }

});

DS.DateTransform.reopen({
  serialize: function(deserialized) {
    // Server is expecting UTC value
    if (deserialized)
      return moment(deserialized).utc().format();
    return deserialized;
  }
});

SL.IsodateTransform = DS.DateTransform.extend({
  deserialize: function(serialized) {
    if (serialized)
      return moment(serialized).toDate();
    return serialized;
  },
  serialize: function(deserialized) {
    if (deserialized)
      return moment(deserialized).format('YYYY-MM-DD');
    return deserialized;
  }
});

SL.MonetaryAmountTransform = DS.Transform.extend({
  deserialize: function(serialized) {
    if (!serialized) return serialized;
    return serialized.as_string;
  },
  serialize: function(deserialized) {
    // noop
  }
});

SL.SalaryTransform = DS.Transform.extend({
  deserialize: function(serialized) {
    if (Em.isBlank(serialized))
      return;
    if (Em.typeOf(serialized) !== 'object')
      return { amount: serialized, unit: 'kPerYear' };
    var unit = serialized.unit;
    serialized.unit = unit = unit && unit.camelize();
    if (unit === 'perYear' || unit === 'perMonth') {
      serialized.amount = serialized.amount && serialized.amount / 1000;
      serialized.unit = unit.replace(/^p/, 'kP');
    }
    return serialized;
  },
  serialize: function(deserialized) {
    if (deserialized && deserialized.unit) {
      deserialized = Em.copy(deserialized);
      deserialized.unit = deserialized.unit.underscore();
    }
    return deserialized;
  }
});

Em.Inflector.inflector = new Em.Inflector();

function mismatch(rec, payload) {
  /* jslint eqeq: true */
  if (rec && rec.then) rec = rec.get('content'); // async relationship
  if (!payload) return !!rec;
  return rec !== payload; // && Em.get(rec, 'id') != payload;
}

function belongsToDirty() {
  var meta = this.constructor.metaForProperty('isDirty');
  
  var relationship =
    this.content._internalModel._relationships.get(meta.key);

  if (mismatch(relationship.inverseRecord, relationship.canonicalState)) {
    // Em.debug('key ' + meta.key + ' mismatch with payload: ' +
    //   relationship.inverseRecord + ', ' + relationship.canonicalState);
    return true;
  }
  if (meta.dep) {
    var record = relationship.getRecord();
    if (record && record.get('isDirty')) {
      // Em.debug('key ' + meta.key + ' is dirty: ' + record);
      return true;
    }
  }
  return false;
}

function hasManyDirty() {
  var meta = this.constructor.metaForProperty('isDirty');
  
  var relationship =
    this.content._internalModel._relationships.get(meta.key);

  if (relationship.members.size !== relationship.canonicalMembers.size) {
    // Quick hack if length mismatches
    // Em.debug('key ' + meta.key + ' has differnt length with payload: ' +
    //   relationship.members.size + ' -> ' +
    //   relationship.canonicalMembers.size);
    return true;
  }
  var mis;
  for (var i = 0; i < relationship.members.list.length; i++) {
    mis = relationship.members.list[i];
    if (mismatch(mis, relationship.canonicalMembers.list[i]))
      break;
    else
      mis = null;
  }
  if (Em.isPresent(mis)) {
    // Em.debug('key ' + meta.key + ' mismatch with payload: ' + mis);
    return true;
  }
  if (meta.dep) {
    var dirty = relationship.getRecords().findBy('isDirty');
    if (Em.isPresent(dirty)) {
      // Em.debug('key ' + meta.key + ' is dirty: ' + dirty);
      return true;
    }
  }
  return false;
}

function buildDirtyChecker(rec, key, kind, dep) {
  if (kind === 'belongsTo')
    // belongsTo
    return Em.ObjectProxy.extend({
      isDirty: Em.computed(key, key + '.isDirty',
        belongsToDirty).meta({ key: key, dep: dep }).readOnly()
    }).create({ content: rec });
  else if (kind === 'hasMany')
    // hasMany
    return Em.ObjectProxy.extend({
      isDirty: Em.computed(key + '.@each.isDirty',
        hasManyDirty).meta({ key: key, dep: dep }).readOnly()
    }).create({ content: rec });
}

SL.FullModelMixin = Em.Mixin.create({
  // The attribute indicates whether the model is fully loaded
  fullModel: DS.attr('boolean')
});

DS.Model.reopen({

  /**
   * Revert any and all relationships to their last-known synchronized state.
   */
  rollbackRelationships: function() {
    var internalModel = this._internalModel;
    this.eachRelationship(function(key, descriptor) {
      // If it's XtoOne relationship, perform full rollback;
      var fullRollback = descriptor.options.toOne;
      var relationship = internalModel._relationships.get(key);

      relationship.members.clear();
      if (descriptor.kind === 'belongsTo') {
        relationship.inverseRecord = null;
        if (fullRollback && relationship.canonicalState)
          relationship.canonicalState.record.rollback();
      } else if (descriptor.kind === 'hasMany') {
        relationship.manyArray.currentState.clear();
        if (fullRollback) {
          for (var j = 0; j < relationship.canonicalMembers.size; j++) {
            var re = relationship.canonicalMembers.list[j];
            if (re && re.record) re.record.rollback();
          }
        }
      }
      relationship.flushCanonicalLater();
    }, this);

    // ImplicitRelationships also need to be rolled back.
    Object.keys(internalModel._implicitRelationships).forEach(function(key) {
      var rel = internalModel._implicitRelationships[key];
      rel.members.clear();
      rel.flushCanonicalLater();
    });
  },

  /**
   * Extend `Model#rollback` to provide additional functionality:
   *
   * - Ensure that the members of all relationships are reverted
   */
  rollback: function() {
    this.rollbackRelationships();
    this.rollbackAttributes();
  },

  isDirty: function() {
    // Lazy creating dirty checker
    if (!this._relationsdirty) {
      this._relationsdirty = Em.makeArray();
      this.eachRelationship(function(name, descriptor) {
        this._relationsdirty.pushObject(
          buildDirtyChecker(this, name, descriptor.kind,
            descriptor.options.toOne));
      }, this);
    }
    return this.get('hasDirtyAttributes') ||
      this._relationsdirty.isAny('isDirty');
  }.property('hasDirtyAttributes', '_relationsdirty.@each.isDirty').readOnly(),

});


})();

(function() {

function isAscII(str) {
  return !str || /^[\x00-\x7F]*$/.test(str);
}

SL.UniversialNameModelMixin = Em.Mixin.create({
  name: Em.computed('eName', 'cName', {
    get: function() {
      var eName = this.get('eName'),
        cName = this.get('cName');
      return Em.I18n.locale === 'zh' ? cName || eName : eName || cName;
    },
    set: function(key, value) {
      var ascII = isAscII(value);
      this.setProperties({
        eName: (ascII ? value : undefined),
        cName: (ascII ? undefined : value),
        isAscIIName: ascII //wired bug, have to sync the property value
      });
      return value;
    }
  }),

  fullName: Em.computed('eFullname', 'cFullname', {
    get: function() {
      var eName = this.get('eFullname'),
        cName = this.get('cFullname');
      if (Em.I18n.locale === 'zh')
        return cName || eName;
      return eName || cName;
    },
    set: function(key, value) {
      var ascII = isAscII(value);
      this.setProperties({
        eFullname: (ascII ? value : undefined),
        cFullname: (ascII ? undefined : value),
      });
      return value;
    }
  }),

  isAscIIName: function() {
    return isAscII(this.get('name'));
  }.property('name')
});


})();

(function() {

SL.BountyRule = DS.Model.extend({
  bountyType: DS.attr('string'),
  price: DS.attr('monetaryAmount'),
  quantity: DS.attr('number')
});

SL.BountyCandidate = DS.Model.extend({
  rules: DS.hasMany('bountyRule', { async: false }),
  unknownProperty: function(prop) {
    if (prop.substr(-4) === 'Rule') {
      Em.defineProperty(this, prop, Em.computed(
          'rules.@each.bountyType', function() {
        var ruleType = prop.replace('Rule', '').decamelize();
        return this.get('rules').findBy('bountyType', ruleType);
      }));
      return this.get(prop);
    }
  }
});

SL.BountyCandidateSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    rules: { serialize: false, deserialize: 'records' }
  }
});


})();

(function() {

SL.DbTransaction = DS.Model.extend({
  user: DS.belongsTo('user', { async: true }),
  issuedAt: DS.attr('date')
});

SL.ChangeHistory = DS.Model.extend({
  transaction: DS.belongsTo('dbTransaction', { async: false }),
  model: DS.attr('string'),
  info: DS.attr(),
  added: DS.attr(),
  deleted: DS.attr(),
  operationType: DS.attr('number')
});

SL.ChangeHistorySerializer = SL.ApplicationSerializer.extend({
  attrs: {
    transaction: { serialize: false, deserialize: 'records' }
  }
});


})();

(function() {

SL.Channel = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName: DS.attr('string'),
  cName: DS.attr('string'),
  inhibited: DS.attr(),
  eDescription: DS.attr('string'),
  cDescription: DS.attr('string'),
  description: function() {
    var eName = this.get('eDescription'),
      cName = this.get('cDescription');
    if (Em.I18n.locale === 'zh')
      return cName || eName;
    return eName || cName;
  }.property('eDescription', 'cDescription').readOnly(),
  isDefault: DS.attr('boolean'),
  category: DS.attr('string'),
  logo: 'images/channel_logo.svg'
});

SL.ChannelPublication = DS.Model.extend({
  vacancyId: DS.attr('number'),
  channel: DS.belongsTo('channel', { async: true }),
  status: DS.attr('string'),
  channelName: Em.computed.readOnly('channel.name')
});


})();

(function() {

SL.CompanyGroup = DS.Model.extend({
  memberStrategy: DS.attr('string'),
  features: DS.attr(),
  primary: DS.belongsTo('company', {
    toOne: true, async: false, inverse: null })
});

SL.CompanyGroupSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    primary: { serialize: false, deserialize: 'records' }
  }
});

SL.Company = DS.Model.extend(SL.FullModelMixin, {
  name          : DS.belongsTo('companyAlias',
                    { async: false, toOne: true }),
  companySize   : DS.attr('number', { defaultValue: 0 }),
  companyType   : DS.attr('number', { defaultValue: 0 }),
  location      : DS.belongsTo('location', { async: true }),
  nationality   : DS.belongsTo('location', { async: true }),
  address       : DS.attr('string'),
  industries    : DS.hasMany('industry', { async: true }),
  tn1           : DS.attr('string'),
  tn2           : DS.attr('string'),
  tn3           : DS.attr('string'),
  website       : DS.attr('string'),
  description   : DS.attr('string'),
  photo         : DS.belongsTo('companyFile', { toOne: true, async: false }),
  editable      : DS.attr('boolean', { defaultValue: true }),
  employees     : DS.attr('number'),
  asGroup       : DS.belongsTo('companyGroup', { toOne: true, async: false }),
  groups        : DS.hasMany('companyGroup', { async: false }),
  reviewPending : DS.attr('boolean'),
  eName         : Em.computed.alias('name.eName'),
  cName         : Em.computed.alias('name.cName')
});

SL.CompanyAdapter = SL.ApplicationAdapter.extend({
  urlForUpdateRecord: function(id, modelName, snapshot) {
    var url = this._super.apply(this, arguments);
    if (snapshot.adapterOptions && snapshot.adapterOptions.forReview)
      return url.replace('company/' + id, 'company/' + id + '?for_review=1');
    return url;
  }
});

SL.CompanySerializer = SL.ApplicationSerializer.extend({
  attrs: {
    name: { embedded: 'always' },
    photo: { embedded: 'always' },
    asGroup: { serialize: false, deserialize: 'records' },
    groups: { serialize: false, deserialize: 'records' },
    industries: { serialize: 'records', deserialize: false }
  }
});

SL.CompanyAlias = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName: DS.attr('string'),
  cName: DS.attr('string'),
  owner: DS.belongsTo('company', { async: false, inverse: null }),
  // Set operation need to take place on owner attribute directly
  ownerId: Em.computed.readOnly('owner.id')
});

SL.CompanyAliasSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    owner: { serialize: 'ids', deserialize: 'records' }
  }
});


})();

(function() {

SL.File = DS.Model.extend({
  fileType  : DS.attr('string'),
  path      : DS.attr('string'),
  filename  : DS.attr('string'),
  originFilename  : DS.attr('string'),
  createAt  : DS.attr('date'),
  creator   : DS.belongsTo('user', { async: true }),
  updateAt  : DS.attr('date'),
  attached  : DS.attr('boolean'),

  url: function() {
    var type = this.get('fileType');
    if (typeof type === 'string' && type.substr(-5) === 'photo') {
      return '/static/upload_files/' + this.get('path') + '/' +
        this.get('filename');
    } else {
      return 'download_file/' + this.get('fileType') + '/' + this.get('id');
    }
  }.property('id', 'fileType', 'path', 'filename').readOnly(),

  thumb: function() {
    /* jscs:disable */
    if (('' + this.get('fileType')).substr(-5) === 'photo') {
      /* jscs:enable */
      var result = {},
          ext = this.get('filename').substr(this.get('filename').
            lastIndexOf('.') + 1),
          path = 'static/upload_files/' + this.get('path') + '/' +
            this.get('id') + '_' + this.get('fileType') + '_',
          rand = +this.get('updateAt');
      Em.$.map(['l', 'm', 's', 'i'], function(v) {
        result[v] = path + v  + '.' + ext + (v === 'i' ? '' : '?r=' + rand);
      });
      return result;
    }
  }.property('id', 'fileType', 'path', 'filename', 'updateAt').readOnly(),
});

SL.PersonFile = SL.File.extend();
SL.CompanyFile = SL.File.extend();


})();

(function() {

SL.Folder = DS.Model.extend({
  name: DS.attr('string'),
  category: DS.attr('string'),
  assignees: DS.hasMany('user', { async: true }),
  isOwner: DS.attr('boolean'),
  owner: DS.belongsTo('user', { async: true }),
  integrity: DS.attr('number'),
});

SL.FolderSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    assignees: { serialize: 'ids' }
  }
});

SL.FolderPersonStatus = DS.Model.extend({
  folder: DS.belongsTo('folder', { async: true }),
  person: DS.belongsTo('realPerson', { async: false }),
  status: DS.attr('number'),
  entryType: DS.attr('string')
});

SL.FolderPersonStatusSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    person: { serialize: 'ids', deserialize: 'records' }
  }
});

SL.FavoritePerson = SL.FolderPersonStatus.extend();
SL.FavoritePersonSerializer = SL.FolderPersonStatusSerializer.extend();

SL.MappingEntry = SL.FolderPersonStatus.extend({
  company: DS.belongsTo('companyAlias', { async: false }),
  title: DS.belongsTo('title', { async: false }),
  associate: DS.belongsTo('realPerson', { async: false }),
  associateType: DS.attr('string'),
  description: DS.attr('string')
});

SL.MappingEntrySerializer = SL.FolderPersonStatusSerializer.extend({
  attrs: {
    person: { serialize: 'ids', deserialize: 'records' },
    associate: { serialize: 'ids', deserialize: 'records' },
    company: { embedded: 'always' },
    title: { embedded: 'always' }
  }
});


})();

(function() {

SL.ForeignVacancy = DS.Model.extend({
  title: DS.attr('string'),
  url: DS.attr('string'),
  location: DS.attr('string')
});


})();

(function() {

SL.Function = DS.Model.extend(SL.FullModelMixin,
  SL.UniversialNameModelMixin, {
  type              : DS.attr('number'),
  eName             : DS.attr('string'),
  cName             : DS.attr('string'),
  eFullname         : DS.attr('string'),
  cFullname         : DS.attr('string'),
  functionId        : DS.attr('number'),
  functionGroupId   : DS.attr('number')
});


})();

(function() {

SL.Industry = DS.Model.extend(SL.FullModelMixin,
  SL.UniversialNameModelMixin, {
  type              : DS.attr('number'),
  eName             : DS.attr('string'),
  cName             : DS.attr('string'),
  eFullname         : DS.attr('string'),
  cFullname         : DS.attr('string'),
  categoryId        : DS.attr('number')
});


})();

(function() {

SL.Title = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName : DS.attr('string'),
  cName : DS.attr('string'),
});


})();

(function() {

SL.LedgerAccount = DS.Model.extend({
  balance: DS.attr('monetaryAmount'),
  minWithdrawAmount: DS.attr('monetaryAmount'),
  withdrawInhibited: DS.attr('string')
});

SL.Split = DS.Model.extend({
  amount: DS.attr('monetaryAmount'),
  transaction: DS.belongsTo('transaction', { async: false }),
  runningBalance: DS.attr('monetaryAmount'),
  enterDate: DS.attr('isodate'),
  memo: DS.attr('string'),
  standardAction: DS.attr('string'),
  customAction: DS.attr('string'),
  absAmount: function() {
    var amount = this.get('amount'), parts = amount.split('.');
    amount = Math.abs(amount);
    return parts.length > 1 ? amount.toFixed(parts[1].length) : amount;
  }.property('amount')
});

SL.Transaction = DS.Model.extend({
  reference: DS.attr('string'),
  description: DS.attr('string')
});

var _ledgerSerializer = SL.ApplicationSerializer.extend({
  primaryKey: 'guid'
});

SL.LedgerAccountSerializer = _ledgerSerializer.extend();
SL.TransactionSerializer = _ledgerSerializer.extend();
SL.SplitSerializer = _ledgerSerializer.extend({
  attrs: {
    transaction: { deserialize: 'records', serialize: false }
  }
});


})();

(function() {

SL.Location = DS.Model.extend(SL.UniversialNameModelMixin,
  SL.FullModelMixin, {
  type          : DS.attr('number'),
  eName         : DS.attr('string'),
  cName         : DS.attr('string'),
  eFullname     : DS.attr('string'),
  cFullname     : DS.attr('string')
});


})();

(function() {

/* folder: 0, inbox; 1, sent; 2, Draft; 3, Spam; 4, Trash;
  5+ customized folder */
SL.Mail = DS.Model.extend({
  folder         : DS.attr('number'),
  createBy       : DS.belongsTo('number'),
  createAt       : DS.belongsTo('date'),
  to             : DS.hasMany('PersonEmail'),
  cc             : DS.hasMany('PersonEmail'),
  bcc            : DS.hasMany('PersonEmail'),
  subject        : DS.attr('string'),
  content        : DS.attr('string'),
  attach         : DS.hasMany('Attachment'),
  priority       : DS.attr('number'),
});

SL.MailSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    owner     : { embedded: 'always' },
    to        : { embedded: 'always' },
    cc        : { embedded: 'always' },
    bcc       : { embedded: 'always' },
  }
});


})();

(function() {

SL.AccountKpi = DS.Model.extend({
  issuedAt: DS.attr('date'),
  status: DS.attr('number'),
  missReason: DS.attr()
});

SL.AccountNewPerson = SL.AccountKpi.extend({
  candidate: DS.belongsTo('person', { async: true }),
});

SL.AccountNewVacancy = SL.AccountKpi.extend({
  vacancy: DS.belongsTo('vacancy', { async: true }),
});

SL.AccountClientMeeting = SL.AccountKpi.extend({
  client: DS.belongsTo('person', { async: true }),
  clientCompany: DS.belongsTo('company', { async: true }),
  interview: DS.belongsTo('personComment', { async: true })
});

SL.AccountInterview = SL.AccountKpi.extend({
  candidate: DS.belongsTo('person', { async: true }),
  interview: DS.belongsTo('personComment', { async: true })
});

SL.AccountClientInterview = SL.AccountKpi.extend({
  candidate: DS.belongsTo('realPerson', { async: true }),
  vacancy: DS.belongsTo('vacancy', { async: true }),
  vacancyStatusStatus: DS.attr('number'),
  previousStatus: function() {
    return this.get('vacancyStatusStatus') - this.get('status');
  }.property('vacancyStatusStatus', 'status')
});

SL.AccountReported = SL.AccountKpi.extend({
  candidate: DS.belongsTo('realPerson', { async: true }),
  vacancy: DS.belongsTo('vacancy', { async: true }),
  vacancyStatusStatus: DS.attr('number'),
  previousStatus: DS.attr('number')
});

SL.AccountPhoneRecord = SL.AccountKpi.extend({
  candidate: DS.belongsTo('person', { async: true }),
  call: DS.belongsTo('personComment', { async: true })
});

SL.AccountBdCall = SL.AccountKpi.extend({
  candidate: DS.belongsTo('person', { async: true }),
  call: DS.belongsTo('personComment', { async: true })
});

SL.AccountFollowUpCall = SL.AccountKpi.extend({
  candidate: DS.belongsTo('person', { async: true }),
  vacancy: DS.belongsTo('vacancy', { async: true }),
  call: DS.belongsTo('personComment', { async: true })
});


})();

(function() {

SL.PersonComment = DS.Model.extend({
  category  : DS.attr('string'),
  comment   : DS.attr('string'),
  ownerId   : DS.attr('number'),
  createAt  : DS.attr('date'),
  creator   : DS.belongsTo('user', { async: true }),
  vacancy   : DS.belongsTo('vacancy', { async: true }),
  owner     : DS.belongsTo('person', { async: true })
});


})();

(function() {

SL.Major = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName    : DS.attr('string'),
  cName    : DS.attr('string')
});

SL.PersonEducationExperience = DS.Model.extend(Em.SortableMixin, {
  startDate       : DS.attr('isodate'),
  endDate         : DS.attr('isodate'),
  major           : DS.belongsTo('major', { async: false }),
  degreeId        : DS.attr('number', { defaultValue: 0 }),
  school          : DS.belongsTo('schoolAlias', { async: false }),
  fullTime        : DS.attr('boolean', { defaultValue: true }),

  sortProperties: ['startDate'],
  sortAscending: false,

  startYear: SL.computed.yearValue('startDate', 'startMonth'),
  startMonth: SL.computed.monthValue('startDate', 'startYear'),
  endYear: SL.computed.yearValue('endDate', 'endMonth'),
  endMonth: SL.computed.monthValue('endDate', 'endYear'),
});

SL.PersonEducationExperienceSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    major: { embedded: 'always' },
    school: { embedded: 'always' }
  }
});


})();

(function() {

SL.PersonMobile = DS.Model.extend({
  name: DS.attr('string'),
  owner: DS.belongsTo('person', { async: false })
});

SL.PersonMobileSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    owner: { serialize: false, deserialize: false }
  }
});

SL.MaskedMobile = DS.Model.extend({
  prefix: DS.attr('string'),
  owner: DS.belongsTo('person', { async: false }),
  name: function() {
    return this.get('prefix') + '********';
  }.property('prefix').readOnly()
});

SL.MaskedMobileSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    owner: { serialize: false, deserialize: false }
  }
});

SL.PersonEmail = DS.Model.extend({
  name: DS.attr('string'),
  owner: DS.belongsTo('person', { async: false })
});

SL.PersonEmailSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    owner: { serialize: false, deserialize: false }
  }
});

SL.MaskedEmail = DS.Model.extend({
  domain: DS.attr('string'),
  owner: DS.belongsTo('person', { async: false }),
  name: function() {
    return '******@' + this.get('domain');
  }.property('domain').readOnly()
});

SL.MaskedEmailSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    owner: { serialize: false, deserialize: false }
  }
});

SL.PersonIm = DS.Model.extend({
  name : DS.attr('string'),
  type : DS.attr('string')
});

SL.Skill = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName     : DS.attr('string'),
  cName     : DS.attr('string')
});

SL.Language = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName     : DS.attr('string'),
  cName     : DS.attr('string')
});

SL.Certificate = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName     : DS.attr('string'),
  cName     : DS.attr('string')
});

SL.Person = DS.Model.extend(SL.FullModelMixin, SL.UniversialNameModelMixin, {
  //basic attributes
  eName             : DS.attr('string'),
  cName             : DS.attr('string'),
  gender            : DS.attr('number'),
  maritalStatus     : DS.attr('number', { defaultValue: 0 }),
  location          : DS.belongsTo('location', { async: true }),
  address           : DS.attr('string'),
  birthDate         : DS.attr('isodate'),
  estimateBirthDate : DS.attr('boolean', { defaultValue: false }),
  mobiles           : DS.hasMany(
    'personMobile', { toOne: true, async: false, inverse: 'owner' }),
  maskedMobiles     : DS.hasMany(
    'maskedMobile', { async: false, inverse: 'owner' }),
  emails            : DS.hasMany(
    'personEmail', { toOne: true, async: false, inverse: 'owner' }),
  maskedEmails      : DS.hasMany(
    'maskedEmail', { async: false, inverse: 'owner' }),
  ims               : DS.hasMany('personIm', { toOne: true, async: false }),
  functions         : DS.hasMany('function', { async: true }),
  preferLocations   : DS.hasMany('location', { async: true }),
  preferIndustries  : DS.hasMany('industry', { async: true }),
  skills            : DS.hasMany('skill', { async: false }),
  languages         : DS.hasMany('Language', { async: true }),
  certificates      : DS.hasMany('certificate', { async: false }),
  updateAt          : DS.attr('date'),
  owner             : DS.belongsTo('realPerson',
                      { async: true, inverse: null }),
  ownerId           : DS.attr('number'),
  isPrivate         : DS.attr('number'),
  pools             : DS.hasMany('talentPerson', { async: false }),

  employmentStatus  : DS.attr('number', { defaultValue: 1 }),
  startWorkYear     : DS.attr('number'),

  //extended attributes
  workExperiences       : DS.hasMany('personWorkExperience',
                            { toOne: true, async: false }),
  educationExperiences  : DS.hasMany('personEducationExperience',
                            { toOne: true, async: false }),
  projectExperiences    : DS.hasMany('personProjectExperience',
                            { toOne: true, async: false }),
  files                 : DS.hasMany('personFile',
                            { toOne: true, async: false }),
  photo                 : DS.belongsTo('personFile',
                            { toOne: true, async: false }),
  careerSummary         : DS.attr('string'),
  interest              : DS.attr('string'),
  employmentType        : DS.attr('number', { defaultValue: 1 }),
  expectedSalary        : DS.attr('number'),
  considerVentureFirm   : DS.attr('boolean', { defaultValue: true }),
  targetCompanies       : DS.hasMany('companyAlias', { async: false }),
  blockedCompanies      : DS.hasMany('companyAlias', { async: false }),
  parentId              : DS.attr('number'),

  currentWork           : DS.belongsTo(
                          'personWorkExperience', { async: false }),
  currentEducation      : DS.belongsTo(
                          'personEducationExperience', { async: false }),
  isMasked              : DS.attr('boolean'),
  isOwnedProfile        : DS.attr('boolean'),
  cost                  : DS.attr('monetaryAmount'),
  shareMessages         : DS.attr(),
  editable              : DS.attr('boolean'),
  extra                 : DS.attr({ defaultValue: {} }),

  // computed

  // The property is only used for full profile display
  lastWork: function() {
    var currId = this.get('currentWork.id');
    return currId && this.get('workExperiences').findBy('id', currId) ||
      this.get('currentWork');
  }.property('currentWork.id', 'workExperiences.@each.id'),

  workPhoneNumber: function() {
    var tn1 = this.get('currentWork.company.owner.tn1'),
        tn2 = this.get('currentWork.company.owner.tn2'),
        tn3 = this.get('currentWork.company.owner.tn3'),
        gl = this.get('lastWork.gl');
    if (tn3) {
      var phoneNumber = SL.helper.phoneNumber(tn1, tn2, tn3);
      if (gl) phoneNumber += gl.length >= 6 ? '/' + gl : ' * ' + gl;
      return phoneNumber;
    } else if (gl && gl.length >= 6) {
      return SL.helper.phoneNumber(tn1, tn2, gl);
    }
  }.property('currentWork.company.owner.tn1', 'currentWork.company.owner.tn2',
    'currentWork.company.owner.tn3', 'lastWork.gl').readOnly(),

  expectedSalaryConv: SL.computed.salary('expectedSalary')

});

SL.PersonSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    emails                : { embedded: 'always' },
    mobiles               : { embedded: 'always' },
    maskedEmails          : { embedded: 'always' },
    maskedMobiles         : { embedded: 'always' },
    ims                   : { embedded: 'always' },

    functions             : { serialize: 'records', deserialize: false },
    preferIndustries      : { serialize: 'records', deserialize: false },
    preferLocations       : { serialize: 'records', deserialize: false },

    skills                : { embedded: 'always' },
    languages             : { serialize: 'records', deserialize: false },
    certificates          : { embedded: 'always' },

    files                 : { embedded: 'always' },
    photo                 : { embedded: 'always' },

    targetCompanies       : { embedded: 'always' },
    blockedCompanies      : { embedded: 'always' },

    currentWork           : { serialize: false, deserialize: 'records' },
    currentEducation      : { serialize: false, deserialize: 'records' },

    projectExperiences    : { embedded: 'always' },
    workExperiences       : { embedded: 'always' },
    educationExperiences  : { embedded: 'always' },
    pools                 : { embedded: 'always' }
  }
});

SL.TalentPerson = DS.Model.extend({
  pool: DS.belongsTo('talentPool', { async: true })
});

SL.TalentPersonSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    pool: { serialize: 'records', deserialize: false }
  }
});

SL.TalentPool = DS.Model.extend({
  type: DS.attr('number')
});

SL.PersonAdapter = SL.ApplicationAdapter.extend({
  urlForCreateRecord: function(modelName, snapshot) {
    var url = this._super.apply(this, arguments);
    if (snapshot.adapterOptions && snapshot.adapterOptions.isOwned)
      return url.replace('person', 'person?owned=true');
    return url;
  }
});

SL.CvFormat = DS.Model.extend(SL.FullModelMixin, SL.UniversialNameModelMixin, {
  eName: DS.attr('string'),
  cName: DS.attr('string'),
  highlights: DS.attr(),
  maskable: DS.attr()
});


})();

(function() {

SL.PersonProjectExperience = DS.Model.extend(Em.SortableMixin, {
  startDate       : DS.attr('isodate'),
  endDate         : DS.attr('isodate'),
  name            : DS.attr('string'),
  description     : DS.attr('string'),
  company         : DS.belongsTo('companyAlias', { async: false }),

  sortProperties: ['startDate'],
  sortAscending: false,

  startYear: SL.computed.yearValue('startDate', 'startMonth'),
  startMonth: SL.computed.monthValue('startDate', 'startYear'),
  endYear: SL.computed.yearValue('endDate', 'endMonth'),
  endMonth: SL.computed.monthValue('endDate', 'endYear'),

  validations: {
    company: {
      presence: { message: 'Company is not set.' }
    },
    startDate: {
      presence: { message: 'Start date is not set.' }
    },
    endDate: {
      presence: { message: 'End date is not set.' }
    },
    name: {
      presence: { message: 'Project name is not set.' }
    }
  }
});

SL.PersonProjectExperienceSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    company: { embedded: 'always' }
  }
});


})();

(function() {

SL.PersonSalary = DS.Model.extend({
  item      : DS.attr('string'),
  value     : DS.attr('number'),
  by        : DS.attr('number', { defaultValue: 1.0 }),
  unit      : DS.attr('number', { defaultValue: 0 }),
  percent   : DS.attr('number', { defaultValue: 100 }),
  comments  : DS.attr('string')
});


})();

(function() {

SL.PersonWorkExperience = DS.Model.extend(Em.SortableMixin, {
  startDate       : DS.attr('isodate'),
  endDate         : DS.attr('isodate'),
  gl              : DS.attr('string'),
  companyEmail    : DS.attr('string'),
  title           : DS.belongsTo('title', { async: false }),
  responsibility  : DS.attr('string'),
  achievement     : DS.attr('string'),
  leaveReason     : DS.attr('string'),
  function        : DS.belongsTo('function', { async: true }),
  company         : DS.belongsTo('companyAlias', { async: false }),
  totalSalary     : DS.attr('number'),
  salaryDetail    : DS.attr('string'),
  reportTo        : DS.belongsTo('realPerson', { async: true }),

  sortProperties: ['startDate'],
  sortAscending: false,

  startYear: SL.computed.yearValue('startDate', 'startMonth'),
  startMonth: SL.computed.monthValue('startDate', 'startYear'),
  endYear: SL.computed.yearValue('endDate', 'endMonth'),
  endMonth: SL.computed.monthValue('endDate', 'endYear')
});

SL.PersonWorkExperienceSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    title: { embedded: 'always' },
    company: { embedded: 'always' }
  }
});


})();

(function() {

SL.RealPerson = DS.Model.extend({
  ownedProfiles: DS.hasMany('person', { async: true }),
  defaultProfile: DS.belongsTo('person', { async: false }),
  profilesIds: DS.attr(),
  vcStatus: DS.attr(),
  name: Em.computed.alias('defaultProfile.name'),

  resetVcStatus: function() {
    if (!Em.isEmpty(this.get('vcStatus')))
      this.set('vcStatus', undefined);
    return this;
  }
});

SL.RealPersonSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    defaultProfile: { deserialize: 'records', serialize: false }
  }
});

SL.RealPersonAdapter = SL.ApplicationAdapter.extend({
  shouldReloadRecord: function(store, snapshot) {
    if (!this._super(store, snapshot))
      return false;
    // Extra check on the profile. Hold the reload until it's unmasked
    var profile = snapshot.belongsTo('defaultProfile');
    return !profile || !profile.attr('isMasked');
  }
});


})();

(function() {

SL.Register = DS.Model.extend({
  email: DS.attr('string'),
  password: DS.attr('string'),
  registerType: DS.attr('string'),
  user: DS.belongsTo('user', { async: true })
});

SL.RegisterSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    user: { serialize: 'records', deserialize: false }
  }
});


})();

(function() {

SL.Report = DS.Model.extend(SL.FullModelMixin, SL.UniversialNameModelMixin, {
  eName         : DS.attr('string'),
  cName         : DS.attr('string'),
  groupId       : DS.attr('number'),
  managerOnly   : DS.attr('boolean'),
  filterFirst   : DS.attr('boolean'),
  filters       : DS.attr(),
  widgets       : DS.attr(),
  queries       : DS.attr(),

  resetFilter: function() {
    var filters = Em.get(this, 'filters') || [];
    filters.forEach(function(f) {
      if (f.type === 'date_range') {
        Em.set(f, 'startValue', undefined);
        Em.set(f, 'endValue', undefined);
      } else {
        Em.set(f, 'value', Em.get(f, 'default_value') || undefined);
      }
    });
    this.setProperties({
      filterPending: this.get('filterFirst') ? undefined : true,
      filterActive: false
    });
  }
});


})();

(function() {

SL.SchoolAlias = DS.Model.extend(SL.UniversialNameModelMixin, {
  eName         : DS.attr('string'),
  cName         : DS.attr('string'),
  isDefault     : DS.attr('boolean'),
  ownerId       : DS.attr('number')
});


})();

(function() {

SL.SocialToken = DS.Model.extend({
  accessTokenExpire: DS.attr('number'),
  updateAt: DS.attr('date'),

  expireAt: function() {
    return moment(this.get('updateAt')).add(this.get('accessTokenExpire'),
      's');
  }.property('accessTokenExpire', 'updateAt')
});

SL.LinkedinToken = SL.SocialToken.extend({
  companyShare: DS.attr('boolean')
});
SL.WeiboToken = SL.SocialToken.extend();
SL.WeixinToken = SL.SocialToken.extend();

SL.WecomToken = DS.Model.extend({
  updateAt: DS.attr('date'),
  uid: DS.attr('string'),
  qrCode: DS.attr('string'),
  avatar: DS.attr('string')
});

SL.WechatSites = DS.Model.extend({
  mobileUrl: DS.attr('string'),
  wechat: DS.attr('string'),
  siteId: function() {
    return this.get('id') && parseInt(this.get('id'));
  }.property('id').readOnly()
});


})();

(function() {

SL.Role = DS.Model.extend({
  name      : DS.attr('string')
});

SL.UserCredential = DS.Model.extend({
  agreedDownloadTerms: DS.attr('boolean'),
  credits: Em.computed.alias('sharedCredit'),
  debits: Em.computed.alias('sharedDebit'),
  sharedCredit: DS.attr('monetaryAmount', { defaultValue: (0).toFixed(2) }),
  sharedDebit: DS.attr('monetaryAmount', { defaultValue: (0).toFixed(2) })
});

SL.User = DS.Model.extend({
  email         : DS.attr('string'),
  password      : DS.attr('string'),
  nickname      : DS.attr('string'),
  roles         : DS.hasMany('role', { async: false }),
  realPerson    : DS.belongsTo('realPerson', { async: true }),
  linkedinToken : DS.belongsTo('linkedinToken', { async: false }),
  weiboToken    : DS.belongsTo('weiboToken', { async: false }),
  weixinToken   : DS.belongsTo('weixinToken', { async: false }),
  wecomToken    : DS.belongsTo('wecomToken', { async: false }),
  company       : DS.belongsTo('company', { async: true }),
  accountId     : DS.attr('number'),
  weakPassword  : DS.attr('boolean'),
  credential    : DS.belongsTo('userCredential', { async: false }),

  name: function() {
    return this.get('realPerson.name') || this.get('nickname') ||
      this.get('email') || Em.I18n.t('common.default_name');
  }.property('realPerson.name', 'nickname', 'email'),

  isManager: function() {
    return this.get('roles').isAny('name', 'admin');
  }.property('roles.@each.name').readOnly(),

  isStaff: function() {
    if (!this.get('roles').isAny('name', 'staff'))
      return false;
    var coerceRole = this.get('coerceRole');
    return Em.isEmpty(coerceRole) || coerceRole === 'staff';
  }.property('roles.@each.name', 'coerceRole').readOnly(),

  isGuest: function() {
    return this.get('roles').isAny('name', 'guest');
  }.property('roles.@each.name').readOnly(),

  isRoot: function() {
    return this.get('roles').isAny('name', 'root');
  }.property('roles.@each.name').readOnly(),

  isHr: function() {
    return this.get('roles').isAny('name', 'hr');
  }.property('roles.@each.name').readOnly(),

  isHunter: function() {
    return this.get('roles').isAny('name', 'hunter');
  }.property('roles.@each.name').readOnly(),

  canPublish: function() {
    return this.get('roles').isAny('name', 'publisher');
  }.property('roles.@each.name').readOnly(),

  isPublisher: function() {
    if (!this.get('canPublish'))
      return false;
    var coerceRole = this.get('coerceRole');
    return Em.isEmpty(coerceRole) || coerceRole === 'publisher';
  }.property('canPublish', 'coerceRole').readOnly(),

  isSorter: function() {
    return this.get('roles').isAny('name', 'sorter');
  }.property('roles.@each.name').readOnly(),

  defaultProfile: Em.computed.readOnly('realPerson.defaultProfile'),
  ownedProfile: Em.computed.readOnly('realPerson.ownedProfiles.firstObject')
});

SL.UserSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    credential: { embedded: 'always' },
    roles: { deserialize: 'records', serialize: false },
    linkedinToken: { embedded: 'always' },
    weiboToken: { embedded: 'always' },
    weixinToken: { embedded: 'always' },
    wecomToken: { embedded: 'always' }
  }
});

SL.UserAdapter = SL.ApplicationAdapter.extend({
  urlForUpdateRecord: function(id, modelName, snapshot) {
    var url = this._super.apply(this, arguments);
    // Only allowd to update own profile
    return url.replace('user', 'my_profile');
  }
});


})();

(function() {

SL.VacancyComment = DS.Model.extend({
  category  : DS.attr('string'),
  comment   : DS.attr('string'),
  ownerId   : DS.attr('number'),
  createAt  : DS.attr('date'),
  creator   : DS.belongsTo('user', { async: true })
});


})();

(function() {

SL.VacancyCandidate = DS.Model.extend(
    SL.FullModelMixin, {
  vacancy         : DS.belongsTo('vacancy', { async: false }),
  candidate       : DS.belongsTo('realPerson', { async: false }),
  status          : DS.attr('number'),
  out             : DS.attr('boolean', { defaultValue: false }),
  sourcingChannel : DS.attr('string'),
  applyStatus     : DS.attr('string'),
  price           : DS.attr('monetaryAmount'),
  channel         : DS.belongsTo('channel', { async: false }),
  updateAt        : DS.attr('date'),
  fromBounty      : DS.belongsTo('bountyCandidate', { async: false }),
  interviewRounds : DS.attr('number'),

  resetRpSummary: function() {
    if (this.get('candidate'))
      Em.run.once(this.get('candidate'), 'resetVcStatus');
  }.observes('status', 'out')
});

SL.VacancyCandidateSerializer = SL.ApplicationSerializer.extend({
  attrs: {
    vacancy: { serialize: 'ids', deserialize: 'records' },
    candidate: { serialize: 'ids', deserialize: 'records' },
    channel: { serialize: false, deserialize: 'records' },
    fromBounty: { serialize: false, deserialize: 'records' }
  }
});

SL.VacancyTag = DS.Model.extend({
  name : DS.attr('string')
});

SL.VacancyNotification = DS.Model.extend({
  method: DS.attr('string'),
  event: DS.attr('string'),
  userId: DS.attr('number')
});

SL.Vacancy = DS.Model.extend(SL.FullModelMixin, {
  title            : DS.belongsTo('title', { async: false }),
  clientCompany    : DS.belongsTo('company', { async: false }),
  hrContact        : DS.belongsTo('realPerson', { async: true }),
  workingCompany   : DS.belongsTo('company', { async: true }),
  workLocation     : DS.belongsTo('location', { async: true }),
  jobFunction      : DS.belongsTo('function', { async: true }),
  headCount        : DS.attr('number', { defaultValue: 1 }),
  salaryFrom       : DS.attr('salary'),
  salaryTo         : DS.attr('salary'),
  salaryNegotiable : DS.attr('boolean', { defaultValue: false }),
  companyDesc      : DS.attr('string'),
  tags             : DS.hasMany('vacancyTag', { async: false }),
  highlight        : DS.attr('string'),
  responsibility   : DS.attr('string'),
  requirements     : DS.attr('string'),
  caseFrom         : DS.belongsTo('user', { async: 'true' }),
  researcher       : DS.belongsTo('user', { async: 'true' }),
  status           : DS.attr('number', { defaultValue: 0 }),
  createAt         : DS.attr('date'),
  creator          : DS.belongsTo('user', { async: true }),
  ownership        : DS.attr('boolean'),
  channelSummary   : DS.hasMany('channel', { async: true }),
  jobType          : DS.attr('string', { defaultValue: 'permanent' }),
  requiredExperience: DS.attr('string'),
  sharable         : DS.attr(),
  notifications    : DS.hasMany('vacancyNotification', { async: false }),
  renewAt          : DS.attr('date'),
  candidateStat    : DS.attr(),
  candidateSummary : DS.attr(),
  renew            : DS.attr(),
  hireAs           : DS.attr('string'),
  expireAt         : DS.attr('date'),
  collaborators    : DS.hasMany('user', { async: true }),
  extra            : DS.attr({ defaultValue: {} }),

  hireAsOwner      : Em.computed.equal('hireAs', 'owner')
});

SL.VacancySerializer = SL.ApplicationSerializer.extend({
  attrs: {
    title          : { embedded: 'always' },
    clientCompany  : { embedded: 'always' },
    tags           : { embedded: 'always' },
    notifications  : { embedded: 'always' },
    collaborators  : { serialize: 'ids' }
  }
});


})();

(function() {

SL.MainTab = Em.ObjectProxy.extend({
  name: function() {
    var translation = this.get('titleTranslation');
    if (translation) {
            return Em.I18n.t(translation, this.get('goto.model'));
    }
    return this.get('title');
  }.property('title', 'titleTranslation').readOnly(),
  active: SL.computed.eql('tabCtl.currentTab', 'content')
});

SL.MainTabsController = Em.Controller.extend({
  init: function() {
    this.set('model', Em.makeArray());
  },

  open: function(tab) {
    // TODO: See bug https://github.com/emberjs/ember.js/issues/3931
    // The addObject call should check the content
    if (!Em.isPresent(this.get('model').filterBy('content', tab))) {
      tab.previous = this.currentTab;
      this.get('model').addObject(SL.MainTab.create({
        content: tab,
        tabCtl: this
      }));
    }
    this.set('currentTab', tab);
  },

  openDefault: function() {
    this.set('defaultTab', {
      titleTranslation: 'application.welcome.tab',
      goto: { route: 'index' }
    });
    this.open(this.defaultTab);
  },

  goTo: function(goto) {
    var params = [goto.route, goto.model, goto.options];
    this.transitionToRoute.apply(this, params.compact());
  }
});

var RouteMixin = Em.Mixin.create({

  mainTabs: function() {
    return this.controllerFor('mainTabs');
  }.property(),

  closeActiveTab: function(tab) {
    this.set('mainTabs.closingTab', tab);
    var goto = tab.previous.isOpen ? tab.previous.goto : { route: 'index' };
    this.get('mainTabs').goTo(goto);
  },

  // This is used by routes to close themselves
  closeTab: function(next) {
    var tab = this.get('tabItem');
    if (next) {
      this.set('mainTabs.currentTab', tab.previous);
      tab.previous = { isOpen: true, goto: next };
    }
    this.closeActiveTab(tab);
  },

  openTab: function() {
    var curTab = this.get('tabItem');
    this.get('mainTabs').open(curTab);
    curTab.isOpen = true;
  },

  reopenTab: function() {
    this.set('currentModel', null);
  },

  initTab: function() {
    Em.$('.infinite-tabs').infiniteTabs();
  },

  actions: {
    goTab: function(tab) {
      this.get('mainTabs').goTo(tab.get('goto'));
    },
    // Handler of individual tab close event
    closeTab: function(tab) {
      if (tab.get('active'))
        // The active tab should be treated differently
        this.closeActiveTab(tab.get('content'));
      else {
        tab.set('isOpen', false);
        this.get('mainTabs.model').removeObject(tab);
      }
    },
    closeOtherTabs: function() {
      var other = this.get('mainTabs.model').filterBy('active', false);
      other.removeObject(this.get('mainTabs.model.firstObject'));
      other.setEach('isOpen', false);
      this.get('mainTabs.model').removeObjects(other);
    },
    closeCurrentTab: function() {
      this.closeTab();
    },
    replaceTab: function(routeName, params) {
      if (this.get('tabItem.goto.route') !== routeName) {
        var options = this.get('tabItem.goto.options') || {};
        if (params)
          options.queryParams = params.values;
        this.closeTab({
          route: routeName,
          model: this.currentModel,
          options: options
        });
      }
    },
    willTransition: function(transition) {
      var tab = this.get('mainTabs.closingTab');
      if (tab) {
        if (!tab.transition)
          // Remember the transition, in case it's aborted.
          tab.transition = transition;
        else if (tab.transition !== transition) {
          // It's not the same transition when close tab triggered
          delete tab.transition;
          this.set('mainTabs.closingTab', null);
        }
      }
      this._super(transition);
      return true;
    },
    didTransition: function() {
      var tab = this.get('mainTabs.closingTab');
      if (tab) {
        // Finish transition
        this.set('mainTabs.closingTab', null);
        delete tab.transition;
        if (tab !== this.get('mainTabs.currentTab')) {
          // Remove the tab only if transftered to another tab
          var tabProxy = this.get('mainTabs.model').findBy('content', tab);
          this.get('mainTabs.model').removeObject(tabProxy);
          tab.isOpen = false;
        }
      }
      this._super();
      return true;
    },
  }
});

SL.TabbedRouteMixin = Em.Mixin.create(RouteMixin, {
  init: function() {
    this.set('tabItem', {});
    this._super.apply(this, arguments);
  },

  beforeModel: function(transition) {
    this.setProperties({
      'tabItem.title': this.tabName,
      'tabItem.titleTranslation': this.tabNameTranslation,
      'tabItem.goto': {
        route: transition.targetName,
        options: { queryParams: transition.queryParams }
      }
    });
    var tabItem = this.get('tabItem');
    if (tabItem.isOpen === false)
      this.reopenTab(tabItem, transition);
    this.openTab();
    return this._super.apply(this, arguments);
  }

});

SL.TabbedDynamicRouteMixin = Em.Mixin.create(RouteMixin, {
  init: function() {
    this._super();
    this.set('tabs', {});
  },

  tabItem: function() {
    var id_ = this.get('tabId');
        if (!this.tabs[id_]) this.tabs[id_] = {
      title: this.tabName,
      titleTranslation: this.tabNameTranslation
    };
    return this.tabs[id_];
  }.property('tabId'),

  setupTab: function(id_) {
    if (id_)
      this.set('tabId', id_.toString());
    var tabItem = this.get('tabItem');
    if (tabItem.isOpen === false)
      this.reopenTab(tabItem, this._pendingTransisiton);
  },

  beforeModel: function(transition) {
    this._pendingTransisiton = transition;
    var contexts = transition.intent.contexts;
    if (Em.isPresent(contexts)) {
      var c = contexts[0];
      this.setupTab(typeof c === 'object' ? Em.get(c, 'id') : c);
    }
    return this._super(transition);
  },

  findModel: function(name, value) {
    this.setupTab(value); // value should be populated as model id
    return this._super(name, value);
  },

  afterModel: function(model, transition) {
    var tabItem = this.get('tabItem');
    Em.set(tabItem, 'goto', {
      route: transition.targetName,
      model: model,
      options: { queryParams: transition.queryParams }
    });
    this.openTab();
    return this._super.apply(this, arguments);
  }
});


})();

(function() {

/* global Raven */

/* All routes need to be accessed by authenticated user */
window.ENV = window.ENV || {};
window.ENV['simple-auth'] = {
  authorizer: 'authorizer:dummy',
  session: 'session:custom',
  localStorageKey: 'ember_simple_auth:session:' + window.location.pathname
};

SL.initializer({
  name: 'pre-simple-auth',
  before: 'simple-auth',
  after: 'store',

  initialize: function(container, app) {
    app.inject('authenticator:custom', 'store', 'service:store');
  }
});

/*
// Translation change with authenticated user
SimpleAuth.AuthenticatedRouteMixin.reopen({
  beforeModel: function(transition) {
    var result = this._super.apply(this, arguments);
    // Translation
    if (this.get('session.isAuthenticated')) {
      var locale = this.get('session.account.locale');
      if (Em.I18n.locale !== locale) {
        transition.abort();
        return Em.$.getJSON('locales/' + locale + '.json', function(json) {
          Em.run(function() {
            Em.I18n.setProperties({
              translations: json,
              locale: locale,
              rerenderPending: true
            });
            moment.locale(locale);
            transition.retry();
          });
        });
      }
    }
    return result;
  },
});
*/

SL.CustomSession = SimpleAuth.Session.extend({

  accountId: Em.computed.readOnly('secure.accountId'),
  account: Em.computed.readOnly('secure.account'),

  restore: function() {
    if (!SimpleAuth.Configuration._setupDone)
      // Delay restore until setup finished
      return;

    if (!Modernizr.localstorage)
      // Local storage not available, reject directly
      return Em.RSVP.reject('LocalStorage not available');

    // Use default authenticator to restore session anyway
    // as we rely on cookies for authenticated session.
    // The authenticator name need to be forced set as it doesn't
    // exist for a brand new visit.
    var restoredContent = this.store.restore();
    var authenticator = (restoredContent.secure || {}).authenticator;
    if (!authenticator) {
      Em.merge(restoredContent, {
        secure: { authenticator: 'authenticator:custom' }});
      this.store.persist(restoredContent);
    }
    return this._super.apply(this, arguments);
  },

  setup: function(authenticator, content, trigger) {
    var acct = content.account;
    var parameter = this.container.lookup('parameters:custom');

    // Override default settings
    parameter.set('content', SL.parameters);

    Raven.setUserContext({
      id: acct.get('id'),
      name: acct.get('name')
    });

    // Wechat enterprise binding
    if (acct.get('isStaff') && parameter.get('enterprise.bindWechat'))
      acct.set('bindWechat', !acct.get('weixinToken'));

    // Role check
    acct.set('coerceRole', this.get('content.coerceRole'));
    if (acct.get('isStaff') && acct.get('isPublisher')) {
      this.set('roleConflict', { staff: true, publisher: true });
      this.trigger('sessionAuthenticationFailed', { msg: 'role_conflict' });
      return;
    }

    if (parameter.hasFeature('activity_tracking')) {
      posthog.identify(parseInt(acct.get('id')));
      posthog.opt_in_capturing();
      posthog.people.set({ email: acct.get('email') });
    }

    this._super.apply(this, arguments);
  },

  clear: function(trigger) {
    Em.run(this, function() {
      this.setProperties({
        roleConflict: null,
        'content.coerceRole': null
      });
    });
    this._super(trigger);
    Raven.setUserContext();
    posthog.opt_out_capturing();
    posthog.reset();
  },

  degraded: function() {
    if (this.get('account.weakPassword'))
      return 'changePassword';
    if (this.get('account.myProfileErrors.length'))
      return 'invalidProfile';
    if (this.get('account.ownedCompanyValid') === false)
      return 'invalidCompany';
  }.property(
    'account.ownedCompanyValid', 'account.myProfileErrors.length',
    'weakPassword')

});

var OwnedCompanyValidator = Em.ObjectProxy.extend(Em.Validations.Mixin, {
  validations: SL.validations.companyWhole
});

var MyProfileValidator = Em.ObjectProxy.extend(Em.Validations.Mixin, {
  validations: {
    personalInfo: true,
    workExperience: true,
    education: true,
    projects: true
  },
  personalInfo: Em.computed.validatable('content', {
    validations: Em.$.extend(true, {
      email: true,
      mobile: true
    }, {
      eName: SL.validations.peopleEdit.eName,
      cName: SL.validations.peopleEdit.cName,
      startWorkYear: SL.validations.peopleEdit.startWorkYear
    }),
    email: Em.computed.validatable('emails', {
      validations: {
        len: Em.Validations.validator(function() {
          if (!this.get('length')) return 'no email';
        })
      }
    }),
    mobile: Em.computed.validatable('mobiles', {
      validations: {
        len: Em.Validations.validator('length', function() {
          if (!this.get('length')) return 'no mobile';
        })
      }
    })
  }),
  workExperience: Em.computed.validatable('content.workExperiences', {
    validations: {
      missingFields: Em.Validations.validator(function() {
        var exp = Em.get(this, 'model.content');
        if (!Em.isArray(exp)) return;
        exp = exp.compact();
        var currentWorkError = exp.find(function(item) {
          if (+(item.get('endDate')) !== +(new Date(3000, 0, 1)))
            return false;
          if (Em.isEmpty(item.get('function.content')) ||
            Em.isEmpty(item.get('totalSalary')))
            return true;
        });
        if (currentWorkError)
          return 'missing fields of current work';

        if (exp.findBy('startDate', null) || exp.findBy('endDate', null) ||
          exp.findBy('company', null) || exp.findBy('title', null))
          return 'missing fields';
      })
    }
  }),
  education: Em.computed.validatable(
      'content.educationExperiences', {
    validations: {
      missingFields: Em.Validations.validator(function() {
        var exp = Em.get(this, 'model.content');
        if (!Em.isArray(exp)) return;
        exp = exp.compact();
        if (exp.findBy('startDate', null) || exp.findBy('endDate', null) ||
          exp.findBy('school', null) || exp.findBy('major', null) ||
          exp.findBy('degreeId', null))
          return 'missing fields';
      })
    }
  }),
  projects: Em.computed.validatable('content.projectExperiences', {
    validations: {
      missingFields: Em.Validations.validator(function() {
        var exp = Em.get(this, 'model.content');
        if (!Em.isArray(exp)) return;
        exp = exp.compact();
        if (exp.findBy('startDate', null) || exp.findBy('endDate', null) ||
          exp.findBy('name', null))
          return 'missing fields';
      })
    }
  })

});

function checkCompanyValid(user) {
  return user.get('company').then(function() {
    function _check() {
      var validator = OwnedCompanyValidator.create({
        content: this
      });
      user.set('ownedCompanyValid', validator.get('isValid'));
    }
    var company = user.get('company.content');
    if (company) {
      Em.addListener(company, 'didUpdate', company, _check, false);
      _check.apply(company);
    }
  });
}

function checkProfileValid(user) {
  return user.get('realPerson').then(function() {
    if (!user.get('isGuest') || !user.get('realPerson.ownedProfiles'))
      return user.set('myProfileErrors', []);

    return user.get('realPerson.ownedProfiles').then(function() {
      function _check() {
        var validator = MyProfileValidator.create({
          content: this
        });
        var errors = [];
        Object.keys(validator.get('validations') || {}).forEach(function(key) {
          if (!validator.get(key + '.isValid'))
            errors.push(
              Em.I18n.t('people.create_edit.' + Em.String.underscore(key)));
        }, validator);
        user.set('myProfileErrors', errors);
      }
      var profile = user.get('ownedProfile');
      if (profile) {
        Em.addListener(profile, 'didUpdate', profile, _check, false);
        _check.apply(profile);
      }
    });
  });
}

function refresh(data, resolve, refreshParam) {
  var actions = [
    checkProfileValid(data.account),
    checkCompanyValid(data.account)
  ];

  if (refreshParam)
    actions.push(SL.refreshParam());

  Em.RSVP.all(actions).then(function() {
    resolve(data);
  });
}

SL.CustomAuthenticator = SimpleAuth.Authenticators.Base.extend({

  authenticate: function(credentials) {
    var store = this.store;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      /* jshint camelcase:false */
      var data = {
        username: credentials.identification,
        password: credentials.password,
        remember_me: credentials.rememberMe
      };
      Em.$.ajax({
        url: 'account/signin',
        type: 'POST',
        data: data
      }).then(function(response) {
        Em.run(function() {
          var acct = store.push(store.normalize('user', response));
          refresh({ account: acct, accountId: acct.id }, resolve, true);
        });
      }, function(xhr) {
        if ([401, 403, 423].indexOf(parseInt(xhr.status)) > -1)
          return Em.run(null, reject, xhr.responseJSON || xhr.responseText);
        Em.run.next(null, reject, xhr);
      });
    });
  },

  restore: function(data) {
    var store = this.store;
    return new Ember.RSVP.Promise(function(resolve, reject) {
      Em.$.get(SL.apiPath + '/my_profile').then(function(response) {
        Em.run(function() {
          /* jslint eqeq: true */
          data.account = store.push(store.normalize('user',
            response.objects[0]));
          data.accountId = data.account.id;
          refresh(data, resolve);
        });
      }, function(xhr) {
        Em.run(null, reject);
      });
    });
  },

  invalidate: function(credentials) {
    return new Ember.RSVP.Promise(function(resolve, reject) {
      Em.$.get('account/signout').then(function(response) {
        Em.run(null, resolve, response);
        if (response.returnUrl)
          window.location.assign(response.returnUrl);
      }, function(xhr) {
        Em.run.next(null, reject, xhr);
      });
    });
  }
});

SL.DummyAuthorizer = SimpleAuth.Authorizers.Base.extend();

SL.StaffAccessRouteMixin = Em.Mixin.create(SimpleAuth.AuthenticatedRouteMixin, {
  beforeModel: function(trans) {
    var sess = this.get('session');
    if (sess.get('isAuthenticated') && !sess.get('account.isStaff')) {
      trans.abort();
      this.set('session.attemptedTransition', trans);
      return this.transitionTo('staff-access');
    }
    return this._super(trans);
  }
});

SL.ChangePasswordRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin, {
  actions: {
    changeComplete: function() {
      this.transitionTo('index');
    }
  }
});

SL.ChangePasswordController = Em.Controller.extend({
  actions: {
    changePassword: function() {
      return true;
    }
  }
});


})();

(function() {

var get = Em.get;

SL.ConfirmationRouteMixin = Em.Mixin.create({
  abortMessageTranslation: 'common.message.abort',
  skipAbort: false,

  onUnload: Ember.K,
  proceedTransition: function() {
    if (this.currentModel && this.preventUnload())
      this.controller.send('cancelEdit');
  },

  preventUnload: function() {
    return !this.skipAbort && get(this, 'currentModel.isDirty');
  },

  onBeforeUnload: function() {
    if (this.preventUnload()) {
      return Em.I18n.t(this.get('abortMessageTranslation'));
    }
  },

  activate: function() {
    window.onbeforeunload = function() {
      return this.onBeforeUnload();
    }.bind(this);

    window.onunload = function() {
      return this.onUnload();
    }.bind(this);
  },

  deactivate: function() {
    window.onbeforeunload = null;
    window.onunload = null;
  },

  actions: {
    willTransition: function(transition) {
      this._super.apply(this, arguments);
      var allow = transition.targetName.indexOf(this.routeName + '.') === 0;
      if (allow) return true;

      if (this.preventUnload() && !window.confirm(
          Em.I18n.t(this.get('abortMessageTranslation')))) {
        transition.abort();
      } else {
        allow = true;
        this.proceedTransition();
      }
      return allow;
    },
    cancelEdit: function() {
      this.currentModel.rollback();
    }
  }
});


})();

(function() {

SL.FinanceRouteMixin = Em.Mixin.create({
  loadProfile: Em.K,
  errorMessage: function(errorKey, options) {
    options = options || {};
    options.serviceNumber = SL.parameters.register.serviceNumber;
    this.send('postMessage', { type: 'error', info:
      Em.I18n.t(errorKey, options) });
  },
  commonLedgerError: function(errorType) {
    switch (errorType) {
      case 'no_shared_coin_access':
      case 'insufficient_balance':
      case 'generic_error':
        this.errorMessage('ledger.' + errorType);
        return true;
    }
  }
});


})();

(function() {

SL.NewRecordRouteMixin = Em.Mixin.create({
  init: function() {
    this._super.apply(this, arguments);
    this.newRecords = {};
  },
  newRecord: function(type, hash) {
    var record = this.store.createRecord(type, hash || {});
    if (type === 'company')
        record.set('name', this.newRecord('companyAlias', (hash || {}).name));
    this.set('newRecords.' + type, record);
    return record;
  }
});


})();

(function() {

SL.ChannelSelectionRouteMixin = Em.Mixin.create({

  afterModel: function(model) {
    this.prepareChannelData(model);
    return this._super.apply(this, arguments);
  },

  prepareChannelData: function(model) {
    var ctl = this.controllerFor('vacancyChannels');
    ctl.set('vacancy', model);

    if (model.get('channelRefreshed')) return;

    var sudoDefault = ctl.get('defaultChannel');
    model.setProperties({
      channelToAdd: [],
      channelToRemove: [],
      channelRefreshed: true
    });

    // No processing for multiple vacancies
    if (Em.isArray(model)) return;

    if (model.get('id')) {
      // Replace default channel if published
      model.get('channelSummary').then(function(channels) {
        channels = channels.toArray();
        if (channels.get('firstObject.isDefault'))
          channels.replace(0, 1, [sudoDefault]);
        model.set('publishedChannels', channels);
      });
    } else {
      // Find channels which should be selected by default
      var toAdd = model.get('channelToAdd');
      this.store.query('channel', {
        default_select: true,
        results_per_page: 99999
      }).then(function(channels) {
        channels.forEach(function(c) {
          if (!c.get('inhibited'))
            toAdd.addObject(c.get('isDefault') ? sudoDefault : c);
        });
      });
    }
  },

  updateSlaveChannels: function() {
    var ctl = this.controllerFor('vacancyChannels'),
        toAdd = ctl.get('_toAdd'), vacancy = ctl.get('vacancy');

    var linkedin = toAdd.filterBy('action', 'syncLinkedin');
    var syncType = (linkedin.get('length') === 2) && 'both' ||
      linkedin.get('firstObject.isCompany') && 'company' ||
      linkedin.get('firstObject') && 'people';
    if (syncType) this.send('syncLinkedin', vacancy, null, syncType);

    if (toAdd.findBy('action', 'syncWeibo'))
      this.send('syncWeibo', vacancy);

    toAdd.removeObjects(ctl.get('slaveChannels'));
  },

  updateChannelStatus: function(vacancies) {
    var ctl = this.controllerFor('vacancyChannels'), store = this.store;
    this.updateSlaveChannels();
    if (!ctl.get('changed')) return Em.RSVP.resolve();
    if (!Em.isArray(vacancies)) vacancies = [ctl.get('vacancy')];
    return new Em.RSVP.Promise(function(resolve, reject) {
      Em.$.ajax({
        url: SL.apiPath + '/vacancy/update_channel',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          vacancy_ids: vacancies.mapBy('id'),
          to_add: ctl.get('_toAdd').mapBy('id').compact(),
          to_remove: ctl.get('_toRemove').mapBy('id').compact()
        })
      }).then(function(data) {
        Em.run(function() {
          Em.get(data, 'objects').forEach(function(v) {
            store.push(store.normalize('vacancy', v));
          });
          ctl.reset();
        });
        resolve();
      }, function(xhr) {
        Em.run.next(reject, xhr);
      });
    });
  },

  actions: {
    selectChannels: function(vacancies, updateOnSave) {
      function scroll() {
        $modal.find('.slim-scroll').slimScroll({ height:'auto' });
        $modal.find('[data-toggle="tooltip"]').tooltip();
      }
      function contentChanged() {
        Em.run.scheduleOnce('afterRender', scroll);
      }
      var $modal = Em.$('#select-channels.modal').modal();
      var mvCtl = this.controllerFor('vacancyChannels');
      mvCtl.addObserver('model.[]', null, contentChanged);
      $modal.one('shown.bs.modal', function() {
        scroll();
      }).one('hidden.bs.modal', function() {
        Em.run(mvCtl, function() {
          this.removeObserver('model.[]', null, contentChanged);
        });
      });

      mvCtl.createEntryPoint(updateOnSave, vacancies);

      var $spin = $modal.find('.spin'), $body = $modal.find('.row');
      $spin.spin().removeClass('hide');
      $body.addClass('hide');

      var self = this;
      this.fetchChannels().then(function(c) {
        // Default channel is garanteed as the first channel returned
        var defaultChannel = c.findBy('isDefault');
        if (defaultChannel) mvCtl.set('_defaultChannel', defaultChannel);

        var channelIds = c.mapBy('id');
        var vacancyIds = Em.isArray(vacancies) ?
          vacancies.mapBy('id') : [self.currentModel.get('id')].compact();

        $spin.addClass('hide');
        $body.removeClass('hide');
        c.removeObject(c.findBy('isDefault'));
        mvCtl.set('model', c.toArray());
        return self.fetchChannelSelection(vacancyIds, channelIds);
      }).then(function(pubed) {
        mvCtl.get('saved').setObjects(pubed.allPubed);
        mvCtl.get('semi').setObjects(pubed.semiPubed);
      });
    },

    updateChannelStatus: function(vacancies) {
      var self = this;
      this.send('loading', true);
      this.updateChannelStatus(vacancies).then(function() {
        self.send('postMessage', { type: 'success', info:
          Em.I18n.t('vacancy.message.publish_channel_succeed') });
      }).catch(SL.bumpRsvpError(function(e) {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('vacancy.message.publish_channel_failed') });
      })).finally(function() {
        self.send('loading', false);
      });
    }
  },

  fetchChannels: function() {
    var param = { results_per_page: 999 };
    return this.store.query('channel', param);
  },

  fetchChannelSelection: function(vacancyIds, channelIds) {
    var pubed = { allPubed: [], semiPubed: [] };
    if (!vacancyIds.length || !channelIds.length)
      return Em.RSVP.resolve(pubed);

    var store = this.store;
    var mvCtl = this.controllerFor('vacancyChannels');

    // Find publication status for the channels
    return new Em.RSVP.Promise(function(resolve) {
      Em.$.ajax({
        url: SL.apiPath + '/vacancy/channel_publication',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          vacancy_ids: vacancyIds,
          channel_ids: channelIds
        })
      }).then(function(pubs) {
        Em.run(function() {
          Em.get(pubs, 'objects').forEach(function(pub) {
            var channel = store.peekRecord('channel', pub.channel_id);
            if (channel.get('isDefault'))
              channel = mvCtl.get('defaultChannel');
            pubed[pub.status.camelize()].addObject(channel);
          });
          resolve(pubed);
        });
      });
    });
  }
});


})();

(function() {

SL.BaseCommentsRouteMixin = Em.Mixin.create(SL.NewRecordRouteMixin, {

  // Shared instance of tailing model
  setupController: function() {
    this._super.apply(this, arguments);
    if (this.get('session.account.isStaff'))
      this.updateCommentsModel();
  },

  fetchComments: function(more) {
    var $loadMask = Em.$('.comments-loading');
    var ctl = this.get('commentCtl');
    var f = {
      order_by: [{ field: 'id', direction: 'desc' }]
    };

    if (more && ctl.get('tailComments.lastObject'))
      f.filters = [{ name: 'id', op: 'lt',
        val: ctl.get('tailComments.lastObject.id')
      }];

    $loadMask.removeClass('hide');
    ctl.set('commentsLoading', true);
    var queryData = {
      q: JSON.stringify(f),
      page: 1,
      results_per_page: 3
    };
    var model = this.commentModel;
    if (this.get('commentCategory') === 'followup') {
      model = 'personComment';
      queryData.vacancy_id = this.get('commentOwnerId');
    } else {
      queryData.owner_id = this.get('commentOwnerId');
    }
    return this.store.query(model, queryData).then(
        function(comments) {
      ctl.set('tailComments', comments);
      return comments;
    }).finally(function() {
      $loadMask.addClass('hide');
      ctl.set('commentsLoading', false);
    });
  },

  newRecord: function(type) {
    if (type === this.get('commentModel')) {
      var category = this.get('commentCtl.commentTypeItem.firstObject');
      if (Em.isArray(category))
        category = category.get('firstObject');
      return this._super(type, { category: Em.get(category, 'value') });
    }
    return this._super.apply(this, arguments);
  },

  updateCommentsModel: function() {
    var ctl = this.get('commentCtl');
    var ownerId = parseInt(this.get('commentOwnerId'));
    var category = this.get('commentCategory');

    if (!ownerId || ctl.get('currentOwner') === ownerId &&
      ctl.get('currentCategory') === category) return;
    if (ctl.get('currentOwner') !== ownerId) {
      ctl.set('category', null);
      category = null;
    }
    ctl.setProperties({
      comments: [],
      currentOwner: ownerId,
      currentCategory: category,
      newComment: this.newRecord(this.commentModel)
    });
    this.fetchComments().then(function(comments) {
      ctl.set('comments', comments.toArray());
    });

  }.observes('commentOwnerId', 'commentCategory'),

  moreComments: function() {
    var $loadMask = Em.$('.comments-loading');
    var ctl = this.get('commentCtl'), self = this;

    if (!$loadMask.hasClass('hide') ||
      ctl.get('tailComments.meta.totalPages') <= 1) {
      return;
    }
    this.fetchComments(true).then(function(comments) {
      var sameOrigin = self.get('commentCategory') === 'followup' ?
        comments.get('firstObject.vacancy.id') :
        comments.isAny('ownerId', ctl.get('currentOwner'));
      if (sameOrigin)
        ctl.get('comments').pushObjects(comments.toArray());
    });
  },
  postComment: function() {
    var self = this;
    var ctl = this.get('commentCtl');
    var ownerId = this.get('commentOwnerId');
    var record = ctl.get('newComment');

    record.set('ownerId', ownerId);
    record.save().then(function(comment) {
      ctl.get('comments').insertAt(0, comment);
      ctl.set('newComment', self.newRecord(self.commentModel));
    }).catch(SL.bumpRsvpError(function() {
      self.send('postMessage',
        { type: 'error', info:
          Em.I18n.t('common.message.save_comment_fail') });
    }));
  },

  commentCategory: Em.computed.readOnly('commentCtl.category'),

  actions: {
    postComment: function() {
      Em.run.debounce(this, 'postComment', 500, true);
    },
    deleteComment: function(comment) {
      //delete commentOwner's comments
      var self = this;
      function fail() {
        self.send('postMessage', { type: 'error',
          info: Em.I18n.t('common.message.del_comment_fail') });
      }
      if (comment.get('creator.id') !== this.get('session.accountId'))
        return fail();
      comment.destroyRecord().then(function() {
        var commentCtl = self.get('commentCtl');
        if (commentCtl)
          commentCtl.get('comments').removeObject(comment);
        self.send('postMessage', { type: 'success',
          info: Em.I18n.t('common.message.del_comment_success') });
      }).catch(SL.bumpRsvpError(fail));
    },
    updateComment: function(record) {
      record.save();
    },
    willTransition: function() {
      this._super.apply(this, arguments);
      // unbind Slim Scroll
      var slimScroll = Em.$('#namecard').closest('.slim-scroll');
      slimScroll.unbind('slimscroll.comment');
      return true;
    },
    didTransition: function() {
      this._super();
      var self = this;
      // Slim Scroll for lazy loading
      Ember.run.scheduleOnce('afterRender', function() {
        var slimScroll = Em.$('#namecard').closest('.slim-scroll');
        slimScroll.bind('slimscroll.comment', function(e, pos) {
          if (pos !== 'bottom') return;
          Em.run.debounce(self, 'moreComments', 800);
        });
      });
      return true;
    },
    toggleCategory: function(category) {
      this.get('commentCtl').set('category', category);
    }
  }

});

SL.PersonCommentsRouteMixin = Em.Mixin.create(SL.BaseCommentsRouteMixin, {

  commentModel: 'personComment',

  commentOwnerCtl: function() {
    return this.controllerFor('people.edit');
  }.property().readOnly(),

  commentOwnerId: function() {
    if (this.get('commentOwnerCtl.commentable'))
      return this.get('commentOwnerCtl.person.id');
  }.property(
    'commentOwnerCtl.commentable', 'commentOwnerCtl.person.id'
  ).readOnly(),

  commentCtl: function() {
    return this.controllerFor('people.comments');
  }.property().readOnly(),

  fetchFollowUpVacancy: function() {
    var store = this.store;
    var f = {
      filters: [
        { name: 'status', op: 'eq', val: 0 }
      ],
      order_by: [{ field: 'id', direction: 'desc' }]
    };
    return Em.$.get(SL.apiPath + '/vacancy/followup', {
      client_id: this.get('commentOwnerId'),
      q: JSON.stringify(f),
      results_per_page: 1000
    }).then(function(data) {
      var myVacancies;
      Em.run(function() {
        myVacancies = data.objects.map(function(v) {
          return store.push(store.normalize('vacancy', v));
        });
      });
      return myVacancies;
    }).fail(SL.onXhrError);
  },

  selectFollowUpVacancy: function(comment) {
    function scroll() {
      $modal.find('.slim-scroll').slimScroll({ height:'auto' });
      $modal.find('[data-toggle="tooltip"]').tooltip();
    }
    function contentChanged() {
      Em.run.scheduleOnce('afterRender', scroll);
    }
    var ctl = this.controllerFor('follow-up-vacancy');
    ctl.set('comment', comment);
    var $modal = Em.$('#follow-up-vacancy.modal').modal();
    var $spin = $modal.find('.spin'),
        $list = $modal.find('#follow-up-vacancy-list');

    ctl.addObserver('model.[]', null, contentChanged);
    if (ctl.get('model.length'))
      ctl.get('model').clear();
    $spin.spin();
    $list.addClass('hide');
    $spin.removeClass('hide');
    this.fetchFollowUpVacancy().then(function(v) {
      $spin.addClass('hide');
      $list.removeClass('hide');
      Em.run(function() {
        ctl.setProperties({
          model: v.toArray(),
          selectedItem: comment.get('vacancy.content')
        });
      });
    });

    $modal.one('shown.bs.modal', function() {
      scroll();
    }).one('hidden.bs.modal', function() {
      Em.run(ctl, function() {
        ctl.removeObserver('model.[]', null, contentChanged);
      });
    });
  },

  promptFollowUpVacancy: function() {
    var newComment = this.get('commentCtl.newComment');
    if (newComment.get('category') === 'client_follow' &&
      !newComment.get('vacancy.id'))
      this.selectFollowUpVacancy(newComment);
  }.observes('commentCtl.newComment.category'),

  actions: {
    changeFollowUpVacancy: function(comment) {
      this.selectFollowUpVacancy(comment);
    },
    setFollowUpVacancy: function(comment, v) {
      comment.set('vacancy', v);
    }
  }

});

SL.VacancyCommentsRouteMixin = Em.Mixin.create(SL.BaseCommentsRouteMixin, {

  commentModel: 'vacancyComment',

  commentOwnerCtl: function() {
    return this.controllerFor('vacancy.profile');
  }.property().readOnly(),

  commentOwnerId: Em.computed.readOnly('commentOwnerCtl.vacancy.id'),

  commentCtl: function() {
    return this.controllerFor('vacancy.comments');
  }.property().readOnly()

});


})();

(function() {

function markTruncate() {
  Em.run.scheduleOnce('afterRender', function() {
    var $members = Em.$('.company-members'),
        overflow = $members.children('.panel-body').height() > 36;
    $members[overflow ? 'addClass' : 'removeClass']('truncate');
    Em.$('.show-more').unbind().click(function(e) {
      e.preventDefault();
      Em.$(this).parent('.company-members').toggleClass('expanded');
    });
  });
}

SL.CompanyGroupMemberRouteMixin = Em.Mixin.create({
  loadGroupMembers: function(group) {
    if (!group || Em.isPresent(group.get('members'))) return markTruncate();
    var store = this.store;
    return new Em.RSVP.Promise(function(resolve, reject) {
      Em.$.ajax({
        url: SL.apiPath + '/company_group/' + group.get('id') + '/members',
        method: 'GET'
      }).then(function(data) {
        Em.run(function() {
          group.set('members', {});
          Object.keys(data).forEach(function(k) {
            group.set('members.' + k, data[k].map(function(c) {
              return store.push(
                store.normalize('company', c)).get('name');
            }));
          });
          resolve(data);
          markTruncate();
        });
      }, function(xhr) {
        Em.run.next(reject, xhr);
      });
    });
  },
  actions: {
    editCGMembers: function() {
      var ctl = this.controllerFor('companyProfile');
      ctl.set('tmpGroupMembers', (ctl.get('groupMembers') || []).slice(0));
    },
    saveCGMembers: function(group) {
      var ctl = this.controllerFor('companyProfile'), self = this;
      this.send('loading', true);
      Em.$.ajax({
        url: SL.apiPath + '/company_group/' + group.get('id') + '/members',
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          normal: ctl.get('tmpGroupMembers').map(function(c) {
            return { id: c.get('ownerId') };
          })
        })
      }).then(function(data) {
        Em.run(function() {
          group.set('members', null);
          self.loadGroupMembers(group);
        });
      }, function(xhr) {
        Em.run(function() {
          self.send('postMessage', { type: 'error', info:
            Em.I18n.t('company.message.' + xhr.responseText) });
        });
      }).always(function() {
        self.send('loading', false);
      });
    },
    willTransition: function() {
      this._super.apply(this, arguments);
      Em.$(window).off('resize.company');
      return true;
    },
    didTransition: function() {
      this._super();
      Em.$(window).on('resize.company', function() {
        Em.run.throttle(markTruncate, 500, true);
        Em.run.debounce(markTruncate, 500);
      });
      return true;
    }
  }
});


})();

(function() {

SL.FavoriteRouteMixin = Em.Mixin.create({
  setupController: function() {
    this._super.apply(this, arguments);
    if (this.get('session.account.isStaff'))
      this.checkFavorite();
  },

  favoriteOwner: function() {
    return this.controllerFor('people.edit');
  }.property().readOnly(),

  _checkFavorite: function() {
    var ctl = this.controllerFor('favorite'),
        ownerId = this.get('favoriteOwner.person.ownerId');

    if (!ownerId || ctl.get('currentOwner') === ownerId) return;
    ctl.set('currentOwner', ownerId);
    var f = { filters: [
      { name: 'person_id', op: 'eq', val: ownerId }
    ] };
    this.store.query('favoritePerson',
      { q: JSON.stringify(f) }).then(function(fps) {
      ctl.set('favorite', fps.get('firstObject'));
    });
  },

  checkFavorite: function() {
    if (!this.get('favoriteOwner.person.isMasked'))
      return this._checkFavorite();
  }.observes('favoriteOwner.person.ownerId'),

  folderUpdated: Em.K,

  actions: {
    toggleFavorite: function() {
      var ctl = this.controllerFor('favorite');
      var favorite = ctl.get('favorite'), owner = this.get('favoriteOwner'),
          self = this;

      var isFavorite = !favorite;
      if (favorite) {
        favorite.deleteRecord();
      } else {
        favorite = this.store.createRecord('favoritePerson', {
          person: owner.get('person.owner')
        });
      }
      this.send('loading', true);
      favorite.save().then(function() {
        ctl.set('favorite', isFavorite ? favorite : null);
        self.set('updates.favorite', true);
        self.folderUpdated(null, ctl.get('isFavorite'),
          [owner.get('person')]);
      }).finally(function() {
        self.send('loading', false);
      });
    }
  }
});


})();

(function() {

SL.FilesRouteMixin = Em.Mixin.create({
  actions: {
    deleteFile: function(file) {
      var model = this.currentModel,
          self = this;

      file.deleteRecord();

      if (!model.get('isNew') && !model.get('editing')) {
        this.send('loading', true);
        model.save().then(function() {
          self.send('postMessage',
            { type: 'success', info:
              Em.I18n.t('people.message.del_file_success') });
        }).catch(SL.bumpRsvpError(function() {
          self.send('postMessage',
            { type: 'error', info:
              Em.I18n.t('people.message.del_file_fail') });
        })).finally(function() {
          self.send('loading', false);
        });
      }
    }
  }
});


})();

(function() {

SL.FiltersRouteMixin = Em.Mixin.create({
  reopenTab: function() {
    this._super.apply(this, arguments);
    if (this.controller)
      this.controller.get('filters').clear();
  },

  filterModel: Em.K,

  model: function(param, transition) {
    var ctl = this.controllerFor(this.get('routeName'));
    if (ctl.get('model') === this.currentModel &&
        ctl.get('page') === param.page &&
        ctl.get('pageSize') === param.pageSize)
      return this.currentModel;
    return this.filterModel(param.page, param.pageSize, transition);
  },

  actions: {
    openFilter: function(filter) {
      var ctl = this.controller;
      ctl.set('filterShown', false);
      var template = filter.constructor.template || filter.constructor.type;
      this.render('filters.' + template.dasherize(), {
        into: 'application',
        outlet: 'filterDialog'
      });
      Em.run.schedule('afterRender', this, function() {
        var self = this, $modal = Em.$('#filters-modal').modal();
        $modal.one('shown.bs.modal', function() {
          // Autofocus handling (IE compatable)
          $modal.find('[autofocus]:not(:focus)').eq(0).focus();
          $modal.find('.tree').slimScroll({ height:'auto' });
          $modal.find('.dropdown-menu.inner').slimScroll({
            height: 'auto', wheelStep: 5
          });
          Em.run(function() {
            ctl.set('filterShown', true);
          });
        }).one('hidden.bs.modal', function() {
          Em.run(function() {
            self.disconnectOutlet({
              outlet: 'filterDialog',
              parentView: 'application'
            });
          });
        });
      });
    },
    refresh: function() {
      this.set('currentModel', null);
      return true;
    }
  }
});


})();

(function() {

SL.FolderAddRouteMixin = Em.Mixin.create({

  actions: {
    addToFolder: function(key) {
      var self = this, store = this.store,
          ctl = this.controllerFor('folderAdd');

      this.send('loading', true);
      var updates = [];
      ctl.get('multiSelected').forEach(function(v) {
        ctl.get('peopleToAdd').forEach(function(p) {
          updates.push(store.createRecord('folderPersonStatus',
            { folder: v, person: p }));
        }, this);
      }, this);
      Em.RSVP.allSettled(updates.invoke('save')).then(function(rs) {
        var peopleLen = ctl.get('peopleToAdd.length');
        var msgAdded = '', msgDuped = '', msgFailed = '';
        self.controllerFor('folderAdd').get('multiSelected').
          forEach(function(f, i) {
          var added = [], duped = [], failed = [];
          for (var j = peopleLen * i; j < peopleLen * (i + 1); j++) {
            var name = ctl.get('peopleToAdd').
              objectAt(j % peopleLen).get('name');
            if (rs[j].state === 'fulfilled') added.push(name);
            /* jslint eqeq:true */
            else if (rs[j].reason.errors[0].status == 409) duped.push(name);
            else failed.push(name);
          }
          var title = f.get('name');
          if (added.length) {
            msgAdded += (msgAdded ? '<br>' : '') +
              Em.I18n.t('list.add.added', {
                people: added.join(', '), folder: title });
            self.get('updates.folder').addObject(f.get('id'));
          }
          if (duped.length)
            msgDuped += (msgDuped ? '<br>' : '') +
              Em.I18n.t('list.add.duped', {
                people: duped.join(', '), folder: title });
          if (failed.length)
            msgFailed += (msgFailed ? '<br>' : '') +
              Em.I18n.t('list.add.failed', {
                people: failed.join(', '), folder: title });
        });
        if (msgAdded)
          self.send('postMessage', { type: 'success', info: msgAdded });
        if (msgDuped)
          self.send('postMessage', { type: 'info', info: msgDuped });
        if (msgFailed)
          self.send('postMessage', { type: 'error', info: msgFailed });
      }).finally(function() {
        self.send('loading', false);
        Em.$('#my-folder.modal').modal('hide');
      });
    },

    openMyFolder: function(peopleToAdd, key) {
      function scroll() {
        $modal.find('.slim-scroll').slimScroll({ height:'auto' });
      }
      function contentChanged() {
        Em.run.scheduleOnce('afterRender', scroll);
      }
      var faCtl = this.controllerFor('folderAdd');
      var $modal = Em.$('#my-folder.modal').modal();
      var $spin = $modal.find('.spin');

      if (!peopleToAdd) {
        peopleToAdd = this.get('controller.multiSelected');
        if (Em.isArray(peopleToAdd) && key)
          peopleToAdd = peopleToAdd.mapBy(key);
      } else if (!Em.isArray(peopleToAdd)) {
        peopleToAdd = [peopleToAdd];
      }

      faCtl.set('peopleToAdd', peopleToAdd);

      faCtl.addObserver('filteredContent.[]', null, contentChanged);
      if (faCtl.get('model')) faCtl.get('model').clear();
      $spin.spin();
      $spin.removeClass('hide');
      this.fetchMyFolder().then(function(v) {
        $spin.addClass('hide');
        faCtl.set('model', v.toArray());
      });

      $modal.one('shown.bs.modal', function() {
        $modal.find('[autofocus]:not(:focus)').eq(0).focus();
        scroll();
      }).one('hidden.bs.modal', function() {
        Em.run(faCtl, function() {
          this.clearMultiSelection();
          this.removeObserver('filteredContent.[]', null, contentChanged);
          this.set('filterValueTmp', '');
        });
      });
    },
  },

  fetchMyFolder: function() {
    var f = {
      filters: [
        { name: 'category', op: 'eq', val: 1 }
      ],
      order_by: [{ field: 'id', direction: 'desc' }]
    };
    return this.store.query('folder', {
      q: JSON.stringify(f),
      results_per_page: '9999999'
    });
  }
});


})();

(function() {

SL.MappingAddRouteMixin = Em.Mixin.create({
  fetchMappingEntries: function(ctl, $modal) {
    if (ctl.get('model')) {
      ctl.get('model').clear();
      Em.run.scheduleOnce('afterRender', function() {
        $modal.find('.slim-scroll').slimScroll({ height: 'auto' });
      });
    }

    var $spin = $modal.find('.spin'), $list = $modal.find('#my-mapping-list');
    $spin.spin();
    $spin.removeClass('hide');
    $list.hide();

    var f = {
      filters: [{ or: [] }, { name: 'person_id', op: 'is_null' }],
      order_by: [{ field: 'id', direction: 'desc' }]
    };
    if (ctl.get('filterTitle') && ctl.get('lastTitle.id'))
      f.filters[0].or.addObject({
        name: 'title_id', op: 'eq', val: ctl.get('lastTitle.id') });
    if (ctl.get('filterCompany') && ctl.get('lastCompany.id'))
      f.filters[0].or.addObject({
        name: 'company_id', op: 'eq', val: ctl.get('lastCompany.id') });
    if (ctl.get('filterSupervisor') && ctl.get('lastSupervisor.id')) {
      f.filters[0].or.addObject({ and: [
        { name: 'associate_id', op: 'eq', val: ctl.get('lastSupervisor.id') },
        { name: 'associate_type', op: 'eq', val: 'subordinate' }
      ] });
    }

    this.store.query('mappingEntry', {
      q: JSON.stringify(f),
      results_per_page: '1000'
    }).then(function(v) {
      $spin.addClass('hide');
      $list.show();
      ctl.setProperties({
        model: v.toArray(),
        selectedItem: null
      });
      Em.run.scheduleOnce('afterRender', function() {
        $modal.find('.slim-scroll').slimScroll({ height: 'auto' });
      });
    });
  },
  actions: {
    openMapping: function(p, work) {
      if (!work)
        return this.send('postMessage', {
          type: 'error', info: Em.I18n.t('list.mapping.ensure_work') });

      var ctl = this.controllerFor('folder.add-mapping');

      ctl.setProperties({
        peopleToAdd: p,
        lastTitle: work.get('title'),
        lastCompany: work.get('company'),
        lastSupervisor: work.get('reportTo.content')
      });

      var $modal = Em.$('#my-mapping.modal').modal();
      var self = this;
      $modal.one('shown.bs.modal', function() {
        Em.run(self, 'fetchMappingEntries', ctl, $modal);
      });
    },
    fetchMappingEntries: function(ctl) {
      this.fetchMappingEntries(ctl, Em.$('#my-mapping.modal').modal());
    },
    addToMapping: function(ctl) {
      var entry = ctl.get('selectedItem');
      if (!entry) return;
      entry.set('person', ctl.get('peopleToAdd'));
      this.send('loading', true);
      var self = this, transInfo = {
        people: entry.get('person.name'),
        folder: entry.get('folder.name')
      };
      entry.save().then(function() {
        self.get('updates.mapping').addObject(entry.get('folder.id'));
        self.send('postMessage', { type: 'success', info: Em.I18n.t(
          'list.add.added', transInfo) });
        Em.$('#my-mapping.modal').modal('hide');
        // Also check differenc between the added CV and mapping target
        if (ctl.cvDiff(entry))
          Em.$('#confirm-added-mapping.modal').modal();
      }).catch(function(e) {
        if (e.errors[0].status === '409')
          self.send('postMessage', { type: 'info', info: Em.I18n.t(
            'list.add.duped', transInfo) });
        else
          self.send('postMessage', { type: 'error', info: Em.I18n.t(
            'list.add.failed', transInfo) });
        entry.rollback();
      }).finally(function() {
        self.send('loading', false);
      });
    },
    updateAsMapping: function(entry, work) {
      if (entry.get('title.id'))
        work.set('title', entry.get('title'));
      if (entry.get('company.id'))
        work.set('company', entry.get('company'));
      if (entry.get('associate.id') &&
          entry.get('associateType') === 'subordinate')
        work.set('reportTo', entry.get('associate'));
      var self = this;
      this.send('loading', true);
      entry.get('person.defaultProfile').save().then(function() {
        self.send('postMessage', {
          type: 'success', info: Em.I18n.t('list.mapping.update_succeed') });
        Em.$('#confirm-added-mapping.modal').modal('hide');
      }).finally(function() {
        self.send('loading', false);
      });
    }
  }
});


})();

(function() {

SL.MissingRecordRouteMixin = Em.Mixin.create({
  actions: {
    error: function(err, transition) {
      if ((err.message || err.errorThrown || '').indexOf('[404]') < 0)
        return this._super(err, transition);
      transition.send('loading', false);
      transition.send('postMessage',
        { type: 'error', info: Em.I18n.t('people.message.invalid_link') });
      var hdInfos = transition.router.currentHandlerInfos;
      if (!hdInfos) {
        // direct load
        this.transitionTo('index');
      } else {
        if (SL.TabbedRouteMixin.detect(this) ||
            SL.TabbedDynamicRouteMixin.detect(this))
          this.closeTab();
        transition.abort();
      }
    }
  }
});


})();

(function() {

SL.PartialLoadListRouteMixin = Em.Mixin.create({

  loadPartial: function(partial, data, trans) {
        data = data.compact();
    // Set total number of items to determine whether it's done loading
    if (!partial.get('meta'))
      partial.set('meta', { numItems: data.get('length') });
    else
      partial.set('meta.numItems', data.get('length'));
    Em.run(this, function() {
      // Initial load
      var batch = data.slice(0, 10);
      partial.pushObjects(trans ? batch.map(trans) : batch);
    });
    if (partial.length < data.length)
      // Start rest of the loading on full transition
      this.router.one('didTransition', this, function() {
        this._loadPartial(partial, data, trans);
      });
  },

  _loadPartial: function(partial, data, trans) {
    if (partial !== this.get('controller.model'))
      // If it's not current model, stop the iteration.
      return;
    var batch = data.slice(partial.length, partial.length + 5);
    partial.pushObjects(trans ? batch.map(trans) : batch);
    if (partial.length < data.length)
      Em.run.later(this, '_loadPartial', partial, data, trans, 100);
  }

});

SL.PaginationRouteMixin = Em.Mixin.create(SL.PartialLoadListRouteMixin, {
  queryParams: {
    page: {
      refreshModel: true
    },
    pageSize: {
      refreshModel: true
    }
  },

  actions: {
    closeTab: function(tab) {
      this.controller.set('page', 1);
      return this._super.apply(this, arguments);
    },
    goPage: function(params) {
      this.transitionTo({ queryParams: params });
    }
  }
});


})();

(function() {

SL.ProfileDownloadRouteMixin = Em.Mixin.create(SL.FinanceRouteMixin, {
  loadProfile: Em.K,

  downloadProfile: function(masked, options) {
    var self = this, action = 'download';
    options = options || {};

    if (masked.vc) {
      if (masked.get('vc.fromBounty.acceptRule') && !options.accepted) {
        var $modal = Em.$('#prompt-accept');
        return $modal.modal();
      }
      options.vc_id = masked.get('vc.id');
      action = 'acquire';
    }

    this.send('loading', true);
    Em.$.ajax({
      url: SL.apiPath + '/person/' + masked.id + '/' + action,
      data: options
    }).then(function(data) {
      Em.run(function() {
        self.loadProfile(masked, data.profile);
        if (data.user_credential)
          self.store.push(
            self.store.normalize('user', data.user_credential));
      });
    }).fail(function(error) {
      Em.run(function() {
        var data = Em.get(error, 'responseJSON'),
            errorType = Em.get(data, 'error');
        if (self.commonLedgerError(errorType)) return;
        switch (errorType) {
          case 'already_downloaded':
            self.loadProfile(masked, data.profile);
            return self.errorMessage('people.' + action + '.' + errorType);
        }
      });
    }).always(function() {
      Em.run(self, 'send', 'loading', false);
    });
  },
  actions: {
    promptDownload: function(masked, cost) {
      if (cost <= 0)
        return this.downloadProfile(masked);

      if (this.get('session.account.credential.sharedCredit') <
          parseFloat(cost)) {
        var action = masked.vc ? 'acquire' : 'download';
        return this.errorMessage(
          'people.' + action + '.insufficient_quota', { cost: cost });
      }

      var $modal = Em.$('#prompt-download');
      $modal.modal();
    },
    confirmDownload: function(masked, accepted) {
      var self = this,
          $modal = Em.$(accepted ? '#prompt-accept' : '#prompt-download');
      $modal.modal('hide').one('hidden.bs.modal', function() {
        Em.run(function() {
          self.downloadProfile(masked, { accepted: accepted });
        });
      });
    }
  }
});


})();

(function() {

var validStayPeriod = 5000;
var lastStayed = null;

function stayed(model) {
  /* jslint eqeq: true */
  if (!lastStayed || lastStayed[0] != model.get('id'))
    lastStayed = [model.get('id'), +(new Date())];
}

function leave(model, withList) {
  if (!lastStayed) return;
  var stayed = (+(new Date()) - lastStayed[1]);
  if (stayed > validStayPeriod) {
    if (model instanceof SL.Person) {
      var source = withList ? 'source' : 'openSource';
      Em.$.ajax({
        url: SL.apiPath + '/track/profile_accessed',
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          profileid: model.get('id'),
          source: model.get('owner.' + source),
          stayed: stayed
        })
      });
    }
  }
  lastStayed = null;
}

SL.ProfileUpdatesRouteMixin = Em.Mixin.create({

  logStayed: false,

  afterModel: function(model) {
    var _super = this._super.apply(this, arguments), store = this.store;
    function refreshProfile() {
      return store.findRecord(model.constructor.modelName, model.id);
    }
    if (_super.then)
      return _super.then(refreshProfile);
    return refreshProfile();
  },

  actions: {
    willTransition: function(transition) {
      var $slimScroll = Em.$('.profile').closest('.slim-scroll');
      $slimScroll.unbind('.profile');
      this._super(transition);
      return true;
    },
    didTransition: function() {
      if (this.get('logStayed')) {
        Ember.run.scheduleOnce('afterRender', this, function() {
          // Slim Scroll for lazy loading
          var $slimScroll = Em.$('.profile').closest('.slim-scroll');
          var self = this;
          $slimScroll.bind('mousemove.profile', function(e, pos) {
            Em.run.throttle(self, stayed, self.currentModel, 1000);
          });
          $slimScroll.bind('mouseleave.profile', function() {
            Em.run(self, leave, self.currentModel, false);
          });
        });
      }
      this._super();
      return true;
    },
    toggleSidebar: function() {
      this.controller.toggleProperty('sidebarActive');
    }
  }
});

SL.SelectableProfileUpdatesRouteMixin = Em.Mixin.create({

  logStayed: false,

  _getProfile: function() {
    return this.controller.get('selectedItem');
  },

  _refreshProfile: function() {
    var model = this._getProfile();
    return model && this.store.findRecord(
      model.constructor.modelName, model.id).then(function(p) {
        Em.run.schedule('afterRender', function() {
          Em.$('[data-toggle="tooltip"]').tooltip();
        });
        return p;
      });
  },

  setupController: function() {
    // Ensure binding value get populated to related profile controller
    this.controller.notifyPropertyChange('sidebarActive');
    this._super.apply(this, arguments);
  },

  profileChanged: function() {
    this.controller.set('sidebarActive', false);
    this._refreshProfile();
  },

  actions: {
    willTransition: function(transition) {
      this.controller.removeObserver('selectedItem', this,
        'profileChanged');
      var $slimScroll = Em.$('.profile').closest('.slim-scroll');
      $slimScroll.unbind('.profile');
      this._super(transition);
      return true;
    },
    didTransition: function() {
      this._refreshProfile();
      this.controller.addObserver('selectedItem', this,
        'profileChanged');
      if (this.get('logStayed')) {
        Ember.run.scheduleOnce('afterRender', this, function() {
          // Slim Scroll for lazy loading
          var $slimScroll = Em.$('.profile').closest('.slim-scroll');
          var self = this;
          $slimScroll.bind('mousemove.profile', function(e, pos) {
            Em.run.throttle(self, stayed, self._getProfile(), 1000);
          });
          $slimScroll.bind('mouseleave.profile', function() {
            Em.run(self, leave, self._getProfile(), true);
          });
        });
      }
      this._super();
      return true;
    },
    toggleSidebar: function() {
      this.controller.toggleProperty('sidebarActive');
    }
  }
});


})();

(function() {

function reScroll() {
  // May be off limit and the attention bar need re-scrolling
  Em.run.later(function() {
    var $scroll = Em.$('#jumpy.slim-scroll');
    $scroll.slimScroll(
      { height: 'auto', size: '10px', disableFadeOut: true });
  });
}

SL.ProfileVcStatusRouteMixin = Em.Mixin.create({
  tryLoadVcStatus: function(rp) {
    if (!rp || !this.get('session.account.isStaff'))
      return;
    var rpId;
    if (['string', 'number'].indexOf(typeof rp) > -1) {
      // Given rp as id
      rpId = rp;
      rp = this.store.peekRecord('realPerson', rp);
    } else {
      // Given rp as model instance
      rpId = rp.get('id');
    }

    if (rp && !Em.isBlank(rp.get('vcStatus'))) return reScroll();

    var store = this.store, p = rp && rp.get('defaultProfile');
    store.findRecord('realPerson', rpId, {
      reload: true,
      adapterOptions: { extra: 'vc_status' }
    }).then(function(rp) {
      if (p && Em.isBlank(rp.get('defaultProfile')))
        // Ensure the original default profile is pushed back
        store.push(store.normalize('realPerson', {
          id: rpId,
          default_profile: { id: p.get('id') }
        }));
      reScroll();
    });
  }
});


})();

(function() {

var fieldMapping = {
  expectedSalary: 'expectedSalaryConv',
  'lastJob.totalSalary': 'salary'
};

SL.ResumeRouteMixin = Em.Mixin.create({
  fetchFormatCfg: function(person, cvFormat) {
    var self = this;
    this.send('loading', true);
    return this.store.findRecord('cvFormat', cvFormat.get('id'), {
      adapterOptions: { lang: Em.I18n.locale }
    }).then(function(format) {
      if (Em.isNone(format.get('_masksConverted'))) {
        var masks = [];
        format.set('_masksConverted', (Em.get(format, 'maskable') || []).map(
            function(mask) {
          if (!mask.startsWith('!'))
            masks.addObject(mask);
          return mask.replace(/^!/, '');
        }));
        format.set('masks', masks);
      }
      if (Em.isNone(format.get('_highlightsConverted'))) {
        (format.get('highlights') || []).forEach(function(entry) {
          // Normalize entry fields
          if (!entry.field.startsWith('extra.'))
            Em.set(entry, 'field', entry.field.split('.').map(
              Em.String.camelize).join('.'));
          if (fieldMapping.hasOwnProperty(entry.field))
            Em.set(entry, 'field', fieldMapping[entry.field]);
        });
        format.set('_highlightsConverted', true);
      }
      (format.get('highlights') || []).forEach(function(entry) {
        // Update values
        Em.set(entry, 'value', person.get(entry.field));
        Em.set(entry, 'disabled', null);
        if (!person.get('lastJob') && (
            entry.field.startsWith('lastJob.') || entry.field === 'salary'))
          Em.set(entry, 'disabled', 'no_last_job');
      });
      Em.run.schedule('afterRender', function() {
        Em.$('#download-format [data-toggle="tooltip"]').tooltip();
      });
      return format;
    }).finally(function() {
      self.send('loading', false);
    });
  },
  actions: {
    promptSend: function(person) {
      var self = this;
      this.send('loading', true);
      Em.$.ajax({
        url: SL.apiPath + '/person/' + person.get('id') + '/share_link',
        dataType: 'json'
      }).then(function(data) {
        var url = Em.get(data, 'share_link');
        if (person.get('vc.id'))
          url += '?vc=' + person.get('vc.id');
        Em.run(person, 'set', 'shareUrl', url);
        Em.$('#send-profile-modal').modal();
      }).always(function() {
        Em.run(self, 'send', 'loading', false);
      });
    },
    promptCvFormat: function(person, cvFormat) {
      function showModal() {
        var $modal = Em.$('#download-format').modal();
        $modal.one('shown.bs.modal', function() {
          $modal.find(
            '.bootstrap-select .dropdown-menu.inner').slimScroll(
              { height: 'auto', wheelStep: 5 });
        });
      }
      if (!cvFormat || cvFormat.get('id') === 'default')
        return showModal();
      cvFormat.set('ready', false);
      this.fetchFormatCfg(person, cvFormat).then(function() {
        // Reinitialize model contents
        Em.run.schedule('afterRender', cvFormat, 'set', 'ready', true);
        showModal();
      });
    },
    fetchCvFormat: function(person, cvFormat) {
      if (!cvFormat || cvFormat.get('id') === 'default')
        return;
      this.fetchFormatCfg(person, cvFormat);
    },
    downloadResume: function(url, person, cvFormat) {
      var self = this;
      function download() {
        self.send('loading', false);
        var masks = (cvFormat.get('masks') || []);
        window.location.href = url + '?' + Em.$.param({
          report: cvFormat.get('id'), masks: masks }, true);
      }
      if (cvFormat.get('id') === 'default') return download();

      (cvFormat.get('highlights') || []).forEach(function(entry) {
        if (!entry.disabled)
          person.set(entry.field, entry.value);
      });
      // FIXME: Better way to get extra updated
      person.set('extra', Em.copy(person.get('extra')));
      this.send('loading', true);
      person.get('content').save().then(download);
    }
  }
});


})();

(function() {

function reScroll() {
  Em.run.later(function() {
    var $scroll = Em.$('#profile-sidebar .slim-scroll');
    $scroll.slimScroll({ height: 'auto', size: '10px' });
  });
}

SL.SimilarProfileRouteMixin = Em.Mixin.create({
  fetchSimilar: function(rp, data) {
    var store = this.store, self = this, query = {};
    if (data) {
      query = { refresh_task: Em.get(data, 'task_id') };
      if (Em.get(data, 'retried'))
        query.retry = Em.get(data, 'retried') + 1;
    }
    Em.$.ajax({
      url: SL.apiPath + '/real_person/' + rp.id + '/similar',
      contentType: 'application/json; charset=utf-8',
      data: query
    }).then(function(data) {
      if (Em.isArray(data)) {
        Em.run(function() {
          rp.set('similarProfiles', data.map(function(p) {
            return store.push(store.normalize('realPerson', p)).set(
              'source', 'similar-profile:' + rp.id);
          }).set('lastFetch', new Date()));
          reScroll();
        });
      } else if (Em.get(data, 'status') === 'refreshing') {
        var retried = Em.get(data, 'retried') || 0;
        if (retried < 3)
          Em.run.later(self, 'fetchSimilar', rp, data, (retried + 1) * 2000);
      }
    });
  },
  tryLoadSimilarProfile: function(rp) {
    if (!rp || !this.get('session.account.isStaff'))
      return;

    var self = this;
    function load(rp) {
      if (SL.helper.noLaterThan(
          rp.get('similarProfiles.lastFetch'), 'm', 30))
        reScroll();
      else
        self.fetchSimilar(rp);
    }

    if (['string', 'number'].indexOf(typeof rp) > -1) {
      // Given rp as id
      this.store.findRecord('realPerson', rp).then(load);
    } else {
      load(rp);
    }

  }
});


})();

(function() {

function markTruncate() {
  Em.$('.history-block').each(function() {
    var $this = Em.$(this), overflow =
      $this.children('.history-content').height() > 90;
    $this[overflow ? 'addClass' : 'removeClass']('truncate');
  });
}

SL.TransactionRouteMixin = Em.Mixin.create({
  queryParams: {
    filter: { refreshModel: true },
    filterValue: { refreshModel: true },
    startDate: { refreshModel: true }
  },
  setupController: function(ctl, model) {
    this._super(ctl, model);
    // Try update yesterday as frequent as possible
    ctl.set('yesterday', moment().subtract(1, 'day').toDate());
  },
  resetController: function(ctl, isExiting, trans) {
    this._super(ctl, isExiting, trans);
    if (isExiting)
      ctl.setProperties({
        filter: undefined,
        filterValue: undefined,
        startDate: undefined
      });
  },
  shouldReloadTransaction: function(params, changes) {
    if (!changes) return true;
    var options = changes.get('options') || {};
    return params.startDate !== options.startDate ||
      params.filter !== options.filter ||
      params.filterValue !== options.filterValue;
  },
  getTransactions: function(url, last)  {
    var options = this.paramsFor(this.routeName), params = {},
        store = this.store, filter = options.filter;
    if (['min_vc_status', 'cv_changes', 'vc_status', 'comments'].indexOf(
        filter) > -1)
      params[filter] = options.filterValue && options.filterValue.split(',');
    var startDate = options.startDate;
    if (last)
      params.last = last;
    else if (startDate) {
      // Convert to browser local timestamp and include the selected date
      startDate = moment(startDate).add(1, 'day');
      params.start = startDate.unix();
    }
    params.results_per_page = 15;
    return new Em.RSVP.Promise(function(resovle, reject) {
      Em.$.ajax({
        url: url + '?' + Em.$.param(params, true)
      }).then(function(history_) {
        Em.run(function() {
          var changes = (history_.objects || []).map(function(h) {
            var model = store.push(store.normalize('changeHistory', h)),
                info = h.info;
            switch (model.get('model')) {
              case 'vacancy_candidate':
                model.set('vc', store.push(
                  store.normalize('vacancyCandidate', info.vc)));
                break;
              case 'person_comment':
                /* falls through */
              case 'person':
                if (info && info.person)
                  model.set('person', store.push(
                    store.normalize('person', info.person)));
                break;
            }
            return model;
          });
          changes.setProperties({
            options: options,
            cutOffAt: startDate || new Date()
          });
          resovle(changes);
        });
      }, function(xhr) {
        Em.run.next(reject, xhr);
      });
    });
  },
  fetchTransactions: Em.K,
  moreHistory: function() {
    var $loadMask = Em.$('.history-loading'),
        transactioins = this.controller.get('transactions');
    if (!$loadMask.hasClass('hide') || transactioins.get('allFetched'))
      return;
    $loadMask.removeClass('hide');
    var self = this;
    this.fetchTransactions().then(
        function(changes) {
      if (Em.isEmpty(changes))
        transactioins.set('allFetched', true);
      transactioins.pushObjects(changes);
      Em.run.scheduleOnce('afterRender', function() {
        Em.$('.timeline').closest('.slim-scroll').slimScroll(
          { height: 'auto' });
        self.bindEvents();
      });
    }).finally(function() {
      $loadMask.addClass('hide');
    });
  },
  bindEvents: function() {
    markTruncate();
    Em.$('.more').unbind().click(function(e) {
      e.preventDefault();
    });
    Em.$('.show-more').unbind().click(function(e) {
      e.preventDefault();
      Em.$(this).parent('.history-block').toggleClass('expanded');
    });
  },
  actions: {
    willTransition: function() {
      this._super.apply(this, arguments);
      Em.$(window).off('resize.timeline').off('scroll.timeline');
      // unbind Slim Scroll
      var slimScroll = Em.$('.timeline').closest('.slim-scroll');
      slimScroll.unbind('slimscroll.timeline');
      return true;
    },
    didTransition: function() {
      this._super();
      var self = this;
      Em.$(window).on('resize.timeline', function() {
        Em.run.throttle(markTruncate, 500, true);
        Em.run.debounce(markTruncate, 500);
      });
      Ember.run.scheduleOnce('afterRender', this, function() {
        this.bindEvents();
        // Slim Scroll for lazy loading
        var slimScroll = Em.$('.timeline').closest('.slim-scroll');
        slimScroll.bind('slimscroll.timeline', function(e, pos) {
          if (pos !== 'bottom') return;
          Em.run.debounce(self, 'moreHistory', 800);
        });
        Em.$(window).on('scroll.timeline', function() {
          var $body = Em.$('body');
          if (Em.$(this).scrollTop() >=
              $body.offset().top + $body.outerHeight() - window.innerHeight)
            Em.run.debounce(self, 'moreHistory', 800);
        });
      });
      return true;
    }
  }
});


})();

(function() {

SL.VacancyAddRouteMixin = Em.Mixin.create({

  actions: {
    addToVacancy: function() {
      var store = this.store;
      var self = this;
      var ctl = this.controllerFor('vacancyAdd');

      var vsItems = [];
      this.send('loading', true);
      ctl.get('multiSelected').forEach(function(v) {
        ctl.get('peopleToAdd').forEach(function(i) {
          var data = { candidate: i, vacancy: v };
          if (!v.get('hireAsOwner'))
            data.sourcingChannel = 'external_referral';
          vsItems.push(store.createRecord('vacancyCandidate', data));
        });
      });

      Em.RSVP.allSettled(vsItems.invoke('save')).then(function(rs) {
        var peopleLen = ctl.get('peopleToAdd.length');
        var msgAdded = '', msgDuped = '', msgFailed = '';
        ctl.get('multiSelected').forEach(function(v, i) {
          var added = [], duped = [], failed = [];
          for (var j = peopleLen * i; j < peopleLen * (i + 1); j++) {
            var name = vsItems[j].get('candidate.name');
            if (rs[j].state === 'fulfilled') added.push(name);
            /* jslint eqeq:true */
            else if (rs[j].reason.errors[0].status == 409) duped.push(name);
            else failed.push(name);
          }
          var title = v.get('title.name');
          if (added.length)
            msgAdded += (msgAdded ? '<br>' : '') +
              Em.I18n.t('vacancy.add.added', {
                people: added.join(', '), vacancy: title });
          if (duped.length)
            msgDuped += (msgDuped ? '<br>' : '') +
              Em.I18n.t('vacancy.add.duped', {
                people: duped.join(', '), vacancy: title });
          if (failed.length)
            msgFailed += (msgFailed ? '<br>' : '') +
              Em.I18n.t('vacancy.add.failed', {
                people: failed.join(', '), vacancy: title });
        });
        if (msgAdded)
          self.send('postMessage', { type: 'success', info: msgAdded });
        if (msgDuped)
          self.send('postMessage', { type: 'info', info: msgDuped });
        if (msgFailed)
          self.send('postMessage', { type: 'error', info: msgFailed });
      }).finally(function() {
        self.send('loading', false);
        Em.$('#my-vacancy.modal').modal('hide');
      });
    },

    openMyVacancy: function(peopleToAdd, key) {
      function scroll() {
        $modal.find('.slim-scroll').slimScroll({ height:'auto' });
        $modal.find('[data-toggle="tooltip"]').tooltip();
      }
      function contentChanged() {
        Em.run.scheduleOnce('afterRender', scroll);
      }
      var mvCtl = this.controllerFor('vacancyAdd');
      var $modal = Em.$('#my-vacancy.modal').modal();
      var $spin = $modal.find('.spin');

      if (!peopleToAdd) {
        peopleToAdd = this.get('controller.multiSelected');
        if (Em.isArray(peopleToAdd) && key)
          peopleToAdd = peopleToAdd.mapBy(key);
      } else if (!Em.isArray(peopleToAdd)) {
        peopleToAdd = [peopleToAdd];
      }
      Em.run(function() {
        mvCtl.set('peopleToAdd', peopleToAdd);
      });

      mvCtl.addObserver('filteredContent.[]', null, contentChanged);
      if (mvCtl.get('model')) mvCtl.get('model').clear();
      $spin.spin();
      $spin.removeClass('hide');
      this.fetchMyVacancy().then(function(v) {
        $spin.addClass('hide');
        Em.run(function() {
          mvCtl.set('model', v.toArray());
        });
      });

      $modal.one('shown.bs.modal', function() {
        $modal.find('[autofocus]:not(:focus)').eq(0).focus();
        scroll();
      }).one('hidden.bs.modal', function() {
        Em.run(mvCtl, function() {
          this.clearMultiSelection();
          this.removeObserver('filteredContent.[]', null, contentChanged);
          this.set('filterValueTmp', '');
        });
      });
    },
  },

  fetchMyVacancy: function() {
    var store = this.store;
    var f = {
      filters: [
        { name: 'status', op: 'le', val: 1 }
      ],
      order_by: [{ field: 'id', direction: 'desc' }]
    };
    return Em.$.get(SL.apiPath + '/vacancy/assigned', {
      q: JSON.stringify(f),
      results_per_page: 1000
    }).then(function(data) {
      var myVacancies;
      Em.run(function() {
        myVacancies = data.objects.map(function(v) {
          return store.push(store.normalize('vacancy', v));
        });
      });
      return myVacancies;
    }).fail(SL.onXhrError);
  }
});


})();

(function() {

SL.VacancyRenewRouteMixin = Em.Mixin.create(SL.FinanceRouteMixin, {

  renewVacancyCtl: function() {
    return this.controllerFor('vacancy.renew');
  }.property().readOnly(),

  renewVacancy: function() {
    var vacancy = this.get('renewVacancyCtl.vacancy'),
        package = this.get('renewVacancyCtl.package'),
        self = this, store = this.store,
        $modal = Em.$('#renew-vacancy-payment.modal');

    $modal.modal('hide').one('hidden.bs.modal', function() {
      Em.run(self, 'send', 'loading', true);
      Em.$.get(SL.apiPath + '/vacancy/' + vacancy.id + '/renew?package=' +
          package.package).then(function(data) {
        Em.run(function() {
          if (data.user_credential)
            store.push(store.normalize('user', data.user_credential));
          store.push(store.normalize('vacancy', data.vacancy));
          self.send('postMessage', { type: 'success', info: Em.I18n.t(
            'vacancy.renew.' + (data.vacancy.renew.extended ?
                'extend_succeed' : 'active_succeed'), {
               validDate: moment().add(
                 data.vacancy.renew.days, 'd').format('L')
             })
          });
        });
      }).fail(function(error) {
        Em.run(function() {
          var data = Em.get(error, 'responseJSON'),
              errorType = Em.get(data, 'error');
          if (self.commonLedgerError(errorType)) return;
        });
      }).always(function() {
        Em.run(self, 'send', 'loading', false);
      });
    });
  },

  actions: {
    promptRenewVacancy: function(v) {
      this.set('renewVacancyCtl.package', null);
      this.set('renewVacancyCtl.vacancy', v);
      var $modal = Em.$('#renew-vacancy.modal');

      if (!v.get('renew'))
        this.store.findRecord('vacancy', v.id, {
          reload: true, adapterOptions: { extra: ['renew'] }});

      if (!this.get('renewVacancyCtl.quotes')) {
        this.send('loading', true);
        var self = this;
        Em.$.get(
            SL.apiPath + '/vacancy/renew/quotes?lang=' + Em.I18n.locale,
            function(quotes) {
          Em.run(self, function() {
            this.set('renewVacancyCtl.quotes', quotes);
            this.send('loading', false);
          });
          $modal.modal();
        });
      } else {
        $modal.modal();
      }
    },
    renewVacancy: function() {
      this.renewVacancy();
    },
    renewVacancyPayment: function(package) {
      if (this.get('session.account.credential.sharedCredit') <
          parseFloat(package.price))
        return this.errorMessage(
          'vacancy.renew.insufficient_quota', { price: package.price });

      Em.$('#renew-vacancy-payment.modal').modal();
    }
  }
});


})();

(function() {

SL.ActivitiesRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
    SL.TabbedRouteMixin, SL.TransactionRouteMixin, {
  tabNameTranslation: 'activities.tab.label',
  queryParams: {
    user: { refreshModel: true }
  },
  model: function(params) {
    var changes = this.currentModel;
    if (this.shouldReloadTransaction(params, changes))
      return this.set('currentModel', null).fetchTransactions();
    return changes;
  },
  shouldReloadTransaction: function(params, changes) {
    if (this._super(params, changes))
      return true;
    return params.user !== changes.get('options.user');
  },
  setupController: function(ctl, model) {
    this._super(ctl, model);
    if (ctl.get('user'))
      this.store.findRecord('user', ctl.get('user')).then(function(u) {
        ctl.set('_user', u);
      });
    else
      ctl.set('_user', null);
  },
  resetController: function(ctl, isExiting, trans) {
    this._super(ctl, isExiting, trans);
    if (isExiting)
      ctl.set('user', undefined);
  },
  fetchTransactions: function() {
    var options = this.paramsFor(this.routeName);
    return this.getTransactions(SL.apiPath + '/activities/' +
	(Em.get(options, 'user') || this.get('session.accountId')),
      this.currentModel && this.currentModel.get('lastObject.id'));
  },
  actions: {
    refresh: function() {
      this.set('currentModel', null);
      return true;
    }
  }
});


})();

(function() {

/* global Messenger */

window.authComplete = function(lastAction) {
  var container = SL.__container__;
  if (lastAction === 'changePassword')
    container.lookup('route:application').send('postMessage',
      { type: 'success',
        info: Em.I18n.t('settings.change_password.update_success')
      });
  container.lookup('session:custom').restore().then(function() {
    if (lastAction === 'changePassword' &&
        container.lookup('service:-routing').get(
          'currentRouteName') === 'change-password')
      container.lookup('route:change-password').send('changeComplete');
  });
};

function updateSlimScroll() {
  Em.$('.slim-scroll').each(function() {
    var $self = Em.$(this), data = $self.data();
    var defauts = { height: 'auto', size: '10px', disableFadeOut: true };
    $self.slimScroll(Em.$.extend(defauts, data));
  });
}

function updateTooltip() {
  Em.$('[data-toggle="tooltip"]').tooltip();
}

SL.ApplicationRoute = Em.Route.extend(SimpleAuth.ApplicationRouteMixin, {

  init: function() {
    this._super.apply(this, arguments);
    this.messenger = new Messenger({
      extraClasses: 'messenger-fixed messenger-on-bottom',
      theme: 'flat',
      maxMessages: 3
    });
    this.controllerFor('mainTabs').openDefault();
  },

  beforeModel: function(transition) {
    var restorePending = !SimpleAuth.Configuration._setupDone;
    var result = this._super.apply(this, arguments);
    if (restorePending) {
      transition.abort();
      return this.get('session').restore().catch(function() {
        // should allow for any restore failure
      }).finally(function() {
        transition.retry();
      });
    }
    return result;
  },

  setupController: function(mainCtl) {
    mainCtl.set('tabs', this.controllerFor('mainTabs'));
    mainCtl.set('quickSearch', this.controllerFor('quickSearch'));
  },

  checkWechatBind: function(target, cb) {
    var $modal = Em.$('#bind-wechat.modal.in');
    if (!$modal.length || !$modal.is(':visible')) return;
    var self = this, pollingRound;
    Em.$.ajax({
      url: SL.apiPath + '/wechat/qrcheck?action=bindent',
      beforeSend: function(xhr, settings) {
        xhr.isPolling = true;
        Em.run(self, function() {
          pollingRound = settings.url.split('_=')[1];
          this.set('pollingRound', pollingRound);
        });
      }
    }).done(function(result) {
      if (self.get('pollingRound') !== pollingRound)
        return;
      if (result === 'timeout')
        return self.checkWechatBind(target, cb);
      if (result === 'scanned') {
        self.get('session').restore().finally(function() {
          self.postMessage({
            type: 'success', info: Em.I18n.t('wechat.bind.done') });
          $modal.modal('hide');
          if (cb) cb.call(target, 'success');
        });
      }
    });
  },

  loading: function(showLoading) {
    if (showLoading !== false) {
      if (!this.loadingMask || this.loadingMask.isDestroyed) {
        this.loadingMask =
          this.container.lookup('component:loading-mask').append();
        this.router.one('didTransition', this.loadingMask, 'destroy');
      }
    } else if (this.loadingMask && !this.loadingMask.isDestroyed) {
      Em.run.schedule('afterRender', this.loadingMask, 'destroy');
    }
  },

  postMessage: function(msg) {
    if (!msg.hasOwnProperty('showCloseButton'))
      msg.showCloseButton = true;
    if (msg.info) {
      msg.message = msg.info;
      delete msg.info;
    }
    this.messenger.post(msg);
  },

  actions: {
    willTransition: function(transition) {
      this._super.apply(this, arguments);
      // Slim Scroll
      Em.$(window).unbind('resize.slimscroll');
      wx.setShare();
      return true;
    },
    didTransition: function() {
      this._super();

      // Slim Scroll
      Ember.run.scheduleOnce('afterRender', this, updateSlimScroll);
      Em.$(window).unbind('resize.slimscroll');
      Em.$(window).bind('resize.slimscroll', function(e) {
        Em.run.debounce(null, updateSlimScroll, 500);
      });

      // Bootstrap tooltip
      Ember.run.scheduleOnce('afterRender', this, updateTooltip);

      // Translation
      if (Em.I18n.rerenderPending) {
        Em.run.schedule('afterRender', this, function() {
          this.router._toplevelView.rerender();
          Em.I18n.rerenderPending = false;
        });
      }

      return true;
    },
    changeLanguage: function(locale) {
      var self = this;
      SL.fetchLocale(locale).then(function(json) {
        Em.run(function() {
          Em.I18n.translations = json;
          Em.set(Em.I18n, 'locale', locale);
          Em.I18n.rerenderPending = true;
          moment.locale(locale);
          if (Modernizr.localstorage)
            localStorage.setItem('preferred_locale', locale);
          self.refresh();
        });
      });
    },
    postMessage: function(msg) {
      this.postMessage(msg);
    },
    loading: function(showLoading) {
      this.loading(showLoading);
    },
    goTo: function(anchor) {
      var slimScroll = Em.$('#jumpy.slim-scroll');
      slimScroll.slimScroll({
        scrollTo: Em.$(anchor).offset().top + slimScroll.scrollTop() -
          Em.$('#section-nav').offset().top - 40
      });
    },
    invalidateSession: function() {
      this.loading(true);
      this.get('session').invalidate().catch(Em.onerror);
    },
    sessionInvalidationSucceeded: function() {
      var attemptedTransition = this.get('session.attemptedTransition');
      if (attemptedTransition) {
        Em.run.next(attemptedTransition, 'retry');
        this.set('session.attemptedTransition', null);
      } else {
        this._super();
      }
    },
    sessionRequiresAuthentication: function() {
      var trans = this.get('session.attemptedTransition'), args = [
        SimpleAuth.Configuration.authenticationRoute];
      if (trans.queryParams.logonType)
        args[1] = { queryParams: { logonType: trans.queryParams.logonType }};
      this.transitionTo.apply(this, args);
    },
    sessionAuthenticationFailed: function(error) {
      this.loading(false);
      var msg = error && error.msg;
      if (!msg)
        return Em.onerror(error);
      if (msg !== 'role_conflict') {
        this.send('postMessage', {
          type: 'error',
          info: Em.I18n.t('application.message.login.' + msg)
        });
      }
    },
    authorizationFailed: function() {
      if (!this.get('session.isAuthenticated')) return;
      this.send('postMessage',
        { type: 'error', info:
          Em.I18n.t('application.message.login.session_expired') });
      this.loading(false);
      this.session.clear();
      this.transitionTo('login');
    },
    refresh: function() {
      this.refresh();
    },
    error: function(error) {
      if (Em.onerror)
        Em.onerror(error);
      return true;
    },
    changePassword: function() {
      var url = 'auth/change';
      open(url, 'change_password', 'height=691,width=400');
    },
    bindWechat: function(target, cb) {
      var self = this;
      function showQR() {
        var $modal = Em.$('#bind-wechat.modal');
        $modal.find('.qr-wrapper').html(
          '<img src="api/wechat/subscribe_qr?action=bindent&id=' +
          (new Date()).valueOf() + '" width="250" height="250" />');
        $modal.find('.no-show')[cb ? 'addClass' : 'removeClass']('hide');
        $modal.one('shown.bs.modal', function() {
          self.checkWechatBind(target, cb);
        });
        $modal.modal();
      }
      Em.run.schedule('afterRender', this, function() {
        if (cb) {
          var $modal = Em.$('#prompt-bind-wechat.modal').modal();
          $modal.find('button[name="yes"]').click(showQR);
        } else {
          showQR();
        }
      });
    }
  }
});

// Backport upstream fix: https://github.com/emberjs/ember.js/pull/13218/files
Em.Route.reopen({
  _actions: {
    queryParamsDidChange: function(changed, totalPresent, removed) {
      if (!this.router.currentState)
        return true;

      var transition = this.router.router.activeTransition;
      if (transition) {
        // Transitioning from other route
        if (transition.targetName !== this.routeName)
          return true;
        var handlerInfo = transition.handlerInfos.findBy(
          'name', this.routeName);
        if (Em.isEmpty(handlerInfo.params) && handlerInfo.context)
          // Ensure handler info is with expected params, to prevent Ember
          // from reporting "not providing engough parameter" error.
          handlerInfo.params = handlerInfo.serialize(handlerInfo.context);
      }

      return this._super.apply(this, arguments);
    }
  }
});


})();

(function() {

SL.CompanyIndexRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, SL.FiltersRouteMixin, SL.PaginationRouteMixin,
  SL.SelectableProfileUpdatesRouteMixin, SL.CompanyGroupMemberRouteMixin, {
  tabNameTranslation: 'company.tab.search',

  _refreshProfile: function() {
    var self = this, model = this._super.apply(this, arguments);
    return model && model.then(function(p) {
      if (p.get('asGroup'))
        self.loadGroupMembers(p.get('asGroup'));
      return p;
    });
  },

  filterModel: function(page, pageSize, transition) {
    var store = this.store,
        self = this,
        indexCtl = this.controllerFor('company.index');

    var queryData = {
      options: {
        from: (page - 1) * pageSize,
        size: pageSize,
        sort: [{ id: 'desc' }]
      }
    };
    indexCtl.applyFilter(queryData);

    return Em.$.ajax({
      method: 'POST',
      url: SL.apiPath + '/search/company',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(queryData)
    }).then(function(data) {
      var result = [];

      Em.run(function() {
        result.set('meta',
          { numResults: data.total, totalPages: data.num_pages });
        self.set('updates.companies', false);
      });

      if (!data.total)
        transition.send('postMessage', { type: 'info', info:
          Em.I18n.t('company.message.no_result') });
      else
        self.loadPartial(result, data.hits.mapBy('data'), function(c) {
          return store.push(store.normalize('company', c));
        });

      return result;
    });
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    this.get('profileCtl').setProperties({
      isEmbedded: true
    });
    if (!ctl.get('selectedItem'))
      ctl.set('selectedItem', model.get('firstObject'));
    else
      ctl.updateProfile();
  },

  profileCtl: function() {
    return this.controllerFor('company.profile');
  }.property().readOnly()

});


})();

(function() {

SL.CompanyBaseRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin, {

  setupController: function(ctl, model) {
    ctl.set('editFlag', this.get('isEdit'));
    if (ctl.get('model.content') !== model) {
      this._super(ctl, model);
      ctl.send('toggleAlerts', 'volatile');
    }
  },

  actions: {
    saveCompany: function() {
      var self = this;
      this.send('loading', true);
      var createVacancy =
        this.get('isOwner') && !this.get('session.account.ownedCompanyValid');
      var model = this.currentModel, saveOptions = {};
      if (!model.get('editable'))
        saveOptions.adapterOptions = { forReview: true };
      model.save(saveOptions).then(function() {
        if (self.isEdit) {
          if (createVacancy)
            // Owned company is not valid originally, now saved to be valid.
            self.closeTab({
              route: Em.$('html').hasClass('no-touch') ?
                'vacancy.new' : 'vacancy.import'
            });
          else
            self.closeTab();
        } else {
          self.closeTab({ route: 'company.profile',
            model: self.currentModel });
          self.set('updates.companies', true);
        }
        Em.run.next(function() {
          if (self.isEdit) {
            if (model.get('editable'))
              self.send('postMessage', { type: 'success', info:
                Em.I18n.t('company.message.update_company_successfully',
                  { name: self.currentModel.get('name.name') }) });
            else
              self.send('postMessage', { type: 'success', info:
                Em.I18n.t('company.message.submit_successfully') });
          } else {
            self.send('postMessage', { type: 'success', info:
              Em.I18n.t('company.message.create_company_successfully',
                { name: self.currentModel.get('name.name') }) });
          }
        });
      }).catch(SL.bumpRsvpError(function() {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('company.message.failed_create_company') });
      })).finally(function() {
        self.send('loading', false);
      });
    }
  }
});

SL.CompanyNewRoute = SL.CompanyBaseRoute.extend(SL.TabbedRouteMixin,
  SL.NewRecordRouteMixin, {
  tabNameTranslation: 'company.tab.create',

  model: function() {
    if (!this.currentModel || this.currentModel.id)
      return this.newRecord('company');
    return this.currentModel;
  },

  actions: {
    cancelEdit: function() {
      this.currentModel = null;
      this.refresh();
    }
  }
});

SL.CompanyEditRoute = SL.CompanyBaseRoute.extend(SL.TabbedDynamicRouteMixin,
    SL.ConfirmationRouteMixin, SL.ProfileUpdatesRouteMixin,
    SL.MissingRecordRouteMixin, {
  isEdit: true,
  tabName: '<i class="fa fa-pencil"></i>',
  controllerName: 'companyNew',

  afterModel: function(model, transition) {
    /* jslint eqeq: true */
    var isOwner = this.get('session.account.company.id') == model.get('id');
    /* jslint eqeq: false */
    this.set('isOwner', isOwner);
    this.set('tabItem.title', isOwner ?
      Em.I18n.t('application.login.my_company') :
      this.tabName + ' ' + model.get('name.name'));
    this._super.apply(this, arguments);
  },
  renderTemplate: function() {
    this.render('company.new');
  }
});


})();

(function() {

SL.CompanyProfileRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
    SL.TabbedDynamicRouteMixin, SL.ProfileUpdatesRouteMixin,
    SL.MissingRecordRouteMixin, SL.CompanyGroupMemberRouteMixin, {
  tabName: '<i class="fa fa-building-o"></i>',

  afterModel: function(model) {
    this.set('tabItem.title', this.tabName + ' ' + model.get('name.name'));
    this.loadGroupMembers(model.get('asGroup'));
    this._super.apply(this, arguments);
  },
  setupController: function(controller, model) {
    this._super(controller, model);
    controller.setProperties({
      company: model,
      isEmbedded: false
    });
  }
});


})();

(function() {

SL.FolderBaseRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, SL.PersonCommentsRouteMixin, SL.VacancyAddRouteMixin,
  SL.FavoriteRouteMixin, SL.ConfirmationRouteMixin, SL.FolderAddRouteMixin,
  SL.PaginationRouteMixin, SL.SelectableProfileUpdatesRouteMixin,
  SL.ResumeRouteMixin, SL.ProfileVcStatusRouteMixin, SL.MappingAddRouteMixin,
  SL.SimilarProfileRouteMixin, {

  templateName: 'folder.index',
  logStayed: true,

  modelUpToDate: Em.K,
  folderQuery: Em.K,

  _getProfile: function() {
    return this.controller.get('selectedItem.person.defaultProfile');
  },

  _refreshProfile: function() {
    this.tryLoadVcStatus(this.controller.get('selectedItem.person'));
    this.tryLoadSimilarProfile(this.controller.get('selectedItem.person'));
    return this._super.apply(this, arguments);
  },

  model: function(param) {
    var ctl = this.controllerFor(this.get('routeName')), self = this;
    if (ctl.get('model') && ctl.get('model') === this.get('currentModel') &&
      ctl.get('page') === param.page &&
      ctl.get('pageSize') === param.pageSize) {
      return this.get('currentModel');
    }
    return this.folderQuery(param, ctl.get('folder.id')).then(function(ps) {
      var result = [];
      result.set('meta', ps.get('meta'));
      ps.forEach(function(p) {
        if (p.get('person'))
          p.get('person').set(
            'source', p.get('entryType') + ':' +
            p.get('folder.id')).resetVcStatus();
      });
      self.loadPartial(result, ps);
      self.modelUpToDate();
      return result;
    });
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    ctl.set('folders', this.showFolders);
    if (!ctl.get('selectedItem'))
      ctl.set('selectedItem', ctl.get('model.firstObject'));

    this.controllerFor('people.edit').setProperties({
      isEmbedded: true,
      editFlag: false,
      person: ctl.get('selectedItem.person.defaultProfile')
    });
  },

  folderUpdated: function(fId, added, people) {
    if (fId === this.get('controller.folder.id')) {
      this.set('currentModel', null);
      this.refresh();
    }
  },

  actions: {
    refresh: function() {
      this.set('currentModel', null);
      return true;
    },
    removeFromFolder: function() {
      var self = this, toRemove = this.get('controller.multiSelected'),
          fId = this.get('controller.folder.id');
      this.send('loading', true);
      Em.RSVP.all(toRemove.invoke('destroyRecord')).then(function() {
        self.folderUpdated(fId, false, toRemove.mapBy('person'));
      }).catch(SL.bumpRsvpError(function() {
        self.send('loading', false);
      }));
    },
    setFlag: function() {
      var self = this;
      this.send('loading', true);
      var fs = this.get('controller.multiSelected');
      Em.RSVP.all(fs.invoke('save')).catch(function(e) {
        fs.invoke('rollback');
              }).finally(function() {
        self.get('controller').clearMultiSelection();
        self.send('loading', false);
      });
    },
    openMyVacancy: function(peopleToAdd) {
      this._super(peopleToAdd, 'person');
    },
    openMyFolder: function(peopleToAdd) {
      this._super(peopleToAdd, 'person');
    },
    assignFolder:  function(folder) {
      Em.$('#select-assignee').modal();
    }
  }

});

SL.FolderIndexRoute = SL.FolderBaseRoute.extend(SL.NewRecordRouteMixin, {
  tabNameTranslation: 'list.tab.list',
  entryType: 'folderPersonStatus',
  showFolders: true,
  queryParams: {
    fid: { refreshModel: true }
  },

  beforeModel: function(params) {
    this._super.apply(this, arguments);

    // FIXME: This is unecessary as long as simple auth ensures the session to
    // be authenticated by the time beforeModel is called.
    if (!this.get('session.isAuthenticated')) return;
    var ctl = this.controllerFor(this.get('routeName')),
        listCtl = this.get('list'),
        fId = params.queryParams.fid || ctl.get('folder.id');

    if (fId && listCtl.get('selectedItem.id') === fId)
      // Selected folder unchanged
      return;

    // Folder changed
    this.set('currentModel', null);
    ctl.set('page', 1);

    if (!fId && listCtl.get('selectedItem'))
      // Unselect folder
      return listCtl.set('selectedItem', null);

    var newFolder = this.getWithDefault('folders', []).findBy('id', fId);
    if (newFolder) {
      ctl.set('folder', newFolder);
      listCtl.set('selectedItem', newFolder);
    } else {
      // Should refresh folders
      this.set('folders', null);  // For update in afterModel hook
      var q = {
        filters: [{
          name: 'category', op: 'eq', val: listCtl.get('defaultCategory') }],
        order_by: [{ field: 'category', direction: 'desc' },
          { field: 'id', direction: 'desc' }]
      };
      if (fId)
        q.filters.push({ name: 'id', op: 'eq', val: fId });
      return this.store.query('folder', {
        q: JSON.stringify(q),
        results_per_page: 1
      }).then(function(fs) {
        ctl.set('folder', fs.get('firstObject'));
        listCtl.set('selectedItem', fs.get('firstObject'));
      });
    }
  },

  reopenTab: function() {
    this._super.apply(this, arguments);
    this.set('folders', undefined);
  },

  folderQuery: function(param, fid) {
    if (!fid) {
      return Em.RSVP.resolve([]);
    }
    var f = {
      filters: [{ name: 'folder_id', op: 'eq', val: fid }],
      order_by: [
        { field: 'sort_key', direction: 'asc' },
        { field: 'id', direction: 'desc' }
      ]
    };
    return this.store.query(this.entryType, {
      q: JSON.stringify(f),
      results_per_page: param.pageSize,
      page: param.page
    });
  },

  modelUpToDate: function() {
    var folder = this.get('list.selectedItem');
    if (!folder) return;
    var updates = this.get('list.currUpdates');
    var ctl = this.controllerFor(this.get('routeName'));
    if (ctl.get('mappingProgress')) {
      var updatePromise = Em.RSVP.resolve(folder);
      if (updates.indexOf(folder.id) >= 0)
        // Updated folder, reload integrity
        updatePromise = this.store.findRecord('folder', folder.id, {
          reload: true, adapterOptions: { extra: 'integrity' }});
      updatePromise.then(function(f) {
        Em.run.schedule('afterRender', function() {
          Em.$('.integrity').tooltip('destroy').tooltip({
            title: Em.I18n.t('list.mapping.progress', {
              progress: ctl.get('mappingProgress') })
          });
        });
      });
    }
    updates.removeObject(folder.id);
  },

  proceedTransition: function() {
    var list = this.get('list');
    list.get('folders').forEach(function(i) {
      if (!i.get('isDirty')) return;
      if (i.get('isNew')) {
        list.get('folders').removeObject(i);
      } else {
        i.rollback();
        list.send('multiSelect', i);
      }
    });
  },

  preventUnload: function() {
    return this.get('list.folders').isAny('isDirty');
  },

  fetchFolders: function() {
    var category = this.get('list.defaultCategory');
    var f = {
      filters: [{
        name: 'category', op: 'eq', val: category }],
      order_by: [{ field: 'id', direction: 'desc' }]
    };
    var q = {
      q: JSON.stringify(f),
      results_per_page: '9999999'
    };
    if (category === 'mapping')
      q.extra = 'integrity';
    return this.store.query('folder', q);
  },

  afterModel: function(model) {
    this._super.apply(this, arguments);
    if (Em.isNone(this.get('folders'))) {
      var self = this;
      return this.fetchFolders().then(function(fs) {
        self.set('folders', fs.toArray());
        self.modelUpToDate();
      });
    }
  },

  renderTemplate: function() {
    this._super();
    this.render('folder.list', {
      outlet: 'folderList',
      controller: this.get('list'),
      into: this.routeName
    });
  },

  setupController: function(ctl, model) {
    this._super.apply(this, arguments);
    var listCtl = this.get('list');
    listCtl.set('folders', this.get('folders'));
    if (!listCtl.get('selectedItem'))
      listCtl.set('selectedItem', this.get('folders.firstObject'));
  },

  list: function() {
    return this.controllerFor('folder.list');
  }.property().readOnly(),

  changeFolder: function(f) {
    if (f === this.get('list.selectedItem')) return;
    if (!f)
      this.controller.set('folder', null);
    this.transitionTo({ queryParams: { fid: f && f.id || '' }});
  },

  folderUpdated: function(fId, add, people) {
    this.get('list.currUpdates').addObject(fId);
    this._super.apply(this, arguments);
  },

  actions: {
    changeFolder: function(f) {
      if (!f.get('isNew'))
        this.changeFolder(f);
    },
    createFolder: function() {
      var newFolder = this.newRecord('folder', {
        category: this.get('list.defaultCategory')
      });
      this.get('list.folders').insertAt(this.get('list.folders').
        filterBy('category', 'system').length, newFolder);
      this.get('list').send('multiSelect', newFolder);
    },
    cancelFolder: function(f) {
      if (f.get('isNew')) {
        this.get('list.folders').removeObject(f);
      } else {
        f.rollback();
        if (this.get('list.multiSelected').indexOf(f) > -1)
          // Exit editing mode
          this.get('list').send('multiSelect', f);
      }
    },
    saveFolder: function(f) {
      var self = this;
      this.send('loading', true);
      f.save().then(function() {
        if (self.get('list.multiSelected').indexOf(f) > -1)
          // Exit editing mode
          self.get('list').send('multiSelect', f);
        if (self.get('list.folders.length') === 1)
          self.changeFolder(f);
      }).finally(function() {
        self.send('loading', false);
      });
    },
    deleteFolder: function(f) {
      var self = this;
      this.send('loading', true);
      f.destroyRecord().then(function() {
        self.get('list.folders').removeObject(f);
        self.changeFolder(self.get('folders.firstObject'));
      }).finally(function() {
        self.send('loading', false);
      });
    }
  }
});

SL.FolderFavoriteRoute = SL.FolderBaseRoute.extend({
  tabNameTranslation: 'list.tab.favorite',

  folderQuery: function(param) {
    return this.store.query('favoritePerson', {
      q: JSON.stringify({
        order_by: [{ field: 'id', direction: 'desc' }]
      }),
      results_per_page: param.pageSize,
      page: param.page
    });
  },

  modelUpToDate: function() {
    this.set('updates.favorite', null);
  },

  folderUpdated: function(fId, add, people) {
    this._super.apply(this, arguments);
    // Sync the favorite icon status if it's in the updated people list
    var favCtl = this.controllerFor('favorite');
    if (!add && people.isAny('id', favCtl.get('currentOwner').toString()))
      favCtl.set('favorite', null);
  }

});

SL.FolderMappingRoute = SL.FolderIndexRoute.extend({
  tabNameTranslation: 'list.tab.mapping',
  entryType: 'mappingEntry',
  controllerName: 'mappingEntries',

  list: function() {
    return this.controllerFor('mappings');
  }.property().readOnly(),

  actions: {
    promptNewMappingEntry: function() {
      var ctl = this.controller;
      ctl.set('ready', false);
      ctl.send('toggleAlerts', 'volatile');
      // Reinitialize model contents
      Em.$('#add-mapping-entry').modal().one('shown.bs.modal', function() {
        Em.run(ctl, 'set', 'ready', true);
      });
    },
    saveMappingEntry: function() {
      var ctl = this.controller;
      var count = parseInt(ctl.get('entryCount'));
      if (!count || count <= 0) return;
      var entries = [];
      var title = ctl.get('mappingTitle');
      if (!title && ctl.get('mappingTitleNew')) {
        title = this.newRecord('title', {
          name: ctl.get('mappingTitleNew') });
        ctl.set('mappingTitle', title);
      }
      var company = ctl.get('mappingCompany');
      if (!company && ctl.get('mappingCompanyNew')) {
        company = this.newRecord('companyAlias', {
          name: ctl.get('mappingCompanyNew') });
        ctl.set('mappingCompany', company);
      }
      for (var i = 0; i < count; i++)
        entries.addObject(this.newRecord('mappingEntry', {
          folder: ctl.get('folder'),
          title: title,
          company: company,
          associate: ctl.get('mappingAssociate'),
          associateType: ctl.get('mappingAssociateType'),
          description: ctl.get('mappingDescription')
        }));
      var self = this;
      this.send('loading', true);
      Em.RSVP.all(entries.map(function(e) {
        return e.save();
      })).then(function() {
        Em.$('#add-mapping-entry').modal('hide');
        self.get('list.currUpdates').addObject(ctl.get('folder.id'));
        self.set('currentModel', null);
        self.refresh();
      }).catch(function() {
        self.send('loading', false);
      });
    }
  }

});


})();

(function() {

SL.IndexRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, SL.VacancyRenewRouteMixin, {

  init: function() {
    this._super.apply(this, arguments);
    // Populate the default tab to the index route so that it could just
    // act as it's the default route
    this.setProperties({
      tabItem: this.get('mainTabs.defaultTab'),
      tabNameTranslation: this.get('mainTabs.defaultTab.titleTranslation')
    });
  },

  beforeModel: function(transition) {
    switch (this.get('session.degraded')) {
      case 'changePassword':
        this.transitionTo('change-password');
        break;
      case 'invalidProfile':
        transition.send('postMessage', { type: 'error', info :
          Em.I18n.t('people.message.complete_info'), id: 'complete_resume' });
        this.transitionTo(
          'people.edit', this.get('session.account.ownedProfile'));
        break;
      case 'invalidCompany':
        transition.send('postMessage', { type: 'error', info:
          Em.I18n.t('company.message.complete_info'), id: 'complete_company' });
        this.transitionTo(
          'company.edit', this.get('session.account.company.content'));
        break;
      default:
        return this._super.apply(this, arguments);
    }
  },

  fetchKpi: function(model, force) {
    if (model.kpi && SL.helper.noLaterThan(model.kpi.updated, 'h', 1) &&
      !force) return;
    var durationStr = '', duration = model.get('kpi.duration');
    if (duration) {
      var m = moment().add(duration, 'w');
      durationStr = '?start_date=' + m.startOf('isoWeek').format('YYYY-MM-DD') +
        '&end_date=' + m.endOf('isoWeek').format('YYYY-MM-DD');
    }
    return Em.$.get(SL.apiPath + '/kpi' + durationStr).then(function(kpi) {
      kpi = Em.Object.create(kpi);
      Em.run(function() {
        kpi.setProperties({
          updated: new Date(),
          duration: Em.isNone(duration) ? 0 : duration
        });
        model.set('kpi', kpi);
      });
      return kpi;
    }).fail(SL.onXhrError);
  },

  fetchChannelPerformance: function(model, force) {
    if (model.cp && SL.helper.noLaterThan(model.cp.updated, 'h', 1) &&
      !force) return;

    return Em.$.get(SL.apiPath + '/channel_performance').then(function(cp) {
      cp = Em.Object.create(cp);
      Em.run(function() {
        cp.setProperties({
          updated: new Date()
        });
        model.set('cp', cp);
      });
      return cp;
    }).fail(SL.onXhrError);
  },

  fetchMyVacancies: function(model, force) {
    if (model.myVacancies && SL.helper.noLaterThan(
        model.myVacancies.updated, 'h', 1) && !force)
      return Em.RSVP.resolve();

    var f = {
      filters: [
        { name: 'status', op: 'eq', val: 0 }
      ],
      order_by: [
        { field: 'renew_at', direction: 'desc' },
        { field: 'id', direction: 'desc' }
      ]
    };

    var store = this.store,
        page = force && force.page || model.get('myVacancies.meta.page');
    return Em.$.ajax({
      url: SL.apiPath + '/vacancy/assigned',
      data: {
        q: JSON.stringify(f),
        page: page,
        results_per_page: 5,
        extra: ['candidate_stat', 'renew']
      },
      traditional: true
    }).then(function(data) {
      Em.run(function() {
        var myVacancies = data.objects.map(function(v) {
          return store.push(store.normalize('vacancy', v));
        });
        myVacancies.setProperties({
          updated: new Date(),
          meta: {
            page: data.page,
            numResults: data.num_results,
            totalPages: data.total_pages
          }
        });
        model.set('myVacancies', myVacancies);
      });
    }).fail(SL.onXhrError);
  },

  fetchPendingVcs: function(model, force) {
    if (model.pendingVcs && SL.helper.noLaterThan(
        model.pendingVcs.updated, 'h', 1) && !force)
      return Em.RSVP.resolve();

    var f = {
      order_by: [{ field: 'update_at', direction: 'desc' }]
    };

    var store = this.store,
        page = force && force.page || model.get('pendingVcs.meta.page');
    return store.query('vacancyCandidate', {
      pending: 1,
      extra: 'vacancy_summary',
      q: JSON.stringify(f),
      page: page,
      results_per_page: 5,
    }).then(function(pendingVcs) {
      pendingVcs.forEach(function(vc) {
        var p = vc.get('candidate.defaultProfile');
        if (p && p.get('isMasked'))
          p.set('vc', vc);
      });
      pendingVcs.set('updated', new Date());
      model.set('pendingVcs', pendingVcs);
    }).catch(Em.onerror);
  },
  fetchKpiReport: function(model, reportId) {
    if (model.kpiReport && SL.helper.noLaterThan(
        model.kpiReport.updated, 'h', 1))
      return Em.RSVP.resolve();
    var kpiReportCtl = this.controllerFor('kpi-report');
    return this.store.find('report', reportId).then(function(config) {
      model.set('kpiReport', config);
      config.resetFilter();
      kpiReportCtl.set('data', config);
      SL.fetchReportData(config).then(function() {
        config.set('updated', new Date());
      });
    });
  },

  model: function() {
    var model = this.get('currentModel') || Em.Object.create();
    var promises = [];
    if (this.get('session.account.isStaff')) {
      promises.push(this.fetchMyVacancies(model));
      promises.push(this.fetchPendingVcs(model));
      if (this.get('parameters.showKPI')) {
        var kpiReport = this.get('parameters.kpi.summaryReport');
        promises.push(
          kpiReport ? this.fetchKpiReport(model, kpiReport) :
          this.fetchKpi(model));
      }
      if (this.get('parameters.showChannelPerformance'))
        promises.push(this.fetchChannelPerformance(model));
    }
    return Em.RSVP.allSettled(promises).then(function() {
      return model;
    });
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    if (model && model.get('kpi'))
      this.controllerFor('performance').set('data', model.get('kpi'));
  },

  actions: {
    updateKpi: function() {
      var self = this;
      this.send('loading', true);
      this.fetchKpi(this.currentModel, true).then(function(kpi) {
        Em.run(self, function() {
          this.controllerFor('performance').set('data', kpi);
          this.send('loading', false);
        });
      });
    },
    updateChannelPerformance: function() {
      var self = this;
      this.send('loading', true);
      this.fetchChannelPerformance(this.currentModel, true).then(function(kpi) {
        Em.run(self, function() {
          this.send('loading', false);
        });
      });
    },
    updateMyVacancies: function(p) {
      var self = this;
      p = p || { page: null };
      if (p && p.page === this.get('currentModel.myVacancies.meta.page'))
        return;
      this.send('loading', true);
      this.fetchMyVacancies(this.currentModel, p).then(function() {
        Em.run(self, function() {
          this.send('loading', false);
        });
      });
    },
    offlineVacancy: function(v) {
      var self = this;
      v.set('status', 2);
      this.send('loading', true);
      v.save().then(function() {
        self.send('postMessage', { type: 'success', info:
          Em.I18n.t('vacancy.offline.success') });
        var myVacancies = self.get('currentModel.myVacancies'), page;
        if (myVacancies.get('length') === 1 &&
            myVacancies.get('meta.page') ===
            myVacancies.get('meta.totalPages'))
          // Last page and last item, go previous page
          page = myVacancies.get('meta.page') - 1;
        return self.fetchMyVacancies(self.currentModel, { page: page });
      }, function() {
        v.rollback();
      }).finally(function() {
        self.send('loading', false);
      });
    },
    updatePendingVcs: function(p) {
      var self = this;
      p = p || { page: null };
      if (p && p.page === this.get('currentModel.pendingVcs.meta.page'))
        return;
      this.send('loading', true);
      this.fetchPendingVcs(this.currentModel, p).then(function() {
        Em.run(self, function() {
          this.send('loading', false);
        });
      });
    },
    didTransition: function() {
      if (this.get('session.account.bindWechat')) {
        if (!localStorage ||
            localStorage.getItem('init_no_bind_wechat') !== 'true') {
          this.send('bindWechat');
          this.set('session.account.bindWechat', false);
        }
      }
      return this._super.apply(this, arguments);
    },
    pendingVcUpdate: function(vc, status) {
      var self = this;
      switch (status) {
        case 'dismiss':
          vc.set('updateAt', new Date());
          break;
        case 'out':
          vc.set('out', true);
          break;
        default:
          vc.set('status', status);
      }
      this.send('loading', true);
      vc.save().then(function() {
        var pendingVcs = self.get('currentModel.pendingVcs'), page;
        if (pendingVcs.get('length') === 1 &&
            pendingVcs.get('meta.page') ===
            pendingVcs.get('meta.totalPages'))
          // Last page and last item, go previous page
          page = pendingVcs.get('meta.page') - 1;
        return self.fetchPendingVcs(self.currentModel, { page: page });
      }, function() {
        vc.rollback();
      }).finally(function() {
        self.send('loading', false);
      });
    },
    applyFilter: function() {
      var report = this.currentModel.get('kpiReport');
      report.setProperties({
        filterPending: undefined,
        updated: undefined
      });
      SL.fetchReportData(report).then(function() {
        report.set('updated', new Date());
      });
    }
  }
});


})();

(function() {

SL.KpiIndexRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, {
  tabNameTranslation: 'reports.kpi.review_tab',
  queryParams: {
    duration: {
      refreshModel: true
    }
  },

  beforeModel: function() {
    // Dirty hack to prevent non-previlleged user to see all KPIs
    if (!this.get('session.account.isManager'))
      return this.transitionTo('kpi.detail', this.get('session.account'));
    this._super.apply(this, arguments);
  },

  model: function(param) {
    // Performance Improvement
    /* jslint eqeq: true */
    if (this.get('currentModel') &&
      this.get('currentModel.duration') == param.duration)
      return this.get('currentModel');
    /* jslint eqeq: false */

    var durationStr = '';
    if (param.duration) {
      var m = moment().add(param.duration, 'w');
      durationStr = '&start_date=' + m.startOf('isoWeek').format('YYYY-MM-DD') +
        '&end_date=' + m.endOf('isoWeek').format('YYYY-MM-DD');
    }
    var store = this.store;
    return Em.$.get(SL.apiPath + '/kpi?group_view=true' + durationStr).then(
      function(data) {
      var kpis;
      Em.run(function() {
        kpis = data.objects.map(function(kpi) {
          kpi.user = store.push(store.normalize('user', kpi.user));
          return kpi;
        });
      });
      kpis.duration = param.duration;
      return kpis;
    });
  }
});

function setSource(records, key, source) {
  var promise = Em.RSVP.resolve(records);
  if (key.indexOf('.') > -1) {
    promise = Em.RSVP.all(records.getEach(key.split('.')[0]));
    key = key.split('.')[1];
  }
  promise.then(function(recs) {
    return recs.getEach(key);
  }).then(function(rps) {
    rps.setEach('source', 'kpi');
  });
  return records;
}

SL.KpiDetailRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedDynamicRouteMixin, SL.MissingRecordRouteMixin, {

  setupController: function(ctl, model) {
    ctl.set('data', model);
  },

  afterModel: function(model, trans) {
    /* jslint eqeq: true */
    this.set('tabItem.titleTranslation',
      model.id == this.get('session.accountId') ?
      'reports.kpi.my_tab' : 'reports.kpi.tab');
    /* jslint eqeq: false */

    this._super.apply(this, arguments);

    var self = this, duration = Em.get(trans, 'state.queryParams.duration');
    duration = duration ? parseInt(duration) : 0;

    // Performance improvement
    /* jslint eqeq: true */
    if (this.get('currentModel') && this.get('currentModel.id') == model.id &&
      this.get('currentModel.kpi.duration') == duration)
      return;
    /* jslint eqeq: false */

    function findQuery(type) {
      var week = moment().add(duration, 'w');
      var filters = {
        filters: [{ name: 'issued_at', op: 'gte', val:
            moment.utc(week.startOf('isoWeek')).format('YYYY-MM-DDTHH:mm:ss') },
          { name: 'issued_at', op: 'lte', val:
            moment.utc(week.endOf('isoWeek')).format('YYYY-MM-DDTHH:mm:ss') },
          { name: 'user_id', op: 'eq', val: model.id }]
      };
      return self.store.query(type, {
        q: JSON.stringify(filters),
        results_per_page: '999999'
      });
    }

    return Em.RSVP.hash({
      newPerson: findQuery('accountNewPerson').then(
          function(records) {
        return setSource(records, 'candidate.owner');
      }),
      newVacancy: findQuery('accountNewVacancy'),
      clientMeeting: findQuery('accountClientMeeting').then(
          function(records) {
        return setSource(records, 'client.owner');
      }),
      interview: findQuery('accountInterview').then(
          function(records) {
        return setSource(records, 'candidate.owner');
      }),
      clientInterview: findQuery('accountClientInterview').then(
          function(records) {
        return setSource(records, 'candidate');
      }),
      reported: findQuery('accountReported').then(
          function(records) {
        return setSource(records, 'candidate');
      }),
      phoneRecord: findQuery('accountPhoneRecord').then(
          function(records) {
        return setSource(records, 'candidate.owner');
      }),
      bdCall: findQuery('accountBdCall').then(
          function(records) {
        return setSource(records, 'candidate.owner');
      }),
      followUpCall: findQuery('accountFollowUpCall').then(
          function(records) {
        return setSource(records, 'candidate.owner');
      })
    }).then(function(hash) {
      hash.duration = duration;
      return model.set('kpi', hash);
    });
  }

});


})();

(function() {

SL.LedgerRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin);

var _dtFmt = 'YYYY-MM-DDTHH:mm:ss';

SL.LedgerSharedRoute = SL.LedgerRoute.extend(SL.TabbedRouteMixin,
    SL.PaginationRouteMixin, SL.FinanceRouteMixin, {

  tabNameTranslation: 'ledger.tab_name',

  queryParams: {
    action: { refreshModel: true }
  },

  accountType: function() {
    return this.get('session.account.isPublisher') ? 'debit' : 'credit';
  }.property('session.account.isPublisher').readOnly(),

  model: function(params) {
    var model = this.currentModel || [], self = this, store = this.store,
        credential = this.get('session.account.credential'),
        accountType = this.get('accountType');

    if (params.page === 1 || !SL.helper.noLaterThan(
        credential.get('updateAt'), 'm', 5)) {
      // Refresh account only if necessary:
      // 1. Visiting the first page
      // 2. Not recently (in 5min) updated
      Em.$.ajax({
        url: SL.apiPath + '/ledger/shared_account',
        data: { type: accountType }
      }).then(function(data) {
        Em.run(function() {
          var acc = store.push(store.normalize('ledgerAccount', data));
          model.set('account', acc);
          credential.set(accountType + 's', acc.get('balance'));
          credential.set('updateAt', new Date());
        });
      });
    }

    if (!this.get('session.account.isManager') &&
        !this.get('session.account.isPublisher'))
      return model;

    // Need to find splits
    return Em.$.ajax({
      url: SL.apiPath + '/ledger/shared_account/splits',
      data: {
        type: accountType,
        results_per_page: params.pageSize,
        page: params.page - 1,
        action: params.action,
        transref: params.transref,
        from_dt: params.startDate &&
          moment(params.startDate).utc().format(_dtFmt),
        to_dt: params.endDate &&
          moment(params.endDate).add(1, 'days').utc().format(_dtFmt)
      }
    }).then(function(data) {
      Em.run(function() {
        model = Em.A().setProperties({
          account: model.get('account'),
          meta: {
            numResults: data.num_models,
            totalPages: data.total_pages
          }
        });
      });
      self.loadPartial(model, data.objects, function(s) {
        return store.push(store.normalize('split', s));
      });
      return model;
    });
  },
  actions: {
    closeTab: function(tab) {
      this.controller.setProperties({
        action: '',
        startDate: null,
        endDate: null,
        transref: null
      });
      return this._super.apply(this, arguments);
    },
    withdraw: function() {
      var $modal = Em.$('#withdraw-modal.modal');
      $modal.modal().one('shown.bs.modal', function() {
        Em.$('#withdraw-modal .money input').focus();
      });
    },
    doWithdraw: function() {
      var self = this;
      this.send('loading', true);
      Em.$.ajax({
        url: SL.apiPath + '/ledger/shared_account/withdraw',
        data: {
          amount: this.controller.get('withdrawAmount')
        }
      }).then(function(data) {
        self.send('postMessage', { type: 'success',
          info: Em.I18n.t('ledger.withdraw.succeed') });
        Em.run(function() {
          // Reload balance
          self.set('session.account.credential.updateAt', null);
          self.refresh();
        });
      }).fail(function() {
        self.commonLedgerError('generic_error');
        self.refresh();
      }).always(function() {
        self.send('loading', false);
        var $modal = Em.$('#withdraw-modal.modal');
        $modal.modal('hide');
      });
    }
  }

});


})();

(function() {

SL.LoginRoute = Em.Route.extend(SimpleAuth.UnauthenticatedRouteMixin, {

  beforeModel: function(transition) {
    this._super.apply(this, arguments);
    if (transition.queryParams.from === 'reset_password') {
      transition.send('postMessage', { type: 'success', info:
        Em.I18n.t('application.reset_password.succeed') });
    }
  },

  checkQR: function(uuid) {
    var self = this;
    Em.$.get(SL.apiPath + '/qrcheck?uuid=' + uuid).then(function(result) {
      if (self.get('controller.logonType') !== 'qr' ||
          self.get('session.accountId') ||
          self.get('controller.qrScanUrl').indexOf(uuid) < 0)
        return;

      switch (result) {
      case 'scanned':
        Em.run(function() {
          self.set('controller.scanned', true);
        });
        /* falls through */
      case 'timeout':
        self.checkQR(uuid);
        break;
      case 'logged_in':
        self.send('loading', true);
        self.get('session').restore().then(function() {
          self.send('sessionAuthenticationSucceeded');
        });
        break;
      case 'expired':
        self.getQRCode();
        break;
      }
    });
  },

  getQRCode: function() {
    var self = this;
    Em.$.get(SL.apiPath + '/qrgen').then(function(uuid) {
      Em.run(function() {
        self.set('controller.qrScanUrl', SL.parameters.mobileSite +
          '/#/qr-scan.html?s=wechat&uuid=' + uuid);
        self.get('controller').setProperties({
          logonType: 'qr',
          scanned: false
        });
      });
      self.checkQR(uuid);
    });
  },

  setupController: function(ctl) {
    if (ctl.get('logonType') === 'qr')
      this.getQRCode();
    ctl.set('identification', ctl.get('username'));
  },

  actions: {
    genQRCode: function() {
      this.getQRCode();
    },
    reLogin: function() {
      this.set('session.roleConflict', null);
    },
    setRole: function(role) {
      var self = this;
      this.get('session').set('content.coerceRole', role).updateStore();
      this.send('loading', true);
      this.get('session').restore().then(function() {
        self.send('sessionAuthenticationSucceeded');
      });
    }
  }
});


})();

(function() {

SL.PeopleEditRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedDynamicRouteMixin, SL.PersonCommentsRouteMixin,
  SL.NewRecordRouteMixin, SL.ConfirmationRouteMixin, SL.FilesRouteMixin,
  SL.ProfileUpdatesRouteMixin, SL.MissingRecordRouteMixin, {
  tabName: '<i class="fa fa-pencil"></i>',

  afterModel: function(model) {
    if (this.get('session.account.realPerson.id') === model.get('owner.id'))
      // If user's editing his own profile
      this.set('tabItem.title', Em.I18n.t('people.tab.edit_my_profile'));
    else
      this.set('tabItem.title', this.tabName + ' ' +
        (model.get('name') || Em.I18n.t('common.no_name')));

    this._super.apply(this, arguments);
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    ctl.setProperties({
      person: model,
      isEmbedded: false,
      editFlag: true
    });
  },

  cleanupBeforeSave: function() {
    this.set('controller.person.currentEmail', null);
    this.set('controller.person.currentMobile', null);
  },

  proceedTransition: function() {
    if (!this.preventUnload())
      return this.controller.send('cancelEdit');
    this._super.apply(this, arguments);
  },

  deleteRecord: function(rec, success, fail) {
    var self = this;
    rec.deleteRecord();
    this.send('loading', true);
    this.currentModel.save().then(function() {
      self.send('postMessage', { type: 'success', info: success });
    }).catch(SL.bumpRsvpError(function() {
      self.send('postMessage', { type: 'error', info: fail });
    })).finally(function() {
      self.send('loading', false);
    });
  },

  addContact: function(oneHolder, moreHolder, array, type, modal) {
    var model = this.get('controller.person'), self = this;
    model.enforceRemoteValidation();
    model.get(oneHolder).validate().then(function() {
      model.get(array).notifyPropertyChange('[]');
      model.set(moreHolder, self.newRecord(type));
      Em.$(modal).modal().one('shown.bs.modal', function() {
        // Autofocus handling (IE compatable)
        Em.$(this).find('[autofocus]:not(:focus)').eq(0).focus();
      });
    }, function() {
      // noop
    });
  },

  saveContact: function(close, oneHolder, moreHolder, array, type, modal) {
    var model = this.get('controller.person'),
        more = model.get(moreHolder), self = this;

    model.enforceRemoteValidation();
    if (!Em.isEmpty(more.get('name')))
        model.get(array).addObject(more.get('content'));
    more.validate().then(function() {
      if (close === true) {
        model.set(oneHolder, model.get(array + '.length') ?
          model.get(array + '.firstObject') : self.newRecord(type));
        Em.$(modal).modal('hide');
      } else {
        model.set(moreHolder, self.newRecord(type));
      }
    }, function() {
      model.get(array).removeObject(more.get('content'));
    }).finally(function() {
      Em.run.next(function() {
        self.controller.send('toggleAlerts', 'focusIn');
        Em.$(modal).find('[autofocus]:not(:focus)').eq(0).focus();
      });
    });
  },

  saveNewCompany: function() {
    var work = this.controller.get('person.currentWork');
    if (work && work.get('createNewCompany'))
      return work.get('newCompany.content').save();
    return Em.RSVP.resolve();
  },

  actions: {
    editBlock: function(block, id) {
      var ctl = this.controller,
          model = this.currentModel;
      switch (block) {
        case 'personalInfo':
          ctl.get('person').setProperties({
            currentEmail: model.get('emails.firstObject') ||
              this.newRecord('personEmail'),
            currentMobile: model.get('mobiles.firstObject') ||
              this.newRecord('personMobile')
          });
          break;
        case 'work':
          if (!id)
            ctl.set('person.currentWork',
              this.newRecord('personWorkExperience', {
                endDate: new Date(3000, 0, 1)
              }));
          ctl.get('person.currentWork').setProperties({
            newCompany: this.newRecord('company'),
            newTitle: this.newRecord('title')
          });
          break;
        case 'education':
          if (!id)
            ctl.set('person.currentEdu',
              this.newRecord('personEducationExperience'));
          ctl.get('person.currentEdu').setProperties({
            newMajor: this.newRecord('major'),
            newSchool: this.newRecord('schoolAlias')
          });
          break;
        case 'project':
          if (!id)
            ctl.set('person.currentProject',
              this.newRecord('personProjectExperience'));
          ctl.get('person.currentProject').set(
            'newCompany', this.newRecord('companyAlias'));
          break;
        case 'extra':
          ctl.set('person.extra', Em.copy(model.get('extra')));
          break;
      }
      ctl.send('toggleAlerts', 'volatile');
    },

    updatePeople: function() {
      var self = this;
      this.send('loading', true);
      this.cleanupBeforeSave();
      this.saveNewCompany().then(function() {
        return self.currentModel.save().then(function() {
          self.send('postMessage', { type: 'success', info:
                    Em.I18n.t('people.message.update_people_success',
                    { name: self.currentModel.get('name') }) });
          // Renew the updates information
          if (self.get('controller.person.currentWork.newCompany.id'))
            self.set('updates.companies', true);
          self.controller.set('person.editing', undefined);
        });
      }).catch(SL.bumpRsvpError(function() {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('people.message.update_people_fail') });
      })).finally(function() {
        self.send('loading', false);
      });
    },

    deleteExperience: function(work) {
      this.deleteRecord(work,
        Em.I18n.t('people.message.del_experience_success'),
        Em.I18n.t('people.message.del_experience_fail'));
    },

    deleteEducation: function(edu) {
      this.deleteRecord(edu,
        Em.I18n.t('people.message.del_education_success'),
        Em.I18n.t('people.message.del_education_fail'));
    },

    deleteProject: function(prj) {
      this.deleteRecord(prj,
        Em.I18n.t('people.message.del_project_success'),
        Em.I18n.t('people.message.del_project_fail'));
    },

    addEmails: function() {
      this.addContact('currentEmail', 'moreEmail',
        'emails', 'personEmail', '#add-emails');
    },

    saveEmails: function(close) {
      this.saveContact(close, 'currentEmail', 'moreEmail',
        'emails', 'personEmail', '#add-emails');
    },

    addMobiles: function() {
      this.addContact('currentMobile', 'moreMobile',
        'mobiles', 'personMobile', '#add-mobiles');
    },

    saveMobiles: function(close) {
      this.saveContact(close, 'currentMobile', 'moreMobile',
        'mobiles', 'personMobile', '#add-mobiles');
    },

    profileParsed: function(profiles) {
      var profile = profiles.length && profiles[0];
      if (!profile) return;
      var model = this.currentModel, changes = [];
      if (!SL.validProfileName(model.get('eName')) && profile.e_name)
        changes.push(
          '<li data-key="eName"><b>' + Em.I18n.t('people.create_edit.e_name') +
          ':</b> <span class="parsed">' + profile.e_name + '</span></li>');
      if (!SL.validProfileName(model.get('cName')) && profile.c_name)
        changes.push(
          '<li data-key="cName"><b>' + Em.I18n.t('people.create_edit.c_name') +
          ':</b> <span class="parsed">' + profile.c_name + '</span></li>');
      if (!changes.length) return;
      var $modal = Em.$('#auto-update').modal();
      $modal.find('#update-hint').html(
        '<ul id="changes">' + changes.join() + '</ul>');
    },

    updateParsed: function() {
      var model = this.currentModel,
        $changes = Em.$('#auto-update #changes>li');
      $changes.each(function() {
        var $change = Em.$(this);
        model.set($change.data('key'), $change.find('.parsed').text());
      });
    }
  }

});


})();

(function() {

SL.PeopleHistoryRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
    SL.TabbedDynamicRouteMixin, SL.ProfileVcStatusRouteMixin,
    SL.TransactionRouteMixin, {
  tabName: '<i class="fa fa-history"></i>',
  afterModel: function(model, trans) {
    this.tryLoadVcStatus(model.get('ownerId') || model.get('owner'));
    if (model.get('isMasked')) {
      this.set('tabItem.title', this.tabName + ' ' +
        Em.I18n.t('people.tab.cv', { id: model.get('id') }));
    } else {
      this.set('tabItem.title', this.tabName + ' ' +
        (model.get('name') || Em.I18n.t('common.no_name')));
    }
    this._super.apply(this, arguments);
    var changes = model.get('changes');
    if (this.shouldReloadTransaction(trans.queryParams, changes))
      model.set('changes', null);
    if (!model.get('changes'))
      return this.fetchTransactions(model).then(
          function(changes) {
        model.set('changes', changes);
      });
  },
  fetchTransactions: function(model) {
    model = model || this.currentModel;
    return this.getTransactions(
      SL.apiPath + '/real_person/' +
        (model.get('ownerId') || model.get('owner.id')) + '/history',
      model && model.get('changes.lastObject.id'));
  },
  click: function(e) {
    console.log(e);
  },
  actions: {
    refresh: function() {
      this.currentModel && this.currentModel.set('changes', null)
          .get('owner').then(function(rp) {
        rp.resetVcStatus();
      });
      return true;
    }
  }
});


})();

(function() {

SL.PeopleIndexRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, SL.PersonCommentsRouteMixin, SL.FiltersRouteMixin,
  SL.PaginationRouteMixin, SL.VacancyAddRouteMixin, SL.FavoriteRouteMixin,
  SL.FolderAddRouteMixin, SL.SelectableProfileUpdatesRouteMixin,
  SL.ResumeRouteMixin, SL.ProfileVcStatusRouteMixin, SL.MappingAddRouteMixin,
  SL.SimilarProfileRouteMixin, {

  tabNameTranslation: 'people.tab.my_pool',
  logStayed: true,

  _getProfile: function() {
    return this.controller.get('selectedItem.defaultProfile');
  },

  _refreshProfile: function() {
    var self = this, model = this._super.apply(this, arguments);
    return model && model.then(function(p) {
      self.tryLoadVcStatus(p.get('ownerId'));
      self.tryLoadSimilarProfile(p.get('ownerId'));
      return p;
    });
  },

  filterModel: function(page, pageSize, transition) {
    var store = this.store,
        self = this,
        indexCtl = this.controllerFor('people.index');

    // Do nothing if default filter is not set
    if (!indexCtl.get('defaultFilterSet')) {
      indexCtl.set('defaultFilterSet', true);
      var companyId = Em.get(transition, 'state.queryParams.company');
      if (Em.isPresent(companyId)) {
        // Find company first
        return this.store.findRecord('company', companyId).then(function(c) {
          indexCtl.addCompanyFilter(c);
          return self.filterModel(page, pageSize, transition);  // continue
        });
      } else {
        indexCtl.addDefaultFilter();
      }
    }

    var withFilter = !!indexCtl.get('filters.length');
    var queryData = {
      options: {
        from: (page - 1) * pageSize,
        size: pageSize,
        sort: [{ id: 'desc' }]
      },
      logging: withFilter
    };
    indexCtl.applyFilter(queryData);

    return Em.$.ajax({
      method: 'POST',
      url: SL.apiPath + '/search/real_person',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(queryData)
    }).then(function(data) {
      var result = [];

      Em.run(function() {
        result.set('meta',
          { numResults: data.total, totalPages: data.num_pages });
        self.set('updates.people', false);
      });

      if (!data.total)
        transition.send('postMessage', { type: 'info', info:
          Em.I18n.t('people.message.no_result') });
      else
        self.loadPartial(result, data.hits.mapBy('data'), function(p) {
          return store.push(store.normalize('realPerson', p)).set(
            'source', withFilter ? 'query:' + data.log_id : 'traverse'
          ).resetVcStatus();
        });

      return result;
    });
  },

  exportCandidates: function() {
    var indexCtl = this.controllerFor('people.index');
    var queryData = {
      lang: Em.I18n.locale,
      options: {
        size: 100,
        sort: [{ id: 'desc' }]
      }
    };
    indexCtl.applyFilter(queryData);
    window.location.assign(
      SL.apiPath + '/export/real_person?' + Em.$.param(queryData));
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    this.controllerFor('people.edit').setProperties({
      isEmbedded: true,
      editFlag: false
    });
    if (!ctl.get('selectedItem'))
      ctl.set('selectedItem', model.get('firstObject'));
    else
      ctl.updateProfile();
  },

  reopenTab: function() {
    this._super();
    this.set('controller.defaultFilterSet', null);
  },

  actions: {
    exportCandidates: function() {
      this.exportCandidates();
    }
  }
});

function showAggreement() {
  Em.run.scheduleOnce('afterRender', function() {
    var $modal = Em.$('#download-terms-modal');
    $modal.modal().one('shown.bs.modal', function() {
      $modal.find('.slim-scroll').slimScroll({ height: 'auto' });
    });
  });
}

SL.CandidateIndexRoute = SL.PeopleIndexRoute.extend({
  controllerName: 'candidateIndex',
  tabNameTranslation: 'people.tab.search',

  renderTemplate: function() {
    this.render('people.index', {
      controller: 'candidateIndex'
    });
  },

  beforeModel: function(transition) {
    var user = this.get('session.account');
    if (user && transition.queryParams.agree) {
      var $modal = Em.$('#download-terms-modal');
      $modal.modal('hide');
      user.set('credential.agreedDownloadTerms', true).save();
      return this._super(transition);
    } else if (user && !user.get('credential.agreedDownloadTerms')) {
      // Need to show aggrement
      var hdInfos = transition.router.currentHandlerInfos;
      if (!hdInfos || hdInfos[hdInfos.length - 1].name === 'login') {
        // Special case for login / direct load
        this.router.one('didTransition', showAggreement);
        return this.transitionTo('index');
      }
      transition.abort();
      return showAggreement();
    }
    return this._super(transition);
  },

  _getProfile: function() {
    return this.controller.get('selectedItem');
  },

  filterModel: function(page, pageSize, transition) {
    var store = this.store,
        self = this,
        indexCtl = this.controllerFor('candidate.index');

    var queryData = {
      options: {
        from: (page - 1) * pageSize,
        size: pageSize,
        sort: [{ id: 'desc' }]
      }
    };
    indexCtl.applyFilter(queryData);

    return Em.$.ajax({
      method: 'POST',
      url: SL.apiPath + '/search/candidate',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(queryData)
    }).then(function(data) {
      var result = [];
      Em.run(function() {
        result.set('meta', {
          numResults: data.total,
          totalPages: data.num_pages,
          sizeExceed: data.results_size_exceeded
        });
      });

      if (!data.total)
        transition.send('postMessage', { type: 'info', info:
          Em.I18n.t('people.message.no_result') });
      else
        self.loadPartial(result, data.hits.mapBy('data'), function(p) {
          return store.push(store.normalize('person', p));
        });

      return result;
    });
  }

});


})();

(function() {

SL.PeopleMergeRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, SL.ConfirmationRouteMixin, SL.MissingRecordRouteMixin, {
  tabNameTranslation: 'people.merge.tab',

  queryParams: {
    to: { refreshModel: true },
    from: { refreshModel: true }
  },

  model: function(params, transition) {
    var store = this.store, self = this;
    /* jslint eqeq: true */
    if (this.currentModel &&
        Em.get(this.currentModel, 'mergeFrom.id') == params.from &&
        Em.get(this.currentModel, 'mergeTo.id') == params.to)
      return this.currentModel;
    /* jslint eqeq: false */
    return store.findRecord('person', params.to).then(function(mergeTo) {
      if (transition.queryParams.source)
        mergeTo.set('owner.source', transition.queryParams.source);
      if (params.from && params.from.indexOf('temp:') === 0) {
        var mergeFrom = store.peekRecord('person', params.from);
        if (mergeFrom)
          return { mergeTo: mergeTo, mergeFrom: mergeFrom };
        return new Em.RSVP.Promise(function(resolve, reject) {
          Em.$.ajax({
            url: SL.apiPath + '/person/' + params.from,
            dataType: 'json'
          }).then(function(cv) {
            mergeFrom = store.createRecord('person', {
              id: params.from });
            store.populateRecord(mergeFrom, cv);
            resolve({ mergeTo: mergeTo, mergeFrom: mergeFrom });
          }, function(err) {
            reject(err);
          });
        });
      } else if (params.from === 'parsed') {
        var ctl = self.controllerFor('people.parse'),
            cv = ctl.get('cv.content');
        if (!cv) throw new Error('[404] no passed record');
        return { mergeTo: mergeTo, mergeFrom: cv };
      } else {
        return store.findRecord('person', params.from).then(
            function(mergeFrom) {
          return { mergeTo: mergeTo, mergeFrom: mergeFrom };
        });
      }
    });
  },

  setupController: function(ctl, model) {
    ctl.setProperties(model);
    ctl.setProperties({
      mergePlan: {
        workExperiences: [],
        educationExperiences: [],
        projectExperiences: [],
        files: []
      },
      selectionChanged: false
    });
  },

  preventUnload: function() {
    return this.controller.get('selectionChanged');
  },

  proceedTransition: function() {
    this.controller.set('selectionChanged', false);
  },

  actions: {
    doMerge: function() {
      var ctl = this.controller, mergeFrom = ctl.get('mergeFrom'),
          mergeTo = ctl.get('mergeTo'), plans = ctl.get('mergePlan');

      // Populate function related extra values
      var extra = mergeFrom.get('extra') || {}, extraToMerge = {},
          spec = Em.get(SL.parameters, 'cvExtra') || {};
      plans.workExperiences.forEach(function(p) {
        var parts = p.split(':');
        if (parts[0] === 'source') {
          var funcId = parseInt(ctl.get('workExperiencesPairs').objectAt(
            parseInt(parts[1])).source.get('function.id'));
          if (!funcId) return;
          Object.keys(extra).forEach(function(k) {
            if (spec[k] && spec[k].for_functions &&
                spec[k].for_functions.indexOf(funcId) > -1)
              extraToMerge[k] = extra[k];
          });
        }
      });
      mergeTo.set('extra', Em.$.extend({}, mergeTo.get('extra'), extraToMerge));

      function getPart(p) {
        var parts = p.split(':');
        return ctl.get(key + 'Pairs').objectAt(parseInt(parts[1]))[parts[0]];
      }
      for (var key in plans) {
        var plan = plans[key];
        if (Em.isArray(plan)) {
          mergeTo.set(key, plan.map(getPart));
        } else {
          if (plan === 'source')
            mergeTo.set(key, mergeFrom.get(key));
        }
      }
      var self = this;
      this.send('loading', true);

      var pendingObjs = [];
      // Find unsaved company
      [mergeTo.get('workExperiences'),
        mergeTo.get('projectExperiences')].invoke('forEach', function(exp) {
        var owner = exp.get('company') && exp.get('company.owner');
        if (owner && owner.get('isNew')) {
          owner.set('name', exp.get('company'));
          pendingObjs.addObject(owner);
        }
      });
      Em.RSVP.all(pendingObjs.invoke('save')).then(function() {
        return mergeTo.save();
      }).then(function() {
        ctl.set('selectionChanged', false);
        self.closeTab({ route: 'people.edit', model: mergeTo });
        Em.run.next(function() {
          self.send('postMessage', { type: 'success', info:
            Em.I18n.t('people.message.merge_people_success',
            { name: mergeTo.get('name') }) });
        });
      }).catch(SL.bumpRsvpError(function() {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('people.message.merge_people_fail') });
      })).finally(function() {
        self.send('loading', false);
      });
    }
  }

});


})();

(function() {

SL.PeopleNewRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, SL.NewRecordRouteMixin, SL.FilesRouteMixin, {
  tabNameTranslation: 'people.tab.create',
  queryParams: {
    owner: {
      refreshModel: true
    }
  },

  beforeModel: function(transition) {
    this._super.apply(this, arguments);
    var owner = transition.queryParams.owner || null;
    var ctl = this.controllerFor('peopleNew');
    if (this.currentModel && !this.currentModel.id) {
      // We are in the middle of creating a people
      if (ctl.get('isOwned') !== owner) {
        if (!window.confirm(
          Em.I18n.t('people.create_edit.confirm_to_recreate')))
          return transition.abort();
      }
    }
    ctl.set('isOwned', owner);
    if (owner) {
      // If user's editing his own profile
      this.set('tabItem.titleTranslation', 'people.tab.create_my_profile');
    }
  },

  model: function(param) {
    if (!this.currentModel || this.currentModel.id)
      return this.newPersonModel(param);
    return this.currentModel;
  },

  setupController: function(ctl, model) {
    if (ctl.get('model.content') !== model) {
      this._super(ctl, model);
      ctl.set('profileType', 'resume');
      ctl.get('model').setProperties({
        currentEmail: this.newRecord('personEmail'),
        currentMobile: this.newRecord('personMobile'),
        'currentWork.newCompany': this.newRecord('company'),
        'currentWork.newTitle': this.newRecord('title')
      });
      ctl.send('toggleAlerts', 'volatile');
    }
  },

  actions: {
    createPeople: function() {
      var self = this, isOwned = this.controller.get('isOwned');
      this.send('loading', true);
      this.cleanUpBeforeSave();
      this.saveNewCompany().then(function() {
        return self.currentModel.save({
          adapterOptions: { isOwned: isOwned }
        });
      }).then(function(model) {
        model.set('owner.source', 'creation');
        self.closeTab({ route: 'people.edit', model: model });
        // Renew the updates information
        self.set('updates.people', true);
        if (self.get('controller.currentWork.newCompany.id'))
          self.set('updates.companies', true);

        if (isOwned) {
          var user = self.get('session.account');
          // Reload the user to get profile updated and the real person
          user.reload();
          self.store.findRecord('realPerson',
            model.get('owner.id'), { reload: true });
        }
        Em.run.next(function() {
          self.send('postMessage', { type: 'success', info:
            Em.I18n.t('people.message.create_people_success',
            { name: model.get('name') }) });
        });
      }).catch(SL.bumpRsvpError(function() {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('people.message.create_people_fail') });
      })).finally(function() {
        self.send('loading', false);
      });
    },
    cancelEdit: function() {
      this.currentModel = null;
      this.refresh();
    }
  },

  cleanUpBeforeSave: function() {
    var person = this.currentModel;
    
    if (person.get('employmentStatus') === 10) {
      // Should not have work experience if the people is created unemployed
      person.get('workExperiences').
        removeObject(person.get('workExperiences.firstObject'));
    }
  },

  saveNewCompany: function() {
    var work = this.controller.get('model.currentWork');
    if (work && work.get('createNewCompany'))
      return work.get('newCompany.content').save();
    return Em.RSVP.resolve();
  },

  newPersonModel: function(param) {
    var person = this.newRecord('person', {
      gender: 2
    });

    // Work Experience
    person.get('workExperiences').addObject(
      this.newRecord('personWorkExperience', {
        endDate: new Date(3000, 0, 1)
      }));

    if (param.owner) {
      if (this.get('session.account.isStaff')) {
        // Hide staff profile by default
        person.set('isPrivate', 1);
        // Staff creating his / her own profile, should be shared to staff pool
        person.get('pools').addObject(this.newRecord('talentPerson', {
          pool: this.newRecord('talentPool', { type: 1 })
        }));
      }
    }
    return person;
  }

});


})();

(function() {

SL.PeopleParseRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, SL.NewRecordRouteMixin, {
  tabNameTranslation: 'import_cv.upload_resume',
  reopenTab: function() {
    this.restart();
    this._super.apply(this, arguments);
  },
  restart: function() {
    this.controller.setProperties({
      step: '', updateTo: null
    });
  },
  completeCV: function(data, updateCv) {
    var store = this.store, ctl = this.controller;
    if (Em.isPresent(Em.get(data, 'mobiles')))
      // Set for one mobile only
      Em.set(data, 'mobiles', [Em.get(data, 'mobiles')[0]]);
    var cv = store.populateRecord(store.createRecord('person'), data);
    cv.setProperties({
      owner: updateCv && updateCv.get('owner'),
      files: [store.push(store.normalize(
        'personFile', this.controller.get('uploaded.files.firstObject')))]
    });
    var lastWork = cv.get('workExperiences').slice().sort(
        SL.sortExperience).get('firstObject');
    ctl.get('cv').setProperties({
      content: cv,
      currentEmail: cv.get('emails.firstObject') ||
        this.newRecord('personEmail', {
          name: updateCv && updateCv.get('emails.firstObject.name')
        }),
      currentMobile: cv.get('mobiles.firstObject') ||
        this.newRecord('personMobile', {
          name: updateCv && updateCv.get('mobiles.firstObject.name')
        }),
      currentWork: lastWork
    });

    if (!lastWork) {
      /* jshint noempty:false */
      // No action
    } else if (updateCv) {
      // Populate missing values for last work from updateTo
      var w = updateCv && updateCv.get('workExperiences').slice().sort(
        SL.sortExperience).get('firstObject');
      if (Em.isBlank(lastWork.get('function.content')))
        lastWork.set('function', w && w.get('function.content'));
      if (Em.isBlank(lastWork.get('totalSalary')))
        lastWork.set('totalSalary', w && w.get('totalSalary'));
    } else {
      lastWork.set('function', null);
    }
    ctl.send('toggleAlerts', 'volatile');
    Em.run.next(this, function() {
      ctl.setProperties({
        step: 'setup', aiParsing: null
      });
    });
  },
  doAiParse: function() {
    var ctl = this.controller, self = this;
    var file = ctl.get('uploaded.files.firstObject');
    Em.run.next(ctl, 'set', 'aiParsing', true);
    this.aiQuery = Em.$.get(
      SL.apiPath + '/upload_file/ai_parse/' + file.id
    ).then(function(data) {
      self.parseCVData(data);
      Em.run.next(ctl, 'set', 'aiParsing', 'done');
    }, function() {
      self.send('postMessage', { type: 'error', info:
        Em.I18n.t('import_cv.ai_parse_failed') });
      Em.run.next(ctl, 'set', 'aiParsing', 'error');
    });
  },
  parseCVData: function(data) {
    var updateTo = this.controller.get('updateTo');
    data = data || this.controller.get('uploaded.profiles.firstObject');
    if (updateTo) {
      var self = this;
      this.store.findRecord(
        'person', updateTo.get('defaultProfile.id')
      ).then(function(updateCv) {
        self.completeCV(data, updateCv);
      });
    } else {
      this.completeCV(data);
    }
  },
  actions: {
    completeCV: function(files) {
      this.parseCVData();
      if (SL.parameters.features.indexOf('prefer_ai') > -1)
        this.doAiParse();
    },
    doImport: function(updateTo) {
      var ctl = this.controller, cv = ctl.get('cv.content'), self = this;
      if (updateTo)
        return this.closeTab({
          route: 'people.merge',
          options: {
            queryParams: {
              to: updateTo.get('defaultProfile.id'),
              from: 'parsed',
              source: 'parse-cv'
            }
          }
        });
      cv.save({
        adapterOptions: { checkValidity: 1 }
      }).then(function(p) {
        p.set('owner.source', 'parse-cv');
        ctl.set('step', 'done');
        self.send('loading', false);
      });
    },
    closeAndEdit: function(profile) {
      this.closeTab({ route: 'people.edit', model: profile });
    },
    restart: function() {
      this.restart();
    },
    loadDup: function(type, id) {
      var ctl = this.controller;
      this.store.find('realPerson', id).then(function(rp) {
        if (ctl.get(type + 'SamePerson') !== null)  // only if loading
          ctl.set(type + 'SamePerson', rp.get('defaultProfile'));
      });
    },
    doAiParse: function() {
      this.doAiParse();
    }
  }
});


})();

(function() {

SL.PeopleProfileRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedDynamicRouteMixin, SL.PersonCommentsRouteMixin,
  SL.FavoriteRouteMixin, SL.VacancyAddRouteMixin, SL.FolderAddRouteMixin,
  SL.MissingRecordRouteMixin,
  SL.ResumeRouteMixin, SL.ProfileVcStatusRouteMixin, SL.MappingAddRouteMixin,
  SL.SimilarProfileRouteMixin, SL.ProfileUpdatesRouteMixin, {
  tabName: '<i class="i i-user3"></i>',
  controllerName: 'peopleEdit',
  logStayed: true,

  afterModel: function(model, transition) {
    if (model.get('isMasked')) {
      this.set('tabItem.title', this.tabName + ' ' +
        Em.I18n.t('people.tab.cv', { id: model.get('id') }));
      if (model.get('vc'))
        // Ensure the vc is fully loaded
        this.store.findRecord('vacancyCandidate', model.get('vc.id'), {
          adapterOptions: { extra: 'from_bounty' }
        });
    } else {
      this.set('tabItem.title', this.tabName + ' ' +
        (model.get('name') || Em.I18n.t('common.no_name')));
    }
    if (!this.get('tabItem.isOpen')) {
      // If tab is just opened, set openSource as possible,
      // which is used as source if profile is accessed within the tab.
      var source = transition.queryParams.source ||
        model.get('owner.source');
      if (source)
        model.set('owner.openSource', source);
    }

    var _super = this._super.apply(this, arguments), self = this;
    function tryLoad() {
      self.tryLoadVcStatus(model.get('ownerId'));
      self.tryLoadSimilarProfile(model.get('ownerId'));
    }
    if (_super.then)
      return _super.then(tryLoad);
    return tryLoad();
  },

  renderTemplate: function() {
    this.render('people.edit');
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    if (ctl.vc)
      model.set('vc', this.store.findRecord('vacancyCandidate', ctl.vc));
    ctl.setProperties({
      person: model,
      isEmbedded: false,
      editFlag: false
    });
  },

  replaceMaskedTab: function(masked, unmasked) {
    var maskedTab = this.get('tabs.' + masked.id);
    if (!maskedTab) return;
    Em.set(maskedTab, 'goto.model', unmasked);
    Em.set(maskedTab, 'title', this.tabName + ' ' + unmasked.get('name'));
    this.set('tabs.' + masked.id, undefined).
      set('tabs.' + unmasked.id, maskedTab);

    var tabButton = this.get('mainTabs.model').findBy('content', maskedTab);
    if (tabButton) {
      tabButton.notifyPropertyChange('name');
      if (tabButton.get('active'))
        // Reload tab with unmasked content if attive
        this.transitionTo('people.profile', unmasked);
    }
  }
});


})();

(function() {

function ajaxURL(path) {
  var traceback = this.controller.get('traceback');
  path = SL.apiPath + '/orgchart/' + path + '/';
  return function(node) {
    return path + node.id + '?' + Em.$.param({
      traceback: traceback,
      lang: Em.I18n.locale
    });
  };
}

SL.PeopleReportingRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
    SL.TabbedDynamicRouteMixin, {
  tabName: '<i class="fa fa-sitemap"></i>',
  queryParams: {
    traceback: { refreshModel: true }
  },
  afterModel: function(model, transition) {
    if (model.get('isMasked')) {
      this.set('tabItem.title', this.tabName + ' ' +
        Em.I18n.t('people.tab.cv', { id: model.get('id') }));
    } else {
      this.set('tabItem.title', this.tabName + ' ' +
        (model.get('name') || Em.I18n.t('common.no_name')));
    }
    this._super.apply(this, arguments);
    if (!model.get('reporting'))
      return this.fetchNode(model, transition.queryParams);
  },
  fetchNode: function(model, params) {
    var ownerId = model.get('ownerId') || model.get('owner.id'),
        url = SL.apiPath + '/orgchart/node/' + ownerId;
    return new Em.RSVP.Promise(function(resolve, reject) {
      var traceback = params.traceback;
      if (traceback === undefined) traceback = '240';  // default: 20yrs
      Em.$.get(url, {
        traceback: traceback, lang: Em.I18n.locale
      }, function(node) {
        Em.run(model, 'set', 'reporting', node || {
          id: ownerId,
          title: model.get('name'),
          className: 'leaved',
          description: '<i>' + Em.I18n.t('people.profile.unemployed') + '</i>',
          relationship: '000'
        });
        resolve(model);
      }).fail(function(xhr) {
        Em.run.next(reject, xhr);
      });
    });
  },
  setupController: function(ctl, model) {
    this._super(ctl, model);
    var traceback = ctl.get('traceback');
    ctl.setProperties({
      tracebackDate: moment().subtract(traceback || '240', 'M'),
      enableTraceback: traceback !== ''
    });
  },
  resetPosition: function() {
    var $chart = Em.$('.orgchart'),
        $node = $chart.find('#' + this.currentModel.get('reporting.id')),
        $canvas = Em.$('#orgchart .canvas');

    if (!$node.is(':visible')) {
      if (window.confirm(Em.I18n.t('people.reporting.locate_confirm', {
        person: this.currentModel.get('name')
      }))) this.refresh();
      return;
    }

    $chart.css('transform', '');
    var newX = ($canvas.width() - $node.width()) / 2 - $node.position().left;
    var newY = (300 - $node.height()) / 2 - $node.position().top;
    $chart.css('transform', 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')');
    if (!$node.hasClass('active'))
      $node.trigger('click');
  },
  refresh: function() {
    this.currentModel && this.currentModel.set('reporting', null);
    this._super();
  },
  actions: {
    didTransition: function() {
      Em.run.scheduleOnce('afterRender', this, function() {
        var reporting = this.currentModel.reporting, store = this.store;
        if (!reporting) return;
        Em.$('#orgchart .canvas .orgchart').remove();
        Em.$('#orgchart .canvas').orgchart({
          data: this.currentModel.reporting,
          nodeTitle: 'title',
          nodeContent: 'description',
          pan: true,
          ajaxURL: {
            parent: ajaxURL.call(this, 'parent'),
            families: ajaxURL.call(this, 'families'),
            siblings: ajaxURL.call(this, 'siblings'),
            children: ajaxURL.call(this, 'children')
          },
          createNode: function($node, data) {
            if (data.work) {
              var work = store.push(store.normalize(
                'personWorkExperience', data.work));
              $node.find('.content').html([
                work.get('title.name'), work.get('company.name')
              ].compact().join('<br>'));
              if (work.get('endDate') < new Date(3000, 0, 1))
                $node.find('.content').prepend('(~' +
                  SL.helper.formatDate(work.get('endDate'), 'duration') +
                  ') ');
              if (data.id !== reporting.id)
                $node.append(
                  '<a class="hover-link" href="#/real-person/' + data.id +
                  '"><i class="fa fa-external-link"></i></a>');
            } else if (data.mappingEntry) {
              var entry = store.push(store.normalize(
                    'mappingEntry', data.mappingEntry)),
                  folder = store.push(store.normalize(
                    'folder', data.folder));
              folder.get('owner').then(function(u) {
                return u.get('realPerson');
              }).then(function() {
                var $info = Em.$('<span class="hover-link">' +
                  '<i class="fa fa-question-circle"></i></span>');
                $node.append($info);
                $info.tooltip({
                  title: Em.I18n.t('people.reporting.mapped_by', {
                    user: folder.get('owner.name'),
                    task: folder.get('name')
                  }), container: '#orgchart' });
              });
              $node.find('.title').html(
                Em.I18n.t('people.reporting.unmapped'));
              return $node.find('.content').html(
                SL.helper.mappingInfo(entry).toString());
            }
          }
        });
        Em.$('.node#' + reporting.id + ' .topEdge').trigger('click');
      });
      return this._super();
    },
    resetPosition: function() {
      this.resetPosition();
    },
    refresh: function() {
      this.refresh();
    }
  }
});


})();

(function() {

SL.PeopleRoute = Em.Route.extend(SL.ProfileDownloadRouteMixin, {
  loadProfile: function(masked, profile) {
    var indexCtl = this.controllerFor('candidateIndex'),
        unmasked = this.store.push(this.store.normalize('person', profile));
    // Refresh owner
    this.store.findRecord(
      'realPerson', unmasked.get('owner.id'), { reload: true });
    // update controllers and routes
    if (indexCtl.get('model')) {
      var indexInList = indexCtl.get('model').indexOf(masked);
      if (indexInList > -1)
        indexCtl.get('model').replace(indexInList, 1, [unmasked]);
    }
    if (indexCtl.get('selectedItem') === masked)
      indexCtl.set('selectedItem', unmasked);
    var profileRoute = this.container.lookup('route:people.profile');
    profileRoute.replaceMaskedTab(masked, unmasked);
  }
});

SL.CandidateRoute = SL.PeopleRoute.extend();

// This route is used to redirect for the default profile of a given
// real person. May need to make it a visitable page in the furture.
SL.RealPersonRoute = Em.Route.extend(SL.MissingRecordRouteMixin, {
  afterModel: function(model) {
    if (model.get('defaultProfile'))
      this.replaceWith('people.profile', model.get('defaultProfile'));
    return Em.RSVP.reject('No default profile found');
  }
});

SL.MyResumeRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin, {
  beforeModel: function(transition) {
    this._super.apply(this, arguments);
    if (this.get('session.isAuthenticated')) {
      var cv = this.get('session.account.defaultProfile');
      if (cv)
        return this.transitionTo('people.edit', cv);
      this.transitionTo(
        'people.new', { queryParams: { owner: true }});
    }
  }
});


})();

(function() {

SL.RegisterRoute = Em.Route.extend(SL.NewRecordRouteMixin, {
  model: function(params) {
    var self = this;
    return this._super(params).then(function(model) {
      return model.set('user', self.newRecord('user'));
    }).catch(function() {
      // noop
    });
  },
  afterModel: function(model, transition) {
    if (!model) {
      transition.send('loading', false);
      transition.abort();
      return new Em.RSVP.Promise(function(resolve) {
        Em.run.later(function() {
          window.alert('注册链接已失效，请重新提交注册申请');
          var registerUrl = SL.parameters.mobileSite + '/#/register.html';
          window.location.assign(registerUrl);
          resolve();
        }, 200);
      });
    }
    if (model.get('registerType') === 'active') {
      window.alert('您已完成注册，请直接登录！');
      this.transitionTo('login');
      return;
    }
  },
  setupController: function(ctl, model) {
    if (ctl.get('model.content') !== model) {
      this._super(ctl, model);
      ctl.send('toggleAlerts', 'volatile');
    }
  },

  bindWechat: Em.computed.readOnly('parameters.register.bindWechat'),

  checkBind: function() {
    if (this.get('controller.step') !== 'scanqr') return;
    var self = this, pollingRound;
    Em.$.ajax({
      url: SL.apiPath + '/wechat/qrcheck?action=create_account',
      beforeSend: function(xhr, settings) {
        xhr.isPolling = true;
        Em.run(self, function() {
          pollingRound = settings.url.split('_=')[1];
          this.set('pollingRound', pollingRound);
        });
      }
    }).done(function(result) {
      if (self.get('pollingRound') !== pollingRound)
        return;
      if (result === 'timeout')
        return self.checkBind();
      if (result === 'scanned')
        self.set('controller.step', 'done');
    });
  },

  saveSetup: function() {
    var self = this;
    this.send('loading', true);
    this.currentModel.save().then(function() {
      self.set('controller.step', 'done');
    }).finally(function() {
      self.send('loading', false);
    });
  },

  actions: {
    setupNext: function() {
      if (!this.get('bindWechat')) {
        this.saveSetup();
        return;
      }
      var self = this;
      Em.$.ajax({
        url: SL.apiPath + '/wechat/subscribe_qr?action=create_account',
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          uuid: this.get('currentModel.id'),
          password: this.get('currentModel.user.password')
        })
      }).done(function() {
        Em.run(self, function() {
          this.controller.set('step', 'scanqr');
          this.checkBind();
        });
      });
    },
    goLogin: function() {
      var self = this;
      this.send('loading', true);
      Em.RSVP.all([
        this.get('session').restore(),
        SL.refreshParam()
      ]).then(function() {
        self.transitionTo('index');
      });
    }
  }
});


})();

(function() {

var fetchData = SL.fetchReportData = function(config) {
  // Update filters
  var filters = Em.get(config, 'filters') || [];
  var normalizedFilters = {};
  filters.forEach(function(f) {
    var field = Em.get(f, 'field');
    switch (Em.get(f, 'type')) {
      case 'button_group':
        if (Em.get(f, 'field_as_value'))
          normalizedFilters[Em.get(f, 'value')] = field;
        else
          normalizedFilters[field] = Em.get(f, 'value');
        break;
      case 'team_member':
      case 'account_user':
        var v = Em.get(f, 'value.id');
        if (v)
          normalizedFilters[field] = v;
        break;
      case 'date_range':
        var start = Em.get(f, 'startValue'),
            end = Em.get(f, 'endValue'), cond = [];
        if (start || end) normalizedFilters[field] = cond;
        if (start) cond.push({ op: 'ge', value: start });
        if (end) cond.push({
          op: 'lt',
          value: moment(end).add(1, 'days').format('YYYY-MM-DD')
        });
        break;
    }
  });
  var dataQuery = {};
  config.get('queries').forEach(function(q) {
    dataQuery[q] = new Em.RSVP.Promise(function(resolve, reject) {
      Em.$.ajax({
        url: SL.apiPath + '/report/' + config.id + '/data/' + q,
        method: 'GET',
        data: { filters: normalizedFilters }
      }).then(function(data) {
        Em.run(null, resolve, data);
      }, function(xhr) {
        Em.run.next(reject, xhr);
      });
    });
  });
  return Em.RSVP.hash(dataQuery).then(function(d) {
    config.get('widgets').forEach(function(w) {
      Em.set(w, 'data', Em.get(d, w.query));
      Em.set(w, 'filters', normalizedFilters);
    });
    config.setProperties({
      filterPending: false,
      filterActive: !!Object.keys(normalizedFilters).length
    });
    return config;
  });
};

SL.ReportsRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, {
  tabNameTranslation: 'statistics.tab.label',
  queryParams: {
    report: { refreshModel: true }
  },

  model: function(params) {
    var reportId = params.report;
    if (!reportId) return Em.RSVP.resolve(null);

    if (this.currentModel && /* jslint eqeq: true */
        this.currentModel.id == reportId) {
      var config = this.currentModel;
      if (config.get('filterPending'))
        return fetchData(config);
      return config;
    }

    return this.store.find('report', reportId).then(function(config) {
      return config.get('filterFirst') ? config : fetchData(config);
    });

  },

  setupController: function(ctl, model) {
    this._super.apply(this, arguments);
    ctl.set('selectedReport', model);
    this.resetFilter();
  },
  resetController: function(ctl, isExiting, trans) {
    this._super(ctl, isExiting, trans);
    if (isExiting)
      ctl.set('report', undefined);
  },
  resetFilter: function() {
    var config = this.currentModel;
    if (!config) return;
    config.resetFilter();
  },

  actions: {
    error:  function(err, transition) {
      transition.send('loading', false);
      transition.send('postMessage', {
        type: 'error', info: Em.I18n.t('statistics.failed') });
    },
    applyFilter: function() {
      this.currentModel.set('filterPending', true);
      this.refresh();
    },
    resetFilter: function() {
      this.resetFilter();
      this.refresh();
    },
    updateQuery: function(widget, params) {
      var url = SL.apiPath + '/report/' + this.controller.report + '/data/' +
        widget.query;
      var self = this;
      this.send('loading', true);
      var query = {
        page: params.page,
        batch: widget.data.batch_id
      };
      if (!query.batch)
        query.filters = widget.filters;

      Em.$.ajax({
        url: url,
        method: 'GET',
        data: query
      }).then(function(data) {
        Em.run(function() {
          Em.set(data, 'page', params.page);
          Em.set(widget, 'data', data);
        });
      }).always(function() {
        self.send('loading', false);
      });
    }
  }

});


})();

(function() {

SL.ResumeRoute = Em.Route.extend(SL.ProfileDownloadRouteMixin,
    SL.MissingRecordRouteMixin, {

  beforeModel: function(transition) {
    this.lastTransition = transition;
    return this._super.apply(this, this.arguments);
  },

  model: function(params) {
    var checkVc = Em.RSVP.resolve(),
        vc = { price: Number.MAX_SAFE_INTEGER };
    var store = this.store;

    if (params.vc) {
      checkVc = new Em.RSVP.Promise(function(resolve, reject) {
        Em.$.ajax({
          url: SL.apiPath + '/resume/' + params.id + '/candidate/' + params.vc
        }).then(function(p) {
          // Not masked (pool profile)
          if (!Em.get(p, 'is_masked'))
            return resolve();

          Em.run(function() {
            vc = store.push(store.normalize(
              'vacancyCandidate', Em.get(p, 'vc')));
          });
          resolve();
        }, function(xhr) {
          Em.run.next(reject, xhr);
        });
      });
    }
    return checkVc.then(function() {
      var query = { vc_id: params.vc };
      if (SL.parameters.isWechatBrowser)
        query.extra = ['share'];
      return Em.$.ajax({
        url: SL.apiPath + '/resume/' + params.id,
        data: query,
        traditional: true
      }).then(function(data) {
        return Em.run(function() {
          var profile = store.push(store.normalize('person', data));
          profile.setProperties({
            resumeId: params.id,
            vc: vc
          });
          var sm = profile.get('shareMessages');
          if (sm) {
            var grpMsg = sm.wx_group && sm.wx_group.split('\n') || ['', ''];
            wx.setShare(
              grpMsg[0], grpMsg.slice(1).join('\n'), sm.wx_activity,
              profile.get('photo.thumb.m') || 'images/no-avatar.png');
          }
          return profile;
        });
      });
    });
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    ctl.setProperties({
      person: model,
      isEmbedded: false,
      editFlag: false
    });
  },

  renderTemplate: function() {
    this.render('people.edit', {
      controller: this.controller
    });
  },

  loadProfile: function(masked, profile) {
    this.refresh();
  },

  downloadProfile: function(masked, options) {
    options = options || {};
    options.resume = masked.resumeId;
    return this._super(masked, options);
  },

  actions: {
    promptDownload: function(profile, cost) {
      var session = this.get('session');
      if (cost > 0 && !session.get('account.isStaff')) {
        // cost required but user is not with valid credit account
        this.send('postMessage', { type: 'error', info:
          Em.I18n.t('people.resume.login_required', {
            serviceNumber: SL.parameters.register.serviceNumber,
          }) });
        if (session.get('isAuthenticated'))
          session.clear();
        session.set('attemptedTransition', this.lastTransition);
        return this.transitionTo('login');
      }
      this._super(profile, cost);
    },
    promptSend: function(person) {
      Em.run(person, 'set', 'shareUrl', location.href);
      Em.$('#send-profile-modal').modal();
    },
    didTransition: function() {
      var model = this.currentModel;
      if (model && model.get('isMasked') && model.get('vc.price') <= 0 &&
          model.get('vc.fromBounty.acceptRule'))
        // VC is free but accept for bounty pending, trigger accept pop
        Em.run.scheduleOnce('afterRender', this, 'downloadProfile', model);
      return true;
    }
  }

});


})();

(function() {

SL.SettingsPrivacyRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, SL.ConfirmationRouteMixin, {
  tabNameTranslation: 'settings.tab.privacy',

  model: function() {
    return this.get('session.account.ownedProfile');
  },

  actions: {
    savePrivacySetting: function(forced) {
      var self = this, model = this.currentModel;
      if (model.get('isDirty') && model.get('isPrivate') > 0 && !forced)
        return Em.$('#privacy-confirm').modal();
      this.send('loading', true);
      this.currentModel.save().then(function() {
        self.closeTab();
        Em.run.next(function() {
          self.send('postMessage', { type: 'success', info:
            Em.I18n.t('settings.privacy.saved') });
        });
      }).finally(function() {
        self.send('loading', false);
      });
    }
  }
});


})();

(function() {

SL.SettingsSocialRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, {
  tabNameTranslation: 'settings.tab.social',

  checkWecomBind: function(target, cb) {
    var $modal = Em.$('#bind-wecom.modal.in');
    if (!$modal.length || !$modal.is(':visible')) return;
    var self = this, pollingRound;
    Em.$.ajax({
      url: SL.apiPath + '/wechat/qrcheck?action=bindwecom',
      beforeSend: function(xhr, settings) {
        xhr.isPolling = true;
        Em.run(self, function() {
          pollingRound = settings.url.split('_=')[1];
          this.set('pollingRound', pollingRound);
        });
      }
    }).done(function(result) {
      if (self.get('pollingRound') !== pollingRound)
        return;
      if (result === 'timeout')
        return self.checkWecomBind(target, cb);
      if (result === 'scanned') {
        self.get('session').restore().finally(function() {
          if (self.get('session.account.wecomToken')) {
            self.send('postMessage', {
              type: 'success', info: Em.I18n.t('wechat.bind.done') });
          } else {
            self.send('postMessage', {
              type: 'error', info: Em.I18n.t('wecom.bind.failed') });
          }
          $modal.modal('hide');
          if (cb) cb.call(target, 'success');
        });
      }
    });
  },

  actions: {
    connectLinkedin: function() {
      var domain = location.host + location.pathname.slice(0, -1);
      var url = SL.apiPath + '/linkedin/signin?redirect_domain=' +
        encodeURIComponent(domain) + '&redirect_path=' +
        encodeURIComponent('refresh_auth.html?last_action=connect_linkedin') +
        '&unauthorized_path=' + encodeURIComponent('refresh_auth.html');
      if (!this.get('session.account.isStaff'))
        url += '&scope=' + encodeURIComponent('r_basicprofile r_emailaddress');
      open(url, '_blank', 'height=691,width=400');
    },
    disconnectLinkedin: function() {
      this.get('session.account').set('linkedinToken', null).save();
    },
    connectWeibo: function() {
      var domain = location.hostname;
      if (location.port) domain += ':' + location.port;
      var url = SL.apiPath + '/weibo/signin?redirect_domain=' +
        encodeURIComponent(domain) + '&redirect_path=' +
        encodeURIComponent('refresh_auth.html?last_action=connect_weibo') +
        '&unauthorized_path=' + encodeURIComponent('refresh_auth.html');
      open(url, '_blank', 'height=500,width=650');
    },
    disconnectWeibo: function() {
      this.get('session.account').set('weiboToken', null).save();
    },
    connectWecom: function() {
      var self = this;
      var $modal = Em.$('#bind-wecom.modal');
      $modal.find('.qr-wrapper').html(
        '<img src="api/wecom/subscribe_qr?id=' +
        (new Date()).valueOf() + '" width="250" height="250" />');
      $modal.one('shown.bs.modal', function() {
        self.checkWecomBind();
      });
      $modal.modal();
    },
    disconnectWecom: function() {
      this.get('session.account').set('wecomToken', null).save();
    }
  }
});


})();

(function() {

SL.TlImportRoute = Em.Route.extend(SimpleAuth.AuthenticatedRouteMixin,
  SL.TabbedRouteMixin, {
  tabNameTranslation: 'import_cv.label',
  queryParams: {
    action: { refreshModel: true },
    data: { refreshModel: true }
  },
  model: function(param) {
    if (this.currentModel && this.currentModel.param.data === param.data)
      return this.currentModel;
    var store = this.store;
    return new Em.RSVP.Promise(function(resolve, reject) {
      Em.$.ajax({
        url: SL.apiPath + '/talink/import',
        data: {
          action: param.action,
          data: param.data
        }
      }).then(function(data) {
        Em.run(function() {
          if (Em.get(data, 'function'))
            Em.set(data, 'function', store.push(
              store.normalize('function', Em.get(data, 'function'))));
          Em.set(data, 'param', param);
          if (!Em.get(data, 'extra'))
            Em.set(data, 'extra', {});
          resolve(data);
        });
      }, function(xhr) {
        if (xhr.status === 500)
          Em.run(null, resolve, {
            param: param,
            fetchError: true
          });
        else
          Em.run.next(null, reject, xhr);
      });
    });
  },
  setupController: function(ctl, model) {
    var oldModel = ctl.get('model');
    this._super(ctl, model);
    if (model && oldModel !== model)
      ctl.send('toggleAlerts', 'volatile');
  },
  actions: {
    doImport: function(action) {
      var self = this;
      var func = Em.get(this.currentModel, 'function');
      if (func) {
        delete this.currentModel['function'];
        Em.set(this.currentModel, 'function_id', func.id);
      }
      Em.$.ajax({
        method: 'POST',
        url: SL.apiPath + '/talink/import?action=' + action,
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(this.currentModel)
      }).then(function(data) {
        if (action === 'update')
          return self.closeTab({
            route: 'people.merge',
            options: {
              queryParams: {
                to: Em.get(data, 'cv_id'),
                from: Em.get(data, 'temp_id'),
                source: 'talink'
              }
            }
          });
        Em.run(self, function() {
          var p = this.store.push(this.store.normalize('person', data));
          p.set('owner.source', 'talink');
          this.controller.setProperties({
            step: 'done',
            profile: p
          });
        });
      }).done(function() {
        Em.run(self, 'send', 'loading', false);
      });
    },
    closeAndEdit: function(profile) {
      this.closeTab({ route: 'people.edit', model: profile });
    },
    loadDup: function(type, id) {
      var ctl = this.controller;
      this.store.find('realPerson', id).then(function(rp) {
        if (ctl.get(type + 'SamePerson') !== null)  // only if loading
          ctl.set(type + 'SamePerson', rp.get('defaultProfile'));
      });
    },
    transferToMerge: function(rp) {
      var ctl = this.controller;
      Em.$.ajax({
        method: 'POST',
        url: SL.apiPath + '/talink/encode-data?data=' +
          encodeURIComponent(ctl.get('data')),
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          cvId: rp.get('defaultProfile.id')
        })
      }).then(function(data) {
        ctl.setProperties({
          data: data,
          action: 'update'
        });
      });
    }
  }
});


})();

(function() {

SL.VacancyAddCandidateRoute = Em.Route.extend(
    SL.StaffAccessRouteMixin, SL.TabbedDynamicRouteMixin,
    SL.NewRecordRouteMixin, SL.VacancyRenewRouteMixin,
    SL.MissingRecordRouteMixin, {
  tabName: '<i class="fa fa-upload"></i>',

  afterModel: function(model, transition) {
    this.set('tabItem.title', this.tabName + ' ' + model.get('title.name'));
    this._super.apply(this, arguments);
  },

  setupController: function(ctl, model) {
    this._super.apply(this, arguments);
    if (!model.get('addCandidate'))
      model.set('addCandidate', { status: null });
    if (!ctl.get('newFolder') || ctl.get('newFolder.id'))
      ctl.set('newFolder', this.newRecord('folder', {
        category: 'private'
      }));
    if (!model.get('addCandidate.channel') && model.get('hireAsOwner')) {
      ctl.get('sources.channels').then(function(cs) {
        model.set('addCandidate.channel', cs.get('firstObject.value'));
      });
    }
  },

  updateCandidateCount: function() {
    var ctl = this.controller,
        fid = ctl.get('model.addCandidate.tempList.id');
    if (!fid)
      return ctl.set('candidateCount', 0);
    var self = this;
    this.send('loading', true);
    this.store.query('folderPersonStatus', {
      q: JSON.stringify({
        filters: [{ name: 'folder_id', op: 'eq', val: fid }]
      }),
      results_per_page: 1
    }).then(function(ps) {
      ctl.set('candidateCount', ps.get('meta.numResults'));
    }).finally(function() {
      self.send('loading', false);
    });
  },

  actions: {
    createFolder: function(folder) {
      var self = this;
      folder.save().then(function(f) {
        self.controller.set('newFolder', self.newRecord('folder', {
          name: f.get('name'),
          category: 'private'
        }));
      });
    },
    refreshFolder: function(files) {
      if (files && files.successful) {
        this.get('updates.folder').addObject(
          this.currentModel.get('addCandidate.tempList.id'));
        this.updateCandidateCount();
      }
    },
    import: function(folder, channel, status) {
      var data = {
        source: 'templist',
        temp_id: folder.get('id')
      };
      if (this.currentModel.get('hireAsOwner')) {
        data.channel_id = channel.id;
        data.status = status;
      }
      this.send('loading', true);
      var self = this, store = this.store;
      Em.$.ajax({
        url: SL.apiPath + '/vacancy/' + this.currentModel.get('id') + '/import',
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      }).then(function(data) {
        Em.run(function() {
          var added = (data.add_person || []).map(function(p) {
            return store.push(store.normalize('realPerson', p)).get('name');
          });
          if (added.length)
            self.send('postMessage', {
              type: 'success', info: Em.I18n.t('vacancy.add.added', {
                people: added.join(', '),
                vacancy: self.currentModel.get('title.name')
              })
            });
          var duped = (data.dup_person || []).map(function(p) {
            return store.push(store.normalize('realPerson', p)).get('name');
          });
          if (duped.length)
            self.send('postMessage', {
              type: 'info', info: Em.I18n.t('vacancy.add.duped', {
                people: duped.join(', '),
                vacancy: self.currentModel.get('title.name')
              })
            });
        });
        self.send('loading', false);
      }).fail(function(xhr) {
        SL.onXhrError(xhr);
        self.send('loading', false);
      });
    },
    willTransition: function(transition) {
      this.controller.removeObserver('model.addCandidate.tempList', this,
        'updateCandidateCount');
      this._super(transition);
      return true;
    },
    didTransition: function() {
      this.controller.addObserver('model.addCandidate.tempList', this,
        'updateCandidateCount');
      this._super();
      return true;
    }
  }

});


})();

(function() {

SL.VacancyFindRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedDynamicRouteMixin, SL.VacancyAddRouteMixin,
  SL.PersonCommentsRouteMixin, SL.FavoriteRouteMixin,
  SL.FolderAddRouteMixin, SL.PaginationRouteMixin,
  SL.SelectableProfileUpdatesRouteMixin, SL.ProfileDownloadRouteMixin,
  SL.VacancyRenewRouteMixin, SL.MissingRecordRouteMixin,
  SL.ResumeRouteMixin, SL.ProfileVcStatusRouteMixin, SL.MappingAddRouteMixin,
  SL.SimilarProfileRouteMixin, {
  tabName: '<i class="fa fa-list-ul"></i>',
  logStayed: true,

  queryParams: {
    status: { refreshModel: true },
    channel: { refreshModel: true }
  },

  fetchSummary: function(vacancyId) {
    return this.store.findRecord('vacancy', vacancyId,
        { reload: true, adapterOptions: { extra: ['candidate_summary'] }}
    );
  },

  model: function(params) {
    if (this.currentModel && this.currentModel.id === params.vacancy_id &&
        !this.currentModel.get('candidateSummary.expired'))
      return this.currentModel;
    // Should refresh vacancy / candidate_summary
    return this.fetchSummary(params.vacancy_id);
  },

  afterModel: function(model, transition) {
    this.setupTab(model.id); // As calling _super in model hook
    this.set('tabItem.title', this.tabName + ' ' + model.get('title.name'));
    this._super.apply(this, arguments);
    var params = transition.queryParams, filters = [
      { name: 'vacancy_id', op: 'eq', val: model.id }
    ];

    if (!model.get('candidateSummary'))
      this.fetchSummary(model.id);

    if (params.channel) {
      filters.push({ name: 'status', op: 'is_null' });
      if (params.channel === 'talentPool')
        filters.push({ name: 'sourcing_channel', op: 'is_null' });
      if (params.channel === 'externalReferral')
        filters.push({
          name: 'sourcing_channel', op: 'eq', val: 'external_referral' });
      else if (params.channel === 'socialMedia')
        filters.push({
          name: 'sourcing_channel', op: 'ne', val: 'external_referral' });
      else if (params.channel !== 'all')
        filters.push({
          name: 'sourcing_channel', op: 'eq', val: params.channel });
    } else if (Em.isPresent(params.status)) {
      if (params.status === 'candidates')
        filters.push({ name: 'status', op: 'is_not_null' });
      else if (params.status === 'interview') {
        filters.push({ name: 'status', op: 'gt', val: 1 });
        filters.push({ name: 'status', op: 'lt', val: 10 });
      } else {
        filters.push({ name: 'status', op: 'eq', val: params.status });
      }
    }
    var self = this;
    return this.store.query('vacancyCandidate', {
      q: JSON.stringify({
        filters: filters,
        order_by: [
          { field: 'sort_key', direction: 'desc' },
          { field: 'id', direction: 'desc' }
        ]
      }),
      page: params.page,
      results_per_page: params.pageSize || 15
    }).then(function(vs) {
      var result = model.getWithDefault('searchList', []);
      result.clear().setProperties({
        meta: vs.get('meta'),
        params: params
      });
      model.set('searchList', result);
      result.set('vacancy', model);
      vs.forEach(function(vc) {
        vc.set(
          'candidate.source', 'search-list:' + vc.get('vacancy.id')
        ).resetRpSummary();
      });
      self.loadPartial(result, vs);
    });
  },

  resetController: function(ctl, isExiting) {
    if (isExiting) {
      ctl.setProperties({
        channel: '',
        status: ''
      });
    }
  },

  _getProfile: function() {
    return this.controller.get('selectedItem.candidate.defaultProfile');
  },

  _refreshProfile: function() {
    var vc = this.controller.get('selectedItem');
    if (vc) {
      // Force refresh vc record for view event notification
      this.store.findRecord('vacancyCandidate', vc.id, {
        adapterOptions: { extra: 'from_bounty' }});
      this.tryLoadVcStatus(vc.get('candidate'));
      this.tryLoadSimilarProfile(vc.get('candidate'));
      this.controller.set('finalRound', vc.get('interviewRounds') || 0);
    }
    return this._super.apply(this, arguments);
  },

  batchUpdateVacacny: function(vs, action) {
    var self = this;
    this.send('loading', true);
    return Em.RSVP.all(vs.invoke(action)).then(function() {
      self.set('currentModel.candidateSummary.expired', true).refresh();
    }).catch(function(e) {
      vs.invoke('rollback');
          });
  },

  loadProfile: function(masked, profile) {
    var unmasked = this.store.push(this.store.normalize('person', profile)),
        vc = masked.vc;
    // update controllers and routes
    vc.set('candidate.defaultProfile', unmasked);
    vc.notifyPropertyChange('candidate');
    var profileRoute = this.container.lookup('route:people.profile');
    profileRoute.replaceMaskedTab(masked, unmasked);
  },

  actions: {
    didTransition: function() {
      if (this.get('parameters').hasFeature('interview_mandatory') &&
          this.get('parameters.kpi.interview')) {
        var self = this;
        Em.run.scheduleOnce('afterRender', function() {
          var $statusBtn = Em.$('#set-candidate-status .dropdown-toggle');
          var opened, ctl = self.get('controller');
          $statusBtn.off('click.status_check').on(
              'click.status_check', function(e) {
            Em.run(function() {
              ctl.set('selectionInterviewed', false);
            });
            if (opened) return;
            e.stopPropagation();
            var candidates = ctl.get('multiSelected');
            Em.$.ajax({
              url: SL.apiPath + '/vacancy_candidate/status_check',
              data: { ids: candidates.mapBy('id').join(',') },
              dataType: 'json'
            }).then(function(data) {
              if (data.status === 'interviewed')
                Em.run.next(function() {
                  ctl.set('selectionInterviewed', true);
                });
              opened = true;
              $statusBtn.trigger('click').parent().one(
                  'hidden.bs.dropdown', function() {
                opened = false;
              });
            }, function(xhr) {
            });
          });
        });
      }
      return this._super();
    },
    saveSearchList: function(model) {
      this.batchUpdateVacacny(
        this.controller.get('multiSelected').filterBy('isDirty'), 'save');
    },
    deleteSearchList: function() {
      var ctl = this.get('controller'),
          items = ctl.get('multiSelected').
            filterBy('sourcingChannel', null).toArray();
      this.batchUpdateVacacny(items, 'destroyRecord').then(function() {
        if (items.contains(ctl.get('selectedItem')))
          ctl.set('selectedItem', null);
      });
    },
    openMyVacancy: function(peopleToAdd) {
      this._super(peopleToAdd, 'candidate');
    },
    openMyFolder: function(peopleToAdd) {
      this._super(peopleToAdd, 'candidate');
    }
  },

  setupController: function(ctl, model) {
    this._super(ctl, model.get('searchList'));
    this.controllerFor('people.edit').setProperties({
      isEmbedded: true,
      editFlag: false
    });
    if (ctl.get('selectedItem'))
      ctl.updateProfile();
    else
      ctl.set('selectedItem', ctl.get('model.firstObject'));
  }

});


})();

(function() {

SL.VacancyImportRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, SL.PaginationRouteMixin, {
  tabNameTranslation: 'vacancy.import.tab_title',

  epochId: '',

  model: function(param) {
    var ctl = this.controllerFor('vacancyImport');
    if (ctl.get('step') !== 'list')
      return;

    if (ctl.get('model') && ctl.get('model') === this.get('currentModel') &&
      ctl.get('page') === param.page && ctl.get('pageSize') === param.pageSize)
      return this.get('currentModel');

    var self = this, store = this.store;
    return Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + ctl.get('board.site') +
        '/list/fetch?' + Em.$.param({
          results_per_page: param.pageSize,
          page: param.page
      })
    }).then(function(data) {
      return Em.run(self, function() {
        if (Em.get(data, 'num_results') === 0) {
          this.send('postMessage', { type: 'error',
            info: Em.I18n.t('vacancy.import.nothing_to_import') });
          this.reset();
        }
        var result = (data.objects || []).map(function(v) {
          return store.push(store.normalize('foreignVacancy', v));
        });
        return result.set('meta', {
          numResults: Em.get(data, 'num_results'),
          totalPages: Em.get(data, 'total_pages')
        });
      });
    }).fail(function() {
      self.handleExecption();
    });
  },

  // STEP 1
  initImport: function(board, forced, lastError) {
    var self = this, firstRun = Em.isEmpty(this.get('epochId'));

    var params = {
      blocking_timeout: 5,
      epoch_id: this.get('epochId')
    };
    if (forced)
      params.force = true;
    if (lastError)
      params.last_error = lastError;

    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/init?' + Em.$.param(params),
      dataType: 'json',
      beforeSend: function(xhr) { xhr.isPolling = true; }
    }).done(function(result) {
      Em.run(self, function() {
        var epochId = Em.get(result, 'epoch_id');
        if (firstRun)
          this.set('epochId', epochId);
        else if (this.get('epochId') !== epochId)
          // Cancel if it's different run
          return;

        switch (Em.get(result, 'status')) {
          case 'ok':
            if (!firstRun) {
              this.closeInterruptionModals();
              this.list(board);
            }
            else {
              // For first run with access, offer to switch account.
              this.openModal('#switch-account');
            }
            break;
          case 'error':
            this.handleInterruption(result);
            this.initImport(board, false, Em.get(result, 'error_type'));
            break;
          case 'cancel':
            this.closeInterruptionModals();
            break;
        }
      });
    }).fail(function() {
      self.handleExecption();
    });
  },

  // STEP 2
  list: function(board, lastError) {
    var self = this;
    var params = {
      blocking_timeout: 5,
      epoch_id: this.get('epochId'),
      epoch_type: board.get('epochType')
    };
    if (lastError)
      params.last_error = lastError;

    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/list?' + Em.$.param(params),
      dataType: 'json',
      beforeSend: function(xhr) { xhr.isPolling = true; }
    }).done(function(result) {
      Em.run(self, function() {
        var epochId = Em.get(result, 'epoch_id');
        if (this.get('epochId') !== epochId)
          // Cancel if it's different run
          return;
        switch (Em.get(result, 'status')) {
          case 'ok':
            this.closeInterruptionModals();
            this.controller.set('step', 'list');
            this.refresh();
            break;
          case 'error':
            this.handleInterruption(result);
            this.list(board, Em.get(result, 'error_type'));
            break;
          case 'cancel':
            this.closeInterruptionModals();
            break;
        }
      });
    }).fail(function() {
      self.handleExecption();
    });
  },

  // STEP 3
  import: function(board, lastError) {
    var self = this, params = {
      blocking_timeout: 5,
      epoch_id: this.get('epochId'),
      epoch_type: board.get('epochType')
    };
    if (lastError)
      params.last_error = lastError;

    var ctl = this.controller;
    if (!this.get('importDataSent')) {
      var data = ctl.getPostData();
      data.data = (data.data || []).mapBy('id');
      return Em.$.ajax({
        url: SL.apiPath + '/import_vacancy/' + board.get('site') +
          '/import?' + Em.$.param(params),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      }).done(function() {
        Em.run(self, function() {
          this.set('importDataSent', true);
          this.import(board);
        });
      }).fail(function() {
        self.handleExecption();
      });
    }

    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/import?' + Em.$.param(params),
      dataType: 'json',
      beforeSend: function(xhr) { xhr.isPolling = true; }
    }).done(function(result) {
      Em.run(self, function() {
        var epochId = Em.get(result, 'epoch_id');
        if (this.get('epochId') !== epochId)
          // Cancel if it's different run
          return;
        switch (Em.get(result, 'status')) {
          case 'ok':
            this.send('loading', false);
            if (this.get('parameters.enterprise.bindWechat'))
              this.openModal('#notify-wechat');
            else
              this.closeInterruptionModals();
            ctl.setProperties({
              importProgress: Em.get(result, 'meta'),
              step: 'import'
            });
            this.set('updates.vacancies', ctl.get('importProgress.done') > 0);
            break;
          case 'error':
            if (Em.get(result, 'error_type') === 'login_error')
              // Dirty solution for the login failed / task restart senario,
              // to resend import selection data.
              this.set('importDataSent', false);
            this.handleInterruption(result);
            this.import(board, Em.get(result, 'error_type'));
            break;
          case 'cancel':
            this.closeInterruptionModals();
            break;
        }
      });
    }).fail(function() {
      self.handleExecption();
    });
  },

  handleExecption: function() {
    Em.run(this, function() {
      this.closeInterruptionModals();
      this.send('loading', false);
      this.send('postMessage', { type: 'error',
        info: Em.I18n.t('vacancy.import.try_again') });
      this.reset();
    });
  },

  connect: function() {
    var self = this, ctl = this.controller,
        data = ctl.getProperties('member', 'username', 'password');
    if (ctl.get('showCaptcha'))
      // Captcha has to been sent if required, null keeps Ajax honoring it
      data.captcha = ctl.get('captcha') || null;

    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + ctl.get('board.site') +
        '/login?' + Em.$.param({ epoch_id: this.get('epochId') }),
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
    }).fail(function() {
      self.handleExecption();
    });
  },

  // Kick confirm
  confirm: function(board) {
    var params = { epoch_id: this.get('epochId') };
    var self = this;
    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/confirm?' + Em.$.param(params)
    }).fail(function() {
      self.handleExecption();
    });
  },

  // Confirm to send sms
  sendSms: function(board) {
    var self = this, params = { epoch_id: this.get('epochId') };
    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/sms_confirm?' + Em.$.param(params),
      type: 'POST'
    }).fail(function() {
      self.handleExecption();
    });
  },

  doSmsVerify: function(board, code) {
    var self = this, params = { epoch_id: this.get('epochId') };
    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/sms_verify?' + Em.$.param(params),
      type: 'POST',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        verify_code: code
      })
    }).fail(function() {
      self.handleExecption();
    });
  },

  handleInterruption: function(result) {
    var ctl = this.controller, $model,
        interruption = Em.get(result, 'error_type'),
        msg = Em.get(result, 'error_message');

    switch (interruption) {
      case 'need_captcha':
        ctl.set('showCaptcha', 'epoch_id=' +
        this.get('epochId') + '&i=' + encodeURIComponent(msg));
      /* falls through */
      case 'need_password':
        ctl.set('loginRequired', true);
        if (($model = this.openModal('#login-jobboard')))
          $model.on('hidden.bs.modal', function() {
            Em.run(function() {
              ctl.setProperties({
                showCaptcha: null,
                loginRequired: false,
                loginError: null,
                captcha: []
              });
            });
          });
        break;
      case 'need_sms_verify':
        var verifyInfo = Em.get(result, 'meta');
        if (msg !== ctl.get('smsVerify.round')) {
          this.send('loading', false);
          verifyInfo.round = msg;
          ctl.set('smsVerify', verifyInfo).resendSmsCountdown();
          ctl.set('smsVerifyCode', '');
        }
        this.openModal('#sms-verify');
        break;
      case 'need_confirm':
        this.openModal('#kick-confirm');
        break;
      case 'login_error':
        this.send('loading', false);
        if (msg.indexOf('sms_') === 0) {
          // Sms verify errors
          ctl.setProperties({
            'smsVerify.error': msg
          });
        } else {
          ctl.setProperties({
            loginError: 'vacancy.import.' + msg,
            password: null,
            captcha: []
          });
        }
        break;
      case 'get_vacancy_details':
        ctl.set('importProgress', Em.get(result, 'meta'));
        this.openModal('#import-progress');
        break;
    }
  },

  refreshCaptcha: function(board) {
    this.set('controller.captchaLoading', true);
    Em.$.getJSON(SL.apiPath + '/import_vacancy/' + board.get('site') +
      '/refresh_captcha?epoch_id=' + this.get('epochId'));
  },

  reopenTab: function(tab) {
    this._super.apply(this, arguments);
    this.reset();
  },

  setStep: function(step) {
    switch (step) {
      case 'jobBoard':
        this.setProperties({
          epochId: '',
          currentModel: null
        });
        break;
    }
  },

  openModal: function(modal) {
    var $modal = Em.$(modal);
    if (!$modal.hasClass('in')) {
      this.closeInterruptionModals();
      this.send('loading', false);
      return $modal.modal().one('shown.bs.modal', function() {
        $modal.find('[data-toggle="tooltip"]').tooltip();
      });
    }
  },

  closeInterruptionModals: function() {
    Em.$('#login-jobboard').modal('hide');
    Em.$('#kick-confirm').modal('hide');
    Em.$('#import-progress').modal('hide');
    Em.$('#sms-verify').modal('hide');
  },

  reset: function() {
    this.setStep('jobBoard');
    this.controller.setStep('jobBoard');
  },

  cancel: function(board, reset) {
    var params = { epoch_id: this.get('epochId') };
    var self = this;
    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/cancel?' + Em.$.param(params)
    }).done(function() {
      Em.run(self, function() {
        this.closeInterruptionModals();
        this.send('loading', false);
        if (reset !== false) this.reset();
      });
    }).fail(function() {
      self.handleExecption();
    });
  },

  setNotify: function(board, method) {
    Em.$.ajax({
      url: SL.apiPath + '/import_vacancy/' + board.get('site') +
        '/set_notify?method=' + method
    });
  },

  actions: {
    init: function(board, forced) {
      this.closeInterruptionModals();
      this.reset();
      this.send('loading', true);
      this.initImport(board, forced);
    },
    list: function(board) {
      this.send('loading', true);
      this.list(board);
    },
    setStep: function(step) {
      this.setStep(step);
    },
    cancel: function(board, reset) {
      this.send('loading', true);
      this.cancel(board, reset);
    },
    connect: function() {
      this.send('loading', true);
      this.connect();
    },
    kickConfirm: function(board, answer) {
      this.send('loading', true);
      this[answer ? 'confirm' : 'cancel'](board);
    },
    refreshCaptcha: function(board) {
      this.refreshCaptcha(board);
    },
    sendSms: function(board) {
      this.send('loading', true);
      this.sendSms(board);
    },
    doSmsVerify: function(board, code) {
      this.send('loading', true);
      this.doSmsVerify(board, code);
    },
    import: function(board) {
      this.send('loading', true);
      this.set('importDataSent', false);
      this.import(board);
    },
    setNotify: function(board, method) {
      if (!this.get('session.account.weixinToken')) {
        this.send('bindWechat', this, function(result) {
          if (result !== 'success') return;
          var self = this;
          this.get('session').restore().then(function() {
            self.setNotify(board, method);
          });
        });
      } else {
        this.setNotify(board, method);
      }
    }
  }
});


})();

(function() {

SL.VacancyIndexRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedRouteMixin, SL.FiltersRouteMixin, SL.PaginationRouteMixin,
  SL.SelectableProfileUpdatesRouteMixin, SL.VacancyCommentsRouteMixin,
  SL.ChannelSelectionRouteMixin, SL.VacancyRenewRouteMixin, {

  tabNameTranslation: 'vacancy.tab.search',

  filterModel: function(page, pageSize, transition) {
    var store = this.store,
        self = this,
        indexCtl = this.controllerFor('vacancy.index');

    // Do nothing if default filter is not set
    if (!indexCtl.get('defaultFilterSet'))
      indexCtl.set('defaultFilterSet', true).addDefaultFilter();

    var queryData = {
      options: {
        from: (page - 1) * pageSize,
        size: pageSize,
        sort: [{ id: 'desc' }]
      }
    };
    indexCtl.applyFilter(queryData);

    return Em.$.ajax({
      method: 'POST',
      url: SL.apiPath + '/search/vacancy',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(queryData)
    }).then(function(data) {
      var result = [];

      Em.run(function() {
        result.set('meta',
          { numResults: data.total, totalPages: data.num_pages });
        self.set('updates.vacancies', false);
      });

      if (!data.total)
        transition.send('postMessage', { type: 'info', info:
          'No vacancy found in terms of your criteria.' });
      else
        self.loadPartial(result, data.hits.mapBy('data'), function(v) {
          return store.push(store.normalize('vacancy', v));
        });

      return result;
    });
  },

  setupController: function(ctl, model) {
    this._super(ctl, model);
    this.controllerFor('vacancy.profile').setProperties({
      isEmbedded: true
    });
    if (!ctl.get('selectedItem'))
      ctl.set('selectedItem', model.get('firstObject'));
    else
      ctl.updateProfile();
  },

  reopenTab: function() {
    this._super();
    this.set('controller.defaultFilterSet', null);
  },

  batchUpdateVacacny: function(vacancies, action) {
    var self = this;
    this.send('loading', true);
    return Em.RSVP.all(vacancies.invoke(action)).catch(function(e) {
      vacancies.invoke('rollback');
            return Em.RSVP.reject(e);
    }).finally(function() {
      self.get('controller').clearMultiSelection();
      self.send('loading', false);
    });
  },

  actions: {
    batchSetStatus: function(status) {
      var self = this;
      this.batchUpdateVacacny(
        this.get('controller.multiSelected').
          filterBy('isDirty'), 'save').then(function() {
        var filter = self.get('controller.filterExists.vacancyStatus');
        if (filter && filter.get('values').indexOf(status) < 0)
          // Changing to status not being filtered, notify for update.
          self.set('updates.vacancies', true);
        self.send('postMessage', { type: 'success', info:
          Em.I18n.t('vacancy.message.update_status_succeed') });
      }, function() {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('vacancy.message.update_status_failed') });
      });
    },
    batchSetExtra: function(field, value) {
      var self = this;
      this.batchUpdateVacacny(
        this.get('controller.multiSelected').
          filterBy('isDirty'), 'save').then(function() {
        var filter = self.get('controller.filters').find(function(item) {
          return item.constructor.type === 'vacancyExtraField' &&
            item.get('field.key') === field.get('key');
        });
        if (!!filter)
          // Changing to status not being filtered, notify for update.
          self.set('updates.vacancies', true);
        self.send('postMessage', { type: 'success', info:
          Em.I18n.t('vacancy.message.update_status_succeed') });
      }, function() {
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('vacancy.message.update_status_failed') });
      });
    }

  }
});


})();

(function() {

function updateNotification(notifyName, method) {
  var notifyCurrent = this.currentModel.get(notifyName),
    notifyRecord = this.controller.get('model._' + notifyName);
  if (notifyCurrent && !notifyRecord) {
    this.currentModel.get('notifications').addObject(
      this.newRecord('vacancyNotification', {
        method: method,
        event: 'applied'
      }));
  } else if (!notifyCurrent && notifyRecord) {
    this.currentModel.get('notifications').removeObject(notifyRecord);
  }
}

SL.VacancyBaseRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.NewRecordRouteMixin, SL.ChannelSelectionRouteMixin, {

  setupController: function(ctl, model) {
    ctl.set('extra', Em.copy(model.get('extra')));
    ctl.set('editFlag', this.get('isEdit'));
    if (!ctl.get('model.newTitle.content') || ctl.get('model.newTitle.id'))
      ctl.set('model.newTitle', this.newRecord('title'));
    if (!ctl.get('model.newCompany.content') || ctl.get('model.newCompany.id'))
      ctl.set('model.newCompany', this.newRecord('company'));
    if (ctl.get('model.content') !== model) {
      this._super(ctl, model);
      ctl.set('model.notifyEmail', !!ctl.get('model._notifyEmail'));
      ctl.set('model.notifyWechat', !!ctl.get('model._notifyWechat'));
      ctl.set('model.notifyEmailContact',
        !!ctl.get('model._notifyEmailContact'));
      ctl.send('toggleAlerts', 'volatile');
    }
  },

  saveNewCompany: function() {
    var vacancy = this.controller.get('model');
    if (vacancy && vacancy.get('createNewCompany'))
      return vacancy.get('newCompany.content').save();
    return Em.RSVP.resolve();
  },

  actions: {
    saveVacancy: function() {
      var self = this;
      this.send('loading', true);
      updateNotification.call(this, 'notifyWechat', 'wechat');
      updateNotification.call(this, 'notifyEmail', 'email');
      updateNotification.call(this, 'notifyEmailContact', 'email_contact');

      this.saveNewCompany().then(function() {
        self.currentModel.set('extra', self.controller.get('extra'));
        return self.currentModel.save().then(function() {
          Em.run.next(function() {
            if (self.isEdit) {
              self.send('postMessage', { type: 'success', info:
                Em.I18n.t('vacancy.message.update_vacancy_successfully',
                  { title: self.currentModel.get('title.name') }) });
            } else {
              self.set('updates.vacancies', true);
              self.send('postMessage', { type: 'success', info:
                Em.I18n.t('vacancy.message.create_vacancy_successfully',
                  { title: self.currentModel.get('title.name') }) });
            }
          });
          self.updateChannelStatus().then(function() {
            if (self.isEdit) {
              self.closeTab();
            } else {
              self.closeTab({ route: 'vacancy.profile',
                model: self.currentModel });
            }
          }).catch(SL.bumpRsvpError(function() {
            self.send('postMessage', { type: 'error', info:
              Em.I18n.t('vacancy.channel.update_failed') });
          })).finally(function() {
            self.send('loading', false);
          });
        });
      }).catch(SL.bumpRsvpError(function() {
        self.send('loading', false);
        self.send('postMessage', { type: 'error', info:
          Em.I18n.t('vacancy.message.failed_create_vacancy') });
      }));
    }
  }
});

function addNotification (vacancy, method) {
  vacancy.get('notifications').addObject(
    this.newRecord('vacancyNotification', {
      method: method,
      event: 'applied'
    })
  );
}

function addCaseFrom(vacancy) {
  if (SL.parameters.kpi.newVacancy && !vacancy.get('ownership'))
    vacancy.set('caseFrom', this.get('session.account'));
}

SL.VacancyNewRoute = SL.VacancyBaseRoute.extend(SL.TabbedRouteMixin, {
  tabNameTranslation: 'vacancy.tab.create',
  queryParams: {
    copyFrom: {
      refreshModel: true
    }
  },
  beforeModel: function(transition) {
    this._super.apply(this, arguments);
    var copyFrom = transition.queryParams.copyFrom;
    var ctl = this.controllerFor('vacancyNew');
    if (this.currentModel && copyFrom && ctl.get('copyFrom') !== copyFrom) {
      if (!window.confirm(
        Em.I18n.t('vacancy.create_edit.confirm_to_recreate')))
        return transition.abort();
      this.currentModel = null;
    }
  },
  model: function(params) {
    if (this.isEdit) return this._super.apply(this, arguments);
    if (!this.currentModel || this.currentModel.id) {
      if (params.copyFrom) {
        var self = this;
        return this.store.findRecord('vacancy', params.copyFrom).then(
            function(model) {
          var data = {};
          [
            'title', 'clientCompany', 'hrContact', 'jobFunction',
            'workLocation', 'workingCompany', 'salaryFrom', 'salaryTo',
            'tags', 'headCount', 'salaryNegotiable', 'companyDesc',
            'highlight', 'responsibility', 'requirements', 'ownership',
            'jobType', 'requiredExperience', 'expireAt'
          ].forEach(function(k) {
            data[k] = model.get(k);
          });
          var v = self.newRecord('vacancy', data);
          model.get('notifications').forEach(function(n) {
            addNotification.call(self, v, n.get('method'));
          });
          addCaseFrom.call(self, v);
          return v;
        });
      } else {
        var vacancy = this.newRecord('vacancy'),
          ownership = !SL.parameters.vacancy.clientCompanySelectable;
        vacancy.setProperties({
          ownership: ownership,
          _revealCompany: ownership
        });
        addNotification.call(this, vacancy, 'email');
        addCaseFrom.call(this, vacancy);
        return vacancy;
      }
    }
    return this.currentModel;
  },

  actions: {
    cancelEdit: function() {
      this.currentModel = null;
      this.refresh();
    }
  }
});

SL.VacancyEditRoute = SL.VacancyBaseRoute.extend(SL.TabbedDynamicRouteMixin,
    SL.ConfirmationRouteMixin, SL.ProfileUpdatesRouteMixin,
    SL.MissingRecordRouteMixin, {
  isEdit: true,
  tabName: '<i class="fa fa-pencil"></i>',
  controllerName: 'vacancyNew',

  preventUnload: function() {
    return this._super() || this.controller.get('vacancyChannels.changed') ||
      ['notifyWechat', 'notifyEmail', 'notifyEmailContact'].find(
        function(method) {
          var notify = !!this.controller.get('model.' + method);
          return notify !==
              Em.isPresent(this.controller.get('model._' + method));
        }, this);
  },

  afterModel: function(model) {
    this.set('tabItem.title', this.tabName + ' ' + model.get('title.name'));
    return this._super.apply(this, arguments);
  },
  setupController: function(ctl, model) {
    this._super(ctl, model);
    ctl.setProperties({
      editFlag: true
    });
  },
  renderTemplate: function() {
    this.render('vacancy.new');
  },
  reopenTab: function(tab) {
    this._super.apply(this, arguments);
    Em.set(tab, 'goto.model.showMoreOptions', undefined);
  },
  actions: {
    cancelEdit: function() {
      this.controller.set('extra', Em.copy(this.currentModel.get('extra')));
      return this._super.apply(this, arguments);
    }
  }
});


})();

(function() {

SL.VacancyProfileRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
    SL.TabbedDynamicRouteMixin, SL.ProfileUpdatesRouteMixin,
    SL.VacancyCommentsRouteMixin, SL.VacancyRenewRouteMixin,
    SL.MissingRecordRouteMixin, {
  tabName: '<i class="i i-stack"></i>',

  afterModel: function(model) {
    this.set('tabItem.title', this.tabName + ' ' + model.get('title.name'));
    this._super.apply(this, arguments);
  },

  setupController: function(controller, model) {
    this._super(controller, model);
    controller.setProperties({
      isEmbedded: false,
      vacancy: model
    });
    this.set('vacancyId', model.id);
  }

});


})();

(function() {

SL.VacancyRecommendRoute = Em.Route.extend(SL.StaffAccessRouteMixin,
  SL.TabbedDynamicRouteMixin, SL.VacancyAddRouteMixin,
  SL.PersonCommentsRouteMixin, SL.FavoriteRouteMixin,
  SL.FolderAddRouteMixin, SL.PaginationRouteMixin,
  SL.SelectableProfileUpdatesRouteMixin, SL.ProfileDownloadRouteMixin,
  SL.VacancyRenewRouteMixin, SL.MissingRecordRouteMixin,
  SL.ResumeRouteMixin, SL.ProfileVcStatusRouteMixin, SL.MappingAddRouteMixin,
  SL.SimilarProfileRouteMixin, {

  tabName: '<i class="fa fa-binoculars"></i>',
  logStayed: true,

  afterModel: function(model, transition) {
    this.set('tabItem.title', this.tabName + ' ' + model.get('title.name'));
    this._super.apply(this, arguments);

    var params = transition.queryParams, self = this, store = this.store;
    return Em.$.ajax({
      url: SL.apiPath + '/vacancy/' + model.id + '/recommendation',
      data: {
        page: params.page,
        results_per_page: params.pageSize || 15
      }
    }).then(function(data) {
      var result = model.getWithDefault('recommends', []);
      Em.run(function() {
        result.clear().set('meta', {
          numResults: data.num_results, totalPages: data.total_pages
        });
        model.set('recommends', result);
        result.set('vacancy', model);
        self.loadPartial(result, data.objects, function(p) {
          return store.push(
            store.normalize('realPerson', p)).resetVcStatus().set(
              'source', 'vacancy-recommend:' + model.id);
        });
      });
    });
  },

  _getProfile: function() {
    return this.controller.get('selectedItem.defaultProfile');
  },

  _refreshProfile: function() {
    var self = this, model = this._super.apply(this, arguments);
    return model && model.then(function(p) {
      self.tryLoadVcStatus(p.get('ownerId'));
      self.tryLoadSimilarProfile(p.get('ownerId'));
      return p;
    });
  },

  loadProfile: function(masked, profile) {
    // noop
  },

  setupController: function(ctl, model) {
    this._super(ctl, model.get('recommends'));
    this.controllerFor('people.edit').setProperties({
      isEmbedded: true,
      editFlag: false
    });
    if (ctl.get('selectedItem'))
      ctl.updateProfile();
    else
      ctl.set('selectedItem', ctl.get('model.firstObject'));
  }

});


})();

(function() {

function linkedinComment(v) {
  var companyDesc = v.get('companyDesc') || '';
  if (companyDesc) {
    companyDesc = '为' + companyDesc;
  }
  var msg = '【' + v.get('jobFunction.fullName') + '】 ' +
    '我们' + companyDesc +
    '诚招一位' + v.get('title.name') +
    '，请点击下图右边的链接了解此职位更多信息。';
  if (SL.parameters.socialNetwork.linkedin.homepage)
    msg += '更多职位及市场信息请关注 ' +
      SL.parameters.socialNetwork.linkedin.homepage;
  return msg;
}

function weiboComment(v) {
  var companyDesc = v.get('companyDesc') || '';
  if (companyDesc) {
    companyDesc = '为' + companyDesc;
  }
  return '【' + v.get('jobFunction.fullName') + '】 ' +
    '我们' + companyDesc +
    '诚招一位' + v.get('title.name') +
    ', 详情请点击链接' + SL.parameters.mobileSite +
    '/#/vacancy/' + v.get('id') + '?s=weibo';
}

function othersComment(v) {
  return SL.parameters.mobileSite +
    '/#/vacancy/' + v.get('id') + '?s=others';
}

SL.VacancyRoute = Em.Route.extend({
  actions: {
    syncLinkedin: function(v, comment, shareTo) {
      if (!SL.parameters.socialNetwork.linkedin) return;
      var self = this;
      Em.$.ajax({
        url: SL.apiPath + '/linkedin/shares',
        type: 'POST',
        data: JSON.stringify({
          content: {
            title: v.get('title.name'),
            description: v.get('companyDesc'),
            'submitted-url': SL.parameters.mobileSite + '/?vid=' + v.get('id') +
              '&s=linkedin#/vacancy/' + v.get('id')
          },
          comment: comment ? comment : linkedinComment(v),
          visibility: {
            code: 'anyone'
          },
          share_to: shareTo || 'people'
        }),
        contentType: 'application/json; charset=utf-8',
        statusCode: {
          201: function() {
            self.send('postMessage', { type: 'success', info:
              Em.I18n.t('vacancy.message.successfully_publish_to_linkedin') });
          }
        }
      }).fail(function(xhr) {
        if (xhr.status !== 403)
          SL.onXhrError(xhr);
        self.send('postMessage', { type: 'error',
          info: Em.I18n.t('vacancy.message.failed_publish_to_linkedin') });
      });
    },

    syncWeibo: function(v, comment) {
      if (!SL.parameters.socialNetwork.weibo) return;
      var self = this;
      Em.$.ajax({
        url: SL.apiPath + '/weibo/shares',
        type: 'POST',
        data: {
          status: comment ? comment : weiboComment(v),
        },
        statusCode: {
          200: function() {
            self.send('postMessage', { type: 'success', info:
              Em.I18n.t('vacancy.message.successfully_publish_to_weibo') });
          }
        }
      }).fail(function(xhr) {
        var result = xhr.responseJSON,
            msg = Em.I18n.t('vacancy.message.failed_publish_to_weibo');
        switch (result && result.error_code) {
          case 10017:
            msg = Em.I18n.t('vacancy.message.missing_weibo_domain');
            break;
          case 21322: // invalid token
          case 21327: // token expire
          case 21315: // token expire
            /* falls through */
            break;
          default:
            SL.onXhrError(xhr);
        }
        self.send('postMessage', { type: 'error', info: msg });
      });
    },

    copyToClipboard: function(msg) {
      if (window.clipboardData) {
        window.clipboardData.clearData();
        window.clipboardData.setData('Text', msg);
        this.send('postMessage', { type: 'success',
          info: Em.I18n.t('vacancy.profile.copy_to_clipboard_success') });
      }
    },

    shareToWechat: function() {
      Em.$('#share-to-wechat').modal();
    },

    shareToLinkedin: function() {
      Em.$('#share-to-linkedin').modal();
      var ctl = this.controllerFor('vacancyProfile');
      ctl.set('linkedinMsg', linkedinComment(ctl.get('vacancy')));
    },

    shareToWeibo: function() {
      Em.$('#share-to-weibo').modal();
      var ctl = this.controllerFor('vacancyProfile');
      ctl.set('weiboMsg', weiboComment(ctl.get('vacancy')));
    },

    shareToOthers: function() {
      Em.$('#share-to-others').modal();
      var ctl = this.controllerFor('vacancyProfile');
      ctl.set('othersMsg', othersComment(ctl.get('vacancy')));
    },

    multiShare: function(vacancies) {
      if (!vacancies) return;
      if (vacancies.length > 20)
        return Em.$('#share-to-wechat-multi-invalid').modal();
      Em.$('#share-to-wechat-multi').modal();
    },

    generateWechatNews: function(vacancies) {
      var self = this, ctl = this.controllerFor('wechat-news-publish');
      this.send('loading', true);
      Em.$.get(SL.apiPath + '/vacancy/wechat_publish?' + Em.$.param({
        vacancy_ids: vacancies.map(function(v) { return Em.get(v, 'id'); })
      }, true)).then(function(sites) {
        Em.run(function() {
          ctl.set('model', sites.map(function(s) {
            return self.store.push(self.store.normalize('wechatSites', s));
          }));
        });
        var $modal = Em.$('#generate-wechat-news').modal();
        $modal.one('shown.bs.modal', function() {
          $modal.find('.slim-scroll').slimScroll({ height: 'auto' });
        });
      }, SL.onXhrError).always(function() {
        Em.run(function() {
          self.send('loading', false);
        });
      });
    },

    doGenerateWechatNews: function(vacancies) {
      var self = this, ctl = this.controllerFor('wechat-news-publish');
      this.send('loading', true);
      Em.$.ajax({
        url: SL.apiPath + '/vacancy/wechat_publish?' + Em.$.param({
            vacancy_ids: vacancies.map(function(v) { return Em.get(v, 'id'); })
          }, true),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          publish_to: ctl.get('multiSelected').mapBy('siteId')
        })
      }).then(function() {
        var $modal = Em.$('#generate-wechat-news').modal();
        $modal.modal('hide');
        Em.run(function() {
          self.send('postMessage', { type: 'success',
            info: Em.I18n.t('vacancy.share.generate_succeeded') });
        });
      }, SL.onXhrError).always(function() {
        Em.run(function() {
          self.send('loading', false);
        });
      });
    }

  }
});


})();

(function() {

SL.WechatComponentRoute = Em.Route.extend({
  actions: {
    goAuth: function() {
      var domain = location.host + location.pathname.slice(0, -1),
          path = location.href;
      path = path.substring(path.indexOf(domain) + domain.length + 1);
      location.replace(
        location.pathname + 'api/wechat_service/component/auth?' +
        'redirect_uri=' + encodeURIComponent(location.href));
    }
  }
});


})();

(function() {

SL.Router.reopen({
  rootURL: window.location.pathname
});

SL.Router.map(function() {
  this.route('login');
  this.route('reset-password');
  this.route('change-password');
  this.route('staff-access');
  this.resource('people', function() {
    this.route('new');
    this.route('profile', { path: ':person_id' });
    this.route('edit', { path: ':person_id/edit' });
    this.route('history', { path: ':person_id/history' });
    this.route('reporting', { path: ':person_id/reporting' });
    this.route('merge');
    this.route('parse');
  });
  this.route('real-person', {
    path: '/real-person/:real_person_id' });
  this.route('my-resume');
  this.resource('candidate', function() {
  });
  this.resource('ledger', function() {
    this.route('shared');
  });
  this.resource('resume', { path: '/resume/:id' });
  this.resource('company', function() {
    this.route('new');
    this.route('profile', { path: ':company_id' });
    this.route('edit', { path: ':company_id/edit' });
  });
  this.resource('vacancy', function() {
    this.route('new');
    this.route('profile', { path: ':vacancy_id' });
    this.route('edit', { path: ':vacancy_id/edit' });
    this.route('find', { path: ':vacancy_id/find' });
    this.route('recommend', { path: ':vacancy_id/recommend' });
    this.route('add-candidate', { path: ':vacancy_id/add' });
    this.route('import');
  });
  this.resource('folder', function() {
    this.route('favorite');
    this.route('mapping');
  });
  this.resource('kpi', function() {
    this.route('detail', { path: ':user_id' });
  });
  this.resource('settings', function() {
    this.route('social');
    this.route('privacy');
  });
  this.route('register', { path: '/register/:register_id' });
  this.route('wechat-component');
  this.route('reports');
  this.route('activities');
  this.route('tl-import');
});


})();

(function() {


// Modal behavior
Em.$(function() {
  Em.$.fn.modal.Constructor.DEFAULTS.backdrop = 'static';
});

// Turning off ajax cache (for IE not to cache get / head requests)
Em.$.ajaxSetup({ cache: false });


})();